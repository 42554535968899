import React from 'react';

import * as com from "../Common.js";
import ContactCustomer from '../Tools/ContactCustomer/ContactCustomer';
import InfoModal from '../CommonUIComponents/InfoModal';
import * as clmn from "../Store/pipelineColumns";
import Spinner from '../CommonUIComponents/Spinner'

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import Calendar from "react-calendar";
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { connect } from 'react-redux';


var Holidays = require('date-holidays')

const { SearchBar, ClearSearchButton } = Search;
const { ExportCSVButton } = CSVExport;


const EmailModal = props => {

  let tmp = com.unCamelCase(props.dataField);
  let key = tmp.charAt(0).toUpperCase() + tmp.slice(1);

  return (<Modal
    show={true}
    centered
    onHide={props.hideModal}
    size="lg"
  >
    <Modal.Header closeButton onClick={props.hideModal}>
      <Modal.Title id="contained-modal-title-vcenter">
        Send customer an email
      </Modal.Title>
    </Modal.Header>
    <Modal.Body >
      <ContactCustomer subject={props.emailTemplates[key].Subject} body={props.emailTemplates[key].Content} customerID={props.customerID} />
    </Modal.Body>
  </Modal>)

}

const mapStateToProps = (state) => {
  return {
    pipelineConfiguration: state.pipelineConfiguration
  }
}


const mapDispatchToProps = (dispatch) => ({
  toogleColumn: (column, payload) => {
    dispatch(clmn.ToogleColumn(column, payload))
  },
  InitializeLayout: (configuration) => {
    dispatch(clmn.initializeLayout(configuration))
  }
});

class Pipeline extends React.Component {
  constructor(props) {
    super(props);
    this.headerStyle = { width: '170px' };
    this.dateInputRegEx = /^(((0?[13578]|1[02])[/.-](0?[1-9]|[12]\d|3[01])[/.-]((19|[2-9]\d)\d{2}),?\s(0?[0-9]|1[0-2]):(0?[0-9]|[1-59]\d)(:(0[0-9]|[1-59]\d))?\s(AM|am|PM|pm))|((0?[13456789]|1[012])[/.-](0?[1-9]|[12]\d|30)[/.-]((19|[2-9]\d)\d{2}),?\s(0?[0-9]|1[0-2]):(0?[0-9]|[1-59]\d)(:(0[0-9]|[1-59]\d))?\s(AM|am|PM|pm))|((0?2)[/.-](0?[1-9]|1\d|2[0-8])[/.-]((19|[2-9]\d)\d{2}),?\s(0?[0-9]|1[0-2]):(0?[0-9]|[1-59]\d)(:(0[0-9]|[1-59]\d))?\s(AM|am|PM|pm))|((0?2)[/.-](29)[/.-]((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)),?\s(0?[0-9]|1[0-2]):(0?[0-9]|[1-59]\d)(:(0[0-9]|[1-59]\d))?\s(AM|am|PM|pm)))$/g;
    this.maxDate = new Date()
    this.maxDate.setFullYear(this.maxDate.getFullYear() + 10);
    this.dateSortFunction = (a, b, order) => {
      if (a === '') {
        a = '01/01/1000'
      }
      if (b === '') {
        b = '01/01/1000'
      }
      if (a === '01/01/1000' && b === '01/01/1000') {
        b = '01/02/1000'
      }

      let A = a.split('/')
      let aMonth = A[0]
      let aDate = A[1]
      let aYear = A[2]

      let B = b.split('/')
      let bMonth = B[0]
      let bDate = B[1]
      let bYear = B[2]

      A = parseInt(aYear + aMonth + aDate)
      B = parseInt(bYear + bMonth + bDate)

      if (order === 'asc') {
        return (A - B) < 0 ? -1 : 1
      } else {
        return (A - B) < 0 ? 1 : -1
      }
    };

    this.emailTemplates = {
      'Clear To Close': { Subject: '', Content: '' },
      'Loan Funded': { Subject: '', Content: '' },
      'Conditions Submitted': { Subject: '', Content: '' }
    }

    this.state = {
      borrowers: [],
      fetched: false,
      redirect: "",
      showFilters: false,
      infoModal: false,
      infoModalTitle: '',
      infoModalBody: '',
      showEmailModal: false,
      emailModalDatafiled: "",
      emailModalID: "",
      modal: false,
      modalTitle: '',
      modalType: '',
      modalTextAreaValue: '',
      initialModalTextAreaValue: '',
      initialModalDateValue: '',
      selectedCustomerID: '',
      selectedLoanID: '',
      selectedDataField: '',
      filterForFilters: "",
      borrowersColumns: [
        {
          dataField: 'loanID',
          text: 'loanID',
          hidden: true,
          csvExport: false
        }, {
          dataField: 'customerID',
          text: 'CustomerID',
          hidden: true,
          csvExport: false
        }, {
          dataField: 'email',
          text: 'Email',
          hidden: true,
        }, 
        // {
        //   dataField: 'lenderCaseNumber',
        //   text: "Lender case number",
        //   sort: true,
        //   editable: false,
        //   headerStyle: this.headerStyle,
        //   hidden: !this.props.pipelineConfiguration['lenderCaseNumber']
        // }, 
        {
          dataField: 'borrowersName',
          text: "Borrower's name",
          sort: true,
          sortFunc: com.sortFullNameByLastAndFirst,
          editable: false,
          headerStyle: this.headerStyle,
          style: (cell, row) => {
            let backgroundColor
            return {
              overflowY: 'auto',
              backgroundColor,
              position: 'sticky',
              left: 0,
              top: 0
            };
          },
          classes: 'hover',
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              this.switchBorrower('/app/borrowerforlp/' + row.loanID)
              // this.switchBorrower('/app/borrower/' + row.loanID)
            },
          },
          formatter: (cell, row) => {
            if (row.newNotifications) {
              return (
                <span>
                  <span style={{ backgroundColor: 'green', padding: '5px', borderRadius: '5px' }}>
                    <i className="far fa-bell"></i>
                  </span>  {cell}
                </span>)
            } else {
              return <span>{cell}</span>
            }
          }
        }, {
          dataField: 'status',
          text: "Status",
          sort: true,
          sortFunc: (a, b, order) => {

            let result = order === 'asc' ? com.CurrentStatus[a] - com.CurrentStatus[b] : com.CurrentStatus[b] - com.CurrentStatus[a];
            return result;
          },
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          hidden: !this.props.pipelineConfiguration['status'],
          ...(!this.props.past ? {
            editor: {
              type: Type.SELECT,
              options: (() => {
                let out = []
                let printOptions = (o, index) => {
                  out.push({ value: o, label: com.splitStatusByWords(o) })
                }
                com.loanstatuses.map(printOptions)
                return out
              })()
            },
          } : { editable: false }),
          filter: textFilter({
            delay: '0ms',
            onFilter: (filterVal, data) => {
              if (filterVal) {
                return data.filter(d => com.splitStatusByWords(d.status).toLowerCase().includes(filterVal.toLowerCase()));
              }
              return data;
            }
          }),
          formatter: (cell, row) => {
            let element = this.state.borrowers.find(e => e.customerID === row.customerID)
            return element.status ? <span>{com.splitStatusByWords(element.status)}</span> : null
          },
        }, {
          dataField: 'substatus',
          text: "Substatus",
          sort: true,
          headerStyle: this.headerStyle,
          editable: false,
          formatter: (cell, row) => {
            let element = this.state.borrowers.find(e => e.customerID === row.customerID)
            return (
              <div>
                {com.interviewStepToSubstatus(com.safeParseInt(element.substatus))}
              </div>
            )
          },
          hidden: !this.props.pipelineConfiguration['substatus']
        }, {
          dataField: 'errors',
          text: "Errors",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          style: { overflowY: 'auto', whiteSpace: 'pre-wrap' },
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "textarea")
              }
            },
          }),
          editable: false,
          formatter: (cell, row) => {
            let element = this.state.borrowers.find(e => e.customerID === row.customerID)
            return (
              <div style={{ height: '21px' }}>
                {element.errors}
              </div>
            )
          },
          hidden: !this.props.pipelineConfiguration['errors']
        }, 
        // {
        //   dataField: 'comments',
        //   text: "Comments",
        //   sort: true,
        //   classes: `${this.props.past ? '' : 'divlink'}`,
        //   headerStyle: this.headerStyle,
        //   formatter: (cell, row) => {
        //     let element = this.state.borrowers.find(e => e.customerID === row.customerID)
        //     return (
        //       <div style={{ height: '21px' }}>
        //         {element.comments}
        //       </div>
        //     )
        //   },
        //   hidden: !this.props.pipelineConfiguration['comments'],
        //   style: { overflowY: 'auto', whiteSpace: 'pre-wrap' },
        //   editable: false,
        //   ...(!this.props.past && {
        //     events: {
        //       onClick: (e, column, columnIndex, row, rowIndex) => {
        //         this.showModal(column, row, "textarea")
        //       }
        //     }
        //   }),
        // }, 
        {
          dataField: 'accountCreated',
          text: "Account created",
          editable: false,
          sort: true,
          sortFunc: this.dateSortFunction,
          headerStyle: this.headerStyle,
          hidden: !this.props.pipelineConfiguration['accountCreated']
        }, {
          dataField: 'lender',
          text: "Lender",
          editable: false,
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          style: { overflowY: 'auto' },
          formatter: (cell, row) => {
            let element = this.state.borrowers.find(e => e.customerID === row.customerID)
            return <div style={{ height: '21px' }}>{element.lender}</div>
          },
          hidden: !this.props.pipelineConfiguration['lender'],
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              if (row.lender !== '') {
                this.showInfoModal(column.text, row.lender)
              }
            }
          }
        }, {
          dataField: 'interviewSubmission',
          text: "Date of interview submission",
          sort: true,
          editable: false,
          headerStyle: this.headerStyle,
          classes: `${this.props.past ? '' : 'divlink'}`,
          hidden: !this.props.pipelineConfiguration['interviewSubmission'],
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.setState({ modalTextAreaValue: row['interviewSubmission'], initialModalTextAreaValue: row['interviewSubmission'] })
                this.showModal(column, row, "text")
              }
            },
          }),
        }, {
          dataField: 'estimatedClosingDate',
          text: "Estimated closing date",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['estimatedClosingDate']
        }, {
          dataField: 'lockRequested',
          text: "Lock Requested",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          editable: false,
          headerStyle: this.headerStyle,
          style: { color: 'blue', fontWeight: 'bolder' },
          hidden: !this.props.pipelineConfiguration['lockRequested'],
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.setState({ modalTextAreaValue: row['lockRequested'], initialModalTextAreaValue: row['lockRequested'] })
                this.showModal(column, row, "text")
              }
            },
          }),
        }, {
          dataField: 'doLpa',
          text: "DO/LPA",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          ...(!this.props.past ? {
            editor: {
              type: Type.SELECT,
              options: [{
                value: 'DO',
                label: 'DO'
              }, {
                value: 'LPA',
                label: 'LPA'
              }, {
                value: 'Manual Underwriting',
                label: 'Manual Underwriting'
              }]
            },
          } : { editable: false }),
          formatter: (cell, row) => {
            let element = this.state.borrowers.find(e => e.customerID === row.customerID)
            return <span>{element.doLpa}</span>
          },
          hidden: !this.props.pipelineConfiguration['doLpa']
        }, {
          dataField: 'loanRunThroughDO',
          text: "Loan ran through DO/LPA",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['loanRunThroughDO']
        }, {
          dataField: 'openedEscrow',
          text: "Opened escrow",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['openedEscrow']
        }, {
          dataField: 'escrowFeesReceived',
          text: "Escrow fees received",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['escrowFeesReceived']
        }, {
          dataField: 'loanRegisteredWithLender',
          text: "Loan registered with lender",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['loanRegisteredWithLender']
        }, {
          dataField: 'LERequested',
          text: "LE requested",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['LERequested']
        }, {
          dataField: 'threeDeathDays',
          text: "TRID-3 DAY DEADLINE",
          sort: true,
          editable: false,
          headerStyle: (colum, colIndex) => {
            return { width: '150px', color: 'red' };
          },
          style: { color: 'red' },
          hidden: !this.props.pipelineConfiguration['threeDeathDays']
        }, {
          dataField: 'initialDisclosresIntentProceedBack',
          text: "Initial disclosres intent to proceed back",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['initialDisclosresIntentProceedBack']
        }, {
          dataField: 'prelimReceived',
          text: "Prelim received",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['prelimReceived']
        }, {
          dataField: 'documentsRequested',
          text: "Documents requested",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['documentsRequested']
        }, {
          dataField: 'documentsReceived',
          text: "Documents received",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['documentsReceived']
        }, {
          dataField: 'loanProcessed',
          text: "Loan processed",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['loanProcessed']
        }, {
          dataField: 'rateLocked',
          text: "RATE LOCKED",
          classes: `${this.props.past ? '' : 'divlink'}`,
          sort: true,
          editable: false,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          headerStyle: (colum, colIndex) => {
            return { width: '150px', color: 'red' };
          },
          style: { color: 'red' },
          hidden: !this.props.pipelineConfiguration['rateLocked']
        }, {
          dataField: 'lockExpires',
          text: "Lock expires",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: (colum, colIndex) => {
            return { width: '150px', color: 'red' };
          },
          style: { color: 'red' },
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['lockExpires']
        }, {
          dataField: 'confirmedLockWithBorrower',
          text: "Confirmed lock with borrower(s)",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['confirmedLockWithBorrower']
        }, {
          dataField: 'loanPackageSubmitted',
          text: "Loan package submitted",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['loanPackageSubmitted']
        }, {
          dataField: 'submittedToUW',
          text: "Submitted to UW",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['submittedToUW']
        }, {
          dataField: 'loanDecisioned',
          text: "Loan decisioned",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['loanDecisioned']
        }, {
          dataField: 'piwAce',
          text: "PIW/ACE",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          ...(!this.props.past ? {
            editor: {
              type: Type.SELECT,
              options: [{
                value: 'Yes',
                label: 'Yes'
              }, {
                value: 'No',
                label: 'No'
              }]
            },
          } : { editable: false }),
          formatter: (cell, row) => {
            let element = this.state.borrowers.find(e => e.customerID === row.customerID)
            return <span>{element.piwAce}</span>
          },
          hidden: !this.props.pipelineConfiguration['piwAce']
        }, {
          dataField: 'conditionsRequested',
          text: "Conditions requested",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['conditionsRequested']
        }, {
          dataField: 'appraisalOrdered',
          text: "Appraisal ordered",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['appraisalOrdered']
        }, {
          dataField: 'appraisalBack',
          text: "Appraisal back",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          hidden: !this.props.pipelineConfiguration['appraisalBack'],
          ...(!this.props.past ? {
            editor: {
              type: Type.SELECT,
              options: [{
                value: 'Appraised value good',
                label: 'Appraised value good'
              }, {
                value: 'Appraised value not good',
                label: 'Appraised value not good'
              }]
            },
          } : { editable: false }),
          formatter: (cell, row) => {
            let element = this.state.borrowers.find(e => e.customerID === row.customerID)
            let style = element.appraisalBack === 'Appraised value not good' ? { color: 'red', fontWeight: 'bolder' } : {}
            return <span style={style}>{element.appraisalBack}</span>
          },
        }, {
          dataField: 'appraisalReceived',
          text: "Appraisal received",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['appraisalReceived']
        }, {
          dataField: 'conditionsSubmitted',
          text: "Conditions submitted",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['conditionsSubmitted']
        }, {
          dataField: 'ptdsSubmitted',
          text: "PTD's submitted",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['ptdsSubmitted']
        }, {
          dataField: 'ptdsSignedOff',
          text: "PTD's signed off",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['ptdsSignedOff']
        }, {
          dataField: 'clearToClose',
          text: "Clear to close",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['clearToClose']
        }, {
          dataField: 'CDOrdered',
          text: "CD Ordered",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['CDOrdered']
        }, {
          dataField: 'CDOut',
          text: "CD out",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['CDOut']
        }, {
          dataField: 'CDAcknowledged',
          text: "CD Acknowledged",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['CDAcknowledged']
        }, {
          dataField: 'docsOrdered',
          text: "Docs Ordered",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['docsOrdered']
        }, {
          dataField: 'docsOut',
          text: "Docs out",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['docsOut']
        }, {
          dataField: 'docsScheduled',
          text: "Docs scheduled",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['docsScheduled']
        }, {
          dataField: 'docsSigned',
          text: "Docs signed",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['docsSigned']
        }, {
          dataField: 'docsBack',
          text: "Docs back",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['docsBack']
        }, {
          dataField: 'ptfsSubmitted',
          text: "PTF's submitted",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['ptfsSubmitted']
        }, {
          dataField: 'ptfsSignedOff',
          text: "PTF's signed off",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          editable: false,
          hidden: !this.props.pipelineConfiguration['ptfsSignedOff']
        }, {
          dataField: 'loanFunded',
          text: "Loan funded",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          hidden: !this.props.pipelineConfiguration['loanFunded'],
          editable: false
        }, {
          dataField: 'disbursementDate',
          text: "Disbursement date",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          hidden: !this.props.pipelineConfiguration['disbursementDate'],
          editable: false
        }, {
          dataField: 'loanAmount',
          text: "Loan amount",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          ...(!this.props.past ? {
            validator: (newValue, row, column) => {
              if (isNaN(newValue)) {
                return {
                  valid: false,
                  message: 'Loan Amount should be numeric'
                };
              }
              return true;
            },
          } : { editable: false }),
          hidden: !this.props.pipelineConfiguration['loanAmount']
        }, {
          dataField: 'totalBrokerCompensation',
          text: "Total broker compensation",
          sort: true,
          headerStyle: this.headerStyle,
          editable: false,
          hidden: !this.props.pipelineConfiguration['totalBrokerCompensation']
        }, {
          dataField: 'scheduledDisbursementDate',
          text: "Scheduled Disbursement Date",
          sort: true,
          classes: `${this.props.past ? '' : 'divlink'}`,
          headerStyle: this.headerStyle,
          sortFunc: this.dateSortFunction,
          ...(!this.props.past && {
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.showModal(column, row, "date")
              }
            },
          }),
          hidden: !this.props.pipelineConfiguration['scheduledDisbursementDate'],
          editable: false
        }
      ]
    }
  }

  componentDidMount() {
    try {
      let config = JSON.parse(sessionStorage.getItem("pipelineConfig"))
      if (config !== null) {
        this.props.InitializeLayout(config)
        var newTableColumns = this.state.borrowersColumns.map((val) => {
          let newValue = config[val.dataField]
          if (!config[val.dataField] !== val.hidden) {
            if (!['loanID', 'customerID', 'email', 'borrowersName'].includes(val.dataField)) {
              val.hidden = !newValue
              this.props.toogleColumn(val.dataField, newValue)
            }
          }
          return val;
        });

        this.setState({ borrowersColumns: newTableColumns })
      }
    }
    catch (e) {
      // nothing
    }
    this.reload();

    let token = sessionStorage.getItem("ZeitroA")

    fetch('/los/getemailtemplates', {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        'X-Keys': JSON.stringify(Object.keys(this.emailTemplates)),
        'X-IsServerSide': false
      }
    }).then(response => {
      if (response.status !== 200) {
        alert('Looks like there was a problem. Status Code: ' +
          response.status);
        return;
      }
      response.json().then(js => {
        let tmp = JSON.parse(js.Emails);
        if (JSON.stringify(tmp) !== '{}') {
          this.emailTemplates = JSON.parse(js.Emails)
        }
      })
    })

  }

  reload = () => {

    let transform = (borrower) => {
      let comments = ''
      let lender = ''
      let LERequested = ''
      let initialDisclosresIntentProceedBack = ''
      let loanRunThroughDO = ''
      let openedEscrow = ''
      let escrowFeesReceived = ''
      let prelimReceived = ''
      let documentsRequested = ''
      let documentsReceived = ''
      let loanProcessed = ''
      let lockExpires = ''
      let confirmedLockWithBorrower = ''
      let loanPackageSubmitted = ''
      let loanDecisioned = ''
      let piwAce = ''
      let appraisalOrdered = ''
      let ptdsSignedOff = ''
      let CDOrdered = ''
      let CDOut = ''
      let docsOrdered = ''
      let docsOut = ''
      let docsScheduled = ''
      let docsSigned = ''
      let docsBack = ''
      let ptfsSubmitted = ''
      let ptfsSignedOff = ''
      let loanFunded = ''
      let errors = ''
      let loanRegisteredWithLender = ''
      let threeDeathDays = ''
      let interviewSubmission = 'not submitted yet'
      let doLpa = ''
      let loanAmount = ''
      let totalBrokerCompensation = ''
      let appraisalReceived = ''
      let ptdsSubmitted = ''
      let estimatedClosingDate = ''
      let submittedToUW = ''
      let lockRequested = ''
      let clearToClose = ''
      let CDAcknowledged = ''
      let appraisalBack = ''
      let conditionsRequested = ''
      let conditionsSubmitted = ''
      let disbursementDate = ''
      let scheduledDisbursementDate = ''

      try {
        let pipe = JSON.parse(borrower.PipelineJSON)
        errors = pipe.errors || ''
        loanRegisteredWithLender = pipe.loanRegisteredWithLender || ''
        comments = pipe.comments || ''
        LERequested = pipe.LERequested || ''
        initialDisclosresIntentProceedBack = pipe.initialDisclosresIntentProceedBack || ''
        loanRunThroughDO = pipe.loanRunThroughDO || ''
        openedEscrow = pipe.openedEscrow || ''
        escrowFeesReceived = pipe.escrowFeesReceived || ''
        prelimReceived = pipe.prelimReceived || ''
        documentsRequested = pipe.documentsRequested || ''
        documentsReceived = pipe.documentsReceived || ''
        loanProcessed = pipe.loanProcessed || ''
        lockExpires = pipe.lockExpires || ''
        confirmedLockWithBorrower = pipe.confirmedLockWithBorrower || ''
        loanPackageSubmitted = pipe.loanPackageSubmitted || ''
        loanDecisioned = pipe.loanDecisioned || ''
        piwAce = pipe.piwAce || ''
        appraisalOrdered = pipe.appraisalOrdered || ''
        ptdsSignedOff = pipe.ptdsSignedOff || ''
        CDOrdered = pipe.CDOrdered || ''
        CDOut = pipe.CDOut || ''
        docsOrdered = pipe.docsOrdered || ''
        docsOut = pipe.docsOut || ''
        docsScheduled = pipe.docsScheduled || ''
        docsSigned = pipe.docsSigned || ''
        docsBack = pipe.docsBack || ''
        ptfsSubmitted = pipe.ptfsSubmitted || ''
        ptfsSignedOff = pipe.ptfsSignedOff || ''
        loanFunded = pipe.loanFunded || ''
        threeDeathDays = pipe.threeDeathDays || ''
        interviewSubmission = pipe.interviewSubmission || 'not submitted yet'
        doLpa = pipe.doLpa || ''
        loanAmount = pipe.loanAmount || ''
        totalBrokerCompensation = pipe.totalBrokerCompensation || ''
        appraisalReceived = pipe.appraisalReceived || ''
        ptdsSubmitted = pipe.ptdsSubmitted || ''
        estimatedClosingDate = pipe.estimatedClosingDate || ''
        submittedToUW = pipe.submittedToUW || ''
        lockRequested = pipe.lockRequested || ''
        clearToClose = pipe.clearToClose || ''
        CDAcknowledged = pipe.CDAcknowledged || ''
        appraisalBack = pipe.appraisalBack || ''
        conditionsRequested = pipe.conditionsRequested || ''
        conditionsSubmitted = pipe.conditionsSubmitted || ''
        disbursementDate = pipe.disbursementDate || ''
        scheduledDisbursementDate = pipe.scheduledDisbursementDate || ''
      } catch (e) {
        // nothing
      }

      if (borrower.Lender !== '' && borrower.Lender !== '[]') {
        let tmp = JSON.parse(borrower.Lender)
        if (tmp.length > 1) {
          lender = tmp.map(l => l.Lender).join(', ')
        } else {
          lender = tmp[0].Lender
        }
      }

      if (loanAmount !== '' && totalBrokerCompensation === '') {
        this.tableValuesChangeHandler('', Number.parseFloat(loanAmount / 100 + 500).toFixed(2), borrower.ID, borrower.LoanID, "totalBrokerCompensation")
      }


      let AccountCreated = this.transformDateRepresentation(borrower.AccountCreated)

      let lockRequestedDate
      if (lockRequested !== '') {
        lockRequestedDate = new Date(lockRequested)
        lockRequested = lockRequestedDate.toLocaleString()
      }



      if (threeDeathDays === '' && interviewSubmission !== 'not submitted yet' && borrower.Address !== '') {
        threeDeathDays = this.calculateThreeDeathDays(interviewSubmission)
        this.tableValuesChangeHandler('', threeDeathDays, borrower.ID, borrower.LoanID, "threeDeathDays")
      }

      if (threeDeathDays !== '' && interviewSubmission === 'not submitted yet') {
        this.tableValuesChangeHandler(threeDeathDays, '', borrower.ID, borrower.LoanID, "threeDeathDays")
      }
      let rateLocked = borrower.LockDate || 'Not locked yet'

      let lenderCaseNumber = borrower.Lendercasenumber

      return {
        customerID: borrower.ID, loanID: borrower.LoanID, newNotifications: borrower.NewNotifications, email: borrower.Email,
        borrowersName: borrower.FirstName + " " + borrower.LastName, errors: errors, accountCreated: AccountCreated,
        loanPurpose: borrower.LoanPurpose, status: borrower.Status, substatus: borrower.Substatus,
        typeOfMortgage: borrower.TypeOfMortgage, loanSize: borrower.LoanSize,
        interviewSubmission, rateLocked: rateLocked, loanRegisteredWithLender: loanRegisteredWithLender, comments,
        lender, LERequested, initialDisclosresIntentProceedBack, loanRunThroughDO,
        openedEscrow, escrowFeesReceived, prelimReceived, documentsRequested,
        documentsReceived, loanProcessed, lockExpires, confirmedLockWithBorrower,
        loanPackageSubmitted, loanDecisioned, piwAce, appraisalOrdered, threeDeathDays,
        ptdsSignedOff, CDOrdered, CDOut, docsOrdered, docsOut, docsScheduled, docsSigned, docsBack, ptfsSubmitted, ptfsSignedOff, loanFunded, doLpa,
        loanAmount, totalBrokerCompensation, appraisalReceived, ptdsSubmitted, estimatedClosingDate, submittedToUW,
        lockRequested, lenderCaseNumber, clearToClose, CDAcknowledged, appraisalBack, conditionsRequested, conditionsSubmitted,
        disbursementDate, scheduledDisbursementDate
      }
    }

    let token = sessionStorage.getItem("ZeitroA")
    fetch('/los/lp/loanlist', {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        'X-Past': this.props.past
      }
    }).then(
      response => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
          return;
        }
        // Examine the text in the response
        response.json().then(js => {

          if (js.Status !== "OK") {
            console.log("Error: " + js.Text)
          } else {
            let docs = js.Loans.map(s => transform(s))
            this.setState({ borrowers: docs })
          }
        });
      }
    )
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      })
      .finally(() => {
        this.setState({ fetched: true })
      })
      ;
  }

  showModal = (column, row, type) => {
    this.setState({
      modal: true,
      modalTitle: column.text,
      modalType: type,
      selectedCustomerID: row.customerID,
      selectedLoanID: row.loanID,
      selectedDataField: column.dataField
    })
    if (type === 'textarea') {
      this.setState({
        modalTextAreaValue: row[column.dataField],
        initialModalTextAreaValue: row[column.dataField],
      })
    }
    if (type === 'date') {
      this.setState({
        initialModalDateValue: row[column.dataField],
      })
    }
  }

  hideModal = () => {
    this.setState({
      modal: false,
      modalTitle: '',
      modalType: '',
      modalTextAreaValue: '',
      initialModalTextAreaValue: '',
      initialModalDateValue: '',
      selectedCustomerID: '',
      selectedLoanID: '',
      selectedDataField: '',
    })
  }

  showInfoModal = (title, body) => {
    this.setState({ infoModal: true, infoModalBody: body, infoModalTitle: title })
  }

  hideInfoModal = () => {
    this.setState({ infoModal: false, infoModalBody: '', infoModalTitle: '' })
  }

  promptEmail = (dataField, customerID) => {
    if (['loanFunded', 'clearToClose', 'conditionsSubmitted'].includes(dataField)) {
      this.setState({ showEmailModal: true, emailModalDatafiled: dataField, emailModalID: customerID })
    }
  }

  hideEmailModalHandler = () => {
    this.setState({ showEmailModal: false })
  }

  filterForFiltersChangeHandler = e => {
    this.setState({ filterForFilters: e.target.value })
  }

  clearFilterForFilters = () => {
    this.setState({ filterForFilters: "" })
  }

  tableValuesChangeHandler = (oldValue, newValue, customerID, loanID, dataField) => {
    if (oldValue === newValue)
      return
    let token = sessionStorage.getItem("ZeitroA")
    let value = newValue

    if (dataField === 'status') {
      let borrowersCopy = this.state.borrowers.map(b => {
        if (b.customerID === customerID) {
          b.status = newValue
        }
        return b
      })
      this.setState({ borrowers: borrowersCopy })
    }

    if (dataField === 'comments') {
      if (!/\nComment was left: \d{1,2}\/\d{1,2}\/\d{4}, \d{1,2}:\d{2}:\d{2} (AM|PM)$/.test(value) && value !== '') {
        newValue += '\nComment was left: ' + new Date().toLocaleString('en-US')
      }
    }
    if (dataField === 'comments' || dataField === 'errors') {
      value = newValue.replaceAll("'", "''").replaceAll('"', `\\"`).replaceAll("\\", "\\\\").replaceAll("\n", "\\n").replace(/\t/g, '\\t').replace(/\v/g, '\\v')
    }

    if (dataField === 'interviewSubmission') {
      let borrowersCopy = this.state.borrowers.map(b => {
        if (b.customerID === customerID) {
          if ((oldValue === 'not submitted yet' || oldValue === '') && (newValue !== '' || newValue !== 'not submitted yet')) {
            return { ...b, threeDeathDays: this.calculateThreeDeathDays(newValue) }
          } else if (oldValue !== 'not submitted yet' && (newValue === '' || newValue === 'not submitted yet')) {
            return { ...b, threeDeathDays: '', interviewSubmission: "not submitted yet" }
          }
        }
        return b
      })
      this.setState({ borrowers: borrowersCopy })
    }

    let body = { CustomerID: customerID, LoanID: loanID, Key: dataField, Value: value }
    fetch('/los/updatepipeline', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    }).then(response => {
      if (response.status !== 200) {
        console.log('Looks like there was a problem. Status Code: ' +
          response.status);
        return;
      }
      let borrowersCopy = this.state.borrowers.map(b => {
        if (b.customerID === customerID) {
          if (dataField === 'loanAmount') {
            return { ...b, [dataField]: newValue, totalBrokerCompensation: Number.parseFloat(newValue / 100 + 500).toFixed(2) }
          } else {
            return { ...b, [dataField]: newValue }
          }
        }
        return b
      })
      this.setState({ borrowers: borrowersCopy })
      this.promptEmail(dataField, customerID)
    })
  }

  columnToggle = (df) => {
    var newTableColumns = this.state.borrowersColumns.map((val) => {
      if (val.dataField === df) {
        let newValue = !val.hidden
        val.hidden = newValue
        this.props.toogleColumn(df, !newValue)
      }
      return val;
    });
    this.setState({ borrowersColumns: newTableColumns }, () => sessionStorage.setItem("pipelineConfig", JSON.stringify(this.props.pipelineConfiguration)))
  }

  showHideAllColumns = (showHide) => {
    let newValue
    if (showHide === 'hide') {
      newValue = true
    } else if (showHide === 'show') {
      newValue = false
    }
    var newTableColumns = this.state.borrowersColumns.map((val) => {
      if (!['loanID', 'customerID', 'email', 'borrowersName'].includes(val.dataField)) {
        val.hidden = newValue
        this.props.toogleColumn(val.dataField, !newValue)
      }
      return val;
    });
    this.setState({ borrowersColumns: newTableColumns }, () => sessionStorage.setItem("pipelineConfig", JSON.stringify(this.props.pipelineConfiguration)))
  }


  textAreaChangeHandler = (e) => {
    this.setState({ modalTextAreaValue: e.target.value })
  }


  transformDateRepresentation = (dateString) => {
    let date_ = new Date(dateString)
    let month = date_.getMonth() + 1
    if (month < 10) {
      month = '0' + month
    }
    let date = date_.getDate()
    if (date < 10) {
      date = '0' + date
    }
    return `${month}/${date}/${date_.getFullYear()}`
  }

  calculateThreeDeathDays = (interviewSubmission) => {
    let interviewSubmissionDate
    if (interviewSubmission !== 'not submitted yet') {
      interviewSubmissionDate = new Date(interviewSubmission)
      interviewSubmission = interviewSubmissionDate.toLocaleString()
    }

    let hd = new Holidays()
    hd.init('US')

    let holidays = ["New Year's Day", "Washington's Birthday", "Memorial Day", "Independence Day", "Independence Day (substitute day)", "Labor Day",
      "Columbus Day", "Veterans Day", "Thanksgiving Day", "Christmas Day (substitute day)", "Christmas Day"]

    let threeDeathDays = new Date(interviewSubmissionDate)
    let dow = interviewSubmissionDate.getDay()
    if (dow === 4 || dow === 5 || dow === 6) {
      let counter = 4
      if ((threeDeathDays.getHours() > 17) || (threeDeathDays.getHours() === 17 && threeDeathDays.getMinutes() > 0) ||
        (threeDeathDays.getHours() === 17 && threeDeathDays.getMinutes() === 0 && threeDeathDays.getSeconds() > 0)) {
        counter++
      }
      for (let i = 0; i < counter; i++) {
        if (i === 0) {
          threeDeathDays.setDate(interviewSubmissionDate.getDate() + 1)
          let isHoliday = hd.isHoliday(threeDeathDays)
          if (isHoliday !== false) {
            if (holidays.includes(isHoliday.name)) {
              if (isHoliday.name === 'Christmas Day (substitute day)') {
                counter--
              }
              counter++
            }
          }
        } else {
          threeDeathDays.setDate(threeDeathDays.getDate() + 1)
          let isHoliday = hd.isHoliday(threeDeathDays)
          if (isHoliday !== false) {
            if (holidays.includes(isHoliday.name)) {
              if (isHoliday.name === 'Christmas Day (substitute day)' && (counter - i) > 1) {
                counter--
              }
              if (isHoliday.name === 'Independence Day (substitute day)') {
                counter--
              }
              counter++
            }
          }
        }
      }
    } else {
      let counter = 3
      if ((threeDeathDays.getHours() > 17) || (threeDeathDays.getHours() === 17 && threeDeathDays.getMinutes() > 0) ||
        (threeDeathDays.getHours() === 17 && threeDeathDays.getMinutes() === 0 && threeDeathDays.getSeconds() > 0)) {
        counter++
      }
      for (let i = 0; i < counter; i++) {
        if (i === 0) {
          threeDeathDays.setDate(interviewSubmissionDate.getDate() + 1)
          let isHoliday = hd.isHoliday(threeDeathDays)
          if (isHoliday !== false) {
            if (holidays.includes(isHoliday.name)) {
              if (isHoliday.name === 'Christmas Day (substitute day)') {
                counter--
              }
              counter++
            }
          }
        } else {
          threeDeathDays.setDate(threeDeathDays.getDate() + 1)
          let isHoliday = hd.isHoliday(threeDeathDays)
          if (isHoliday !== false) {
            if (holidays.includes(isHoliday.name)) {
              if (isHoliday.name === 'Christmas Day (substitute day)' && (counter - i) > 1) {
                counter--
              }
              if (isHoliday.name === 'Independence Day (substitute day)') {
                counter--
              }
              counter++
            }
          }
        }
      }
    }
    if (threeDeathDays.getDay() === 0) {
      threeDeathDays.setDate(threeDeathDays.getDate() + 1)
    }


    return this.transformDateRepresentation(threeDeathDays)
  }

  switchBorrower = (where) => {
    this.setState({ redirect: "" })
    sessionStorage.setItem("state", '{}')
    sessionStorage.setItem("originalstate", '{}')
    window.location.href = where
  }


  render() {
    let result = []
    let tmp = []
    let counter = 1
    for (let i = 0; i < this.state.borrowersColumns.length; i++) {
      if (this.state.borrowersColumns[i].dataField !== 'loanID' && this.state.borrowersColumns[i].dataField !== 'customerID' && this.state.borrowersColumns[i].dataField !== 'email' && this.state.borrowersColumns[i].dataField !== "borrowersName") {
        if (this.state.filterForFilters === "" || this.state.borrowersColumns[i].text.toLowerCase().includes(this.state.filterForFilters.toLowerCase())) {
          tmp.push(this.state.borrowersColumns[i])
        }
        if (counter % 5 === 0) {
          result.push(tmp)
          tmp = []
        }
        counter++
        if (i === this.state.borrowersColumns.length - 1) {
          // kostil 
          tmp.push({})
          result.push(tmp)
        }
      }
    }

    return (
      <>
        {this.state.borrowers.length === 0 && !this.state.fetched && <Spinner addSpinnerClass={true} />}
        {this.state.borrowers.length === 0 && this.state.fetched && <h2 className='text-center mt-4'>No loans has been assigned to you.</h2>}
        {this.state.borrowers.length !== 0 && this.state.fetched &&
          <ToolkitProvider
            bootstrap4
            keyField='customerID'
            data={this.state.borrowers}
            columns={this.state.borrowersColumns}
            search
            exportCSV={{
              fileName: 'Loan officer pipeline.csv'
            }}
          >
            {
              props => {
                return (
                  <div>
                    <SearchBar {...props.searchProps} />
                    <ClearSearchButton {...props.searchProps} />
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Button
                        onClick={() => this.setState({ showFilters: !this.state.showFilters })}
                        variant="link"
                        size="sm"
                        className='button-link'
                      >
                        {this.state.showFilters ? <i className="fas fa-arrow-circle-up"></i> : <i className="fas fa-arrow-circle-down"></i>}
                        {this.state.showFilters ? "Hide filters" : "Show filters"}
                      </Button>
                    </div>
                    {this.state.showFilters &&
                      <>
                        <hr style={{ borderTop: "2px solid rgba(0,0,0,.1)" }} />
                        <Form.Group controlId="filterForFilters">
                          <Form.Control style={{ display: 'inline-block', width: '30%' }} placeholder="Search" type="text" onChange={this.filterForFiltersChangeHandler} value={this.state.filterForFilters} />
                          <Button variant="light" style={{ background: 'transparent', border: 'none' }} onClick={this.clearFilterForFilters}>Clear</Button>
                        </Form.Group>
                        {result.map((rows, j) => (
                          <Row>
                            {rows.map(columns => ({
                              ...columns
                            }))
                              .map((column, i) => {
                                if (typeof column.text !== 'undefined') {
                                  return (
                                    <Col key={i}>
                                      <input onChange={() => this.columnToggle(column.dataField)} type="checkbox" id={column.dataField} name={column.dataField} checked={this.props.pipelineConfiguration[column.dataField]} />
                                      <label htmlFor={column.dataField} style={{ marginLeft: '3px' }}>{column.text}</label>
                                    </Col>
                                  )
                                } else {
                                  return (
                                    <>
                                      <Col key={i}>
                                      </Col>
                                      <Col key={i + 1}>
                                        <Button className='button-custom' onClick={() => this.showHideAllColumns('hide')}>Hide all</Button>
                                      </Col>
                                      <Col key={i + 2}>
                                        <Button className='button-custom' onClick={() => this.showHideAllColumns('show')}>Show all</Button>
                                      </Col>
                                    </>
                                  )
                                }
                              })}
                          </Row>
                        ))}
                      </>
                    }
                    <BootstrapTable id="dashboard"
                      striped bootstrap4 bordered={false}
                      {...props.baseProps}
                      wrapperClasses="table-responsive"
                      cellEdit={cellEditFactory({
                        mode: 'click',
                        blurToSave: true,
                        autoSelectText: true,
                        beforeSaveCell: (oldValue, newValue, row, column, done) => this.tableValuesChangeHandler(oldValue, newValue, row.customerID, row.loanID, column.dataField, done)
                      })}
                      pagination={paginationFactory()}
                      filter={filterFactory()}
                    />
                  </div>
                )
              }
            }
          </ToolkitProvider>
        }
        {this.state.modal && (this.state.modalType === 'textarea' || this.state.modalType === 'text') &&
          <Modal
            show={true}
            centered
            onHide={this.hideModal}
          >
            <Modal.Header closeButton onClick={this.hideModal}>
              <Modal.Title id="contained-modal-title-vcenter">
                {this.state.modalTitle}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body >
              {this.state.modalType === 'textarea' &&
                <Form.Group controlId="CommentsTextarea">
                  <Form.Label>
                    Add new text or change the old one.
                    <br />
                    Area scrolls and expands (drag by the bottom-right corner).
                  </Form.Label>
                  <Form.Control as="textarea" style={{ height: '300px' }} onChange={e => this.textAreaChangeHandler(e)} defaultValue={this.state.modalTextAreaValue} />
                </Form.Group>
              }
              {this.state.modalType === 'text' &&
                <Form.Group controlId="CommentsTextarea">
                  <Form.Label>
                    Enter date and time. Input shoud be in a format: "mm/dd/yyyy hh:mm AM|PM" or empty
                  </Form.Label>
                  <Form.Control type="text" onChange={e => this.textAreaChangeHandler(e)} defaultValue={this.state.modalTextAreaValue} />
                </Form.Group>
              }
            </Modal.Body>
            <Modal.Footer >
              <Button variant="primary" disabled={this.state.modalType === 'text' && !(this.dateInputRegEx.test(this.state.modalTextAreaValue) || this.state.modalTextAreaValue === '')} onClick={() => {
                if (this.state.modalType === 'text') {
                  this.tableValuesChangeHandler(this.state.initialModalTextAreaValue, this.state.modalTextAreaValue, this.state.selectedCustomerID, this.state.selectedLoanID, this.state.selectedDataField)
                  this.hideModal()
                } else if (this.state.modalType === 'textarea') {
                  this.tableValuesChangeHandler(this.state.initialModalTextAreaValue, this.state.modalTextAreaValue, this.state.selectedCustomerID, this.state.selectedLoanID, this.state.selectedDataField)
                  this.hideModal()
                }
              }}>Submit</Button>
              <Button variant="danger" onClick={this.hideModal} >Close</Button>
            </Modal.Footer>
          </Modal>}
        {this.state.modal && this.state.modalType === 'date' && <>
          <div
            id="myModal"
            style={{ display: "block", zIndex: '5' }}
            className="mymodal"
            onClick={this.hideModal}
          >
            <div
              style={{ borderWidth: "0px", backgroundColor: "rgb(0,0,0,0.0)" }}
              className="mymodal-content"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <div className='flex-column-align-center'>
                <Calendar
                  maxDate={this.maxDate}
                  minDate={new Date(1960, 0, 1)}
                  calendarType={"Hebrew"}
                  className='text-center'
                  onChange={date => {
                    let month = date.getMonth() + 1
                    let day = date.getDate()
                    if (('' + month).length === 1) {
                      month = '0' + month
                    }
                    if (('' + day).length === 1) {
                      day = '0' + day
                    }
                    this.tableValuesChangeHandler(this.state.initialModalDateValue, `${month}/${day}/${date.getFullYear()}`, this.state.selectedCustomerID, this.state.selectedLoanID, this.state.selectedDataField)
                    this.hideModal()
                  }}
                />
                <Button
                  style={{ marginTop: '5px' }}
                  variant="danger"
                  onClick={() => {
                    this.tableValuesChangeHandler(this.state.initialModalDateValue, ``, this.state.selectedCustomerID, this.state.selectedLoanID, this.state.selectedDataField)
                    this.hideModal()
                  }}
                >Empty this date</Button>
              </div>
            </div>
          </div>
        </>}
        {this.state.showEmailModal &&
          <EmailModal hideModal={this.hideEmailModalHandler} dataField={this.state.emailModalDatafiled} customerID={this.state.emailModalID} emailTemplates={this.emailTemplates} />
        }
        <InfoModal
          onHide={this.hideInfoModal}
          show={this.state.infoModal}
          modalTitle={this.state.infoModalTitle}
          modalBody={this.state.infoModalBody}
        />
        {this.state.redirect}
      </>
    )
  }


};

export default connect(mapStateToProps, mapDispatchToProps)(Pipeline);