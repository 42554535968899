import React, { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import * as com from "../../Common";
import { Button, Dropdown, Select, Tag } from "antd";

import "./common.css";
import { useSelector } from "react-redux";

interface CurrentStatusProps {
  currentStatus: string;
  currentStage: string;
  loanID: string;
  handleStatusChange: (loanID: string, status: string) => void;
}

const CurrentStatus = ({
  currentStatus,
  loanID,
  handleStatusChange,
}: CurrentStatusProps) => {
  const currentStage = useSelector(
    (state: any) => state.application.currentStage
  );

  const loanPurpose: string = useSelector(
    (state: any) => state.application?.property?.purpose
  );

  const items = com.CurrentStatusUtils.getStatusesByStage(
    currentStage,
    loanPurpose
  ).map(({ code, name }, idx) => {
    return {
      value: name,
      key: code,
      label: <div>{com.CurrentStatusUtils.getStatusName(name)}</div>,
    };
  });

  const [statusToDisplay, setStatusToDisplay] = useState(currentStatus);

  useEffect(() => {
    const applicableStatuses =
      com.CurrentStatusUtils.getStatusesByStage(currentStage, loanPurpose);
    if (!applicableStatuses.some((status) => status.name === currentStatus)) {
      const firstApplicableStatus = applicableStatuses[0].name;
      setStatusToDisplay(firstApplicableStatus);
      handleStatusChange(loanID, firstApplicableStatus);
    } else {
      setStatusToDisplay(currentStatus);
    }
  }, [currentStatus, currentStage]);

  return (
    <div className="loansStatusContainer">
      <div
        className="loansStatus"
        style={{
          borderColor:
            com.CurrentStatusUtils.getStatusBorderColor(currentStatus),
          backgroundColor:
            com.CurrentStatusUtils.getStatusBackgroundColor(currentStatus),
        }}
      >
        <Select
          style={{
            backgroundColor:
              com.CurrentStatusUtils.getStatusBackgroundColor(currentStatus),
            color: com.CurrentStatusUtils.getStatusTextColor(currentStatus),
            border: "none",
            borderRadius: "6px",
          }}
          value={com.CurrentStatusUtils.getStatusName(statusToDisplay)}
          onChange={(value) => {
            handleStatusChange(loanID, value);
          }}
          options={items}
          suffixIcon={
            <img src="/images/stepper-menu-arrow-down.svg" alt="down-arrow" />
          }
        />
      </div>
    </div>
  );
};

export default CurrentStatus;
