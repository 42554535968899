import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { Form, Input, Button, Col, Row, Modal, Table, Tag, Select, Radio, Popover } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { useStatsigClient } from "@statsig/react-bindings/src";

export default function Footer({ isFourText }) {
    const { firstNoFinish, open, showLenders, step, customerLenders, showAchievement, showPricing, first, selectLenders, isFinished, WebsiteInfo } = useSelector(state => {


        return state.manager
    })
    const dispatch = useDispatch()
    const { client } = useStatsigClient();
    const text = () => {


        if (
            first

        ) {
            return 'Complete'
        } else {
            if (WebsiteInfo) {
                return 'Complete'
            }
            if (isFinished) {
                return 'Resume'
            } else {
                return 'Start'
            }

        }


    }
    const ButtonText = [
        () => {

            return 'Next'
            return 'Next'
        },
        () => {

            return 'Next '
        },
        () => {

            return 'Save'
        },
        () => {

            if (!isFourText) {

                return 'Next '
            } else {
                return 'Complete'
            }

        },
        () => {
            if (step === 4 && !showAchievement) {
                return 'Complete'
            }
            if (
                first

            ) {
                return 'Complete'
            } else {
                if (WebsiteInfo) {
                    return 'Complete'
                }
                if (isFinished) {
                    return 'Resume'
                } else {
                    return 'Start'
                }

            }

        },


    ]
    const ButtonClick = [
        () => {
            const saves = document.getElementsByClassName('lendersSave')
            console.log(saves)
            if (saves.length > 0) {
                for (let index = 0; index < saves.length; index++) {
                    const element = saves[index];
                    element.click()
                    
                }
                // saves.forEach(element => {
                //     element.click()
                // })
            }

            // return
            dispatch({ type: "SHOW_ACHIEVEMENT", data: true })
            dispatch({ type: "SHOW_LENDERS", data: false })
            dispatch({ type: "SHOW_PRICiNG", data: false })

        },
        () => {
            const span = document.getElementById('spanId')
            span.click()




            return
            dispatch({ type: "SHOW_ACHIEVEMENT", data: false })
            dispatch({ type: "STEP", data: step + 1 })
        },
        () => {
            const Submit = document.querySelector('#validateForm')

            Submit.click()


            //  
        },
        () => {
            if (
                !isFourText
            ) {
                dispatch({ type: "SHOW_ACHIEVEMENT", data: false })
                dispatch({ type: "STEP", data: step + 1 })

            } else {
                const Submit = document.querySelector('#emailForm')

                Submit.click()



            }
        },
        async () => {

            if (showAchievement) {
                if (
                    first

                ) {
                    dispatch({ type: "SHOW_ACHIEVEMENT", data: false })
                    dispatch({ type: "SHOW_PRICiNG", data: true })
                } else {
                    if (WebsiteInfo) {
                        dispatch({ type: "SHOW_ACHIEVEMENT", data: false })
                        dispatch({ type: "SHOW_PRICiNG", data: true })
                        return
                    }
                    if (isFinished) {
                        dispatch({ type: "SHOW_ACHIEVEMENT", data: false })
                        dispatch({ type: "STEP", data: firstNoFinish })

                    } else {
                        dispatch({ type: "SHOW_ACHIEVEMENT", data: false })
                        dispatch({ type: "STEP", data: step + 1 })
                    }

                }



            } else {
                let token = sessionStorage.getItem("ZeitroA")
                const data = await fetch("/los/getprofilesetup", {
                    cache: 'no-cache',
                    method: 'GET',
                    headers: {
                        Authorization: "Bearer " + token,
                        Cache: "no-cache"
                    },
                })
                const info = await data.json()

                let tosend = {
                    ...info, LandingPageSetUp: {
                        SetupGuideStatus: {
                            ...info.LandingPageSetUp?.SetupGuideStatus,
                            ZeitroWidget: true
                        }
                    }
                }

                // statsig logEvent
                const savedUserInfo = JSON.parse(sessionStorage.getItem('statsig_user_info'));
                client.logEvent("publish_personal_website", savedUserInfo.custom.customerid, {
                    item_name: "publishWebsite",
                    startTime: new Date,
                    timestamp: Date.now(),
                    path: window.location.pathname,
                    customerid: savedUserInfo.custom.customerid,
                    email: savedUserInfo.email
                })

                const response = await fetch('/los/updateprofilesetup', {
                    method: 'POST',
                    body: JSON.stringify(tosend),
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: "Bearer " + token,
                    },
                })

                dispatch({ type: "SHOW_ACHIEVEMENT", data: true })
                dispatch({ type: "STEP", data: 0 })
            }

        }

    ]
    const ButtonShow = [
        () => {
            return showLenders

        },
        () => {
            return true
        },
        () => {
            return true
        },
        () => {
            return true
        },
        () => {
            return true
        },
    ]
    const radio = [0, 1, 2, 3, 4]

    return (
        <div style={{
            color: "#6E6E70",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            <div style={{ width: "100px" }}></div>



            {
                step >= 1 && <div>
                    {!showAchievement && !showLenders && !showPricing &&
                        <div className='AchievementRoundF'>
                            {radio.map((item) => {
                                return <div className='AchievementRound ' onClick={() => {

                                    dispatch({ type: "STEP", data: item })
                                }}>

                                    {step >= item ? <div style={{ background: "#325CEB", width: 8, height: 8, borderRadius: '50%' }}></div> : null}
                                </div>
                            })}
                        </div>

                    }
                </div>
            }


            {
                showPricing ? <></> : <div className='d-flex align-items-center'>
                    {(step == 4 || first || WebsiteInfo) ? <></> : <div style={{ marginRight: 15 }} onClick={() => {
                        if (showAchievement) {
                            dispatch({ type: "SHOW_ACHIEVEMENT", data: false })
                            dispatch({ type: "SHOW_PRICiNG", data: true })
                            return
                        }
                        dispatch({ type: "SHOW_ACHIEVEMENT", data: false })
                        dispatch({ type: "SHOW_LENDERS", data: false })
                        dispatch({ type: "STEP", data: step + 1 })
                        return

                        //dispatch({ type: "STEP", data: step + 1 })

                    }}> Skip</div>}
                    {!showPricing && ButtonShow[step]() && <Button type='primary'
                        onClick={() => {
                            // if (step == 2) {
                            //     dispatch({ type: "STEP", data: step + 1 })
                            //     return
                            // }
                            ButtonClick[step]()

                        }}

                    >{ButtonText[step]()}</Button>}
                    {
                        step == 4 ? <></> : showAchievement && <Button type='primary'
                            onClick={() => {
                                // dispatch({ type: "SHOW_ACHIEVEMENT", data: false })
                                // dispatch({ type: "SHOW_PRICiNG", data: true })
                                if (
                                    first

                                ) {
                                    dispatch({ type: "SHOW_ACHIEVEMENT", data: false })
                                    dispatch({ type: "SHOW_PRICiNG", data: true })
                                } else {
                                    if (WebsiteInfo) {
                                        dispatch({ type: "SHOW_ACHIEVEMENT", data: false })
                                        dispatch({ type: "SHOW_PRICiNG", data: true })
                                        return
                                    }
                                    if (isFinished) {
                                        dispatch({ type: "SHOW_ACHIEVEMENT", data: false })
                                        dispatch({ type: "STEP", data: firstNoFinish })
                                    } else {
                                        dispatch({ type: "SHOW_ACHIEVEMENT", data: false })
                                        dispatch({ type: "STEP", data: 0 })
                                    }

                                }

                            }}

                        >{text()}</Button>
                    }

                </div>
            }
        </div >)
}