import React, { useState } from "react";
import { Modal, Button, Select, Row, Col } from "antd";
import * as com from "../Common";

const TITLE_STYLE = {
  fontSize: 12,
  color: "#6E6E70",
  fontWeight: 500,
  marginBottom: 8,
};

const BulkChangeModal = ({
  show,
  type,
  LOOptions,
  handleSubmit,
  handleClose,
}) => {
  const [selectedLO, setSelectedLO] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedStage, setSelectedStage] = useState("");

  const confirmModal = () => {
    /**
     * when submitting, we need to
     * 1. if this is status change, check if the selected stage and status are valid and send both values as "stage-status".
     * 2. if this is LO change, check if the selected LO is valid and send the value.
     */
    const slOption =
      type === "status"
        ? selectedStage + "-" + selectedStatus
        : selectedLO;
    console.log(slOption);
    handleSubmit(slOption);
  };

  const closeModal = () => {
    handleClose();
  };

  return (
    <Modal
      title={
        type === "status"
          ? "Change current stage and status"
          : "Assign loan officer to the borrower"
      }
      open={show}
      onCancel={closeModal}
      onOk={confirmModal}
      okText="Submit"
      destroyOnClose
      okButtonProps={{
        disabled:
          type === "status"
            ? selectedStage === "" || selectedStatus === ""
            : selectedLO === "",
      }}
    >
      <div className="pt-3 pb-3">
        {type === "status" ? (
          <Row gutter={16}>
            <Col span={9}>
              <div style={TITLE_STYLE}>Stage</div>
              <div>
                <Select
                  placeholder={"Select stage"}
                  style={{
                    width: "100%",
                  }}
                  onChange={(value) => setSelectedStage(value)}
                  options={Object.entries(com.CurrentStage).map(
                    ([stage, _], idx) => ({
                      value: stage,
                      label: stage,
                    })
                  )}
                />
              </div>
            </Col>
            <Col span={1}></Col>
            <Col span={14}>
              <div style={TITLE_STYLE}>Status</div>
              <div>
                <Select
                  placeholder={"Select status"}
                  style={{
                    width: "100%",
                  }}
                  onChange={(value) => setSelectedStatus(value)}
                  options={com.CurrentStatusUtils
                    .getStatusesByStage(selectedStage)
                    .map(({ name }) => ({
                      value: name,
                      label: com.CurrentStatusUtils.getStatusName(name),
                    })
                  )}
                />
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col span={24} className="px-0">
              <div style={TITLE_STYLE}>Loan Officer</div>
              <div>
                <Select
                  placeholder={"Select Loan Officer"}
                  style={{
                    width: "100%",
                  }}
                  onChange={(value) => setSelectedLO(value)}
                  options={LOOptions.map((t) => ({
                    value: t.id,
                    label: `${t.firstname} ${t.lastname}`,
                  }))}
                />
              </div>
            </Col>
          </Row>
        )}
      </div>
    </Modal>
  );
};

export default BulkChangeModal;
