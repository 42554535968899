import React, { useCallback } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import './Chat.css'

const MarkdownRenderer = ({ answer, quoteLocations, referenceFunc }) => {
    const preprocessMarkdown = useCallback((markdown) => {
        // Remove "Referenced context:" lines that contain reference links
        markdown = markdown.replace(/^Referenced context:\s*\[[^\]]+\]\s*$/gm, '')

        // Remove specific unicode characters
        markdown = markdown.replace(/\u3010.*?\u3011/g, '')

        // Add a newline after the colon to ensure line breaks
        markdown = markdown.replace(/:\s*$/gm, ':\n')

        // Add a newline before `a)`, `b)`, `c)`
        markdown = markdown.replace(/(\n\s*)([a-z]\))\s+/g, '\n$1$2 ')

        // Convert h3 to h6 and ensure bold
        markdown = markdown.replace(
            /(^|\n)###\s+(.*?)($|\n)/g,
            (match, start, p1, end) => {
                p1 = p1.replace(/\*\*/g, '')
                return `${start}<span style="font-size: 16px; font-weight: bold;">${p1.trim()}</span>${end}`
            }
        )

        // Replace reference links with custom tag, keeping only valid references
        markdown = markdown.replace(
            /\[([1-9]\d*(?:\s*,\s*[1-9]\d*)*)\]/g,
            (match, numbers) => {
                const refs = numbers.split(',').map(n => parseInt(n.trim()));
                // Get unique page numbers from quoteLocations
                const uniquePages = new Set(quoteLocations.map(([page]) => page));
                const maxValidRef = uniquePages.size;
                
                // Only filter refs if we have valid locations
                if (Array.isArray(quoteLocations) && quoteLocations.length > 0) {
                    const validRefs = refs.filter(ref => ref <= maxValidRef);
                    return validRefs.length > 0 ? `<reference-link refs="${validRefs.join(',')}" />` : '';
                }
                
                // If no valid locations, keep original reference
                return `<reference-link refs="${numbers}" />`;
            }
        )

        return markdown
    }, [quoteLocations])

    const components = {
        'reference-link': ({ node }) => {
            const refs = node.properties?.refs.split(',').map((ref) => ref.trim())

            // Check if there are valid locations
            const hasValidLocations = Array.isArray(quoteLocations) && quoteLocations.length > 0

            // Get page number that the reference link points to
            const handleClick = (clickedRef) => {
                if (!hasValidLocations) return

                const groupedLocations = quoteLocations.reduce(
                    (acc, [page, coords]) => {
                        if (!acc[page]) {
                            acc[page] = []
                        }
                        acc[page] = acc[page].concat(coords)
                        return acc
                    },
                    {}
                )
                const uniqueLocations = Object.entries(groupedLocations).map(
                    ([page, coords]) => [page, coords]
                )
                const sortedPages = Array.from(
                    new Set(uniqueLocations.map((location) => location[0]))
                ).sort((a, b) => a - b)

                const index = parseInt(clickedRef) - 1
                if (index >= 0 && index < sortedPages.length) {
                    const pageNumber = sortedPages[index]
                    if (pageNumber) {
                        referenceFunc(pageNumber, uniqueLocations)
                    }
                }
            }

            return (
                <span
                    style={{
                        cursor: hasValidLocations ? 'pointer' : 'not-allowed',
                        color: hasValidLocations ? 'blue' : 'gray',
                        opacity: hasValidLocations ? 1 : 0.6,
                    }}
                >
                    [
                    {refs.map((ref, idx) => (
                        <React.Fragment key={idx}>
                            <span onClick={() => handleClick(ref)}>
                                {ref}
                            </span>
                            {idx < refs.length - 1 && ', '}
                        </React.Fragment>
                    ))}
                    ]
                </span>
            )
        },
    }

    return (
        <ReactMarkdown
            className="markdown-body"
            rehypePlugins={[rehypeRaw]}
            remarkPlugins={[remarkGfm]}
            components={components}
        >
            {preprocessMarkdown(answer)}
        </ReactMarkdown>
    )
}

export default MarkdownRenderer
