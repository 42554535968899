import React, { useState, useEffect, useRef } from 'react'
import { Form, Input, Button, Col, Row, Modal, Table, Tag, Select, Radio, Spin } from 'antd'
import { useImmer } from "use-immer"
import { CheckCircleOutlined, CheckCircleFilled, CheckOutlined } from '@ant-design/icons'
import Upgrade from './upgrade'
import UserModal from './userModal'
import './index.css'

import FullHeightModal from "../../Common/components/FullHeightModal"
import { useSelector, useDispatch } from 'react-redux'
export default function PlanPricing(props) {
    const dispatch = useDispatch()
    const subscription = useSelector(state => {

        return state.subscriptionReducer.currentInfo
    })
    const { open } = useSelector(state => {
        return state.manager
    })
    const { invite } = props
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isUserModalOpen, setIsUserModalOpen] = useState(false)
    const [isPlanModalOpen, setPlanIsModalOpen] = useState(false)
    const [isSupportModalOpen, setSupportModalOpen] = useState(false)
    const [messageReceivedModalOpen, setMessageReceivedModalOpen] = useState(false)
    const [isUpgrade, setIsUpgrade] = useState(false)
    const [checkUser, setCheckUser] = useState([])
    const [inviteStatus, setInviteStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const tableRef = useRef(null)
    const { TextArea } = Input
    const pricingContent = [
        {
            title: "Starter",
            price: 'FREE',
            date: "per month",
            button: 'Get Started Free',
            details: [
                'Unlimited price engine requests',
                'Unlimited 1003 PDF generations',
                '3 MISMO 3.4 Exports per month',
                'Agent personal webpage'

            ]

        },
        {
            title: "Business",
            price: '$99',
            date: "per user/month",
            button: 'Upgrade now',
            details: [
                'Unlimited price engine requests',
                'Unlimited 1003 PDF generation',
                'Unlimited MISMO 3.4 Export',
                'Credit pull intergration',
                'Agent personal webpage & customized url',
                'Rate & affordability calculator widget',
                'MortgageGPT',
                'Document autoprocessing',
                'Team & branch setup'

            ]

        },
        {
            title: "Enterprise",
            price: 'LET\'S TALK',
            date: "per month",
            button: 'Contact us',
            details: [
                'Customized software solutions',
                'Dedicated customer support'
            ]

        },



    ]

    const catStyle = {
        color: '#1F2890',
        fontWeight: 'bold',
        fontSize: '20px',
        width: '100%'
    }
    const dataSource = [
        {
            title: (<div className='UserSource' style={catStyle}>Basic</div>),
            Starter: false,
            Business: false,
            Enterprise: false,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Cross Platform</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Agent Webpage</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Customized Web Page URL</div>),
            Starter: false,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Team & Branch Setup</div>),
            Starter: false,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={catStyle}>Pricing Engine</div>),
            Starter: false,
            Business: false,
            Enterprise: false,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Requests</div>),
            Starter: 'Unlimited',
            Business: "Unlimited",
            Enterprise: "Unlimited",
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Add Wholesale Lenders</div>),
            Starter: 'Unlimited',
            Business: "Unlimited",
            Enterprise: "Unlimited",
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}> Borrower Rate Pricer</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Affordability Calculator</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}> Compensation Editor</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>  Overlay Editor</div>),
            Starter: false,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}> Plug-in and Widgets</div>),
            Starter: false,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={catStyle}>Point-of-Sale</div>),
            Starter: false,
            Business: false,
            Enterprise: false,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Multi-Users 1003 System</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Automated Pre-Qual System</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Automated Soft Credit Pulls</div>),
            Starter: false,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Automated Liability Review</div>),
            Starter: false,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={catStyle}>Loan Origination System</div>),
            Starter: false,
            Business: false,
            Enterprise: false,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>1003 PDF Generation</div>),
            Starter: "Unlimited",
            Business: "Unlimited",
            Enterprise: "Unlimited",
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>MISMO 3.4 Export</div>),
            Starter: "3/month",
            Business: "Unlimited",
            Enterprise: "Unlimited",
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Document Management</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Email Communication System</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Unlimited File Storage</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>AI Document Auto Processing</div>),
            Starter: false,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={catStyle}>Guideline GPT</div>),
            Starter: false,
            Business: false,
            Enterprise: false,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Guideline Reader</div>),
            Starter: false,
            Business: "Unlimited",
            Enterprise: "Unlimited",
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Q&A</div>),
            Starter: false,
            Business: "Unlimited",
            Enterprise: "Unlimited",
        },
        {
            title: (<div className='UserSource' style={catStyle}>Help Center</div>),
            Starter: false,
            Business: false,
            Enterprise: false,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Onboarding Support</div>),
            Starter: true,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}>Dedicated Support</div>),
            Starter: false,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}> Rest APIs</div>),
            Starter: false,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}> Modular Solutions</div>),
            Starter: false,
            Business: true,
            Enterprise: true,
        },
        {
            title: (<div className='UserSource' style={{ fontWeight: 'bold' }}> Full Customization Solution</div>),
            Starter: false,
            Business: false,
            Enterprise: true,
        },

    ]
    const [currentInfo, setCurrentInfo] = useState({ loanOfficers: [] })
    const [showUserSelection, setShowUserSelection] = useState(false);
    useEffect(

        async () => {

            getcurrentsubscription()
        }, [])
    const getcurrentsubscription = async () => {


        setLoading(true)

        const { customerID, loanOfficers, plan, stripeCustomer, recurringInterval } = subscription
        console.log(loanOfficers, 7)
        setCurrentInfo({
            customerID,
            loanOfficers: loanOfficers.filter(e => e.Status !== "disabled"),
            plan,
            stripeCustomer,
            recurringInterval
        })
        setCheckUser(loanOfficers.filter(e => e.Status !== "disabled"))
        setTimeout(() => {
            setLoading(false)
            setIsUpgrade(false)

        }, 500)
    }
    const columns = [
        {
            title: '',
            dataIndex: 'title',
            width: 20,
            ellipsis: true,
            key: 'title',
        },
        {
            title: <div className='plan-table-header'>Starter</div>,
            dataIndex: 'Starter',
            align: 'center',
            key: 'Starter',
            width: '60px',
            render: (text) => <>{typeof text === 'string' ? <span style={{ color: "#1F2890", fontSize: '16px', fontWeight: '600' }}>{text}</span> : text ? <img src='/images/plan-check.svg' /> : <></>}</>,

        },
        {
            align: 'center',
            title: <div className='plan-table-header'>Business</div>,
            width: '60px',
            dataIndex: 'Business',
            key: 'Business',
            render: (text) => <>{typeof text === 'string' ? <span style={{ color: "#1F2890", fontSize: '16px', fontWeight: '600' }}>{text}</span> : text ? <img src='/images/plan-check.svg' /> : <></>}</>,

        },
        {
            title: <div className='plan-table-header'>Enterprise </div>,
            align: 'center',
            width: '60px',
            dataIndex: 'Enterprise',
            key: 'Enterprise',
            render: (text) => <>{typeof text === 'string' ? <span style={{ color: "#1F2890", fontSize: '16px', fontWeight: '600' }}>{text}</span> : text ? <img src='/images/plan-check.svg' /> : <></>}</>,

        },


    ]
    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
        }
    }
    async function getPlans() {
        let token = sessionStorage.getItem("ZeitroA")
        setLoading(true)
        const response = await fetch('/los/getcurrentsubscription', {
            method: 'get',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        })
        const DataInfo = await response.json()
        const { customerID, loanOfficers, plan, stripeCustomer, recurringInterval } = DataInfo
        dispatch({ type: "SUBSCRIPTION_INFO", data: { ...DataInfo, loading: true } })
        setCurrentInfo({
            customerID,
            loanOfficers,
            plan,
            stripeCustomer,
            recurringInterval
        })
        setLoading(false)

    }
    const scrollToTop = () => {
        const tableBody = tableRef.current?.querySelector('.ant-table-body')
        if (tableBody) {
            tableBody.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
    }
    return (
        <>
            <div className='mx-3'>

                <div className='plans-top-bar'>
                    <div className='plans-title'>
                        Plans and Pricing
                    </div>
                    <Button className='plans-support-btn' onClick={() => {
                        setSupportModalOpen(true)
                    }}>Support</Button>
                </div>

                <Spin spinning={loading}>
                    <div className='pricingContent'>
                        {isUpgrade ? (
                            <Upgrade 
                                back={() => {
                                    setIsUpgrade(false)
                                    setShowUserSelection(true)
                                    getPlans()
                                }} 
                                loanOfficers={checkUser} 
                                stripeCustomer={currentInfo.stripeCustomer} 
                                userNumber={currentInfo.loanOfficers.length}
                                onBackToUserSelection={() => {
                                    setIsUpgrade(false)
                                    setShowUserSelection(true)
                                }}
                            />
                        ) : showUserSelection ? (
                            <UserModal 
                                isModalOpen={true} 
                                loanOfficers={currentInfo.loanOfficers} 
                                closeModal={(selectedUsers) => {
                                    if (selectedUsers) {
                                        setCheckUser(selectedUsers)
                                        setIsUpgrade(true)
                                    }
                                    setShowUserSelection(false)
                                }}
                            />
                        ) : <Row gutter={12} className='price'>
                            {pricingContent.map((item, index) => {

                                return (
                                    // <Col lg={{ span: 6, offset: index > 0 ? 2 : 1 }}>
                                    <Col xl={8} span={24}>
                                        <div className={currentInfo.plan === item.title.toLocaleLowerCase() ? 'pricingBlock chosen' : 'pricingBlock'}>
                                            <div>
                                                <div style={{ display: "flex", justifyContent: "end" }}>
                                                    {currentInfo.plan === item.title.toLocaleLowerCase() ? <CheckCircleFilled style={{ fontSize: "20px", color: "#325CE9" }} /> : <div className='circle'></div>
                                                    }
                                                </div>
                                                <div className='pricingTitle'>{item.title}</div>
                                                <div className='pricingPrice'>{item.price}</div>
                                                <div style={{marginTop: 5, height: 43, color: '#474849', fontSize: 12}}>{index === 1 && <span>{item.date}</span>}</div>

                                                <div className='scroll'>      
                                                    {item.details.map(item => {
                                                        return (
                                                            <div className='pricingDetails'>
                                                                <img src='/images/plan-checkCircle.svg' />
                                                                <div title={item} style={{ color: '#222', lineHeight: 'normal' }} className='pricingDetailsCon'>{item}</div>
                                                            </div>

                                                        )
                                                    })}
                                                </div>

                                            </div>
                                            <div>
                                                {index === 1 &&
                                                    <div style={{ padding: '0 30px 0 18px' }}>
                                                        <Button type='primary' className='plan-main-btn' style={{ width: '100%', height: '44px' }} onClick={() => {
                                                            if (open) {
                                                                if (currentInfo.plan === 'starter') {
                                                                    setIsModalOpen(true)
                                                                    return
                                                                }
                                                                dispatch({ type: "SHOW_PRICiNG", data: false })
                                                                dispatch({ type: "STEP", data: 1 })
                                                                return

                                                            }
                                                            if (currentInfo.plan !== 'starter') {
                                                                invite(false)
                                                                return
                                                            }
                                                            if (currentInfo.loanOfficers.length > 1) {
                                                                setIsUserModalOpen(true)
                                                                return
                                                            }
                                                            if (index === 1) {
                                                                if (inviteStatus) {
                                                                    if (currentInfo?.loanOfficers.length > 1) {
                                                                        setIsUserModalOpen(true)
                                                                    } else {
                                        
                                                                        setIsModalOpen(false)
                                                                        setIsUpgrade(true)
                                                                    }
                                                                } else {
                                                                    setIsModalOpen(true)
                                                                }
                                                            }


                                                        }}>{currentInfo.plan === 'starter' ? item.button : 'Manage'}</Button>
                                                    </div>
                                                }
                                                {
                                                    index === 2 && (
                                                        <div style={{ padding: '0 30px 0 18px' }}>
                                                            <Button className='plan-contact-btn' style={{ width: '100%', height: '44px', marginTop: '20px' }} onClick={() => {
                                                                if (index === 2) {
                                                                    setSupportModalOpen(true)

                                                                }
                                                            }}>
                                                                Contact us
                                                            </Button>
                                                        </div>
                                                    )
                                                }
                                                <div style={{ color: '#325CEB', fontWeight: 600, lineHeight: '24px', textAlign: 'center', marginTop: 12, paddingRight: 12 }}><span style={{ cursor: 'pointer' }} onClick={() => { setPlanIsModalOpen(true) }}>Plan details</span></div>

                                            </div>
                                        </div>
                                    </Col>

                                )

                            })
                            }


                        </Row>
                        }
                    </div>
                </Spin>
                <Modal open={isModalOpen} footer={null} onCancel={() => {
                    setIsModalOpen(false)
                }}>
                    <div style={{ textAlign: 'center', marginTop: '80px', fontSize: '18px', fontWeight: '600' }}>
                        <img src='/images/email.svg'></img>

                        <p>Before we upgrade you to the Zeitro Business Plan,</p>
                        <p>would you like to invite other team members to join?</p>

                    </div>
                    <div style={{ textAlign: 'center', marginBottom: '80px', marginTop: '60px', }}>
                        <Button className='ant-btn-default-zeitro' style={{ height: '44px' }} onClick={() => {
                            if (currentInfo.loanOfficers.length > 1) {

                                setIsUserModalOpen(true)
                            } else {

                                setIsModalOpen(false)
                                setIsUpgrade(true)
                            }
                            setInviteStatus(true)

                        }}>Proceed to upgrade</Button>
                        <Button style={{ width: '40%', marginLeft: '10px', height: '44px' }} type='primary' onClick={() => {
                            if (open) {

                                dispatch({ type: "SHOW_PRICiNG", data: false })
                                dispatch({ type: "STEP", data: 1 })
                                return

                            }
                            invite(true)

                        }}>Invite user</Button>

                    </div>

                </Modal>
                <Modal 
                    width={786}
                    className='help-modal'
                    open={isSupportModalOpen} 
                    footer={null} 
                    onCancel={() => {
                        setSupportModalOpen(false)
                    }}>
                    <div className='help-modal-content'>
                        <div className='help-modal-content-title'>How can we help?</div>
                        <div className='help-modal-content-tips'>For inquiries regarding billing, payments, product feedback, or any issues encountered while using Zeitro, we're here to assist you promptly. </div>

                        <Form
                            className='help-modal-form'
                            onFinish={onFinish}
                            form={form}
                            style={{ marginTop: '20px', }}
                            layout='vertical'
                            validateMessages={validateMessages}

                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        className='help-modal-form-item'
                                        name="message"
                                        label="Message"
                                    >
                                        <TextArea
                                            className='help-modal-form-item-textarea'
                                            placeholder="Please leave us a message..." 
                                            style={{ 
                                                height: '250px',
                                                resize: 'none'
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <div style={{fontFamily: 'Inter', color: '#6E6E70', width: '100%', textAlign: 'center', marginTop: 20, lineHeight: '20px', letterSpacing: '0.2px'}}>Alternatively, you could also email us at <span style={{color: '#325CEB'}}>contact@zeitro.com</span></div>
                            </Row>

                            <div style={{width: '100%', display: 'flex', justifyContent: 'center', gap: '48px'}}>
                                <Button className='help-modal-btn cancel-btn' onClick={() => {
                                    setSupportModalOpen(false)

                                }}>Cancel</Button>
                                <Button className='help-modal-btn send-btn' type='primary' htmlType="submit">Send Message</Button>

                            </div>
                        </Form>
                    </div>


                </Modal>

                <FullHeightModal open={isPlanModalOpen} width={1000} footer={null} onClose={() => {
                    setPlanIsModalOpen(false)
                }}
                    content={<>
                        <div style={{ fontFamily: 'Inter', fontSize: 36, color: '#000', fontWeight: 700, lineHeight: '54px', marginBottom: 12, textAlign: 'center' }}>Plan Details</div>
                        <div style={{ fontFamily: 'Inter', fontSize: 16, color: "#6E6E70", textAlign: "center", lineHeight: 'normal', letterSpacing: '0.2px', marginBottom: 24 }}>Unlock seamless loan origination with Zeitro's tailored subscription plans</div>
                        <Table
                            ref={tableRef}
                            columns={columns}
                            dataSource={dataSource}
                            className="allLoansTable plan-Details-table"
                            showSorterTooltip={false}
                            pagination={false}
                            scroll={{
                                x: true,
                                y: "calc(100vh - 320px)"
                            }}
                        ></Table><div style={{color: '#325CEB', fontWeight: 600, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={scrollToTop}><div style={{cursor: 'pointer'}}>Back to top</div></div></>}>

                </FullHeightModal>
                <Modal open={messageReceivedModalOpen} footer={null} onCancel={() => {
                    setMessageReceivedModalOpen(false)
                }}>
                    <div className='text-center p-5'>
                        <div><img src='/images/emailSent.svg' /></div>
                        <div className='User_modal_title my-3'>We've received your message</div>
                        <div className='title-5 text-center'>Thank you for reaching out. </div>
                        <div className='title-5 text-center'>We will get back to you via email as soon as possible.</div>
                        <Button type='primary' size='large' className='mt-4' onClick={() => setMessageReceivedModalOpen(false)}>Done</Button>
                    </div>
                </Modal>
                <UserModal isModalOpen={isUserModalOpen} loanOfficers={currentInfo.loanOfficers} closeModal={(e) => {
                    if (!e) {
                        setIsUserModalOpen(false)
                        return
                    }
                    setCheckUser(e)
                    setIsModalOpen(false)
                    setIsUpgrade(true)
                    setIsUserModalOpen(false)

                }}></UserModal>
            </div>

        </>
    )
    function onFinish(val) {
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/los/customersupporttoslack', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
            body: JSON.stringify({
                Message: val.message
            })
        }).then(async response => {
            if (!response.ok) {
                return
            }
        })
        setSupportModalOpen(false)
        setMessageReceivedModalOpen(true)
    }

}
