import React from 'react'
import { runStatsigAutoCapture } from '@statsig/web-analytics';
import { useStatsigClient } from "@statsig/react-bindings/src";

export const updateStatsigUser = async (client, email, customerid) => {
    try {
        if (!client) {
            return;
        }
        const context = client.getContext();
        const currentUser = context.user;
        const derivedFields = context.values.derived_fields;

        const userInfo = {
            ...currentUser,
            userID: email,
            email: email,
            custom: {
                customerid: customerid,
                ip: derivedFields.ip,
                country: derivedFields.country,
                email: email,
            }
        }
        sessionStorage.setItem('statsig_user_info', JSON.stringify(userInfo));

        await client.updateUserAsync(userInfo);
        runStatsigAutoCapture(client);
        // if (newContext.user.email === email) {
        //     runStatsigAutoCapture(client);
        //     console.log('Auto capture reinitialized');
        //     return true;
        // } else {
        //     console.warn('Update may not have taken effect');
        //     return false;
        // }
    } catch (error) {
        console.log("statsig error", error)
    }
}

export const withStatsig = (WrappedComponent) => {
    return function WithStatsigWrapper(props) {
        const { client } = useStatsigClient();
        return <WrappedComponent {...props} statsigClient={client} />;
    };
};
