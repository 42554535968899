import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { 
  Checkbox,
  DatePicker,
  Modal,
  Input,
  Button,
  Collapse,
  Radio,
  Space,
  Form,
  Select,
  Col,
  Row
} from 'antd';
import { DownOutlined, CloseOutlined } from '@ant-design/icons';
import { Document, Page } from 'react-pdf';
import { connect } from 'react-redux'
import "./UnderwritingChecklist.css";
import * as act from "../Store/actions"
import * as com from "../Common"
const { TextArea } = Input
const mapStateToProps = (state) => {
    return {
        application: state.application,
        property: state.application.property,
        hardmoney: state.application.hardmoneyloan,
        progress: state.progress,
    }
  }
  
const mapDispatchToProps = (dispatch) => ({
    updateHardMoneyLoanAttribute: (t, verb) => {
      dispatch(act.UpdateHardMoneyLoanAttribute(t, verb));
    }
  })

export default function UnderwritingChecklist(props) {
    const [form] = Form.useForm()
    const [pageWidth, setPageWidth] = useState(window.innerWidth)
    const [isViewfilesOpen, setIsViewfilesOpen] = useState(false)
    const [isOverWriteOpen, setIsOverWriteOpen] = useState(false)
    const [largeOpen, setLargeOpen] = useState(false)
    const [modalType, setModalType] = useState(1) // 1 overwrite 2 conditionally approve 3 files 4 comment 5 approve
    const [checkListKey, setCheckListKey] = useState("")
    const [overWriteValue, setOverWriteValue] = useState(0)
    const [overWriteListKey, setOverWriteListKey] = useState("")
    const [overWriteListIndex, setOverWriteListIndex] = useState("")
    const [file, setFile] = useState([])
    const [fileIndex, setFileIndex] = useState(0)
    const [numPages, setNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [loading, setLoading] = useState(false)
    const [nameList, setNameList] = useState([])
    const [checkList,setCheckList] = useState([])
    const defaultCheckList = [
        {
            key: '1',
            label: 'Business Purpose Loan Application',
            list: [
                {
                    content: 'Ensure it does not say “owner occupied”.',
                    status: 1
                }
            ],
            file: [
                {
                    id: '12321',
                    filename: 'Corporate Resolution',
                    type: 'Corporate Resolution',
                    size: 12344,
                    url: '/fannie_mae_guideline.pdf'
                },
                {
                    id: '35434',
                    filename: 'Agreement_Zeitra',
                    type: 'Operating Agreement',
                    size: 12344,
                    url: '/newfi_jumbo_guideline.pdf'
                },
                {
                    id: '4545',
                    filename: 'Application_Signed',
                    type: 'Application Form',
                    size: 12344,
                    url: ''
                },
                {
                    id: '7777',
                    filename: 'Supplement Doc1',
                    type: 'Type A',
                    size: 12344,
                    url: ''
                }
            ]
        },
        {
            key: '2',
            label: 'Borrower’s ID',
            list: [
                {
                    content: 'Make sure the ID is NOT expired.',
                    status: 2
                },
                {
                    content: 'If borrower is a foreign national, please ask for a valid work visa (H1b, F1, L1, etc).',
                    status: 1
                },
                {
                    content: 'Make sure the borrower’s country is not on the sanction list by the U.S. government.',
                    status: 1
                }
            ],
            file: []
        },
        {
            key: '3',
            label: 'Purchase Agreement',
            list: [
                {
                    content: 'Check the purchase price and any condition. Ensure the buyer’s name matches the borrower’s name.',
                    status: 0
                },
                {
                    content: 'Ensure there is no seller carry back.',
                    status: 0
                },
                {
                    content: 'If there is any assignment fee, please escalate it.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '4',
            label: 'Borrower Credit',
            list: [
                {
                    content: 'Credit score > 640',
                    status: 0
                },
                {
                    content: 'No late payment, delinquency or fraud via LexisNexis. If you see them, please ask the borrower for explanation.',
                    status: 0
                },
                {
                    content: 'Please check LexisNexis to see if there is any lien against the borrower.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '5',
            label: 'Insurance',
            list: [
                {
                    content: 'The premium for the insurance policy needs to have been paid by the Close of Escrow date. Official receipt from the insurance agent is best, but if you receive an email confirmation, it must include the policy number and property address.',
                    status: 0
                },
                {
                    content: 'Check if the property has NHD report, or if it’s in Flood zone, forest fire zone.',
                    status: 0
                },
                {
                    content: 'Lender requires a 438BFU Endorsement (Lender’s Loss Payee Endorsement) signed by insurance carrier.',
                    status: 0
                },
                {
                    content: 'Required Property Insurance coverage equal to the maximum commercially available amount, but no less than 100% of the replacement cost of all improvements on the rehabbed property (estimated coverage amount equal to $2,200,000.)',
                    status: 0
                },
                {
                    content: 'Insurance policy terms must be NO less than 12 months from funding date.',
                    status: 0
                },
                {
                    content: 'The property must be shown as vacant, if the borrower is not adding any sq.ft. We do not accept "forced" or "force-placed" insurance.',
                    status: 0
                },
                {
                    content: 'Policy must include replacement cost and stored materials coverage.',
                    status: 0
                },
                {
                    content: 'General Liability coverage must be at least $1,000,000.',
                    status: 0
                },
                {
                    content: 'California Fair Plan Insurance policies are not acceptable.',
                    status: 0
                },
                {
                    content: 'Evidence of Property Insurance must be provided on standard ACORD-27 form.',
                    status: 0
                },
                {
                    content: 'Certificate of Liability Insurance must be provided on standard ACORD-25 form.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '6',
            label: 'Preliminary Title Report',
            list: [
                {
                    content: 'Covenants, conditions and restrictions (known as CC&Rs) are required if mentioned in Preliminary Title Report.',
                    status: 0
                },
                {
                    content: 'Ensure no lien/no significant sq.ft difference on property from DataTree',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '7',
            label: 'Bank Statements - Recent 2 Months',
            list: [
                {
                    content: 'Make sure there is enough money in the account to make the down payment. If not, ask the borrower how they will make the initial payment',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '8',
            label: 'Borrower Being Entity (LLC/Inc)',
            list: [
                {
                    content: 'For borrowrs in Texas, Florida, Iowa, South Dakota, Kansa, and Oklahoma, where states have homestead protections, the borrower needs to be LLC/Inc, not an individual.',
                    status: 0
                },
                {
                    content: 'If there is a member of the LLC on the Operating Agreement who will not be signing the loan documents, a Borrower Resolution is required',
                    status: 0
                },
                {
                    content: 'The Operating Agreement must show ownership %. In accordance to our policies, anyone with more than 10% ownership must be a guarantor on the loan.',
                    status: 0
                },
                {
                    content: 'If you see there was a Manager change for the Entity, then make sure to review the Operating Agreement/Bylaws to see what the process is for changing Managers.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '9',
            label: 'Spousal Consent Form',
            list: [
                {
                    content: 'If the borrower’s  spouse is separated, then they still need to sign a spousal consent form. Also notify Title that they will need to prepare a quit claim deed for the spouse to sign unless an entity is signing on the spouse’s behalf',
                    status: 0
                }
            ],
            file: []
        }
    ]
    const manufacturedHomeCheckList = [
        {
            key: '2',
            label: 'Borrower’s ID',
            list: [
                {
                    content: 'Make sure the ID is NOT expired.',
                    status: 2
                },
                {
                    content: 'If borrower is a foreign national, please ask for a valid work visa (H1b, F1, L1, etc).',
                    status: 1
                },
                {
                    content: 'Make sure the borrower’s country is not on the sanction list by the U.S. government.',
                    status: 1
                }
            ],
            file: []
        },
        {
            key: '3',
            label: 'Purchase Agreement',
            list: [
                {
                    content: 'Check the purchase price and any condition. Ensure the buyer’s name matches the borrower’s name.',
                    status: 0
                },
                {
                    content: 'Ensure there is no seller carry back.',
                    status: 0
                },
                {
                    content: 'If there is any assignment fee, please escalate it.',
                    status: 0
                },
                {
                    content: 'Must include correct sales price, full down payment, make, model, year, and serial number of home.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '4',
            label: 'Borrower Credit',
            list: [
                {
                    content: 'Credit score > 640',
                    status: 0
                },
                {
                    content: 'No late payment, delinquency or fraud via LexisNexis. If you see them, please ask the borrower for explanation.',
                    status: 0
                },
                {
                    content: 'Please check LexisNexis to see if there is any lien against the borrower.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '1',
            label: 'Contract order form',
            list: [
                {
                    content: 'Obtain the signed contract order form.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '5',
            label: '30 day written payoff from lien-holders',
            list: [
                {
                    content: 'Secure the 30-day written payoff from lien-holders.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '6',
            label: 'VOI: Verification of Income',
            list: [
                {
                    content: 'Collect VOI documents.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '7',
            label: 'VOE: Verification of employment letter',
            list: [
                {
                    content: 'Obtain the VOE letter.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '8',
            label: 'Signed 4506-T',
            list: [
                {
                    content: 'Ensure the 4506-T form is signed.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '9',
            label: 'Source of Down Payment',
            list: [
                {
                    content: 'Bank statement, escrow receipts, etc. If gift, include gift letter, borrower deposit.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '10',
            label: 'Deposit Verification Form',
            list: [
                {
                    content: 'Collect the deposit verification form.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '11',
            label: 'Park Lease Agreement',
            list: [
                {
                    content: 'Required if home is in a park. Must include lot rent and manager’s signature.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '12',
            label: 'Community Certification',
            list: [
                {
                    content: 'Required if home is on private property not owned by customer.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '13',
            label: 'Landlord Waiver',
            list: [
                {
                    content: 'Obtain the landlord waiver.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '14',
            label: 'Copy of Mobile Home Title or MSO',
            list: [
                {
                    content: 'Must be in the seller’s name. If the seller is deceased, a copy of the death certificate is required.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '15',
            label: 'One year insurance policy',
            list: [
                {
                    content: 'Flood cert to be ordered by 21st mtg.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '16',
            label: 'Acknowledge of receipt of appraisal or NADA value',
            list: [
                {
                    content: 'Acknowledge receipt of appraisal or NADA value.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '17',
            label: 'Mortgage clause',
            list: [
                {
                    content: '21st Mortgage Corp, ISAOA, ATIMA, PO Box 477 Knoxville, TN 37901.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '18',
            label: "Driver's License(s) and Social Security Card(s)",
            list: [
                {
                    content: "Make sure both IDs are clear, legible copies of both the front and back of each. Make sure the Driver's License is not expired, and that no edges are cut off for both the Driver's License(s) and Social Security Card(s).",
                    status: 0
                },
            ],
            file: []
        },
        {
            key: '19',
            label: "Foreign Nationals",
            list: [
                {
                    content: "If applicable, provide a valid work visa (e.g., H1B, F1, L1). Ensure your country is not on the U.S. sanctions list.",
                    status: 0
                },
            ],
            file: []
        },
        {
            key: '20',
            label: 'Signed Listing Agreement',
            list: [
                {
                    content: 'Obtain the signed listing agreement.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '21',
            label: 'Primary Residence Statement',
            list: [
                {
                    content: 'Collect the primary residence statement.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '22',
            label: 'Mortgage Loan Origination Agreement',
            list: [
                {
                    content: 'Signed 1003 or 21st Mortgage Credit Application: Showing all applicant’s income, employment, and asset information.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '23',
            label: 'Auto Draft Form: (If required)',
            list: [
                {
                    content: 'Obtain the auto draft form, if required.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '24',
            label: 'Voided Check: (If required)',
            list: [
                {
                    content: 'Collect the voided check, if required.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '25',
            label: 'Person to Person Seller Interview',
            list: [
                {
                    content: 'Must have 2 contact phone numbers.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '26',
            label: 'Escrow Estimated Closing Cost Statement',
            list: [
                {
                    content: 'Obtain the escrow estimated closing cost statement.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '27',
            label: 'Appraisal',
            list: [
                {
                    content: 'Collect the appraisal document.',
                    status: 0
                }
            ],
            file: []
        },
        {
            key: '28',
            label: 'Foreign Nationals',
            list: [
                {
                    content: 'If applicable, provide a valid work visa (e.g., H1B, F1, L1). Ensure the country is not on the U.S. sanctions list.',
                    status: 0
                }
            ],
            file: []
        }
    ];
    
    

    const getUnderwritingChecklistData = () => {
        setLoading(true)
        let token = sessionStorage.getItem("ZeitroA")
        const paths = window.location.pathname.split('/');
        fetch('/los/getloancomments', {
          body: JSON.stringify(
            {LoanID: paths[paths.length - 1]}
          ),
          method: 'POST',
          headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
          }
        }).then(
          response => {
            if (response.status !== 200) {
              console.log('Looks like there was a problem. Status Code: ' +
                response.status);
              setLoading(false)
              alert("There was a problem. Please try again later.")
              return;
            }
            response.json().then(js => {
                if (props.application.property.propertytype === "mobilehome") {
                    setCheckList(manufacturedHomeCheckList)
                    return
                }
                let d = JSON.parse(js)
                if (d["pipeline"] !== undefined && d["pipeline"]["underwritingchecklist"] !== undefined) {
                    setCheckList(d["pipeline"]["underwritingchecklist"])
                } else {
                    setCheckList(defaultCheckList)
                }
                setLoading(false)
            });
          }
        ).catch(function (err) {
            console.log('Fetch Error :-S', err);
            setLoading(false)
          });
      }

    const updateUnderwritingChecklistData = (checklist) => {
    let token = sessionStorage.getItem("ZeitroA")
    const paths = window.location.pathname.split('/');

    fetch('/los/hardmoneyloan/updateunderwritingchecklist', {
        body: JSON.stringify(
        {
            LoanID: paths[paths.length - 1],
            Data: JSON.stringify(checklist),
        }
        ),
        method: 'POST',
        headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
        }
    }).then(
        response => {
        if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' +
            response.status);
            setLoading(false)
            return;
        }
        }
    ).catch(function (err) {
        console.log('Fetch Error :-S', err);
        setLoading(false)
        });
    }

    useEffect(() => {
        getLoanofficer()
        getUnderwritingChecklistData()
        const handleResize = () => {
            let width = window.innerWidth
            if (width < 1312) {
                setPageWidth(window.innerWidth - 100)
            } else {
                setPageWidth(1200)
            }
        }
        handleResize()
        window.addEventListener('resize', handleResize)
    
        return () => {
          window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        if (checkList.length > 0) {
            updateUnderwritingChecklistData(checkList)
        }
    }, [checkList])

    const disabledDate = (current) => {
        return current && current < dayjs().endOf('day');
    }
    const onChange = (key) => {
        console.log(key)
    }
    const checkAll = (item) => {
        return item.list.every(value => value.status === 1)
    }
    const onCheckAllChange = (e, item) => {
        const updatedCheckList = checkList.map((checkItem) => {
            if (checkItem.key === item.key) {
              const updatedList = checkItem.list.map((listItem) => {
                return {
                  ...listItem,
                  status: e.target.checked ? 1 : 0
                }
              })
        
              return {
                ...checkItem,
                list: updatedList
              }
            }
        
            return checkItem
        }) 
        setCheckList(updatedCheckList)
    }
    const onCheckItem = (e, item, index) => {
        const updatedCheckList = checkList.map((checkItem) => {
            if (checkItem.key === item.key) {
              const updatedList = checkItem.list.map((listItem, listIndex) => {
                if (listIndex === index) {
                  return {
                    ...listItem,
                    status: e.target.checked ? 1 : 0
                  }
                }
                return listItem
              })
        
              return {
                ...checkItem,
                list: updatedList
              }
            }
        
            return checkItem
        })
        
        setCheckList(updatedCheckList)
    }
    const overWrite = (list, index) => {
        setOverWriteListKey(list.key)
        setOverWriteListIndex(index)
        setOverWriteValue(1)
        setModalType(1)
        setIsOverWriteOpen(true)
    }
    const handleConfirm = () => {
        if (modalType === 1) {
            const updatedCheckList = checkList.map((checkItem) => {
                if (checkItem.key === overWriteListKey) {
                  const updatedList = checkItem.list.map((listItem, listIndex) => {
                    if (listIndex === overWriteListIndex) {
                      return {
                        ...listItem,
                        status: overWriteValue
                      }
                    }
                    return listItem
                  })
            
                  return {
                    ...checkItem,
                    list: updatedList
                  }
                }
            
                return checkItem
            })
            
            setCheckList(updatedCheckList)
        } else {
            props.handleApprove()
        }
        setIsOverWriteOpen(false)

    }
    const handleSave = () => {
        if (modalType === 3) {
            setFileIndex(0)
            setIsViewfilesOpen(true)
        } else if (modalType === 4) {
            form.validateFields().then((values) => {
                const comment = {
                    fullName: values.name,
                    category: values.category,
                    created: dayjs().format('MM/DD/YYYY'),
                    duedate: dayjs(values.dueDate).format('MM/DD/YYYY'),
                    detailes: values.comment,
                    status: 2,
                }
                addComments(comment)
                resetForm()
                setIsViewfilesOpen(false)
                alert("Comment added successfully. Loan-related comments will appear in the Communications section.")
            })
            .catch((errorInfo) => {
                console.log('Validation failed:', errorInfo)
            })

        } else {
            setLargeOpen(false)
        }
    }

    const addComments = (comment) => {
        let token = sessionStorage.getItem("ZeitroA")  
        fetch('/los/addloancomments', {
          body: JSON.stringify(
            {
                LoanID: props.loanid,
                Data: JSON.stringify(comment),
            }
          ),
          method: 'POST',
          headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
          }
        }).then(
          response => {
            setLoading(false)
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' + response.status)
                return
            }
            resetForm()
            setLargeOpen(false)
          }
        ).catch(function (err) {
            console.log('Fetch Error :-S', err);
            setLoading(false)
            resetForm()
            setLargeOpen(false)
          });
    }
    const handleCancel = () => {
        if (modalType === 1 || modalType === 2) {
            setIsOverWriteOpen(false)
        } else if (modalType === 3) {
            setLargeOpen(false)
        } else {
            resetForm()
            setLargeOpen(false)
        }
        
    }
    const overWriteChange = (e) => {
        setOverWriteValue(e.target.value)
    }
    const handleReject = () => {

        props.handleReject()
        Modal.info({
            title: 'The loan has been rejected',
            content: (
              <div>
                <p>The loan status has been updated to declined, but you can still change it using the top status bar.</p>
              </div>
            ),
            onOk() {},
          });
    }
    const handleApprove = () => {
        let allApprove = checkList.every(item => item.list.every(subItem => subItem.status === 1))
        if (allApprove) {
            setLargeOpen(true)
            setModalType(5)
        } else {
            setModalType(2)
            setIsOverWriteOpen(true)
        }
    }
    const openFileList = (item) => {
        if (item.file.length === 0) {
            return
        }
        if (item.file.length === 1) {
            setFile(item.file)
            setFileIndex(0)
            setIsViewfilesOpen(true)
        } else {
            setFile([])
            setModalType(3)
            setLargeOpen(true)
        }
        setCheckListKey(item.key)
    }
    const openComment = (item) => {
        setModalType(4)
        setLargeOpen(true)
        setCheckListKey(item.key)
    }
    const convertBytes = (bytes) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
        if (bytes === 0) return '0 Byte'
        const i = Math.floor(Math.log(bytes) / Math.log(1024))
        const size = parseFloat((bytes / Math.pow(1024, i)).toFixed(2))
        return `${size} ${sizes[i]}`
    }
    const onChangeSelectFile = (checkedValues) => {
        console.log('checked = ', checkedValues)
        let fileList = checkList.filter(item => { return item.key === checkListKey})[0].file
        const orderedCheckedValues = fileList.filter(item => checkedValues.includes(item)).map(item => checkedValues.find(value => value === item))
        setFile(orderedCheckedValues)
    }
    const currentListFile = () => {
        let fileList = checkList.filter(item => { return item.key === checkListKey})[0].file
        if (fileList.length > 0) {
            return (
                <div className='file-table-content'>
                    <Checkbox.Group style={{ width: '100%'}} value={file} onChange={onChangeSelectFile} >
                        {
                            fileList.map(item =>(
                                <div className='file-table-row' key={item.id}>
                                    <div className='checkBox-wrap'><Checkbox value={item} ></Checkbox></div>
                                    <div className='file-name'><img className='file-img' src='/images/pdf3.svg' /><div className='file-info'><div style={{color: '#222', fontWeight: 500}}>{ item.filename }</div><div style={{color: '#6E6E70'}}>{ convertBytes(item.size) }</div></div></div>
                                    <div className='type'>{ item.type }</div>
                                </div>
                            ))
                        }
                    </Checkbox.Group>
                </div>
            )
        }
    }
    const getDefaultActiveKey = () => {
        const list = checkList.find(item => item.list.some(subItem => subItem.status === 0 || subItem.status === 2))
        return list && list.length > 0 ? [list.key] : []
    }
    const validateMessages = {
        required: '${label} is required!',
    }
    const handleValuesChange = (changedValues, allValues) => {
        
    }

    const customHeader = (item) => (
        <div className='checkList-collapse-header'>
            <div className='collapse-title'>{item.label}</div>
            <div className='link-file'>
                {/* <span className={item.file.length > 0 ? '' : 'link-disabled'} onClick={() => openFileList(item)}>Link to file</span> */}
            </div>
            {
                item.list.some(value => value.status === 2) ? (
                    <img style={{width: 24, height: 24, cursor: 'pointer'}} src="/images/checkList-warning.svg" />
                ) : (
                    <div className='checkList-checkbox-wrap'><Checkbox className='checkList-checkbox' onChange={(e) => onCheckAllChange(e, item)} checked={checkAll(item)}></Checkbox></div>
                )
            }
            <div style={{flex: 1, textAlign: 'center'}}><img style={{cursor: 'pointer'}} onClick={() => openComment(item)} src='/images/checkList-msg.svg' /></div>
        </div>
    )
    const resetForm = () => {
        form.resetFields()
    }
    const CalcHardMoneyLoanRate = (props) =>  {
        let result = {
            unqualified: false,
            unqualifiedReason: "", 
            floorRate: 0,
            point: 0,
            monthlyPayment: 0,
            closingCost: 0,
        
        }
        let propertyType = props.application.property.propertytype
        let scre = props.application.hardmoneyloan.estimatedfico
        let borrowerExperience = props.application.hardmoneyloan.timesofflippingaproperty + props.application.hardmoneyloan.timesofpurchasingarentalproperty
        let loanamount = ""
        let ltv = 0
        if (props.application.property.salesprice==="") {
            ltv = 0
        } else {
            switch (props.application.property.purpose) {
            case "purchase":
                loanamount = props.application.hardmoneyloan.initialloanamount
                break;
            case "refinance":
                loanamount = props.application.hardmoneyloan.refinanceloanamount
                break;
            case "construction":
                loanamount = props.application.hardmoneyloan.constructionloanamount
                break;
            }
            ltv = 100 * com.safeParseInt(loanamount) / com.safeParseInt(props.application.property.salesprice)
        }
        
        let floorRate = 8
        let point = 1
        
        if (ltv>60 && ltv <=70) {
            floorRate += 0.5
        } else if (ltv>70 && ltv <=80) {
            floorRate += 1
        } else if (ltv > 80) {
            result.unqualified = true
            result.unqualifiedReason = "The maximum LTV is 80%, while current LTV is "+ Math.round(ltv) + "%."
        }
        
        if (scre >= 650) {
            if (scre >= 700 && scre < 730) {
            floorRate += 0.5
            }
            if (scre >= 650 && scre < 700) {
            floorRate += 0.5
            point += 0.5
            }
        } else {
            result.unqualified = true
            result.unqualifiedReason = "The minimum FICO score is 650, whereas the FICO score you have provided is " + scre + "."
        }
        
        if (borrowerExperience>=10 && borrowerExperience <= 20) {
            floorRate += 0.5
        } else if (borrowerExperience>=3 && borrowerExperience <10) {
            floorRate += 1
        } else if (borrowerExperience < 3) {
            floorRate += 1.5
            point += 0.5
        }
        
        if (propertyType === "commercial" || propertyType === "land") {
            floorRate += 3
            point += 1
        }
        if (propertyType === "condominium" || propertyType === "mixeduse") { 
            floorRate += 2
            point += 1
        }
        
        
        let mp = Math.round(com.MonthlyPayment(0.01 * floorRate/12, com.safeParseInt(loanamount), props.application.hardmoneyloan.mortgagetermmonths))
        let cc = Math.round(com.safeParseInt(loanamount) * point * 0.01)
        
        result.floorRate = floorRate
        result.point = point
        result.monthlyPayment = mp
        result.closingCost = cc
        return result
    }
    const calcLoanAmount = () => {
        let lm = ""
        if (props.property.purpose === "purchase") {
            lm = props.hardmoney.initialloanamount
        }
        if (props.property.purpose === "refinance") {
            lm = props.hardmoney.refinanceloanamount
        }
        if (props.property.purpose === "construction") {
            lm = props.hardmoney.constructionloanamount
        }
        return lm
    }
    const getLoanAmount = (props) => {
        let lm = calcLoanAmount(props)
        if (lm !== "") {
            return "$" + com.commaize(lm)
        }
        return "-"
    }
    const getOriginationPoint = (props) =>  {
        let lm = calcLoanAmount(props)
        if (lm !== "") {
            return com.commaize(parseInt(lm) / 100)
        }
        return "-"
    }
    const getAsIsValue = () => {
        if (props.property.salesprice !== "") {
            return "$" + com.commaize(props.property.salesprice)
        }
        return "-"
    }
    const getLTV = () => {
        if (props.property.salesprice !== "") {
            let sp = com.safeParseInt(props.property.salesprice)
            let lm = com.safeParseInt(calcLoanAmount())
            return Math.round(100 * lm / sp) + "%"
        }
        return "-"
    }
    let rateResult = CalcHardMoneyLoanRate(props)
    const modalContent = () => {
        if (modalType === 1) {
            return (
                <Radio.Group className="overWhite-group" onChange={overWriteChange} value={overWriteValue}>
                    <Space size={0} direction="vertical">
                        <div className="radio-item"><Radio value={1}><div className={`radio-text ${overWriteValue === 1 ? 'select-radio' : ''}`}>Yes, check this off the list.</div></Radio><img style={{width: 24, height: 24}} src="/images/checkList-warning.svg"/><img style={{width: 24, height: 24, margin: '0 4px'}} src="/images/double-right-arrow.svg"/><Checkbox className='checkList-checkbox' checked></Checkbox></div>
                        <div className="radio-item"><Radio value={0}><div className={`radio-text ${overWriteValue === 0 ? 'select-radio' : ''}`}>Leave this item unchecked.</div></Radio><img style={{width: 24, height: 24}} src="/images/checkList-warning.svg"/><img style={{width: 24, height: 24, margin: '0 4px'}} src="/images/double-right-arrow.svg"/><Checkbox className='checkList-checkbox' checked={false}></Checkbox></div>
                    </Space>
                </Radio.Group>
            )
        } else if (modalType === 2) {
            return <div className='approval-text'>The Conditional Approval function is intended solely for internal use.  No notification will be sent to the borrowers. Please ensure that all items in the underwriting checklist have been validated before  officially approving the loan application.</div>
        } else if (modalType === 3) {
            return (
                <div className='file-table'>
                    <div className='file-table-header'>
                        <div className='checkBox-wrap'></div>
                        <div className='file-name'>FILE NAME</div>
                        <div className='type'>TYPE</div>
                    </div>
                    {currentListFile()}
                </div>
            )
        } else if (modalType === 4) {
            return (
                <div className='comment-wrap'>
                    <Form
                        className='comment-form'
                        layout='vertical'
                        form={form}
                        onValuesChange={handleValuesChange}
                        validateMessages={validateMessages}
                        size="large"
                    >
                        <Row gutter={60}>
                                <Col span={24} sm={12} style={{marginBottom: '12px'}}>
                                    <Form.Item
                                        name="name" 
                                        label="Assign this comment to"
                                        rules={[
                                            {
                                                required: true,
                                            }
                                        ]}
                                    >
                                        <Select
                                            allowClear
                                            options={nameList}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24} sm={12} style={{marginBottom: '12px'}}>
                                    <Form.Item
                                        name="dueDate" 
                                        label="Due Date"
                                        rules={[
                                            {
                                                required: true,
                                            }
                                        ]}
                                    >
                                        <DatePicker
                                            style={{width: '100%'}}
                                            className='closing-date'
                                            disabledDate={disabledDate}
                                            placeholder="MM/DD/YYYY"
                                            format="MM/DD/YYYY"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        <Form.Item 
                            name="category" 
                            label="Category"
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                            <Input placeholder="Borrower Credit"  />
                        </Form.Item>
                        <Form.Item
                            name="comment"
                            label="Comment"
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                            <TextArea
                                autoSize={{
                                    minRows: 4,
                                    maxRows: 6
                                }}
                                placeholder="The following criteria is not satisfied: “Please check LexisNexis to see if there is any lien against the borrower”..."
                            />
                        </Form.Item>
                    </Form>
                </div>
            )
        } else {
            return (
                <div className='confirm-loan-wrap'>
                    <div className='confirm-loan-title'>Please confirm the loan terms</div>
                    <div className='confirm-loan-tips'>Once you click <span style={{fontWeight: 600}}>Approve</span>, borrowers will receive the approval notification, and the loan terms cannot be changed thereafter.</div>
                    <div className='loan-terms-card'>
                        <div className='loan-terms-item top'>
                            <div><span>Loan Amount</span><div><span>{getLoanAmount(props)}</span></div></div>
                            <div><span>As-Is-Value</span><div><span>{getAsIsValue()}</span></div></div>
                            <div><span>Loan-to-Value</span><div><span>{getLTV()}</span></div></div>
                        </div>
                        <div className='loan-terms-item middle'>
                            <div><span>Interest Rate</span><div><span>{rateResult.floorRate}%</span></div></div>
                            <div><span>Monthly Payment</span><div><span>${com.commaize(rateResult.monthlyPayment)}</span></div></div>
                            <div><span>Loan Term</span><div><span>{props.hardmoney.mortgagetermmonths} Months</span></div></div>
                        </div>
                        <div className='loan-terms-item bottom'>
                            <div><span>Processing Fee</span><div><span>$500</span></div></div>
                            <div><span>Origination Point</span><div><span>${getOriginationPoint(props)}</span><span className='explain'>(1%Loan Amount)</span></div></div>
                        </div>
                    </div>
                </div>
            )
        }
    }
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages)
        setPageNumber(1)
    }
    const prevPage = () => {
        if (pageNumber === 1) {
            return
        }
        setPageNumber(pageNumber - 1)
    }
    const nextPage = () => {
        if (pageNumber === numPages) {
            return
        }
        setPageNumber(pageNumber + 1)
    }
    const nextFile = () => {
        if (fileIndex === file.length - 1) {
            return
        }
        setFileIndex(fileIndex + 1)
    }
    const showAddComment = () => {
        setModalType(4)
        setLargeOpen(true)
    }

    const getLoanofficer = () => {
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/los/loanofficerlist', {
        method: 'GET',
        headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache",
            "X-Image-Free": "true",
        }
        }).then(
        response => {
            if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' +
                response.status)
            alert("Something went wrong. Please try again later.")
            return;
            }
            response.json().then(js => {
            if (js.Status !== "OK") {
                console.log("Error: " + js.Text)
            } else {
                let docs = js.LoanOfficers.map(item => ({value: `${item.FirstName} ${item.LastName}`, label: `${item.FirstName} ${item.LastName}`}))
                setNameList(docs)
            }})
        }).catch(function (err) {
        console.log('Fetch Error :-S', err)
      })
    }
    return (
        <div className="underwriting-checklist text-left">
            <Modal className='viewFiles-modal' maskClassName="viewFiles-modal-mask" centered width={1280} zIndex={1002} closable={false} open={isViewfilesOpen}
                footer={null}
            >
                <div className='viewFiles-content'>
                    <Document file={file[fileIndex]?.url} onLoadSuccess={onDocumentLoadSuccess} >
                        <Page pageNumber={pageNumber} width={pageWidth} />
                    </Document>
                </div>
                <div className='viewfiles-top-bar'>
                    <div><CloseOutlined className='close-icon' onClick={() => setIsViewfilesOpen(false)} /><img style={{width: 22, height: 22}} src='/images/pdf2.png' /><span className='viewfiles-top-name'>{file[fileIndex]?.filename}</span></div>
                    <Button key="submit" disabled={fileIndex === file.length - 1} className='viewFiles-modal-btn btn-default' type="primary" onClick={nextFile}>
                        Next File
                    </Button>
                    <div className='page-info'>{pageNumber} / {numPages}</div>
                </div>
                <div className='viewfiles-bottom-bar'>
                    <Button key="back" style={{marginRight: 30}} className='viewFiles-modal-btn btn-line' onClick={showAddComment}>
                        Add Comment
                    </Button>
                    <Button key="submit" className='viewFiles-modal-btn btn-default' type="primary" onClick={handleConfirm}>
                        Validate
                    </Button>
                </div>
                {pageNumber !== 1 && <img className='viewfiles-prevPage' style={{width: 69, height: 69}} src='/images/left-arrow.svg' onClick={prevPage} />}
                {pageNumber !== numPages && <img className='viewfiles-nextPage' style={{width: 69, height: 69}} src='/images/right-arrow.svg' onClick={nextPage} />}
            </Modal>
            <Modal className='overWrite-modal' width={524} getContainer={false} zIndex={1002} closable={false} open={isOverWriteOpen}
                footer={[
                    <Button key="back" className='overWrite-modal-btn btn-line' onClick={handleCancel}>
                      Never Mind
                    </Button>,
                    <Button key="submit" className='overWrite-modal-btn btn-default' type="primary" onClick={handleConfirm}>
                      Confirm
                    </Button>
                  ]}
            >
                <div className='overWrite-modal-title'>{ modalType === 1 ? 'Would you like to overwrite this auto alert?' : 'Conditionally approve this loan?' }</div>
                {modalContent()}
            </Modal>
            <Modal className={`large-modal ${modalType === 5 ? 'loan-terms-modal' : ''}`} zIndex={modalType === 4 ? 1003 : 1002} width={700} getContainer={false} open={largeOpen} onCancel={handleCancel}
                footer={[
                    <Button key="back" className='overWrite-modal-btn btn-line' onClick={handleCancel}>
                      {modalType === 5 ? 'Edit' : 'Cancel'}
                    </Button>,
                    <Button key="submit" disabled={modalType === 3 && file.length === 0} className='overWrite-modal-btn btn-default' type="primary" onClick={handleSave}>
                      { modalType === 3 ? 'Confirm' : modalType === 4 ? 'Save' : 'Approve'}
                    </Button>
                  ]}
            >
                {
                    modalType === 3 && (
                        <div className='large-modal-title'>
                            <div className='sub-title'>There are multiple files in this category.</div>
                            <div className='main-title'>Which files would you like to inspect?</div>
                        </div>
                    )
                }
                {modalContent()}
            </Modal>
            <Row className='underwriting-topBar'>
                <Col xl={12} span={24} className='underwriting-title'>Underwriting Checklist</Col>
                <Col xl={12} span={24} className='underwriting-operate'>
                    <Button key="back" style={{marginRight: 26}} className='overWrite-modal-btn btn-line' onClick={handleReject}>
                        Reject Application
                    </Button>
                    <Button key="submit" className='overWrite-modal-btn btn-default' type="primary" onClick={handleApprove}>
                        Approve
                    </Button>
                </Col>
            </Row>
            <div className='checklist'>
                <Collapse className='checkList-collapse' defaultActiveKey={getDefaultActiveKey()} collapsible="icon" ghost expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />} expandIconPosition="end" onChange={onChange}>
                    {
                        checkList.map(list => (
                            <Collapse.Panel header={customHeader(list)} key={list.key}>
                                {
                                    list.list.map((item, index) => (
                                        <div className='checkList-panel-item' key={index}>
                                            <div className='checkList-name'><div className='dot'></div><div style={{flex: 1}}>{item.content}</div></div>
                                            {
                                                item.status === 2 ? (
                                                    <img style={{width: 24, height: 24, cursor: 'pointer'}} onClick={() => overWrite(list, index)} src="/images/checkList-warning.svg" />
                                                ) : (
                                                    <div className='checkList-checkbox-wrap'><Checkbox className='checkList-checkbox' onChange={(e) => onCheckItem(e, list, index)} checked={item.status === 1}></Checkbox></div>
                                                )
                                            }
                                        </div>
                                    ))
                                }
                            </Collapse.Panel>
                        ))
                    }
                </Collapse>
            </div>
        </div>
    )
}

UnderwritingChecklist = connect(mapStateToProps, mapDispatchToProps)(UnderwritingChecklist);
