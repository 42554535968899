import React, { useState } from "react";
import { Select } from "antd";

import * as com from "../../Common";

import "./common.css";

interface StageProps {
  currentStage: string;
  loanID: string;
  handleStageChange: (loanID: string, stage: string) => void;
}

const CurrentStage = ({
  currentStage,
  loanID,
  handleStageChange,
}: StageProps) => {
  const items = Object.entries(com.CurrentStage).map(([key, _]) => {
    return {
      value: key,
      key: key,
      label: <div>{key}</div>,
    };
  });
  return (
    <div className="loansStageContainer">
      <div className="loansStage">
        <Select
          value={currentStage}
          options={items}
          onChange={(value) => {
            handleStageChange(loanID, value);
          }}
          suffixIcon={
            <img src="/images/stepper-menu-arrow-down.svg" alt="down-arrow" />
          }
        />
      </div>
    </div>
  );
};

export default CurrentStage;
