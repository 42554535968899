import React, { Component, useState, useEffect, useContext } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "./Interview1003View.css"
import "../App.css"
import { SimpleTip } from "../Common/Tooltip"
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Tabs } from 'antd-mobile'
import {
    Anchor,
    Dropdown,
    Space,
    Input,
    InputNumber,
    Checkbox,
    Select,
    Button,
    DatePicker,
    Spin,
    Alert,
    Skeleton,
} from "antd"
import dayjs from "dayjs"
import AddressAutocomplete from "../Common/AddressAutocomplete"
import IncomeSourcesTable, {
    OtherAssetsTable,
    LiabilitiesTable,
    PropertyMortgagesTable,
    GiftsTable,
    OtherLiabilitiesTable,
    OwnedPropertyLoansTable,
} from "./tables"
import { DownOutlined, LeftOutlined, RightOutlined, MinusSquareOutlined, MinusCircleOutlined } from "@ant-design/icons"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import { withRouter } from "react-router-dom"
import CheckGroup from "../Common/components/CheckGroup"
import InputGroup from 'react-bootstrap/InputGroup'
import * as st from "../State"
import * as act from "../Store/actions"
import { updateStatus } from "../Store/status"
import { getCurrentState, mergeState } from "../Store"

import { connect } from "react-redux"
import {
    UpdateChangeProgress,
    UpdateChangeVisitedStage,
    UpdateChangeLastTouch,
} from "../Store/progress"
import * as com from "../Common.js"
import { throttle, update } from "lodash"
import { forEach } from "jszip"
const { Option } = Select
const deepcopy = require('deepcopy')

const mapStateToProps = (state) => {
    return {
        application: state.application,
        borrower: state.borrower,
        progress: state.progress,
        selection: state.application.selection,
        status: state.updateStatus.status,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateBrokerInfo: (value, verb) => {
        dispatch(act.UpdateBrokerInfo(value, verb))
    },
    updateEqualOpportunitySub: (value, who, section, verb) => {
        dispatch(act.UpdateEqualOpportunitySub(value, who, section, verb))
    },
    addPreviousAddress: (event, who) => {
        dispatch(act.AddPreviousAddress(event.target.value, who))
    },
    updatePreviousAddress: (event, who, verb, n) => {
        dispatch(act.UpdatePreviousAddress(event.target.value, who, verb, n))
    },
    updateStatus: (status) => {
        dispatch(updateStatus(status))
    },
    changeBorrowerInfo: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.value, who, verb))
    },
    changePhoneInfo: (input, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(input, who, verb))
    },
    changeBorrowerInfoByValue: (val, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(val, who, verb))
    },
    changeBorrowerInfoByNumberValue: (val, who, verb) => {

        val = val === null ? '' : val.toString().replace(/\D/g, "")
        dispatch(act.ChangeBorrowerInfo(val, who, verb))
    },
    updateCoborrower: (event) => {
        dispatch(act.UpdateHasCoborrower(event))
    },
    changeOccupationInfo: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(event.target.value, who, verb))
    },
    changeOccupationInfoByValue: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(event, who, verb))
    },
    updateIncomeMonthly: (val, who, verb) => {
        dispatch(act.UpdateIncomeMonthly(val, who, verb))
    },
    updateIncomeMonthlyByVal: (event, who, verb) => {
        dispatch(act.UpdateIncomeMonthly(event, who, verb))
    },
    updateIncomeAtttribute: (t, who, verb) => {
        dispatch(act.UpdateIncomeAtttribute(t, who, verb))
    },
    addBorrowerOtherOccupation: (who) => {
        dispatch(act.AddBorrowerOtherOccupation(who))
    },
    removeBorrowerOtherOccupation: (who, index) => {
        dispatch(act.RemoveBorrowerOtherOccupation(who, index))
    },
    clearBorrowerOtherOccupations: (who) => {
        dispatch(act.ClearBorrowerOtherOccupations(who))
    },
    changeBorrowerOtherOccupationValue: (t, i, who, verb) => {
        dispatch(act.ChangeBorrowerOtherOccupationValue(t, i, who, verb))
    },
    changeBorrowerPreviousOccupationIncome: (t, i, who, verb) => {
        dispatch(act.ChangeBorrowerPreviousOccupationIncome(t, i, who, verb))
    },
    addBorrowerPreviousOccupation: (who) => {
        dispatch(act.AddBorrowerPreviousOccupation(who))
    },
    removeBorrowerPreviousOccupation: (who, index) => {
        dispatch(act.RemoveBorrowerPreviousOccupation(who, index))
    },
    clearBorrowerPreviousOccupations: (who) => {
        dispatch(act.ClearBorrowerPreviousOccupations(who))
    },
    changeBorrowerPreviousOccupationValue: (t, i, who, verb) => {
        dispatch(act.ChangeBorrowerPreviousOccupationValue(t, i, who, verb))
    },
    changeOccupationCheck: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(event.target.checked, who, verb))
    },
    addAdditionaOccupation: (event, who) => {
        dispatch(act.AddAdditionaOccupation(event, who))
    },
    removeAdditionalOccupation: (event, who, i) => {
        dispatch(act.RemoveAdditionalOccupation(event.target.value, who, i))
    },
    clearAdditionalOccupations: (event, who) => {
        dispatch(act.ClearAdditionalOccupations(event, who))
    },
    bulkAddBorrowerOwnedProperties: (t) => {
        dispatch(act.BulkAddBorrowerOwnedProperties(t))
    },
    addBorrowerOwnedProperty: (t) => {
        dispatch(act.AddBorrowerOwnedProperty(t))
    },
    addCoborrowerOwnedProperty: (t) => {
        dispatch(act.AddCoborrowerOwnedProperty(t))
    },
    changeBorrowerOwnedProperty: (t, i, v) => {
        dispatch(act.ChangeBorrowerOwnedProperty(t, i, v))
    },
    changeCoborrowerOwnedProperty: (t, i, v) => {
        dispatch(act.ChangeCoborrowerOwnedProperty(t, i, v))
    },
    removeBorrowerOwnedProperty: (i) => {
        dispatch(act.RemoveBorrowerOwnedProperty(i))
    },
    removeCoborrowerOwnedProperty: (i) => {
        dispatch(act.RemoveCoborrowerOwnedProperty(i))
    },
    clearBorrowerOwnedProperty: (t) => {
        dispatch(act.ClearBorrowerOwnedProperty(t))
    },
    clearCoborrowerOwnedProperty: (t) => {
        dispatch(act.ClearCoborrowerOwnedProperty(t))
    },
    updateExpenses: (val, who, verb) => {
        dispatch(act.UpdateExpenses(val, who, verb))
    },
    updateBorrowerAdditionalIncome: (event, who, verb, n) => {
        dispatch(
            act.UpdateBorrowerAdditionalIncome(event.target.value, who, verb, n)
        )
    },
    changePastOccupationInfo: (event, who, verb, index) => {
        dispatch(
            act.ChangePastOccupationInfo(event.target.value, who, verb, index)
        )
    },
    changePastOccupationDate: (date, who, verb, index) => {
        dispatch(act.ChangePastOccupationInfo(date, who, verb, index))
    },
    changePastOccupationPhone: (phone, who, verb, index) => {
        dispatch(act.ChangePastOccupationInfo(phone, who, verb, index))
    },
    changePastOccupationCheck: (event, who, verb, index) => {
        dispatch(
            act.ChangePastOccupationInfo(event.target.checked, who, verb, index)
        )
    },
    addBorrowerAdditionalIncome: (t, who) => {
        dispatch(act.AddBorrowerAdditionalIncome(t, who))
    },
    removeBorrowerAdditionalIncome: (i, who) => {
        dispatch(act.RemoveBorrowerAdditionalIncome(i, who))
    },
    clearBorrowerAdditionalIncome: (who) => {
        dispatch(act.ClearBorrowerAdditionalIncome(who))
    },
    updateBorrowerAdditionalIncome: (event, who, verb, n) => {
        dispatch(
            act.UpdateBorrowerAdditionalIncome(event.target.value, who, verb, n)
        )
    },
    changeMobileHomeInfo: (value, verb) => {
        dispatch(act.ChangeMobileHomeInfo(value, verb))
    },
    changeMainProperty: (value, verb) => {
        dispatch(act.ChangeMainProperty(value, verb))
    },
    changeMainPropertyPurpose: (val) => {
        dispatch(act.ChangeMainPropertyPurpose(val))
    },

    changeMainPropertyAttribute: (t, verb) => {
        dispatch(act.ChangeMainPropertyAttribute(t, verb))
    },
    updateSelectField: (input, verb) => {
        dispatch(act.UpdateSelectField(input, verb))
    },
    clearBorrowerGift: () => {
        dispatch(act.ClearBorrowerGift())
    },
    changeDeclaration: (event, who, verb) => {
        dispatch(act.ChangeDeclaration(event, who, verb))
    },
    updateEqualOpportunity: (value, who, verb) => {
        dispatch(act.UpdateEqualOpportunity(value, who, verb))
    },
    updateEqualOpportunitySub: (value, who, section, verb) => {
        dispatch(act.UpdateEqualOpportunitySub(value, who, section, verb))
    },
    updateChangeProgress: (step, stage) => {
        dispatch(UpdateChangeProgress(step, stage))
    },
    updateChangeLastTouch: (t) => {
        dispatch(UpdateChangeLastTouch(t))
    },
    updateChangeVisitedStage: (step, stage) => {
        dispatch(UpdateChangeVisitedStage(step, stage))
    },
    updateApplicationAttribute: (input, verb) => {
        dispatch(act.UpdateApplicationAttribute(input, verb))
    },
    changeBorrowerOtherOccupationIncome: (t, i, who, verb) => {
        dispatch(act.ChangeBorrowerOtherOccupationIncome(t, i, who, verb))
    },
})

class Interview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editMode: true,
            loanLimits: [],
            showOwnPropertyWarning1: false,
            showOwnPropertyWarning2: false,
            activeKey: 's1',
            offsetTop: '',
            handingOff: false,
            currentView: props.view || "borrower",
            bodependentages: [],
            codependentages: [],
            loading: false,
            companyInfo: {},
            loInfo: {},
        }
        this.throttleScroll = throttle(
            () => {
                let currentKey = this.tabItems[0].key
                for (const item of this.tabItems) {
                    const element = document.getElementById(`${item.key}`)
                    if (!element) continue
                    const rect = element.getBoundingClientRect()
                    if (rect.top <= 360) {
                        currentKey = item.key
                    } else {
                        break
                    }
                }
                this.setState({ activeKey: currentKey })
            },
            {
                leading: true,
                trailing: true,
                wait: 2000,
            }
        )
    }

    notifyBorrowerAboutNewAccount = e => {
        this.setState({ handingOff: true })
        let token = sessionStorage.getItem("ZeitroA")
        let id = this.props.borrower.id
        let body = { token: id, type: this.props.handingOffType }
        fetch('/borrower/handoff', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(
            response => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Please try later.')
                    this.setState({ handingOff: false })
                    return
                }
                response.json().then(js => {
                    this.setState({ handingOff: false })
                    alert("An email has been sent to your borrower instructing them to log in and continue with the loan application.")
                })
            }
        ).catch((err) => {
            console.log('Fetch Error :', err)
        })
    }
    getCompanyProfile = () => {
        console.log("getting company profile")
        this.setState({ loading: true })
        let token = sessionStorage.getItem("ZeitroA")
        fetch("/los/getcompanyprofilesetup", {
            cache: 'no-cache',
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(res => {
            if (!res.ok) {
                this.setState({ loading: false })
            } else {
                res.json().then(js => {
                    let data = js
                    let addresses = data.Address.split(",")
                    let street = ""
                    let city = ""
                    let state = ""
                    let zip = ""
                    if (addresses.length === 4){
                        street = addresses[0]
                        city = addresses[1]
                        state = addresses[2]
                        zip = addresses[3]
                    }
                    if (addresses.length === 3){
                        street = addresses[0]
                        city = addresses[1]
                        state = addresses[2].split(" ")[0]
                        zip = addresses[2].split(" ")[1]
                    }
                    let info = {
                        name: data.Name,
                        street: street,
                        city: city,
                        state: com.convertAbbStateToFull(state),
                        zip: zip,
                        nmls: data.CompanyNMLS,
                        licenseid: data.LicenseId,
                    }

                    this.setState({ companyInfo: info, loading: false })
                    let brokerinfo = this.props.application.brokerinfo
                    Object.keys(info).forEach(key => {
                        if (key === "state"){
                            if (brokerinfo[key] === "" || com.convertAbbStateToFull(info[key]) === brokerinfo[key]) {
                                this.props.updateBrokerInfo(com.convertAbbStateToFull(info[key]), key)
                            }
                        }
                        else{
                            if (brokerinfo[key] === "" || info[key] === brokerinfo[key]) {
                                this.props.updateBrokerInfo(info[key], key)
                            }
                        }
                    })
                })
            }
        }).catch(err => {
            this.setState({ companyProfile: {} })
            console.log("Revalidate catch error " + err)
        })
    }

    getLOProfile = () => {
        console.log("getting lo profile")
        this.setState({ loading: true })
        let token = sessionStorage.getItem("ZeitroA")
        const paths = window.location.pathname.split('/')
        fetch("/los/getloanprocessor", {
            body: JSON.stringify({ LoanID: paths[paths.length - 1] }),
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(res => {
            if (!res.ok) {
                this.setState({ loading: false })
            } else {
                res.json().then(js => {
                    this.setState({ loading: false, loInfo: js.CurrentLO })
                    let info = {
                        loanofficername: js.CurrentLO.FirstName + " " + js.CurrentLO.LastName,
                        phone: js.CurrentLO.Phone,
                        email: js.CurrentLO.Email,
                        loanofficernmls: js.CurrentLO.NMLS,
                        loanofficerlicenseid: js.CurrentLO.LicenseID,
                    }
                    let brokerinfo = this.props.application.brokerinfo
                    Object.keys(info).forEach(key => {
                        if (brokerinfo[key] === "" || info[key] === brokerinfo[key]) {
                            this.props.updateBrokerInfo(info[key], key)
                        }
                    })
                })
            }
        }).catch(err => {
            this.setState({ loading: false })
            console.log("Revalidate catch error " + err)
        })
    }


    getLoanLimits = () => {
        fetch("/data/loanlimits")
            .then((res) => {
                if (!res.ok) {
                    throw Error(res.statusText)
                }
                return res.json()
            })
            .then((res) => {
                this.setState({ loanLimits: res })
            })
            .catch((err) => {
                console.error(err)
            })
    };

    getCountyOptions = (st) => {
        let options = []
        options.push({value:"", label:""})
        let s = st.toUpperCase()
        if (st.length === 2) {
            s = com.convertAbbStateToFull(st).toUpperCase()
        }

        if (s in this.state.loanLimits) {
            let keys = Object.keys(this.state.loanLimits[s])

            keys.forEach((x) => {
                options.push({ value: com.capitalize(x), label: com.capitalize(x) })
            })
        }
        return options
    };

    getStateOptions = () => {
        let options = []
        options.push({value:"",label:""})
        let keys = Object.keys(this.state.loanLimits)

        keys.forEach((x) => {
            options.push({
                value: com.capitalize(x),
                label: com.capitalize(x),
            })
        })
        return options
    };

    updateIncomeMonthly = (val, who, attr) => {
        // this.props.updateIncomeMonthlyByVal("monthly", who, attr + "monthly")
        this.props.updateIncomeMonthly(val, who, attr)
    }

    dateField = (label, value, onChangeFunc, placeholder) => {
        let dateFormat = "MM/DD/YYYY"
        let earliest = "01/01/1900"
        return (
            <div>
                {label === null ? (
                    ""
                ) : (
                    <div style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}>
                        {label}
                    </div>
                )}
                {this.state.editMode ? (
                    <DatePicker
                        size="large"
                        style={value === '' && this.props.mode !== 'document' ? { border: '1px solid #faad14' } : {}}
                        value={value === "" ? value : dayjs(value, dateFormat)}
                        format={dateFormat}
                        onChange={onChangeFunc}
                    />
                ) : (
                    <div style={{ fontSize: 16 }}>{value}</div>
                )}
            </div>
        )
    };

    inputField = (label, value, onChangeFunc, placeholder, out) => {
        return (
            <div>
                {label === null ? (
                    ""
                ) : (
                    <div style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}>
                        {label}
                    </div>
                )}
                {this.state.editMode ? (
                    <InputGroup>
                        <Form.Control
                            value={value}
                            defaultValue={value}
                            style={value === '' && !out && this.props.mode !== 'document' ? { border: '1px solid #faad14' } : {}}
                            onChange={onChangeFunc}
                            placeholder={typeof placeholder !== "undefined" ? placeholder : ""}
                            aria-describedby="basic-addon2"
                        />
                        {value === '' && !out && <div className="ExclamationCircleOutlined"><ExclamationCircleOutlined /></div>}

                    </InputGroup>
                ) : (
                    <div style={{ fontSize: 16 }}>{value}</div>
                )}
            </div>
        )
    };

    updateDependentsAges = (idx, age, ages, currentView, verb) => {
        let newages = ages
        if (age == "") {
            newages[idx] = " "
        } else {
            newages[idx] = age
        }
        let agelist = newages.join(",").trim();
        if (newages.every(age => age.trim() === "")) {
            agelist = "";
        }
        this.props.changeBorrowerInfo({target: {value: agelist}}, currentView, verb)
    }

    getAgesFields = (num, ages, currentView, verb) => {
        let fields = []
        for (let i = 0; i < num; i++) {
            fields.push(
                <Col sm={6} className="mb-4">
                    {this.inputFieldWithPrefix(`Age of dependent ${i + 1}`, ages[i], (e) => this.updateDependentsAges(i,e.target.value, ages, currentView, verb), "Age")}
                </Col>
            )
        }
        return fields
    }

    inputFieldWithPrefix = (label, value, onChangeFunc, prefix, tipContent, out, onBlurFunc) => {
        return (
            <div>
                {label !== null ? (
                    <div style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}>
                        {label}
                    </div>
                ) : (
                    <div style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}>
                        &nbsp;
                    </div>
                )}

                {this.state.editMode ? (
                    <Input
                        value={value}
                        status={value === '' && (prefix === 'Phone' || prefix === 'Email' || prefix === '$') && !out && this.props.mode !== 'document' ? 'warning' : null}
                        onChange={onChangeFunc}
                        addonBefore={typeof tipContent !== "undefined" ? <div>{prefix}<SimpleTip content={tipContent} /> </div> : prefix}
                        size="large"
                        onBlur={onBlurFunc}
                    />
                ) : (
                    <div style={{ fontSize: 16 }}>{prefix === "$" ? prefix + " " + value : prefix + ": " + value}</div>
                )}
            </div>
        )
    };
    inputNumberFields = (label, values, onChangeFuncs, prefixes, suffixes) => {
        let inputFields = []
        for (let i = 0; i < values.length; i++) {
            inputFields.push(
                this.state.editMode ? (
                    <InputNumber
                        status={values[i] === '' && this.props.mode !== 'document' ? 'warning' : null}
                        value={values[i]}
                        defaultValue={values[i]}
                        onChange={onChangeFuncs[i]}
                        addonBefore={prefixes !== null ? prefixes[i] : ""}
                        addonAfter={suffixes !== null ? suffixes[i] : ""}
                        size="large"
                        className="pr-2"
                    />
                ) : (
                    <div style={{ fontSize: 16 }} className="pr-2">
                        {values[i] + " " + suffixes[i]}
                    </div>
                )
            )
        }

        return (
            <div>
                {label !== null ? (
                    <div style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}>
                        {label}
                    </div>
                ) : (
                    <div style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}>
                        &nbsp;
                    </div>
                )}
                <div className="d-flex">{inputFields}</div>
            </div>
        )
    };
    inputFields = (label, values, onChangeFuncs, prefixes, suffixes, outs) => {
        let inputFields = []
        for (let i = 0; i < values.length; i++) {
            inputFields.push(
                this.state.editMode ? (
                    <Input
                        status={values[i] === '' && !outs[i] && this.props.mode !== 'document' ? 'warning' : null}

                        value={values[i]}
                        onChange={onChangeFuncs[i]}
                        addonBefore={prefixes !== null ? prefixes[i] : ""}
                        suffix={suffixes !== null ? suffixes[i] : ""}
                        className={i === values.length - 1 ? "" : "pr-4"}
                    />
                ) : (
                    <div
                        style={{ fontSize: 16 }}
                        className={i === values.length - 1 ? "" : "pr-4"}
                    >
                        {prefixes[i] + ": " + values[i] + " " + suffixes[i]}
                    </div>
                )
            )
        }

        return (
            <div>
                {label !== null && (
                    <div style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}>
                        {label}
                    </div>
                )}
                <div className="d-flex">{inputFields}</div>
            </div>
        )
    };
    dropdownField = (label, value, options, onChangeFunc, out) => {
        if (!options) return ""
        let selected = options.filter((o) => o.value === value)
        return (
            <div>
                {label === null ? (
                    ""
                ) : (
                    <div style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}>
                        {label}
                    </div>
                )}
                {this.state.editMode ? (
                    <>

                        <Form.Control
                            as="select"
                            defaultValue={value}
                            value={value}
                            onChange={onChangeFunc}
                            style={value === '' && !out && this.props.mode !== 'document' ? { border: '1px solid #faad14' } : {}}
                        >
                            {options.map((o) => (
                                <option value={o.value}>{o.label}</option>
                            ))}
                        </Form.Control>
                    </>
                ) : (
                    <div style={{ fontSize: 16 }}>
                        {selected.length > 0 ? selected[0].label : ""}
                    </div>
                )}
            </div>
        )
    };
    horizontalDropdownField = (label, value, options, onChangeFunc) => {
        let selected = options.filter((o) => o.value === value)
        return (
            <div className="d-flex justify-content-between align-items-center">
                <div className="mr-2 col-7">{label}</div>
                {this.state.editMode ? (
                    <Form.Control
                        as="select"
                        defaultValue={value}
                        onChange={onChangeFunc}
                    >
                        {options.map((o) => (
                            <option value={o.value}>{o.label}</option>
                        ))}
                    </Form.Control>
                ) : (
                    <div style={{ fontSize: 16 }}>
                        {selected.length > 0 ? selected[0].label : ""}
                    </div>
                )}
            </div>
        )
    };
    selectionField = (label, value, options, onChangeFunc, isHorizontal, isVerticalgroup) => {
        let selected = options.filter((o) => o.value === value)
        if (isHorizontal) {
            return (
                <div className="d-flex justify-content-between">
                    <div className="mr-2">{label}</div>
                    {this.state.editMode ? (
                        <CheckGroup
                            data={options}
                            onChangeSelect={onChangeFunc}
                            value={value}
                            type="radio"
                            style={{
                                width: "100%",
                                ...(value === '' && {
                                    padding: '11px 5px 0px 5px',
                                    ...(this.props.mode !== 'document' && { border: '1px solid #faad14' })
                                })
                            }}
                            direction="horizontal"
                        />
                    ) : (
                        <div style={{ fontSize: 16 }}>
                            {selected.length > 0 ? selected[0].label : ""}
                        </div>
                    )}
                </div>
            )
        } else {
            return (
                <div>
                    <div style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}>
                        {label}
                    </div>
                    {this.state.editMode ? (
                        <CheckGroup
                            mode={this.props.mode === 'document' ? 'document-checkGroup' : 'normal'}
                            data={options}
                            onChangeSelect={onChangeFunc}
                            value={value}
                            type="radio"
                            style={{
                                width: "100%",
                                ...((value === '' || value === null) && {
                                    padding: '11px 5px 0px 5px',
                                    ...(this.props.mode !== 'document' && { border: '1px solid #faad14' })
                                })
                            }}
                            direction={isVerticalgroup ? "vertical" : "horizontal"}
                        />
                    ) : (
                        <div style={{ fontSize: 16 }}>
                            {selected.length > 0 ? selected[0].label : ""}
                        </div>
                    )}
                </div>
            )
        }
    };
    yesNoField = (label, value, onChangeFunc) => {
        let options = [
            {
                value: "yes",
                label: "Yes",
            },
            {
                value: "no",
                label: "No",
            },
        ]
        let selected = options.filter((o) => o.value === value)
        return (
            <div className="d-flex justify-content-between align-items-center">
                <div className="mr-2 fontWeight500 col-7 pl-0">{label}</div>
                {this.state.editMode ? (
                    <CheckGroup
                        data={options}
                        onChangeSelect={onChangeFunc}
                        value={value}
                        type="radio"
                        style={{ width: "100%" }}
                        direction="horizontal"
                    />
                ) : (
                    <div style={{ fontSize: 16 }}>
                        {selected.length > 0 ? selected[0].label : ""}
                    </div>
                )}
            </div>
        )
    };
    onAddressChange = (e) => {
        let addr = e.target.value
        if (addr === "") {
            this.props.changeMainProperty(false, "lookupsuccessful")
        }
        return this.props.changeBorrowerInfo(
            e,
            "borrower",
            "presentaddressstreet"
        )
    };
    onStreetAddressCompletion = (address, who, isMaillingAddress) => {
        let num = com.getSubaddress(address, "street_number", "long_name")

        let city = com.getSubaddress(address, "locality", "long_name")
        let state = com.getSubaddress(
            address,
            "administrative_area_level_1",
            "short_name"
        )
        let zip = com.getSubaddress(address, "postal_code", "long_name")

        let county = com.getSubaddress(
            address,
            "administrative_area_level_2",
            "long_name"
        )
        let longstate = com.getSubaddress(
            address,
            "administrative_area_level_1",
            "long_name"
        )

        let prefix = "present"
        if (isMaillingAddress === true) {
            prefix = "mailing"
        }

        this.props.changePhoneInfo(city, who, prefix + "addresscity")
        this.props.changePhoneInfo(county, who, prefix + "addresscounty")
        this.props.changePhoneInfo(com.capitalize(com.convertAbbStateToFull(state)), who, prefix + "addressstate")
        this.props.changePhoneInfo(zip, who, prefix + "addresszip")
        let sfx = ""
        let short = com.getSubaddress(address, "route", "short_name")

        let stradd = num + " " + short
        this.props.changeBorrowerInfo(
            { target: { value: stradd } },
            who,
            prefix + "addressstreet"
        )

    };

    onPrevAddressCompletion = (address, who) => {
        let num = com.getSubaddress(address, "street_number", "long_name");
        let city = com.getSubaddress(address, "locality", "long_name");
        let state = com.getSubaddress(
          address,
          "administrative_area_level_1",
          "short_name"
        );
        let zip = com.getSubaddress(address, "postal_code", "long_name");

        let county = com.getSubaddress(
          address,
          "administrative_area_level_2",
          "long_name"
        );
        let longstate = com.getSubaddress(
          address,
          "administrative_area_level_1",
          "long_name"
        );

        this.props.updatePreviousAddress({target: {value : city}}, who, "city", 0)
        this.props.updatePreviousAddress({target: {value : county}}, who, "county", 0)
        this.props.updatePreviousAddress({target: {value : com.capitalize(com.convertAbbStateToFull(state))}}, who, "state", 0)
        this.props.updatePreviousAddress({target: {value : zip}}, who, "zip", 0)
        let short = com.getSubaddress(address, "route", "short_name");
        let stradd = num + " " + short;
        this.props.updatePreviousAddress({target: {value : stradd}}, who, "street", 0)

      }

    getTotalIncome = (who) => {
        let emp = this.props.application[who].occupation
        let i1 = parseFloat(emp.income.base === "" ? 0 : emp.income.base)
        let i2 = parseFloat(emp.income.bonuses === "" ? 0 : emp.income.bonuses)
        let i3 = parseFloat(emp.income.commissions === "" ? 0 : emp.income.commissions)
        let i4 = parseFloat(emp.income.overtime === "" ? 0 : emp.income.overtime)
        let i5 = parseFloat(emp.income.military === "" ? 0 : emp.income.military)
        let i6 = parseFloat(emp.income.other === "" ? 0 : emp.income.other)
        return i1 + i2 + i3 + i4 + i5 + i6
    }
    renderOwnedProperty = (i, isSubjectProperty,isExtra, currentView) => {
        let property
        if (isSubjectProperty) {
            property = this.props.application.property
        } else {
            property = this.props.application[currentView].ownedproperties[i]
        }
        this.section1aDom('3a. Property You Own', property, ['address', 'state', "county", 'zipcode', "presentaddressstreet", 'insurancemaintenancetaxes'])
        const changeAttribute = (val, i, key) => {
            if (isSubjectProperty) {
                this.props.changeMainPropertyAttribute(val, key)
            } else {
                if (currentView === "borrower") {
                    this.props.changeBorrowerOwnedProperty(val, i, key)
                }
                if (currentView === "coborrower") {
                    this.props.changeCoborrowerOwnedProperty(val, i, key)
                }
            }
        }

        return (
            <>
                {isExtra && <div className="form1003Title d-flex justify-content-between">
                    <div>Extra Property {i - 2}</div>
                    <div className="text-right">
                        <MinusSquareOutlined
                            onClick={() => {
                                if (currentView === "borrower") {
                                    this.props.removeBorrowerOwnedProperty(i)
                                }
                                if (currentView === "coborrower") {
                                    this.props.removeCoborrowerOwnedProperty(i)
                                }
                            }}
                            style={{ color: "#325CEB", fontSize: 16, cursor: "pointer" }}
                        />
                    </div>
                </div>}
                <div className={isExtra ? "p-4" : ""}>
                    <Row className="">
                        <Col>
                            {this.state.editMode ? <AddressAutocomplete
                                id={isSubjectProperty ? "SubjectPropertyAddress" + i : "OwnedPropertyAddress" + i}
                                label={<div className="fontWeight500">Address</div>}
                                required={true}
                                name="addessline"
                                border={true}
                                style={property.address === '' && this.props.mode !== 'document' ? { border: '1px solid #faad14' } : {}}
                                defaultValue={property.address}
                                onChange={(e) => changeAttribute(e.target.value, i, "address")}
                                pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                placeholder="Please choose from the suggested addresses generated based on the input."
                                goodfeedback="Looks good!"
                                badfeedback="Please provide your street address."
                                onCompletion={e => {
                                    let address = e
                                    let state = com.getSubaddress(
                                        address,
                                        "administrative_area_level_1",
                                        "short_name"
                                    )
                                    let zip = com.getSubaddress(address, "postal_code", "long_name")
                                    let city = com.getSubaddress(address, "locality", "long_name")
                                    let county = com.getSubaddress(
                                        address,
                                        "administrative_area_level_2",
                                        "long_name"
                                    )
                                    let num = com.getSubaddress(address, "street_number", "long_name")
                                    let short = com.getSubaddress(address, "route", "short_name")
                                    let stradd = num + " " + short + ", " + city + ", " + state + " " + zip
                                    changeAttribute(com.capitalize(com.convertAbbStateToFull(state)), i, "state")
                                    changeAttribute(county, i, "county")
                                    changeAttribute(city, i, "city")
                                    changeAttribute(zip, i, "zipcode")
                                    changeAttribute(stradd, i, "address")
                                }}
                                size="md"
                            /> : <div className="fontSize16">{property.address}</div>}

                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4} className="mb-4">
                            {this.dropdownField(
                                "State",
                                property.state,
                                this.getStateOptions(),
                                (e) => changeAttribute(e.target.value, i, "state")
                            )}
                        </Col>
                        <Col sm={4} className="mb-4">
                            {this.dropdownField(
                                "County",
                                property.county,
                                this.getCountyOptions(property.state),
                                (e) => changeAttribute(e.target.value, i, "county")
                            )}
                        </Col>
                        <Col sm={4} className="mb-4">
                            {this.inputField(
                                "Zip",
                                property.zipcode,
                                (e) => changeAttribute(e.target.value, i, "zipcode"),
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} className="mb-4">
                            {this.inputFieldWithPrefix(
                                "Property value",
                                isSubjectProperty ? property.appraisal : property.marketvalue,
                                (e) =>
                                    changeAttribute(e.target.value, i, isSubjectProperty ? "appraisal" : "marketvalue"),
                                "$"
                            )}
                        </Col>
                        <Col sm={6} className="mb-4">
                            {this.dropdownField(
                                "Status",
                                property.status,
                                [
                                    { value: "sold", label: "Sold" },
                                    { value: "pending", label: "Pending sale" },
                                    { value: "retained", label: "Retained" },
                                ],
                                (e) => changeAttribute(e.target.value, i, "status"),
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} className="mb-4">
                            {this.dropdownField(
                                "Intended occupancy",
                                property.occupancy,
                                [
                                    { value: "principal", label: "Primary Residence" },
                                    { value: "secondhome", label: "Second Home" },
                                    { value: "investment", label: "Investment" },
                                    isSubjectProperty ? { value: "fhasecond", label: "FHA Secondary Residence" }
                                    :
                                    { value: "other", label: "Other" },
                                ],
                                (e) =>
                                    changeAttribute(e.target.value, i, "occupancy")
                            )}
                        </Col>
                        <Col sm={6} className="mb-4">
                            {this.dropdownField(
                                "Property type",
                                property.propertytype,
                                [
                                    { value: isSubjectProperty ? "singlefamilyattached" : "singlefamily", label: "Single family" },
                                    { value: isSubjectProperty ? "singlefamilydetached" : "townhouse", label: "Townhouse" },
                                    { value: isSubjectProperty ? "twotofourfamily" : "twotofour", label: "Multi family" },
                                    { value: "condominium", label: "Condo" },
                                ],
                                (e) =>
                                    changeAttribute(e.target.value, i, "propertytype")
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            {this.inputFieldWithPrefix(
                                "Monthly insurance, taxes, association dues, etc.",
                                property.insurancemaintenancetaxes,
                                (e) => changeAttribute(e.target.value, i, "insurancemaintenancetaxes"),
                                "$"
                            )}
                        </Col>
                    </Row>
                    {(
                        property.occupancy === "investment" ||
                        property.propertytype === "twotofour"
                    ) && <Row className="mb-4">
                            <Col md={6}>
                                {this.inputFieldWithPrefix(
                                    "Expected monthly rental income",
                                    property.grossrentalincome,
                                    (e) =>
                                        changeAttribute(
                                            e.target.value,
                                            i,
                                            "grossrentalincome"
                                        ),
                                    "$",
                                    undefined,
                                    true
                                )}
                            </Col>
                        </Row>}
                    <Row className="mb-4">
                        <OwnedPropertyLoansTable i={i} isSubjectProperty={isSubjectProperty} currentView={this.state.currentView} />
                    </Row>
                </div>
            </>
        )
    }
    sectionS1 = () => {
        return (
            <div id="s1" className="p-4">
                <div className="fontWeight500" style={{ fontSize: 20 }}>
                    Section 1: Borrower Information
                </div>
                <div>
                    This section asks about your personal information and your income from
                    employment and other sources, such as retirement, that you want
                    considered to qualify for this loan.
                </div>
            </div>
        )
    };
    section2aDom = (data, list) => {
        const link = document.getElementById('leted')


        for (let i = 0; i < list.length; i++) {

            if (data[list[i]] === '' || data[list[i]] === null) {
                if (link === null) return

                link.style.display = 'inline'

                break
            } else {
                if (link === null) return
                link.style.display = 'none'
            }
        }
    }
    section1aDom = (title, data, list) => {
        const link = document.getElementsByClassName('ant-anchor-link-title')
        const flag = false

        for (let index = 0; index < link.length; index++) {

            if (link[index].title === title) {

                for (let i = 0; i < list.length; i++) {

                    if (Array.isArray(list[i])) {
                        if (data[list[i][0]][list[i][1]] === '' || data[list[i][0]][list[i][1]] === null) {
                            link[index].style.color = '#faad14'
                            break
                        } else {
                            link[index].style.color = '#000'
                        }
                    } else {
                        if (data[list[i]] === '' || data[list[i]] === null) {
                            link[index].style.color = '#faad14'
                            break
                        } else {
                            link[index].style.color = '#000'
                        }
                    }
                }
            }
        }
    }

    // showSection1aDom = ( data, list) => {
    //   let  flog=false
    //    for (let i = 0; i < list.length; i++) {

    //     if (data[list[i]] === '' || data[list[i]] === null) {
    //       // flog=false
    //       break
    //     } else {
    //       // flog=true
    //     }
    //     return flog
    // }
    section1a = (currentView) => {
        // mailingaddressstate
        // samemailingaddress
        let onPrevAddressChange = (e) => {
            com.touch();
            return this.props.updatePreviousAddress(e, currentView, "street", 0);
        }

        let b = currentView === "borrower" ? this.props.application.borrower : this.props.application.coborrower
        let who = currentView === "borrower" ? "borrower" : "coborrower"

        const roleList = ['presentaddressownership', "presentaddressmonths", 'presentaddressyears', "presentaddressstreet",  'email', 'homephone', "maritalstatus", 'hascoborrower', 'firstname', 'lastname', 'socialsecurity', 'dateofbirth']
        if (!b.samemailingaddress) roleList.push('mailingaddressstreet')
        this.section1aDom('1a. Personal Information', b, roleList)
        this.section2aDom(b, roleList)
        return (
            <div id="1a">
                <div className="form1003Title">1a. Personal Information    {this.props.mode !== 'document' && <span className="leted" id="leted">UNCOMPLETED</span>}</div>
                <div className="p-4">
                    <Row className="mb-4">
                        <Col sm={4}>
                            {this.inputField(
                                "First name",
                                b.firstname,
                                (event) => this.props.changeBorrowerInfo(event, currentView, "firstname")
                            )}
                        </Col>
                        <Col sm={4}>
                            {this.inputField(
                                "Middle name",
                                b.middlename,
                                (event) => this.props.changeBorrowerInfo(event, currentView, "middlename"),
                                null,
                                true
                            )}
                        </Col>
                        <Col sm={4}>
                            {this.inputField(
                                "Last name",
                                b.lastname,
                                (event) => this.props.changeBorrowerInfo(event, currentView, "lastname")
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4} className="mb-4">
                            {this.dropdownField(
                                "Suffix",
                                b.suffix,
                                [
                                    { value: "", label: "-- No Suffix --" },
                                    { value: "Jr.", label: "Jr." },
                                    { value: "Sr.", label: "Sr." },
                                    { value: "I", label: "I" },
                                    { value: "II", label: "II" },
                                    { value: "III", label: "III" },
                                    { value: "IV", label: "IV" },
                                    { value: "Ph.D.", label: "Ph.D." },
                                    { value: "M.D.", label: "M.D." },
                                    { value: "Esq.", label: "Esq." },
                                    { value: "J.D.", label: "J.D." },
                                    { value: "CPA", label: "CPA" },
                                    { value: "DDS", label: "DDS" },
                                    { value: "KBE", label: "KBE" },
                                    { value: "OBE", label: "OBE" },
                                    { value: "MBE", label: "MBE" },
                                ],
                                (event) => this.props.changeBorrowerInfo(event, currentView, "suffix"),
                                true
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} className="mb-3">
                            {this.inputField(
                                "Social security number",
                                b.socialsecurity,
                                (e) => {
                                    const cleanedValue = e.target.value.replace(/\D/g, "") // Remove non-digit characters
                                    const firstPart = cleanedValue.slice(0, 3)
                                    const secondPart = cleanedValue.slice(3, 5)
                                    const thirdPart = cleanedValue.slice(5, 9)
                                    let formattedValue = ""
                                    if (firstPart) {
                                        formattedValue += firstPart
                                    }
                                    if (secondPart) {
                                        formattedValue += `-${secondPart}`
                                    }
                                    if (thirdPart) {
                                        formattedValue += `-${thirdPart}`
                                    }
                                    this.props.changeBorrowerInfo(
                                        { target: { value: formattedValue } },
                                        currentView,
                                        "socialsecurity"
                                    )
                                }
                            )}
                        </Col>
                        <Col sm={6} className="mb-3">
                            {this.dateField(
                                "Date of birth",
                                b.dateofbirth,
                                (date, dateString) =>
                                    this.props.changeBorrowerInfo(
                                        { target: { value: dateString } },
                                        currentView,
                                        "dateofbirth"
                                    )
                            )}
                        </Col>
                    </Row>
                    <Row className="">
                        <Col sm={6}>
                            {this.dropdownField(
                                "Citizenship",
                                b.citizenship === null ? "" : b.citizenship,
                                [
                                    { value: "", label: "..." },
                                    { value: "citizen", label: "U.S. Citizen" },
                                    { value: "permanent", label: "Permanent Resident Alien" },
                                    { value: "alien", label: "Non-Permanent Resident Alien" },
                                ],
                                (event) =>
                                    this.props.changeBorrowerInfo(
                                        event,
                                        currentView,
                                        "citizenship"
                                    ),
                                // true,
                            )}
                        </Col>
                        <Col className="pr-0"></Col>
                    </Row>
                    <hr className="form1003Divider"></hr>
                    <Row className="mb-4">
                        <Col>
                            {this.selectionField(
                                "Type of credit",
                                this.props.application.hascoborrower,
                                [
                                    {
                                        value: "alone",
                                        label: "I am applying for individual credit",
                                    },
                                    {
                                        value: "withcoborrower",
                                        label: "I am applying for joint credit",
                                    },
                                ],
                                (e) => this.props.updateCoborrower(e)
                            )}
                        </Col>
                    </Row>
                    <hr className="form1003Divider"></hr>
                    <Row className="mb-4">
                        <Col>
                            {this.selectionField(
                                "Marital status",
                                b.maritalstatus,
                                [
                                    { value: "married", label: "Married" },
                                    { value: "separated", label: "Separated" },
                                    { value: "unmarried", label: "Unmarried" },
                                ],
                                (e) => this.props.changeBorrowerInfoByValue( e, currentView, "maritalstatus")
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col sm={6} md={12}>
                            {this.inputFieldWithPrefix(
                                "Dependents (not listed by another borrower)",
                                b.numberofdependents,
                                (e) =>
                                    this.props.changeBorrowerInfo(
                                        e,
                                        currentView,
                                        "numberofdependents"
                                    ),
                                "Number"
                            )}
                        </Col>
                    </Row>
                    {b.numberofdependents > 0 && <Row>
                        {this.getAgesFields(b.numberofdependents, currentView === "coborrower" ? this.state.codependentages : this.state.bodependentages, currentView, "dependentsages")}
                    </Row>}
                    {/* <Row>
                        <Col sm={6}>
                            {this.inputFieldWithPrefix(
                                null,
                                b.dependentsages,
                                (e) =>
                                    this.props.changeBorrowerInfo(
                                        e,
                                        currentView,
                                        "dependentsages"
                                    ),
                                "Ages",
                                `Divided ages by ",". For example, "12, 20, 39"`,
                            )}
                        </Col>
                    </Row> */}
                    <hr className="form1003Divider"></hr>
                    <Row>
                        <Col sm={6}>
                            {this.inputFieldWithPrefix(
                                "Contact",
                                b.email,
                                (e) => this.props.changeBorrowerInfo(e, currentView, "email"),
                                "Email"
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col sm={4}>
                            {this.inputFieldWithPrefix(
                                null,
                                b.homephone,
                                (e) => {
                                    const phone = com.fixPhoneInput(e.target.value)
                                    this.props.changeBorrowerInfo({ target: { value: phone } }, who, "homephone")
                                },
                                "Home phone"
                            )}
                        </Col>
                        <Col sm={4}>
                            {this.inputFieldWithPrefix(
                                null,
                                b.mobilephone,
                                (e) => {
                                    const phone = com.fixPhoneInput(e.target.value)
                                    this.props.changeBorrowerInfo({ target: { value: phone } }, who, "mobilephone")
                                },
                                "Mobile phone"
                            )}
                        </Col>
                        <Col sm={4}>
                            {this.inputFieldWithPrefix(
                                null,
                                b.workphone,
                                (e) => {
                                    const phone = com.fixPhoneInput(e.target.value)
                                    this.props.changeBorrowerInfo({ target: { value: phone } }, who, "workphone")
                                },
                                "Work phone"
                            )}
                        </Col>
                    </Row>
                    <hr className="form1003Divider"></hr>
                    <Row className="mb-1">
                        <Col>
                            {this.state.editMode ? <AddressAutocomplete
                                id="Address"
                                border={true}
                                style={b.presentaddressstreet === '' && this.props.mode !== 'document' ? { border: '1px solid #faad14' } : {}}
                                label={<div className="fontWeight500">Current residential address</div>}
                                required={true}
                                name="addessline"
                                defaultValue={
                                    b.presentaddressstreet
                                }
                                onChange={(e) => this.props.changeBorrowerInfo(e, currentView, "presentaddressstreet")}
                                pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                placeholder="Please choose from the suggested addresses generated based on the input."
                                goodfeedback="Looks good!"
                                badfeedback="Please provide your street address."
                                onCompletion={e => this.onStreetAddressCompletion(e, currentView)}
                                size="md"
                            /> : <div className="fontSize16">{b.presentaddressstreet}</div>}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3} className="mb-4">
                            {this.dropdownField(
                                "State",
                                b.presentaddressstate,
                                this.getStateOptions(),
                                (event) =>
                                    this.props.changeBorrowerInfo(
                                        event,
                                        currentView,
                                        "presentaddressstate"
                                    ),
                                // true
                            )}
                        </Col>
                        <Col sm={3} className="mb-4">
                            {this.dropdownField(
                                "County",
                                b.presentaddresscounty,
                                this.getCountyOptions(
                                    b.presentaddressstate
                                ),
                                (event) =>
                                    this.props.changeBorrowerInfo(
                                        event,
                                        currentView,
                                        "presentaddresscounty"
                                    )
                            )}
                        </Col>
                        <Col sm={3} className="mb-4">
                            {this.inputField(
                                "City",
                                b.presentaddresscity,
                                (event) =>
                                    this.props.changeBorrowerInfo(
                                        event,
                                        currentView,
                                        "presentaddresscity"
                                    ),
                                null,
                                // true
                            )}
                        </Col>
                        <Col sm={3} className="mb-4">
                            {this.inputField(
                                "Zip",
                                b.presentaddresszip,
                                (event) =>
                                    this.props.changeBorrowerInfo(
                                        event,
                                        currentView,
                                        "presentaddresszip"
                                    ),
                                null,
                                // true
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            {this.inputNumberFields(
                                "How long have you been at your current address?",
                                [
                                    b.presentaddressyears,
                                    b.presentaddressmonths,
                                ],
                                [
                                    (event) => {
                                        console.log("check ! evet",event)
                                        if (event !== "" && parseInt(event) < 2 && b.previousaddresses.length === 0) {
                                          this.props.addPreviousAddress({target:{value: ""}},currentView)
                                        }
                                        this.props.changeBorrowerInfoByNumberValue(
                                            event,
                                            currentView,
                                            "presentaddressyears"
                                        )
                                    },
                                    (event) => {
                                        this.props.changeBorrowerInfoByNumberValue(
                                            event,
                                            currentView,
                                            "presentaddressmonths"
                                        )
                                    }
                                ],
                                null,
                                ["Years", "Months"]
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            {this.selectionField(
                                "Housing",
                                b.presentaddressownership,
                                [
                                    {
                                        value: st.O_Rentfree,
                                        label: "No primary housing expense",
                                    },
                                    {
                                        value: st.O_Own,
                                        label: "Own",
                                    },
                                    {
                                        value: st.O_Rent,
                                        label: "Rent",
                                    },
                                ],
                                (e) =>
                                    this.props.changeBorrowerInfoByValue(
                                        e,
                                        currentView,
                                        "presentaddressownership"
                                    )
                            )}
                        </Col>
                    </Row>
                    {/* b.presentaddressownership === st.O_Rent && */}
                    {b.presentaddressownership === st.O_Rent && <Row className="mb-4">
                        <Col md={6}>
                            {this.inputFieldWithPrefix(
                                "Monthly Rent",
                                b.expenses.currentrent,
                                (event) => {
                                    this.props.updateExpenses(
                                        event.target.value,
                                        currentView,
                                        "currentrent"
                                    )
                                },
                                "$"
                            )}
                        </Col>
                    </Row>}

                    {/*If current address less than 2 year, ask former address */}
                    { parseInt(b.presentaddressyears) <2 &&
                        <>
                        <hr className="form1003Divider"></hr>
                        <Row className="mb-1">
                            <Col>
                            {this.state.editMode ? <AddressAutocomplete
                                id="formerAddress"
                                border={true}
                                style={b.presentaddressstreet === '' ? { border: '1px solid #faad14' } : {}}
                                label={<div className="fontWeight500">Previous residential address</div>}
                                required={true}
                                name="addessline"
                                defaultValue={
                                b.previousaddresses.length>0 ? b.previousaddresses[0].street:""
                                }
                                onChange={onPrevAddressChange}
                                pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                placeholder="Please choose from the suggested addresses generated based on the input."
                                goodfeedback="Looks good!"
                                badfeedback="Please provide your street address."
                                onCompletion={e => this.onPrevAddressCompletion(e, currentView)}
                                size="md"
                            /> : <div className="fontSize16">{b.presentaddressstreet}</div>}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={3} className="mb-4">
                            {this.dropdownField(
                                "State",
                                b.previousaddresses.length>0?b.previousaddresses[0].state:"",
                                this.getStateOptions(),
                                (event) =>
                                this.props.updatePreviousAddress(
                                    event,
                                    currentView,
                                    "state",
                                    0
                                ),
                                true
                            )}
                            </Col>
                            {/* <Col sm={3} className="mb-4">
                            {this.dropdownField(
                                "County",
                                b.previousaddresses.length>0?b.previousaddresses[0].county:"",
                                this.getCountyOptions(
                                b.previousaddresses.length>0 ?b.previousaddresses[0].state: ""
                                ),
                                (event) =>
                                this.props.updatePreviousAddress(
                                    event,
                                    currentView,
                                    "county",
                                    0
                                )
                            )}
                            </Col> */}
                            <Col sm={3} className="mb-4">
                            {this.inputField(
                                "City",
                                b.previousaddresses.length>0?b.previousaddresses[0].city:"",
                                (event) =>
                                this.props.UpdatePreviousAddress(
                                    event,
                                    currentView,
                                    "city",
                                    0
                                ),
                                null,
                                true
                            )}
                            </Col>
                            <Col sm={3} className="mb-4">
                            {this.inputField(
                                "Zip",
                                b.previousaddresses.length>0?b.previousaddresses[0].zip:"",
                                (event) =>
                                this.props.updatePreviousAddress(
                                    event,
                                    currentView,
                                    "zip",
                                    0
                                ),
                                null,
                                true
                            )}
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col>
                            {this.inputNumberFields(
                                "How long have you been at your previous address?",
                                [
                                b.previousaddresses.length>0?b.previousaddresses[0].years:"",
                                b.previousaddresses.length>0?b.previousaddresses[0].months:"",
                                ],
                                [
                                (event) =>
                                    this.props.updatePreviousAddress(
                                    {target:{value: event}},
                                    currentView,
                                    "years",
                                    0
                                    ),
                                (event) =>
                                    this.props.updatePreviousAddress(
                                    {target:{value: event}},
                                    currentView,
                                    "months",
                                    0
                                    ),
                                ],
                                null,
                                ["Years", "Months"]
                            )}
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col>
                            {this.selectionField(
                                "Housing",
                                b.previousaddresses.length>0?b.previousaddresses[0].ownership:"",
                                [
                                {
                                    value: st.O_Rentfree,
                                    label: "No primary housing expense",
                                },
                                {
                                    value: st.O_Own,
                                    label: "Own",
                                },
                                {
                                    value: st.O_Rent,
                                    label: "Rent",
                                },
                                ],
                                (e) =>
                                this.props.updatePreviousAddress(
                                    {target:{value: e}},
                                    currentView,
                                    "ownership",
                                    0
                                )
                            )}
                            </Col>
                        </Row>
                        {b.previousaddresses.length>0 && b.previousaddresses[0].ownership === st.O_Rent && <Row className="mb-4">
                            <Col md={6}>
                                {this.inputFieldWithPrefix(
                                    "Monthly Rent",
                                    b.previousaddresses[0].rent,
                                    (e) => {
                                        this.props.updatePreviousAddress(
                                            {target:{value: e.target.value}},
                                            currentView,
                                            "rent",
                                            0
                                        )
                                    },
                                    "$"
                                )}
                            </Col>
                        </Row>}
                        </>
                    }
                    <Row className="mb-4">
                        <Col>
                            <Checkbox
                                checked={!b.samemailingaddress}
                                onChange={(e) => {
                                    this.props.changeBorrowerInfoByValue(
                                        !e.target.checked,
                                        currentView,
                                        "samemailingaddress"
                                    )
                                    if (!e.target.checked) {
                                        this.props.changeBorrowerInfoByValue(b.presentaddressstreet, currentView, "mailingaddressstreet")
                                        this.props.changeBorrowerInfoByValue(b.presentaddresscity, currentView, "mailingaddresscity")
                                        this.props.changeBorrowerInfoByValue(b.presentaddresscounty, currentView, "mailingaddresscounty")
                                        this.props.changeBorrowerInfoByValue(b.presentaddressstate, currentView, "mailingaddressstate")
                                        this.props.changeBorrowerInfoByValue(b.presentaddresszip, currentView, "mailingaddresszip")
                                    }
                                }
                                }
                                className="fontWeight500"
                            >
                                Mailing address is different from current address
                            </Checkbox>
                        </Col>
                    </Row>
                    {!b.samemailingaddress && <Row className="mb-2">
                        <Col>
                            {this.state.editMode ? <AddressAutocomplete
                                id="mailingAddress"
                                border={true}
                                style={b.mailingaddressstreet === '' && this.props.mode !== 'document' ? { border: '1px solid #faad14' } : {}}
                                label={<div className="fontWeight500">Mailing Address</div>}
                                required={true}
                                name="mailingaddessline"
                                defaultValue={
                                    b.mailingaddressstreet
                                }
                                onChange={(e) => this.props.changeBorrowerInfo(e, currentView, "mailingaddressstreet")}
                                pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                placeholder="Please choose from the suggested addresses generated based on the input."
                                goodfeedback="Looks good!"
                                badfeedback="Please provide your mailling address."
                                onCompletion={e => this.onStreetAddressCompletion(e, currentView, true)}
                                size="md"
                            /> : <div className="fontSize16">{b.mailingaddressstreet}</div>}
                        </Col>
                    </Row>}
                    {!b.samemailingaddress && <Row className="mb-4">
                        <Col>
                            {this.dropdownField(
                                "State",
                                b.mailingaddressstate,
                                this.getStateOptions(),
                                (event) =>
                                    this.props.changeBorrowerInfo(
                                        event,
                                        currentView,
                                        "mailingaddressstate"
                                    )
                            )}
                        </Col>
                        <Col className="">
                            {this.dropdownField(
                                "County",
                                b.mailingaddresscounty,
                                this.getCountyOptions(
                                    b.mailingaddressstate
                                ),
                                (event) =>
                                    this.props.changeBorrowerInfo(
                                        event,
                                        currentView,
                                        "mailingaddresscounty"
                                    )
                            )}
                        </Col>
                        <Col>
                            {this.inputField(
                                "City",
                                b.mailingaddresscity,
                                (event) =>
                                    this.props.changeBorrowerInfo(
                                        event,
                                        currentView,
                                        "mailingaddresscity"
                                    ),
                                null,
                                // true
                            )}
                        </Col>
                        <Col>
                            {this.inputField(
                                "Zip",
                                b.mailingaddresszip,
                                (event) =>
                                    this.props.changeBorrowerInfo(
                                        event,
                                        currentView,
                                        "mailingaddresszip"
                                    ),
                                null,
                                // true
                            )}
                        </Col>
                    </Row>}

                </div>
            </div>
        )
    };
    section1b = (currentView) => {
        const emp = currentView === "borrower" ? this.props.application.borrower.occupation : this.props.application.coborrower.occupation
        let checkouts = ['employername', 'phone', 'employeraddress', 'positiontitle', 'from', 'yearsatlineofwork', 'monthsatlineofwork', ['income', 'base']]
        if (emp.selfemployed) {
            checkouts = ['employername', 'phone', 'employeraddress', 'positiontitle', 'from', 'yearsatlineofwork', 'monthsatlineofwork', 'percentownership', 'incomeorloss']
        }
        this.section1aDom('1b. Current Employment/Self-Employment and Income', emp, checkouts)
        let who = currentView === "borrower" ? "borrower" : "coborrower"
        return (
            <div id="1b">
                <div className="form1003Title">1b. Employment and Income</div>
                <div className="p-4">
                    <Row>
                        <Col sm={6} className="mb-4">
                            {this.inputField(
                                "Employer or business name",
                                emp.employername,
                                (e) => this.props.changeOccupationInfo(e, currentView, "employername")
                            )}
                        </Col>
                        <Col sm={6} className="mb-4">
                            {this.inputField("Phone", emp.phone, (e) => {
                                let phone = com.fixPhoneInput(e.target.value)
                                this.props.changeOccupationInfo({ target: { value: phone } }, who, "phone")
                            }
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            {this.state.editMode ? <AddressAutocomplete
                                id="CEmploymentAddress"
                                border={true}
                                style={emp.employeraddress === '' && this.props.mode !== 'document' ? { border: '1px solid #faad14', fontSize:"16px" } : {fontSize:"16px"}}
                                label={<div className="fontWeight500">Address</div>}
                                required={true}
                                name="addessline"
                                defaultValue={
                                    emp.employeraddress
                                }
                                onChange={(e) => this.props.changeOccupationInfo(e, currentView, "employeraddress")}
                                pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                placeholder="Please choose from the suggested addresses generated based on the input."
                                goodfeedback=""
                                badfeedback="Please provide address."
                            /> : <div className="fontSize16">
                                <div>Address</div>
                                <div>{emp.employeraddress}</div>
                            </div>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col sm={6} className="mb-4">
                            {this.inputField("Position or title", emp.positiontitle, (e) =>
                                this.props.changeOccupationInfo(
                                    e,
                                    currentView,
                                    "positiontitle"
                                )
                            )}
                        </Col>
                        <Col sm={6} className="mb-4">
                            {this.dateField(
                                "Start date",
                                emp.from,
                                (date, dateString) =>
                                    this.props.changeOccupationInfoByValue(
                                        dateString,
                                        currentView,
                                        "from"
                                    )
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            {this.inputNumberFields(
                                "How long in this line of work?",
                                [emp.yearsatlineofwork, emp.monthsatlineofwork],
                                [
                                    (event) =>
                                        this.props.changeOccupationInfoByValue(
                                            event,
                                            currentView,
                                            "yearsatlineofwork"
                                        ),
                                    (event) =>
                                        this.props.changeOccupationInfoByValue(
                                            event,
                                            currentView,
                                            "monthsatlineofwork"
                                        ),
                                ],
                                null,
                                ["Years", "Months"]
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <Checkbox
                                checked={emp.employedbyagent}
                                onChange={(e) => this.props.changeOccupationInfoByValue(e.target.checked, currentView, "employedbyagent")}
                                className="fontWeight500"
                            >
                                You are employed by a family member, property seller, real estate agent, or other party to the transaction.
                            </Checkbox>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <Checkbox
                                checked={emp.selfemployed}
                                onChange={(e) => this.props.changeOccupationInfoByValue(e.target.checked, currentView, "selfemployed")}
                                className="fontWeight500"
                            >
                                Check if you are the business owner or self-employed
                            </Checkbox>
                        </Col>
                    </Row>
                    {emp.selfemployed && (
                        <Row>
                            <Col sm={6} className="mb-4">
                                {this.inputField(
                                    "I have an ownership share of",
                                    emp.percentownership,
                                    (e) => this.props.changeOccupationInfo(e, currentView, "percentownership")
                                )}
                            </Col>
                            <Col sm={6} className="mb-4">
                                {this.inputField(
                                    "Monthly Income (or Loss)",
                                    emp.incomeorloss,
                                    (event) => this.props.changeOccupationInfoByValue(event.target.value, currentView, "incomeorloss")
                                )}
                            </Col>
                        </Row>
                    )}

                    {!emp.selfemployed &&
                        <>
                            <hr className="form1003Divider"></hr>
                            <Row>
                                <Col className="mb-4">
                                    {this.inputFields(
                                        "Gross monthly income",
                                        [emp.selfemployed ? (emp.incomeorlossperiod === "monthly" ? emp.incomeorloss : emp.incomeorloss / 12) : (emp.income.basemonthly === 'monthly' ? emp.income.base : emp.income.base / 12), emp.income.overtimemonthly === "monthly" ? emp.income.overtime : emp.income.overtime / 12],
                                        [
                                            (e) => this.updateIncomeMonthly(emp.income.basemonthly === "monthly" ? e.target.value : e.target.value * 12, currentView, "base"),
                                            (e) => this.updateIncomeMonthly(emp.income.overtimemonthly === "monthly" ? e.target.value : e.target.value * 12, currentView, "overtime"),
                                        ],
                                        ["Base   ", "Overtime  "],
                                        ["/ month", "/ month"],
                                        [false, true]
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mb-4">
                                    {this.inputFields(
                                        null,
                                        [emp.income.bonusesmonthly === "monthly" ? emp.income.bonuses : emp.income.bonuses / 12, emp.income.commissionsmonthly === "monthly" ? emp.income.commissions : emp.income.commissions / 12],
                                        [
                                            (e) => this.updateIncomeMonthly(emp.income.bonusesmonthly === "monthly" ? e.target.value : e.target.value * 12, currentView, "bonuses"),
                                            (e) => this.updateIncomeMonthly(emp.income.commissionsmonthly === "monthly" ? e.target.value : e.target.value * 12, currentView, "commissions"),
                                        ],
                                        ["Bonus", "Commission"],
                                        ["/ month", "/ month"],
                                        [true, true]
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mb-4">
                                    {this.inputFields(
                                        null,
                                        [emp.income.militarymonthly === "monthly" ? emp.income.military : emp.income.military / 12, emp.income.othermonthly === "monthly" ? emp.income.other : emp.income.other / 12],
                                        [
                                            (e) => this.updateIncomeMonthly(emp.income.militarymonthly === "monthly" ? e.target.value : e.target.value * 12, currentView, "military"),
                                            (e) => this.updateIncomeMonthly(emp.income.othermonthly === "monthly" ? e.target.value : e.target.value * 12, currentView, "other"),
                                        ],
                                        ["Military", "Other"],
                                        ["/ month", "/ month"],
                                        [true, true]
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    {this.inputFields(
                                        "Total income",
                                        [this.getTotalIncome(who)],
                                        [],
                                        ["$"],
                                        ["/ month"]
                                    )}
                                </Col>
                            </Row>
                        </>
                    }

                </div>
            </div>
        )
    };
    section1c = (currentView) => {
        const changeVal = (i, t, useValue) => {
            return (e) =>
                this.props.changeBorrowerOtherOccupationValue(
                    useValue ? e : e.target.value,
                    i,
                    currentView,
                    t
                )
        }
        const changeChecked1 = (e, i, t) => {
            this.props.changeBorrowerOtherOccupationValue(
                e.target.checked,
                i,
                currentView,
                t
            )
            // return (e) =>
            //     this.props.changeBorrowerOtherOccupationValue(
            //         e.target.checked,
            //         i,
            //         currentView,
            //         t
            //     )
        }
        const changeChecked = (i, t) => {
            return (e) =>
                this.props.changeBorrowerOtherOccupationValue(
                    e.target.checked,
                    i,
                    currentView,
                    t
                )
        }

        const addIncome = () => {
            let b = currentView === "borrower" ? this.props.application.borrower : this.props.application.coborrower

            if (b.otheroccupations === null) {
                this.props.clearBorrowerOtherOccupations(currentView)
            }
            this.props.addBorrowerOtherOccupation(currentView)
        }
        const displayAdditionalOccupation = () => {
            let b = currentView === "borrower" ? this.props.application.borrower : this.props.application.coborrower

            if (
                b.otheroccupations === null ||
                b.otheroccupations.length === 0
            ) {
                return ""
            }
            let out = []
            for (
                let i = 0;
                i < b.otheroccupations.length;
                i++
            ) {
                out.push(additonalIncome(i))
            }
            return out
        }

        const additonalIncome = (i) => {
            const emp = currentView === "borrower" ? this.props.application.borrower.otheroccupations[i] : this.props.application.coborrower.otheroccupations[i]
            const newarray = emp.selfemployed ? ['employername', 'phone', 'employeraddress', 'positiontitle', 'from', 'yearsatlineofwork', 'monthsatlineofwork'] : ['employername', 'phone', 'employeraddress', 'positiontitle', 'from', 'yearsatlineofwork', 'monthsatlineofwork', ['income', 'base'], ['income', 'overtime'], ['income', 'bonuses'], ['income', 'commissions']]

            this.section1aDom('1c. Additional Employment/Self-Employment and Income', emp, newarray)

            const getTotalIncome = () => {
                const i1 = parseFloat(emp.income.base === "" ? 0 : emp.income.base)
                const i2 = parseFloat(emp.income.bonuses === "" ? 0 : emp.income.bonuses)
                const i3 = parseFloat(emp.income.commissions === "" ? 0 : emp.income.commissions)
                const i4 = parseFloat(emp.income.overtime === "" ? 0 : emp.income.overtime)
                const i5 = parseFloat(emp.income.military === "" ? 0 : emp.income.military)
                const i6 = parseFloat(emp.income.other === "" ? 0 : emp.income.other)
                return i1 + i2 + i3 + i4 + i5 + i6
            }

            return (
                <div className="p-4 removable">
                    <div className="text-right">
                        <MinusSquareOutlined
                            onClick={() => this.props.removeBorrowerOtherOccupation("borrower", i)}
                            style={{ color: "#325CEB", fontSize: 16, cursor: "pointer" }}
                        />
                    </div>
                    <Row>
                        <Col sm={6} className="mb-4">
                            {this.inputField("Employer or business name", emp.employername, changeVal(i, "employername"))}
                        </Col>
                        <Col sm={6} className="mb-4">
                            {this.inputField(
                                "Phone",
                                emp.phone,
                                (e) => {
                                    const phone = com.fixPhoneInput(e.target.value)
                                    this.props.changeBorrowerOtherOccupationValue(phone, i, currentView, "phone")
                                }
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            {this.state.editMode ? <AddressAutocomplete
                                id={`AdditionEmploymentAddress${i}`}
                                border={true}
                                style={emp.employeraddress === '' && this.props.mode !== 'document' ? { border: '1px solid #faad14', fontSize:"16px" } : {fontSize:"16px"}}
                                label={<div className="fontWeight500">Address</div>}
                                required={true}
                                name="addessline"
                                defaultValue={emp.employeraddress}
                                onChange={(e) => this.props.changeBorrowerOtherOccupationValue(
                                    e.target.value,
                                    i,
                                    currentView,
                                    "employeraddress"
                                )}
                                pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                placeholder="Please choose from the suggested addresses generated based on the input."
                                goodfeedback=""
                                badfeedback="Please provide address."
                            /> : <div className="fontSize16">
                                <div>Address</div>
                                <div>{emp.employeraddress}</div>
                            </div>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} className="mb-4">
                            {this.inputField(
                                "Position or title",
                                emp.positiontitle,
                                changeVal(i, "positiontitle")
                            )}
                        </Col>
                        <Col sm={6} className="mb-4">
                            {this.dateField(
                                "Start date",
                                emp.from,
                                (date, dateString) =>
                                    this.props.changeBorrowerOtherOccupationValue(
                                        dateString,
                                        i,
                                        currentView,
                                        "from"
                                    )
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            {this.inputNumberFields(
                                "How long have you been in this line of work?",
                                [emp.yearsatlineofwork, emp.monthsatlineofwork],
                                [
                                    changeVal(i, "yearsatlineofwork", true),
                                    changeVal(i, "monthsatlineofwork", true),
                                ],
                                null,
                                ["Years", "Months"]
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <Checkbox
                                checked={emp.employedbyagent}
                                onChange={changeChecked(i, "employedbyagent")}
                                className="fontWeight500"
                            >
                                You are employed by a family member, property seller, real estate
                                agent, or other party to the transaction.
                            </Checkbox>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <Checkbox
                                checked={emp.selfemployed}
                                onChange={(e) => {
                                    console.log(e.target.checked)

                                    changeChecked1(e, i, "selfemployed")

                                }}
                                className="fontWeight500"
                            >
                                Check if you are the business owner or self-employed
                            </Checkbox>
                        </Col>
                    </Row>
                    {emp.selfemployed && (
                        <Row>
                            <Col sm={6} className="mb-4">
                                {this.inputField(
                                    "I have an ownership share of",
                                    emp.percentownership,
                                    changeVal(i, "percentownership")
                                )}
                            </Col>
                            <Col sm={6} className="mb-4">
                                {this.inputField(
                                    "Monthly Income (or Loss)",
                                    emp.incomeorloss,
                                    (e) => this.props.changeBorrowerOtherOccupationValue(
                                        e.target.value,
                                        i,
                                        currentView,
                                        "incomeorloss"
                                    ),
                                )}
                            </Col>
                        </Row>
                    )}
                    {!emp.selfemployed && <>
                        <hr className="form1003Divider"></hr>
                        <Row className="mb-4">
                            <Col>
                                {this.inputFields(
                                    "Gross monthly income",
                                    [emp.income.base, emp.income.overtime],
                                    [
                                        (e) => this.props.changeBorrowerOtherOccupationIncome(e.target.value, i, currentView, "base"),
                                        (e) =>
                                            this.props.changeBorrowerOtherOccupationIncome(e.target.value, i, currentView, "overtime"),
                                    ],
                                    ["Base   ", "Overtime  "],
                                    ["/ month", "/ month"],
                                    [false, true]
                                )}
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col>
                                {this.inputFields(
                                    null,
                                    [emp.income.bonuses, emp.income.commissions],
                                    [
                                        (e) => this.props.changeBorrowerOtherOccupationIncome(e.target.value, i, currentView, "bonuses"),
                                        (e) =>
                                            this.props.changeBorrowerOtherOccupationIncome(
                                                e.target.value,
                                                i,
                                                currentView,
                                                "commissions"
                                            ),
                                    ],
                                    ["Bonus", "Commission"],
                                    ["/ month", "/ month"],
                                    [true, true]
                                )}
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col>
                                {this.inputFields(
                                    null,
                                    [emp.income.military, emp.income.other],
                                    [
                                        (e) => this.props.changeBorrowerOtherOccupationIncome(e.target.value, i, currentView, "military"),
                                        (e) =>
                                            this.props.changeBorrowerOtherOccupationIncome(
                                                e.target.value,
                                                i,
                                                currentView,
                                                "other"
                                            ),
                                    ],
                                    ["Military", "Other"],
                                    ["/ month", "/ month"],
                                    [true, true]
                                )}
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col>
                                {this.inputFields(
                                    "Total income",
                                    [getTotalIncome(i)],
                                    [],
                                    ["$"],
                                    ["/ month"]
                                )}
                            </Col>
                        </Row>
                    </>}
                </div>
            )
        }

        return (
            <div id="1c">
                <div className="form1003Title">
                    1c. Additional Employment/Self-Employment and Income
                </div>
                <div>{displayAdditionalOccupation()}</div>
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 500,
                        color: "#325CEB",
                        cursor: "pointer",
                    }}
                    onClick={() => addIncome()}
                >
                    <img
                        src="/images/bluePlusInCircleMark.png"
                        style={{ width: 20, height: 20 }}
                        className="ml-4 mr-2 my-3"
                    />
                    Add addtional income
                </div>

            </div>
        )
    };

    section1d = (currentView) => {
        const changeVal = (i, t) => {
            return (e) => {
                let val = e.target.value
                if (t === 'income') {
                    val = val * 12
                }
                this.props.changeBorrowerPreviousOccupationValue(val, i, currentView, t)
            }
        }

        const displayPreviousOccupation = () => {
            const b = currentView === "borrower" ? this.props.application.borrower : this.props.application.coborrower
            if (b.previousoccupations === null || b.previousoccupations.length === 0) {
                return ""
            }

            const out = []
            for (let i = 0; i < b.previousoccupations.length; i++) {
                out.push(previousIncome(i))
            }

            return out
        }

        const changeChecked = (i, t) => {
            return (e) => this.props.changeBorrowerPreviousOccupationValue(e.target.checked, i, currentView, t)
        }

        const previousIncome = (i) => {
            const emp = currentView === "borrower" ? this.props.application.borrower.previousoccupations[i] : this.props.application.coborrower.previousoccupations[i]
            this.section1aDom('1d. Previous Employment/Self-Employment and Income', emp, ['employername', 'employeraddress', 'positiontitle', 'from', 'to', 'income'])

            return (
                <div className="p-4 removable">
                    <div className="text-right">
                        <MinusSquareOutlined
                            onClick={() => this.props.removeBorrowerPreviousOccupation("borrower", i)}
                            style={{ color: "#325CEB", fontSize: 16, cursor: "pointer" }}
                        />
                    </div>
                    <Row>
                        <Col sm={6} className="mb-4">
                            {this.inputField("Employer or business name", emp.employername, changeVal(i, "employername"))}
                        </Col>
                        <Col sm={6} className="mb-4">
                            {this.inputField("Position or title", emp.positiontitle, changeVal(i, "positiontitle"))}
                        </Col>
                        {/* <Col sm={6} className="mb-4">
                            {this.inputField("Phone", emp.phone, (e) => {
                                let phone = com.fixPhoneInput(e.target.value)
                                this.props.changeBorrowerPreviousOccupationValue(phone, i, currentView, "phone")
                            }
                            )}
                        </Col> */}
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            {this.state.editMode ? <AddressAutocomplete
                                id={`PrevEmploymentAddress${i}`}
                                border={true}
                                style={emp.employeraddress === '' && this.props.mode !== 'document' ? { border: '1px solid #faad14', fontSize:"16px" } : {fontSize:"16px"}}
                                label={<div className="fontWeight500">Address</div>}
                                required={true}
                                name="addessline"
                                defaultValue={emp.employeraddress}
                                onChange={(e) => this.props.changeBorrowerPreviousOccupationValue(e.target.value, i, currentView, "employeraddress")}
                                pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                placeholder="Please choose from the suggested addresses generated based on the input."
                                goodfeedback=""
                                badfeedback="Please provide address."
                            /> : <div className="fontSize16">
                                <div>Address</div>
                                <div>{emp.employeraddress}</div>
                            </div>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} className="mb-4">
                            {this.dateField(
                                "From",
                                emp.from,
                                (date, dateString) => this.props.changeBorrowerPreviousOccupationValue(dateString, i, currentView, "from")
                            )}
                        </Col>
                        <Col sm={6} className="mb-4">
                            {this.dateField(
                                "To",
                                emp.to,
                                (date, dateString) => this.props.changeBorrowerPreviousOccupationValue(dateString, i, currentView, "to")
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <Checkbox
                                checked={emp.employedbyagent}
                                onChange={changeChecked(i, "employedbyagent")}
                                className="fontWeight500"
                            >
                                You are employed by a family member, property seller, real estate agent, or other party to the transaction.
                            </Checkbox>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <Checkbox
                                checked={emp.selfemployed}
                                onChange={changeChecked(i, "selfemployed")}
                                className="fontWeight500"
                            >
                                Check if you are the Business Owner or Self-Employed
                            </Checkbox>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col md={6}>
                            {this.inputFields(
                                "Income",
                                [emp.incomeperiod === "monthly" ? emp.income : emp.income / 12],
                                [changeVal(i, "income")],
                                ["$"],
                                ["/ month"],
                                [false]
                            )}
                        </Col>
                    </Row>
                </div>
            )
        }

        const addPreviousIncome = () => {
            const b = currentView === "borrower" ? this.props.application.borrower : this.props.application.coborrower
            if (b.previousoccupations === null) {
                this.props.clearBorrowerPreviousOccupations(currentView)
            }
            this.props.addBorrowerPreviousOccupation(currentView)
        }

        return (
            <div id="1d">
                <div className="form1003Title">
                    1d. Previous Employment/Self-Employment and Income
                </div>
                <div>{displayPreviousOccupation()}</div>
                <div style={{ fontSize: 16, fontWeight: 500, color: "#325CEB", cursor: "pointer" }} onClick={() => addPreviousIncome()}>
                    <img
                        src="/images/bluePlusInCircleMark.png"
                        style={{ width: 20, height: 20 }}
                        className="ml-4 mr-2 my-3"
                    />
                    Add previous income
                </div>
            </div>
        )
    };

    section1e = (currentView) => {
        const displayOtherIncomes = () => {
            let inc = currentView === "borrower" ? this.props.application.borrower.income.otherincome : this.props.application.coborrower.income.otherincome
            if (inc === null || inc.length === 0) {
                return ""
            }
            let out = []
            const selectBefore = (i) => {
                return (
                    <Select
                        style={{ minWidth: 200 }}
                        onSelect={(e) =>
                            this.props.updateBorrowerAdditionalIncome(
                                { target: { value: e } },
                                currentView,
                                "incometype",
                                i
                            )
                        }
                        value={inc[i].incometype}
                    >
                        {com.othersouresofincome.map((st) => (
                            <Option value={st[0]}>{st[1]}</Option>
                        ))}
                    </Select>
                )
            }

            for (let i = 0; i < inc.length; i++) {
                out.push(
                    <Row>
                        <Col className="mx-2 my-2">
                            <Input
                                addonBefore={selectBefore(i)}
                                suffix="/ month"
                                prefix="$"
                                value={inc[i].amount}
                                size="large"
                                onChange={(e) =>
                                    this.props.updateBorrowerAdditionalIncome(
                                        e,
                                        currentView,
                                        "amount",
                                        i
                                    )
                                }
                            />
                        </Col>
                        <Col className="text-left d-flex align-items-center">
                            <MinusCircleOutlined onClick={() => this.props.removeBorrowerAdditionalIncome(i, "borrower")} style={{ fontSize: 16, color: "#325CEB", cursor: "pointer" }} />
                        </Col>
                    </Row>
                )
            }
            return out
        }
        const addIncome = (e) => {
            this.props.addBorrowerAdditionalIncome(0, currentView)
        }

        return (
            <div id="1e">
                <div className="form1003Title mb-4">1e. Income from Other Sources</div>
                <div>{displayOtherIncomes()}</div>
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 500,
                        color: "#325CEB",
                        cursor: "pointer",
                    }}
                    onClick={addIncome}
                >
                    <img
                        src="/images/bluePlusInCircleMark.png"
                        style={{ width: 20, height: 20 }}
                        className="ml-4 mr-2 my-3"
                    />
                    Add income from other resources
                </div>
            </div>
        )
    };
    sectionS2 = () => {
        return (
            <div id="s2" className="p-4">
                <div className="fontWeight500" style={{ fontSize: 20 }}>
                    Section 2: Financial Information — Assets and Liabilities
                </div>
                <div>
                    This section asks about things you own that are worth money and that
                    you want considered to qualify for this loan. It then asks about your
                    liabilities (or debts) that you pay each month, such as credit cards,
                    alimony, or other expenses.
                </div>
            </div>
        )
    };
    section2a = () => {
        return (
            <div id="2a">
                <div className="form1003Title">
                    2a. Assets – Bank Accounts, Retirement, and Other Accounts You Have
                </div>
                <div className="p-4">
                    <Row>
                        <Col className="px-0">
                            <IncomeSourcesTable />
                        </Col>
                    </Row>
                </div>
            </div>
        )
    };
    section2b = () => {
        return (
            <div id="2b">
                <div className="form1003Title">
                    2b. Other Assets and Credits You Have
                </div>
                <div className="p-4">
                    <Row>
                        <Col className="px-0">
                            <OtherAssetsTable />
                        </Col>
                    </Row>
                </div>
            </div>
        )
    };
    section2c = () => {
        return (
            <div id="2c">
                <div className="form1003Title">
                    2c. Liabilities – Credit Cards, Other Debts, and Leases that You Owe
                </div>
                <div className="p-4">
                    <Row>
                        <Col className="px-0">
                            <LiabilitiesTable />
                        </Col>
                    </Row>
                </div>
            </div>
        )
    };

    section2d = (currentView) => {
        return (
            <div id="2d">
                <div className="form1003Title">2d. Other Liabilities and Expenses</div>
                <div className="p-4">
                    <Row>
                        <Col className="px-0">
                            <OtherLiabilitiesTable who={currentView} />
                        </Col>
                    </Row>
                </div>
            </div>
        )
    };
    sectionS3 = () => {
        return (
            <div id="s3" className="p-4">
                <div className="fontWeight500" style={{ fontSize: 20 }}>
                    Section 3: Financial Information — Real Estate
                </div>
                <div>
                    This section asks you to list all properties you currently own and
                    what you owe on them.
                </div>
            </div>
        )
    };
    section3a = (currentView) => {
        let count = this.props.application[currentView].ownedproperties.length
        let isPurchase = this.props.application.property.purpose === "purchase"
        const renderProperties = () => {
            // If you are refinancing, list the property you are refinancing FIRST
            if (!isPurchase) {
                return this.renderOwnedProperty(0, true, false, currentView)
            }
            if (count === 0) {
                return ""
            }
            return this.renderOwnedProperty(0, false, false, currentView)
        }
        return (
            <div id="3a">
                <div className="form1003Title">3a. Property You Own</div>
                <Row>
                    <Col>
                        <div className="p-4">
                            <Checkbox
                                checked={
                                    isPurchase && count === 0
                                }
                                className={!isPurchase ? "hide" : "fontWeight500 mb-4"}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        if (isPurchase) this.props.clearBorrowerOwnedProperty(0)
                                    } else {
                                        if (count === 0) {
                                            if (currentView === "borrower") {
                                                this.props.addBorrowerOwnedProperty()
                                            }
                                            if (currentView === "coborrower") {
                                                this.props.addCoborrowerOwnedProperty()
                                            }
                                        }
                                    }
                                }}
                            >
                                I do not own any real estate
                            </Checkbox>
                            {renderProperties()}
                        </div>
                    </Col>
                </Row>
            </div>
        )
    };
    section3b = (currentView) => {
        let count = this.props.application[currentView].ownedproperties.length
        let isPurchase = this.props.application.property.purpose === "purchase"
        const renderProperties = () => {
            if (!isPurchase) {
                if (count === 0) {
                    return ""
                }
                return this.renderOwnedProperty(0, false, false, currentView)
            } else {
                if (count <= 1) {
                    return ""
                }
                return this.renderOwnedProperty(1, false, false, currentView)
            }
        }
        return (
            <div id="3b">
                <div className="form1003Title">3b. Additional Property</div>
                <div className="p-4">
                    <Row className="mb-4">
                        <Col>
                            <Checkbox
                                checked={
                                    isPurchase ? count < 2 : count < 1
                                }
                                className="fontWeight500"
                                onChange={(e) => {
                                    if (!e.target.checked) {
                                        if (isPurchase) {
                                            if (count === 0) {
                                                if (currentView === "borrower") {
                                                    this.props.addBorrowerOwnedProperty()
                                                    this.props.addBorrowerOwnedProperty()
                                                }
                                                if (currentView === "coborrower") {
                                                    this.props.addCoborrowerOwnedProperty()
                                                    this.props.addCoborrowerOwnedProperty()
                                                }
                                                this.setState({ showOwnPropertyWarning1: true })
                                            } else if (count === 1) {
                                                if (currentView === "borrower") {
                                                    this.props.addBorrowerOwnedProperty()
                                                }
                                                if (currentView === "coborrower") {
                                                    this.props.addCoborrowerOwnedProperty()
                                                }
                                                this.setState({ showOwnPropertyWarning1: false })
                                            }
                                        } else {
                                            if (count === 0) {
                                                if (currentView === "borrower") {
                                                    this.props.addBorrowerOwnedProperty()
                                                }
                                                if (currentView === "coborrower") {
                                                    this.props.addCoborrowerOwnedProperty()
                                                }
                                                this.setState({ showOwnPropertyWarning1: false })
                                            }
                                        }
                                    } else {
                                        let indexToClear = isPurchase ? 1 : 0
                                        if (count > indexToClear) {
                                            for (let i = indexToClear; i < count; i++) {
                                                if (currentView === "borrower") {
                                                    this.props.removeBorrowerOwnedProperty(i)
                                                }
                                                if (currentView === "coborrower") {
                                                    this.props.removeCoborrowerOwnedProperty(i)
                                                }
                                            }
                                        }
                                        this.setState({ showOwnPropertyWarning1: false, showOwnPropertyWarning2: false })
                                    }
                                }}
                            >
                                Does not apply
                            </Checkbox>
                        </Col>
                    </Row>
                    {this.state.showOwnPropertyWarning1 && <Row>
                        <Col className="mb-3">
                            <Alert
                                message="Warning"
                                description="Please fill the property information in section 3a first"
                                type="warning"
                                showIcon
                                closable
                            />
                        </Col>
                    </Row>}
                    {renderProperties()}
                </div>
            </div>
        )
    };
    section3c = (currentView) => {
        let count = this.props.application[currentView].ownedproperties.length
        let isPurchase = this.props.application.property.purpose === "purchase"
        const renderProperties = () => {
            if (!isPurchase) {
                if (count <= 1) {
                    return ""
                }
                return this.renderOwnedProperty(1, false, false, currentView)
            } else {
                if (count <= 2) {
                    return ""
                }
                return this.renderOwnedProperty(2, false, false, currentView)
            }
        }
        return (
            <div id="3c">
                <div className="form1003Title">3c. Additional Property</div>
                <div className="p-4">
                    <Row className="mb-4">
                        <Col>
                            <Checkbox
                                checked={isPurchase ? count < 3 : count < 2}
                                className="fontWeight500"
                                onChange={(e) => {

                                    if (!e.target.checked) {
                                        if (isPurchase) {
                                            if (count === 0) {
                                                if (currentView === "borrower") {
                                                    this.props.addBorrowerOwnedProperty()
                                                    this.props.addBorrowerOwnedProperty()
                                                    this.props.addBorrowerOwnedProperty()
                                                }
                                                if (currentView === "coborrower") {
                                                    this.props.addCoborrowerOwnedProperty()
                                                    this.props.addCoborrowerOwnedProperty()
                                                    this.props.addCoborrowerOwnedProperty()
                                                }
                                                this.setState({ showOwnPropertyWarning1: true, showOwnPropertyWarning2: true })
                                            } else if (count === 1) {
                                                if (currentView === "borrower") {
                                                    this.props.addBorrowerOwnedProperty()
                                                    this.props.addBorrowerOwnedProperty()
                                                }
                                                if (currentView === "coborrower") {
                                                    this.props.addCoborrowerOwnedProperty()
                                                    this.props.addCoborrowerOwnedProperty()
                                                }
                                                this.setState({ showOwnPropertyWarning1: false, showOwnPropertyWarning2: true })
                                            } else if (count === 2) {
                                                if (currentView === "borrower") {
                                                    this.props.addBorrowerOwnedProperty()
                                                }
                                                if (currentView === "coborrower") {
                                                    this.props.addCoborrowerOwnedProperty()
                                                }
                                                this.setState({ showOwnPropertyWarning1: false, showOwnPropertyWarning2: false })
                                            }
                                        } else {
                                            if (count === 0) {
                                                if (currentView === "borrower") {
                                                    this.props.addBorrowerOwnedProperty()
                                                    this.props.addBorrowerOwnedProperty()
                                                }
                                                if (currentView === "coborrower") {
                                                    this.props.addCoborrowerOwnedProperty()
                                                    this.props.addCoborrowerOwnedProperty()
                                                }
                                                this.setState({ showOwnPropertyWarning1: false, showOwnPropertyWarning2: true })
                                            } else if (count === 1) {
                                                if (currentView === "borrower") {
                                                    this.props.addBorrowerOwnedProperty()
                                                }
                                                if (currentView === "coborrower") {
                                                    this.props.addCoborrowerOwnedProperty()
                                                }
                                                this.setState({ showOwnPropertyWarning1: false, showOwnPropertyWarning2: false })
                                            }
                                        }
                                    } else {
                                        let indexToClear = isPurchase ? 2 : 1
                                        if (count > indexToClear) {
                                            for (let i = indexToClear; i < count; i++) {
                                                if (currentView === "borrower") {
                                                    this.props.removeBorrowerOwnedProperty(i)
                                                }
                                                if (currentView === "coborrower") {
                                                    this.props.removeCoborrowerOwnedProperty(i)
                                                }
                                            }
                                        }
                                        this.setState({ showOwnPropertyWarning1: false, showOwnPropertyWarning2: false })
                                    }
                                }}
                            >
                                Does not apply
                            </Checkbox>
                        </Col>
                    </Row>
                    {this.state.showOwnPropertyWarning2 && <Row>
                        <Col className="mb-3">
                            <Alert
                                message="Warning"
                                description="Please fill the property information in section 3a and 3b first"
                                type="warning"
                                showIcon
                                closable
                            />
                        </Col>
                    </Row>}
                    {renderProperties()}
                </div>
            </div>
        )
    };
    section3Extra = (currentView) => {
        let count = this.props.application[currentView].ownedproperties.length
        let isPurchase = this.props.application.property.purpose === "purchase"

        const renderProperties = (num) => {
            let properties = []
            if (!isPurchase) {
                if (count <= 2) {
                    return ""
                }
                for (let i = num; i > 0; i--) {
                    properties.push(this.renderOwnedProperty(count - i, false, true, currentView))
                }
                return properties
            } else {
                if (count <= 3) {
                    return ""
                }
                for (let i = num; i > 0; i--) {
                    properties.push(this.renderOwnedProperty(count - i, false, true, currentView))
                }
                return properties
            }
        }
        return (
            <div id="3extra">
                { count > 3 && <div className="">
                    {renderProperties(count-3)}
                </div>}
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 500,
                        color: "#325CEB",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        if (currentView === "borrower") {
                            this.props.addBorrowerOwnedProperty()
                        }
                        if (currentView === "coborrower") {
                            this.props.addCoborrowerOwnedProperty()
                        }
                    }}
                >
                    <img
                        src="/images/bluePlusInCircleMark.png"
                        style={{ width: 20, height: 20 }}
                        className="ml-4 mr-2 my-3"
                    />
                    Add property
                </div>
            </div>
        )
    };
    sectionS4 = () => {
        return (
            <div id="s4" className="p-4">
                <div className="fontWeight500" style={{ fontSize: 20 }}>
                    Section 4: Loan and Property Information
                </div>
                <div>
                    This section asks about the loan’s purpose and the property you want
                    to purchase or refinance.
                </div>
            </div>
        )
    };

    section4a = () => {
        this.section1aDom('4a. Loan and Property Information', {
            ...this.props.application.selection,
            ...this.props.application.property,

        }, ['loansize',"loanbalance", 'purpose', 'address', 'zipcode', 'appraisal', 'occupancy'])
        return (


            <div id="4a">
                <div className="form1003Title">4a. Loan and Property Information</div>
                <div className="p-4">
                    <Row>
                        <Col sm={6} className="mb-4">
                            {this.dropdownField(
                                "Loan purpose",
                                this.props.application.property.purpose,
                                [
                                    { value: "purchase", label: "Purchase" },
                                    { value: "refinance", label: "Rate/Term refinance" },
                                    { value: "cashoutrefinance", label: "Cash-out refinance" },
                                ],
                                (e) => {
                                    this.props.changeMainPropertyPurpose(e.target.value)
                                    if (e.target.value === "purchase") {
                                        this.props.changeMainPropertyAttribute(true, "hascontract")
                                    }
                                }
                            )}
                        </Col>
                        <Col sm={6} className="mb-4">
                            {this.dropdownField(
                                "Intended occupancy",
                                this.props.application.property.occupancy,
                                [
                                    { value: "principal", label: "Primary Residence" },
                                    { value: "secondhome", label: "Second Home" },
                                    { value: "investment", label: "Investment" },
                                    { value: "fhasecond", label: "FHA Secondary Residence" },
                                ],
                                (e) =>
                                    this.props.changeMainProperty(e.target.value, "occupancy")
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} className="mb-4">
                            {this.inputFieldWithPrefix(
                                "Property value",
                                this.props.application.property.appraisal,
                                (e) => {
                                    this.props.changeMainProperty(e.target.value, "appraisal")
                                    if (this.props.application.property.purpose === "purchase") {

                                        let loansize = com.safeParseInt(this.props.application.property.salesprice) - com.safeParseInt(this.props.selection.downpayment)
                                        let salesprice = com.safeParseInt(e.target.value)
                                        let downpay = salesprice - loansize
                                        // console.log("downpay", loansize, downpay)
                                        if (downpay >= 0) {
                                            this.props.updateSelectField(downpay, "downpayment")
                                            this.props.changeMainProperty(e.target.value, "salesprice")
                                        }
                                    }
                                },
                                "$"
                            )}
                        </Col>
                        {(this.props.application.property.purpose === "refinance" || this.props.application.property.purpose === "cashoutrefinance") && <Col sm={6} className="mb-4">
                            {this.inputFieldWithPrefix(
                                "Current balance",
                                this.props.application.loanbalance,
                                (e) => {
                                    this.props.updateApplicationAttribute(e.target.value,"loanbalance")

                                },
                                "$",
                            )}
                        </Col>}
                        <Col sm={6} className="mb-4">
                            {this.inputFieldWithPrefix(
                                "Loan amount",
                                this.props.application.selection.loansize,
                                (e) => {
                                    this.props.updateSelectField(e.target.value, "loansize")
                                    if (this.props.application.property.purpose === "purchase") {
                                        let salesprice = com.safeParseInt(this.props.application.property.salesprice)
                                        let loansize = com.safeParseInt(e.target.value)
                                        if (salesprice >= loansize) {
                                            this.props.updateSelectField(salesprice - loansize, "downpayment")
                                        }
                                    } else if (this.props.application.property.purpose === "cashoutrefinance") {
                                        let salesprice = com.safeParseInt(this.props.application.property.salesprice)
                                        let loanbalance = com.safeParseInt(this.props.application.loanbalance)
                                        let loansize = com.safeParseInt(e.target.value)
                                        if (salesprice >= loansize && loansize >= loanbalance) {
                                            this.props.updateSelectField(loansize - loanbalance, "cashout")
                                        }
                                    } else {
                                        let salesprice = com.safeParseInt(this.props.application.property.salesprice)
                                        let loanbalance = com.safeParseInt(this.props.application.loanbalance)
                                        let loansize = com.safeParseInt(e.target.value)
                                        if (salesprice >= loansize && loanbalance >= loansize) {
                                            this.props.updateSelectField(loanbalance - loansize, "paydown")
                                        }
                                    }
                                },
                                "$",
                                undefined,
                                undefined,
                                (e) => {
                                    if (this.props.application.property.purpose === "purchase") {
                                        let salesprice = com.safeParseInt(this.props.application.property.salesprice)
                                        let loansize = com.safeParseInt(e.target.value)
                                        if (salesprice < loansize) {
                                            alert("Loan amount cannot be greater than the property value. Please enter a suitable loan amount!")
                                        }
                                    }
                                    else if (this.props.application.property.purpose === "cashoutrefinance") {
                                        let loanbalance = com.safeParseInt(this.props.application.loanbalance)
                                        let loansize = com.safeParseInt(e.target.value)
                                        if (loansize < loanbalance) {
                                            alert("Loan amount cannot be less than the current loan balance. Please enter a suitable loan amount!")
                                        }
                                    }
                                    else {
                                        let loanbalance = com.safeParseInt(this.props.application.loanbalance)
                                        let loansize = com.safeParseInt(e.target.value)
                                        if (loansize > loanbalance) {
                                            alert("Loan amount cannot be greater than the current loan balance. Please enter a suitable loan amount!")
                                        }
                                    }
                                }

                            )}
                        </Col>
                    </Row>
                    <Row className="">
                        <Col>
                            {this.state.editMode ? <AddressAutocomplete
                                id="PropertyAddress"
                                label={<div className="fontWeight500">Address</div>}
                                required={true}
                                name="addessline"
                                border={true}
                                style={this.props.application.property.address === '' && this.props.mode !== 'document' ? { border: '1px solid #faad14' } : {}}
                                defaultValue={this.props.application.property.address}
                                onChange={(e) => this.props.changeMainProperty(e.target.value, "address")}
                                pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                placeholder="Please choose from the suggested addresses generated based on the input."
                                goodfeedback="Looks good!"
                                badfeedback="Please provide your street address."
                                onCompletion={e => {
                                    let address = e
                                    let state = com.getSubaddress(
                                        address,
                                        "administrative_area_level_1",
                                        "short_name"
                                    )
                                    let zip = com.getSubaddress(address, "postal_code", "long_name")

                                    let county = com.getSubaddress(
                                        address,
                                        "administrative_area_level_2",
                                        "long_name"
                                    )
                                    let num = com.getSubaddress(address, "street_number", "long_name")
                                    let short = com.getSubaddress(address, "route", "short_name")
                                    let city = com.getSubaddress(address, "locality", "long_name")
                                    let stradd = num + " " + short + ", " + city + ", " + state + " " + zip
                                    this.props.changeMainProperty(com.capitalize(com.convertAbbStateToFull(state)), "state")
                                    this.props.changeMainProperty(county, "county")
                                    this.props.changeMainProperty(city, "city")
                                    this.props.changeMainProperty(zip, "zipcode")
                                    this.props.changeMainProperty(stradd, "address")
                                }}
                                size="md"
                            /> : <div className="fontSize16">{this.props.application["borrower"]
                                .presentaddressstreet}</div>}

                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3} className="mb-4">
                            {this.dropdownField(
                                "State",
                                this.props.application.property.state,
                                this.getStateOptions(),
                                (e) => this.props.changeMainProperty(e.target.value, "state"),
                                // true
                            )}
                        </Col>
                        <Col sm={3} className="mb-4">
                            {this.dropdownField(
                                "County",
                                this.props.application.property.county,
                                this.getCountyOptions(this.props.application.property.state),
                                (e) => this.props.changeMainProperty(e.target.value, "county"),
                                // true
                            )}
                        </Col>
                        <Col sm={3} className="mb-4">
                            {this.inputField(
                                "City",
                                this.props.application.property.city,
                                (e) => this.props.changeMainProperty(e.target.value, "city"),

                            )}
                        </Col>
                        <Col sm={3} className="mb-4">
                            {this.inputField(
                                "Zip",
                                this.props.application.property.zipcode,
                                (e) => this.props.changeMainProperty(e.target.value, "zipcode"),

                            )}
                        </Col>
                    </Row>
                    <hr className="form1003Divider"></hr>
                    <Row className="mb-4">
                        <Col>
                            <div>
                                <div
                                    style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}
                                >
                                    1. Mixed-use property
                                </div>
                                <div>
                                    {this.selectionField(
                                        "If you will occupy the property, will you set aside space within the property to operate your own business?",
                                        this.props.application.property.isMixedUse === true
                                            ? true
                                            : false,
                                        [
                                            {
                                                value: true,
                                                label: "Yes",
                                            },
                                            {
                                                value: false,
                                                label: "No",
                                            },
                                        ],
                                        (e) => this.props.changeMainProperty(e, "isMixedUse"),
                                        true
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <div>
                                <div
                                    style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}
                                >
                                    2. Manufactured home
                                </div>
                                <div>
                                    {this.selectionField(
                                        "Is the property a manufactured home? (e.g., a factory built dwelling built on a permanent chassis)",
                                        this.props.application.property.isManufacturedHome === true
                                            ? true
                                            : false,
                                        [
                                            {
                                                value: true,
                                                label: "Yes",
                                            },
                                            {
                                                value: false,
                                                label: "No",
                                            },
                                        ],
                                        (e) => {
                                            this.props.changeMainProperty(e, "isManufacturedHome")
                                            if (e === false) {
                                                this.props.changeMainProperty("singlefamilydetached", "propertytype")
                                            } else {
                                                this.props.changeMainProperty("mobilehome", "propertytype")
                                            }
                                        },
                                        true
                                    )}

                                </div>
                            </div>
                        </Col>
                    </Row>
                    {this.props.application.property.isManufacturedHome === true &&
                    <div>
                        <Row className="mb-4">
                            <Col>
                                <div style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}>Manufactured home details</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} className="mb-4">
                                {this.inputField(
                                    "Manufactured home park full name",
                                    this.props.application.property.mobilehomeinfo.mobilehomeparkfullname,
                                    (e) => this.props.changeMobileHomeInfo(e.target.value, "mobilehomeparkfullname"),

                                )}
                            </Col>
                            <Col sm={6} className="mb-4">
                                {this.inputField(
                                    "Park Address",
                                    this.props.application.property.mobilehomeinfo.parkAddress,
                                    (e) => this.props.changeMobileHomeInfo(e.target.value, "parkAddress"),

                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} className="mb-4">
                                {this.inputFieldWithPrefix(
                                    "Monthly space rent",
                                    this.props.application.property.mobilehomeinfo.leasedSpaceRentAmount,
                                    (e) => this.props.changeMobileHomeInfo(e.target.value, "leasedSpaceRentAmount"),
                                    "$",
                                )}
                            </Col>
                            <Col sm={6} className="mb-4">
                                {this.inputField(
                                    "Space number",
                                    this.props.application.property.mobilehomeinfo.space,
                                    (e) => this.props.changeMobileHomeInfo(e.target.value, "space"),
                                )}
                            </Col>
                        </Row>
                        <Row >
                            <Col sm={6} className="mb-4">
                                <Form.Group className="inputSection">
                                    <Form.Label style={{fontSize: 14, fontWeight: 500, marginBottom: 10}}>
                                        Width
                                    </Form.Label>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ width: "49%" }}>
                                            {this.state.editMode ? (
                                                <Input
                                                    value={this.props.application.property.mobilehomeinfo.widthft}
                                                    onChange={(e) => this.props.changeMobileHomeInfo(e.target.value, "widthft")}
                                                    suffix="ft"
                                                    size="large"
                                                />
                                            ) : (
                                                <div style={{ fontSize: 16 }}>
                                                    {this.props.application.property.mobilehomeinfo.widthft} ft
                                                </div>
                                            )}
                                        </div>
                                        <div style={{ width: "49%" }}>
                                            {this.state.editMode ? (
                                                <Input
                                                    value={this.props.application.property.mobilehomeinfo.widthinch}
                                                    onChange={(e) => this.props.changeMobileHomeInfo(e.target.value, "widthinch")}
                                                    suffix="inch"
                                                    size="large"
                                                />
                                            ) : (
                                                <div style={{ fontSize: 16 }}>
                                                    {this.props.application.property.mobilehomeinfo.widthinch} inch
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col sm={6} className="mb-4">
                                <Form.Group className="inputSection">
                                    <Form.Label style={{fontSize: 14, fontWeight: 500, marginBottom: 10}}>
                                        Length
                                    </Form.Label>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ width: "49%" }}>
                                            {this.state.editMode ? (
                                                <Input
                                                    value={this.props.application.property.mobilehomeinfo.lengthft}
                                                    onChange={(e) => this.props.changeMobileHomeInfo(e.target.value, "lengthft")}
                                                    suffix="ft"
                                                    size="large"
                                                />
                                            ) : (
                                                <div style={{ fontSize: 16 }}>
                                                    {this.props.application.property.mobilehomeinfo.lengthft} ft
                                                </div>
                                            )}
                                        </div>
                                        <div style={{ width: "49%" }}>
                                            {this.state.editMode ? (
                                                <Input
                                                    value={this.props.application.property.mobilehomeinfo.lengthinch}
                                                    onChange={(e) => this.props.changeMobileHomeInfo(e.target.value, "lengthinch")}
                                                    suffix="inch"
                                                    size="large"
                                                />
                                            ) : (
                                                <div style={{ fontSize: 16 }}>
                                                    {this.props.application.property.mobilehomeinfo.lengthinch} inch
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} className="mb-4">
                                {this.dropdownField(
                                    "Condition",
                                    this.props.application.property.mobilehomeinfo.mobilehomedescription,
                                    [
                                        { value: "New", label: "New" },
                                        { value: "Used", label: "Used" },
                                        { value: "Repo", label: "Repo" },
                                    ],
                                    (e) => this.props.changeMobileHomeInfo(e.target.value, "mobilehomedescription"),
                                )}
                            </Col>
                            <Col sm={6} className="mb-4">
                                {this.dropdownField(
                                    "Type",
                                    this.props.application.property.mobilehomeinfo.mobilehometype,
                                    [
                                        { value: "SingleWide", label: "Single Wide" },
                                        { value: "DoubleWide", label: "Double Wide" },
                                        { value: "TripleWide", label: "Triple Wide" },
                                    ],
                                    (e) => this.props.changeMobileHomeInfo(e.target.value, "mobilehometype"),
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} className="mb-4">
                                {this.inputField(
                                    "Manufacturer make",
                                    this.props.application.property.mobilehomeinfo.manufacturerMake,
                                    (e) => this.props.changeMobileHomeInfo(e.target.value, "manufacturerMake"),
                                )}
                            </Col>
                            <Col sm={6} className="mb-4">
                                {this.inputField(
                                    "Manufacturer model",
                                    this.props.application.property.mobilehomeinfo.manufacturerModel,
                                    (e) => this.props.changeMobileHomeInfo(e.target.value, "manufacturerModel"),
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} className="mb-4">
                                {this.inputField(
                                    "Year built",
                                    this.props.application.property.mobilehomeinfo.year,
                                    (e) => this.props.changeMobileHomeInfo(e.target.value, "year"),
                                )}
                            </Col>
                            <Col sm={6} className="mb-4">
                                {this.dropdownField(
                                    "Location",
                                    this.props.application.property.mobilehomeinfo.mobilehomelocation,
                                    [
                                        { value: "Park", label: "Park" },
                                        { value: "LandorHome", label: "Land/home" },
                                        { value: "CoopPark", label: "Co-op park" },
                                        { value: "SharedPark", label: "Shared park" },
                                    ],
                                    (e) => this.props.changeMobileHomeInfo(e.target.value, "mobilehomelocation"),
                                )}
                            </Col>
                        </Row>
                    </div>}
                </div>
            </div>
        )
    };
    section4b = () => {
        return (
            <div id="4b">
                <div className="form1003Title">
                    4b. Other New Mortgage Loans on the Property You are Buying or
                    Refinancing
                </div>
                <div className="p-4">
                    <Row className="mb-4">
                        <PropertyMortgagesTable />
                    </Row>
                </div>
            </div>
        )
    };
    section4c = () => {
        return (
            <div id="4c">
                <div className="form1003Title">
                    4c. Rental Income on the Property You Want to Purchase (For Purchase Only)
                </div>
                <div className="p-4">
                    <Row className="mb-4">
                        <Col>
                            <Checkbox
                                checked={
                                    this.props.application.property.propertytype === "twotofourfamily"
                                }
                                className="fontWeight500"
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        this.props.changeMainProperty("twotofourfamily", "propertytype")
                                    } else {
                                        this.props.changeMainProperty("singlefamilydetached", "propertytype")
                                    }
                                }}
                            >
                                This is a 2-4 unit property
                            </Checkbox>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <Checkbox
                                checked={this.props.application.property.occupancy === "investment"}
                                className="fontWeight500"
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        this.props.changeMainProperty("investment", "occupancy")
                                    } else {
                                        this.props.changeMainProperty("principal", "occupancy")
                                    }
                                }}
                            >
                                This is a investment property
                            </Checkbox>
                        </Col>
                    </Row>
                    {this.props.application.property.purpose === "purchase" && (
                        this.props.application.property.occupancy === "investment" ||
                        (this.props.application.property.propertytype === "twotofourfamily" && this.props.application.property.occupancy === "principal")
                    ) && <Row className="mb-4">
                            <Col className="pl-0">
                                {this.inputFieldWithPrefix(
                                    "Expected monthly rental income",
                                    this.props.application.property.purchase
                                        .expectedgrossmonthlyrentalincome,
                                    (e) =>
                                        this.props.changeMainProperty(
                                            e.target.value,
                                            "purchase|expectedgrossmonthlyrentalincome"
                                        ),
                                    "$",
                                    undefined,
                                    true
                                )}
                            </Col>
                            <Col className="pl-0">
                                {this.inputFieldWithPrefix(
                                    "Expected net monthly income",
                                    this.props.application.property.purchase.expectednetmonthlyrentalincome,
                                    (e) =>
                                        this.props.changeMainProperty(
                                            e.target.value,
                                            "purchase|expectednetmonthlyrentalincome"
                                        ),
                                    "$",
                                    undefined,
                                    true
                                )}
                            </Col>
                        </Row>}
                </div>
            </div>
        )
    };
    section4d = () => {
        return (
            <div id="4d">
                <div className="form1003Title">
                    4d. Gifts or Grants You Have Been Given or Will Receive for this Loan
                </div>
                <div className="p-4">
                    <Row className="mb-4">
                        <Col className="px-0">
                            <GiftsTable />
                        </Col>
                    </Row>
                </div>
            </div>
        )
    };
    sectionS5 = () => {
        return (
            <div id="s5" className="p-4">
                <div className="fontWeight500" style={{ fontSize: 20 }}>
                    Section 5: Declarations
                </div>
                <div>
                    This section asks you specific questions about the property, your
                    funding, and your past financial history
                </div>
            </div>
        )
    };
    section5aDom = (title, data, list) => {
        const link = document.getElementsByClassName('ant-anchor-link-title')
        const flag = false
        for (let index = 0; index < link.length; index++) {

            if (link[index].title === title) {

                for (let i = 0; i < list.length; i++) {
                    if (data[list[i][0]][list[i][1]] === '' || data[list[i][0]][list[i][1]] === null) {
                        link[index].style.color = '#faad14'
                        break
                    } else {
                        link[index].style.color = '#000'
                    }
                }
            }
        }
    }
    section5a = (currentView) => {
        let b = currentView === "borrower" ? this.props.application.borrower : this.props.application.coborrower
        // this.section5aDom('5a. About this Property and Your Money for this Loan', b,
        //  [['declarations','occupyasprimary'],['declarations','ownershipinterest'],['declarations','propertyowned'],['declarations','titleheld'],['declarations','familyrelationship'],['declarations','downpaymentborrowed'],['declarations','applyingonanother'],['declarations','applyingnewcredit'],['declarations','cleanenergylien']])
        return (
            <div id="5a">
                <div className="form1003Title">
                    5a. About this Property and Your Money for this Loan
                </div>
                <div className="p-4">
                    <Row className="mb-4">
                        <Col className="px-0">
                            {this.yesNoField(
                                "A. Will you occupy the property as your primary residence?",
                                b.declarations.occupyasprimary ===
                                    "yes"
                                    ? "yes"
                                    : "no",
                                (e) =>
                                    this.props.changeDeclaration(e, currentView, "occupyasprimary")
                            )}
                        </Col>
                    </Row>
                    {b.declarations.occupyasprimary ===
                        "yes" && (
                            <Row className="mb-4">
                                <Col className="px-0">
                                    {this.yesNoField(
                                        "Have you had an ownership interest in another property in the last three years?",
                                        b.declarations
                                            .ownershipinterest === "yes"
                                            ? "yes"
                                            : "no",
                                        (e) =>
                                            this.props.changeDeclaration(
                                                e,
                                                currentView,
                                                "ownershipinterest"
                                            )
                                    )}
                                </Col>
                            </Row>
                        )}
                    {b.declarations.occupyasprimary === "yes" && b.declarations.ownershipinterest ===
                        "yes" && (
                            <Row className="mb-4">
                                <Col className="px-0">
                                    {this.horizontalDropdownField(
                                        "(1) What type of property did you own: primary residence (PR), FHA secondary residence (SR), second home (SH), or investment property (IP)?",
                                        b.declarations.propertyowned ===
                                            null
                                            ? ""
                                            : b.declarations
                                                .propertyowned,
                                        [
                                            { value: "", label: "..." },
                                            { value: "principal", label: "Primary residence" },
                                            { value: "secondhome", label: "Second Home" },
                                            { value: "fhasecond", label: "FHA Second Home" },
                                            { value: "investment", label: "Investment" },
                                        ],
                                        (e) =>
                                            this.props.changeDeclaration(e.target.value, currentView, "propertyowned")
                                    )}
                                </Col>
                            </Row>
                        )}
                    {b.declarations.occupyasprimary === "yes" && b.declarations.ownershipinterest ===
                        "yes" && (
                            <Row className="mb-4">
                                <Col className="px-0">
                                    {this.horizontalDropdownField(
                                        "(2) How did you hold title to the property: by yourself (S), jointly with your spouse (SP), or jointly with another person (O)?",
                                        b.declarations.titleheld ===
                                            null
                                            ? ""
                                            : b.declarations.titleheld,
                                        [
                                            { value: "", label: "..." },
                                            { value: "yourself", label: "By yourself" },
                                            {
                                                value: "jointwithspouse",
                                                label: "Jointly with your spouse",
                                            },
                                            {
                                                value: "jointwithother",
                                                label: "Jointly with another person",
                                            },
                                        ],
                                        (e) =>
                                            this.props.changeDeclaration(e.target.value, currentView, "titleheld")
                                    )}
                                </Col>
                            </Row>
                        )}
                    <hr className="form1003Divider"></hr>
                    <Row className="mb-4">
                        <Col className="px-0">
                            {this.yesNoField(
                                "B. If this is a Purchase Transaction: Do you have a family relationship or business affiliation with the seller of the property?",
                                b.declarations
                                    .familyrelationship === "yes"
                                    ? "yes"
                                    : "no",
                                (e) =>
                                    this.props.changeDeclaration(
                                        e,
                                        currentView,
                                        "familyrelationship"
                                    )
                            )}
                        </Col>
                    </Row>
                    <hr className="form1003Divider"></hr>
                    <Row className="mb-4">
                        <Col className="px-0">
                            {this.yesNoField(
                                "C. Are you borrowing any money for this real estate transaction or obtaining any money from another party, such as the seller or realtor, that you have not disclosed on this loan application?",
                                b.declarations
                                    .downpaymentborrowed === "yes"
                                    ? "yes"
                                    : "no",
                                (e) =>
                                    this.props.changeDeclaration(
                                        e,
                                        currentView,
                                        "downpaymentborrowed"
                                    )
                            )}
                        </Col>
                    </Row>
                    {b.declarations.downpaymentborrowed === "yes" && (
                        <Row className="mb-4 align-items-center">
                            <Col md={7}>
                                What is the amount of this money?
                            </Col>
                            <Col md={5} className="px-0">
                                {this.inputFieldWithPrefix(
                                "Amount:",
                                b.declarations.moneyborrowed,
                                (e) =>
                                    this.props.changeDeclaration(e.target.value, currentView, "moneyborrowed"),
                                "$",
                                )}
                            </Col>
                        </Row>
                    )}
                    <hr className="form1003Divider"></hr>
                    <Row className="mb-4">
                        <Col className="px-0">
                            {this.yesNoField(
                                "D. 1. Have you or will you be applying for a mortgage loan on another property (not the property securing this loan) on or before closing this transaction that is not disclosed on this loan application?",
                                b.declarations
                                    .applyingonanother === "yes"
                                    ? "yes"
                                    : "no",
                                (e) =>
                                    this.props.changeDeclaration(
                                        e,
                                        currentView,
                                        "applyingonanother"
                                    )
                            )}
                        </Col>
                    </Row>
                    <hr className="form1003Divider"></hr>
                    <Row className="mb-4">
                        <Col className="px-0">
                            {this.yesNoField(
                                "D. 2. Have you or will you be applying for any new credit on or before closing this loan that is not disclosed on this application?",
                                b.declarations
                                    .applyingnewcredit === "yes"
                                    ? "yes"
                                    : "no",
                                (e) =>
                                    this.props.changeDeclaration(
                                        e,
                                        currentView,
                                        "applyingnewcredit"
                                    )
                            )}
                        </Col>
                    </Row>
                    <hr className="form1003Divider"></hr>
                    <Row className="mb-4">
                        <Col className="px-0">
                            {this.yesNoField(
                                "E. Will this property be subject to a lien that could take priority over the first mortgage lien, such as a clean energy lien paid through your property taxes (e.g., the Property Assessed Clean Energy Program)?",
                                b.declarations.cleanenergylien ===
                                    "yes"
                                    ? "yes"
                                    : "no",
                                (e) =>
                                    this.props.changeDeclaration(e, currentView, "cleanenergylien")
                            )}
                        </Col>
                    </Row>
                </div>
            </div>
        )
    };
    section5b = (currentView) => {
        let chaptersLabelMap = {
            "chapter7": "Chapter 7",
            "chapter11": "Chapter 11",
            "chapter12": "Chapter 12",
            "chapter13": "Chapter 13",
        }
        let questions = [
            [
                "F. Are you a co-signer or guarantor on any debt or loan that is not disclosed on this application?",
                "comakeronnote",
            ],
            ["G. Are there any outstanding judgments against you?", "judgements"],
            [
                "H. Are you currently delinquent or in default on a Federal debt?",
                "delinquentondebt",
            ],
            [
                "I. Are you a party to a lawsuit in which you potentially have any personal financial liability?",
                "lawsuit",
            ],
            [
                "J. Have you conveyed title to any property in lieu of foreclosure in the past 7 years?",
                "conveyedtitle",
            ],
            [
                "K. Within the past 7 years, have you completed a pre-foreclosure sale or short sale, whereby the property was sold to a third party and the Lender agreed to accept less than the outstanding mortgage balance due?",
                "loanforeclosure",
            ],
            [
                "L. Have you had property foreclosed upon in the last 7 years?",
                "propertyforeclosure",
            ],
            ["M. Have you declared bankruptcy within the past 7 years?", "bancrupt"],
        ]
        let fields = []
        let b = currentView === "borrower" ? this.props.application.borrower : this.props.application.coborrower

        // this.section1aDom('5b. About Your Finances', b,
        // [['declarations','comakeronnote'],['declarations','delinquentondebt'],['declarations','lawsuit'],['declarations','conveyedtitle'],['declarations','loanforeclosure'],['declarations','propertyforeclosure'],['declarations','bancrupt']])

        for (let i = 0; i < questions.length; i++) {
            fields.push(
                <Row className="mb-4">
                    <Col className="px-0">
                        {this.yesNoField(
                            questions[i][0],
                            b.declarations[questions[i][1]] ===
                                "yes"
                                ? "yes"
                                : "no",
                            (e) =>
                                this.props.changeDeclaration(e, currentView, questions[i][1])
                        )}
                    </Col>
                </Row>
            )
            if (i < questions.length - 1) {
                fields.push(<hr className="form1003Divider"></hr>)
            }
        }

        return (
            <div id="5b">
                <div className="form1003Title">5b. About Your Finances</div>
                <div className="px-4 pt-4">{fields}</div>
                {b.declarations.bancrupt === "yes" && <Row className="mb-4 px-4">
                    <Col className="px-0">
                        <div className= "d-flex justify-content-between align-items-center">
                            <div className="mr-2 fontWeight500 col-7 pl-0">Identify the type(s) of bankruptcy</div>
                            {["chapter7", "chapter11", "chapter12", "chapter13"].map(s => (
                                <Checkbox
                                    checked={b.declarations[s]}
                                    onChange={e => this.props.changeDeclaration(e.target.checked, currentView, s)}
                                    key={s}
                                >
                                    {chaptersLabelMap[s]}
                                    </Checkbox>
                            ))}
                        </div>
                    </Col>
                </Row>}

            </div>
        )
    };
    sectionS6 = () => {
        return (
            <div id="s6">
                <div className="p-4">
                    <div className="fontWeight500" style={{ fontSize: 20 }}>
                        Section 6: Acknowledgments and Agreements.
                    </div>
                    <div>
                        This section tells you about your legal obligations when you sign
                        this application.
                    </div>
                </div>
                <div className="form1003Title">Acknowledgments and Agreements</div>
                <div className="p-4">
                    <Row className="mb-4">
                        <Col className="px-0">
                            <div className="fontWeight500 mb-3">Definitions:</div>
                            <div>
                                <li>
                                    "Lender" includes the Lender’s agents, service providers, and
                                    any of their successors and assigns.{" "}
                                </li>
                                <li>
                                    "Other Loan Participants" includes (i) any actual or potential
                                    owners of a loan resulting from this application (the “Loan”),
                                    (ii) acquirers of any beneficial or other interest in the
                                    Loan, (iii) any mortgage insurer, (iv) any guarantor, (v) any
                                    servicer of the Loan, and (vi) any of these parties' service
                                    providers, successors or assigns.
                                </li>
                            </div>
                            <div className="fontWeight500 my-3">
                                I agree to, acknowledge, and represent the following:
                            </div>
                            <div className="fontWeight500 mb-3">
                                (1) The Complete Information for this Application{" "}
                            </div>
                            <li>
                                The information I have provided in this application is true,
                                accurate, and complete as of the date I signed this application.
                            </li>
                            <li>
                                If the information I submitted changes or I have new information
                                before closing of the Loan, I must change and supplement this
                                application, including providing any updated/supplemented real
                                estate sales contract.{" "}
                            </li>
                            <li>
                                {" "}
                                For purchase transactions: The terms and conditions of any real
                                estate sales contract signed by me in connection with this
                                application are true, accurate, and complete to the best of my
                                knowledge and belief. I have not entered into any other
                                agreement, written or oral, in connection with this real estate
                                transaction
                            </li>
                            <li>
                                The Lender and Other Loan Participants may rely on the
                                information contained in the application before and after
                                closing of the Loan
                            </li>
                            <li>
                                {" "}
                                Any intentional or negligent misrepresentation of information
                                may result in the imposition of:{" "}
                            </li>
                            <div>
                                (a) civil liability on me, including monetary damages, if a
                                person suffers any loss because the person relied on any
                                misrepresentation that I have made on this application, and/or
                            </div>
                            <div>
                                (b) criminal penalties on me including, but not limited to, fine
                                or imprisonment or both under the provisions of Federal law (18
                                U.S.C. §§ 1001 et seq.).
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    };
    sectionS7 = (currentView) => {
        let b = currentView === "borrower" ? this.props.application.borrower : this.props.application.coborrower
        // this.section1aDom('S7. Military Service', b,
        // [['declarations','servearmedforces']])
        let militaryServiceLabelMap = {
            "currentlyserving": "Currently serving on active duty with projected expriation date of service/tour",
            "currentlyretired":"Currently retired, discharged, or separated from service",
            "nonactive": "Only period of service was as a non-activated member of Reserve or National Guard",
            "survivingspouse": "Surviving spouse",
        }
        let changeMilitary = (verb, isdate) =>{
            let m = {...b.declarations.militaryservice}
            return e => {
                let value = isdate ? e.target.value : ""
                if (isdate){
                    if (/^[\d/]*$/.test(value)){
                        let formattedValue = value.replace(/\D/g,'')
                        if (formattedValue.length > 2){
                            formattedValue = formattedValue.slice(0, 2) + "/" + formattedValue.slice(2, 4) + "/" + formattedValue.slice(4, 8)
                        } else if (formattedValue.length > 2){
                            formattedValue = formattedValue.slice(0, 2) + "/" + formattedValue.slice(2, 4)
                        }
                        value = formattedValue
                    }
                }
                m[verb] = isdate ? value : e.target.checked
                this.props.changeDeclaration(m, currentView, "militaryservice")
            }
        }

        return (
            <div id="s7">
                <div className="p-4">
                    <div className="fontWeight500" style={{ fontSize: 20 }}>
                        Section 7: Military Service
                    </div>
                    <div>
                        This section asks questions about your (or your deceased spouse's)
                        military service.
                    </div>
                </div>
                <div className="form1003Title">Military Service of Borrower</div>
                <div className="p-4">
                    <Row className="mb-4">
                        <Col className="px-0">
                            {this.yesNoField(
                                "Did you (or your deceased spouse) ever serve, or are you currently serving, in the United States Armed Forces?",
                                b.declarations
                                    .servearmedforces === "yes"
                                    ? "yes"
                                    : "no",
                                (e) =>
                                    this.props.changeDeclaration(
                                        e,
                                        currentView,
                                        "servearmedforces"
                                    )
                            )}
                        </Col>
                    </Row>
                    {b.declarations.servearmedforces === "yes" && <Row className="mb-4">
                        <Col className="px-0">
                            <div className="d-flex flex-column">
                                {["currentlyserving", "currentlyretired", "nonactive", "survivingspouse"].map(s => (
                                    <div className="militaryServiceMultiCheckWrap" style={{display: s==="currentlyserving" ? "flex" : ""}}>
                                        <Checkbox
                                            checked={b.declarations.militaryservice[s]}
                                            onChange={changeMilitary(s)}
                                            key={s}
                                            className="mb-2 w-100"
                                        >
                                            {militaryServiceLabelMap[s]}
                                        </Checkbox>
                                        {s === "currentlyserving" && b.declarations.militaryservice.currentlyserving &&
                                            <div>
                                                <Input
                                                    value={b.declarations.militaryservice.projectedexpirationdate}
                                                    type="text"
                                                    placeholder="MM/DD/YYYY"
                                                    style={{width:"200px", marginLeft:"20px", marginBottom:"10px"}}
                                                    onChange={changeMilitary("projectedexpirationdate", true)}

                                                />
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>}
                </div>
            </div>
        )
    };
    sectionS8 = (currentView) => {
        let b = currentView === "borrower" ? this.props.application.borrower : this.props.application.coborrower
        let who = currentView === "borrower" ? "borrower" : "coborrower"
        let ethnicityOptions = [
            {key: "mexican", label: "Mexican"},
            {key: "puertorican", label: "Puerto Rican"},
            {key: "cuban", label:"Cuban"},
            {key: "other", label: "Other Hispanic or Latino"}
        ]
        let asianRaceOptions = [
            {key: "asianindian", label: "Asian Indian"},
            {key: "chinese", label: "Chinese"},
            {key: "filipino", label: "Filipino"},
            {key: "japanese", label: "Japanese"},
            {key: "korean", label: "Korean"},
            {key: "vietnamese", label: "Vietnamese"},
            {key: "otherasian", label: "Other Asian"}
        ]
        let pacificRaceOptions = [
            {key: "hawaian", label: "Native Hawaian"},
            {key: "guamanian", label: "Guamanian or Chamorro"},
            {key: "samoan", label: "Samoan"},
            {key: "other", label: "Other Pacific Islander"},
        ]
        let raceDetailsMap = {
            "asian": asianRaceOptions,
            "pacific": pacificRaceOptions,
            "americanindian": [],
            "black": [],
            "white": [],
            "notfurnished": [],
        }
        let raceOptions = [
            {key: "americanindian", label:"American Indian or Alaska Native"},
            {key: "asian", label: "Asian"},
            {key: "black", label: "Black or African American"},
            {key: "pacific", label: "Native Hawaiian or Other Pacific Islander"},
            {key: "white", label: "White"},
            {key: "notfurnished", label: "I do not wish to provide this information"},
        ]

        const getRace = () => {
            let r = currentView === "borrower" ? this.props.application.borrower.equalopportunity.race : this.props.application.coborrower.equalopportunity.race
            if (r.asian) {
                return "asian"
            } else if (r.black) {
                return "black"
            } else if (r.pacific) {
                return "pacific"
            } else if (r.white) {
                return "white"
            } else if (r.americanindian) {
                return "americanindian"
            } else if (r.notfurnished) {
                return "notfurnished"
            }
            return ""
        }

        const onOtherHispanic = (event) => {
            com.touch()

            this.props.updateEqualOpportunitySub(event.target.value, who, "hispanic", "otherdesctiption")
        }

        const onOtherAsian = (event) => {
            com.touch()

            this.props.updateEqualOpportunitySub(event.target.value, who, "race|asiandetails", "otherdesctiption")
        }

        const onPacific = (event) => {
            com.touch()

            this.props.updateEqualOpportunitySub(event.target.value, who, "race|pacificdetails", "otherdetails")
        }

        let onTribe = (event) => {
            com.touch()
            this.props.updateEqualOpportunitySub(event.target.value, who, "race", "indiantribe")
        }

        const updateEqualOpportunityCheck = (who, section, verb) =>{
            return e => {
                com.touch()

                this.props.updateEqualOpportunitySub(e.target.checked, who, section, verb)
            }
        }

        const getEthnicityOptions = () => {
            let options = ethnicityOptions.map(e => {
                if (e.key !== "other"){
                    return <div className="mb-4 multiCheckboxWrapper">
                        <Checkbox
                            checked = {
                                b.equalopportunity.hispanic[e.key]
                            }
                            className="fontWeight500"
                            onChange={updateEqualOpportunityCheck(currentView, "hispanic", e.key)}
                        >
                            {e.label}
                        </Checkbox>
                    </div>
                }
            })
            options.push(
                <div className="d-flex justify-content-start w-100">
                <div className="mb-4 multiCheckboxWrapper">
                    <Checkbox
                        checked = {
                            b.equalopportunity.hispanic.other
                        }
                        className="fontWeight500 w-100"
                        onChange={updateEqualOpportunityCheck(currentView, "hispanic", "other")}
                    >
                        Other Hispanic or Latino
                    </Checkbox>
                </div>
                {b.equalopportunity.hispanic.other && <div style={{marginLeft: "20px", width: "calc((100% - 40px) / 3)"}}>
                    <Input
                        className="otherEthinicityInput"
                        value={b.equalopportunity.hispanic.otherdesctiption}
                        type="text"
                        placeholder="Chilean, Perivuan, and so on."
                        onChange={onOtherHispanic}
                    />
                </div>}
                </div>
            )
            return options
        }
        const getRaceOptions = () => {
            let options = raceOptions.map(e =>{
                return <>
                    <Row className="multiCheckboxWrapperVertical">
                        <Checkbox
                            checked = {b.equalopportunity.race[e.key]}
                            className="fontWeight500 w-100"
                            onChange={updateEqualOpportunityCheck(currentView, "race", e.key)}
                        >
                            {e.label}
                        </Checkbox>
                    </Row>
                    <Row className="mt-4">
                        {getRaceDetails(b.equalopportunity.race[e.key], e.key)}
                    </Row>
                </>
            })
            return options
        }

        const getRaceDetails = (checked, type) => {
            if (checked){
                if (type === "americanindian") {
                    return <Col md={12} xs={12} className="d-flex align-items-center mb-4">
                        <div className="mr-2">Print name of enrolled or principal tribe </div>
                        <Input
                            value={b.equalopportunity.race.indiantribe}
                            type="text"
                            style={{width:"220px"}}
                            onChange={onTribe}
                        />
                    </Col>
                } else if (type !== "americanindian" && raceDetailsMap[type].length === 0) {
                    return ""
                } else {
                    let options = raceDetailsMap[type].map(e => {
                        if (e.key === "otherasian" || e.key === "other") {
                            return <Col md={12} xs={12} className="d-flex align-items-center mb-4">
                                <Checkbox
                                    checked = {
                                        e.key === "otherasian"?
                                        b.equalopportunity.race.asiandetails.otherasian :
                                        b.equalopportunity.race.pacificdetails.other
                                    }
                                    className="fontWeight500"
                                    onChange={
                                        e.key === "otherasian"?
                                        updateEqualOpportunityCheck(currentView, "race|asiandetails", e.key) :
                                        updateEqualOpportunityCheck(currentView, "race|pacificdetails", e.key)
                                    }
                                >
                                    {e.label}
                                </Checkbox>
                                <Input
                                    value={
                                        e.key === "otherasian" ?
                                        b.equalopportunity.race.asiandetails.otherdesctiption :
                                        b.equalopportunity.race.pacificdetails.otherdetails
                                    }
                                    type="text"
                                    placeholder={
                                        e.key === "otherasian" ?
                                        "Hmong, Laotian, and so on..." :
                                        "Fijian, Tongan, and so on..."
                                    }
                                    style={{width: "220px"}}
                                    onChange={
                                        e.key === "otherasian" ?
                                        onOtherAsian :
                                        onPacific
                                    }
                                />
                            </Col>
                        } else {
                            return <Col md={4} xs={12} className="mb-4">
                                <Checkbox
                                    checked = {
                                        type === "asian" ?
                                        b.equalopportunity.race.asiandetails[e.key] :
                                        b.equalopportunity.race.pacificdetails[e.key]
                                    }
                                    className="fontWeight500"
                                    onChange={
                                        type === "asian" ?
                                        updateEqualOpportunityCheck(currentView, "race|asiandetails", e.key) :
                                        updateEqualOpportunityCheck(currentView, "race|pacificdetails", e.key)
                                    }
                                >
                                    {e.label}
                                </Checkbox>
                            </Col>
                        }
                    })
                    return options
                }
            } else {
                return ""
            }

        }

        this.section1aDom('S8. Demographic Information', b,
            [['equalopportunity', 'ethnicity'], ['equalopportunity', 'sex'], ['equalopportunity', getRace()]])
        return (
            <div id="s8">
                <div className="p-4">
                    <div className="fontWeight500" style={{ fontSize: 20 }}>
                        Section 8: Demographic Information
                    </div>
                    <div>This section asks about your ethnicity, sex, and race.</div>
                </div>
                <div className="form1003Title">Demographic Information of Borrower</div>
                <div className="p-4">
                    <Row className="mb-4">
                        <Col className="px-0">
                            {this.selectionField(
                                "Ethnicity",
                                b.equalopportunity.ethnicity,
                                [
                                    { value: "hispanic", label: "Hispanic or Latino" },
                                    { value: "nonhispanic", label: "Not Hispanic or Latino" },
                                    { value: "donttell", label: "I do not wish to provide this information" },
                                ],
                                (e) =>
                                    this.props.updateEqualOpportunity(e, currentView, "ethnicity"),
                                false,
                                true
                            )}
                        </Col>
                    </Row>
                    {b.equalopportunity.ethnicity === "hispanic" && <>
                        <div className="fontWeight500 mb-3">Check all that apply:</div>
                        <Row className="justify-content-between">
                                {getEthnicityOptions()}
                            </Row>
                        </>
                    }
                    <Row className="mb-4">
                        <Col className="px-0">
                            {this.selectionField(
                                "Sex",
                                b.equalopportunity.sex,
                                [
                                    { value: "female", label: "Female" },
                                    { value: "male", label: "Male" },
                                    { value: "other", label: "Other" },
                                    { value: "donttell", label: "I do not wish to provide this information" },
                                ],
                                (e) => this.props.updateEqualOpportunity(e, currentView, "sex"),
                                false,
                                true
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <div className="d-flex flex-column w-100">
                            <div className="mb-3">Race</div>
                            {getRaceOptions()}
                        </div>
                    </Row>
                </div>
            </div>
        )
    };

    sectionS9 = () => {
        let b = this.props.application.brokerinfo

        this.section1aDom('S9. Loan Originator Information (to be completed by the loan originator)', b,
            ["name", "nmls", "licenseid", "loanofficername", "loanofficernmls", "loanofficerlicenseid", "email", "phone"])

        const onBrokerAddressCompletion = (address) => {
            let num = com.getSubaddress(address, "street_number", "long_name")

            let city = com.getSubaddress(address, "locality", "long_name")
            let state = com.getSubaddress(
                address,
                "administrative_area_level_1",
                "short_name"
            )
            let zip = com.getSubaddress(address, "postal_code", "long_name")

            let county = com.getSubaddress(
                address,
                "administrative_area_level_2",
                "long_name"
            )
            let longstate = com.getSubaddress(
                address,
                "administrative_area_level_1",
                "long_name"
            )

            this.props.updateBrokerInfo(city, "city")
            this.props.updateBrokerInfo(county, "county")
            this.props.updateBrokerInfo(com.capitalize(com.convertAbbStateToFull(state)), "state")
            this.props.updateBrokerInfo(zip, "zip")
            let short = com.getSubaddress(address, "route", "short_name")

            let stradd = num + " " + short
            this.props.updateBrokerInfo(stradd, "street")
        }

        return (
            <div id="s9">
                <div className="p-4">
                    <div className="fontWeight500" style={{ fontSize: 20 }}>
                        Section 9: Loan Originator Information
                    </div>
                    <div>
                        To be completed by the loan originator.
                    </div>
                </div>
                <div className="form1003Title">Loan Originator Information</div>
                <div className="p-4">
                    <Row>
                        <Col md={4} className="mb-4">
                            {this.inputField(
                                "Loan originator organization name",
                                b.name,
                                (e) => this.props.updateBrokerInfo(e.target.value, "name")
                            )}
                        </Col>
                        <Col md={4} className="mb-4">
                            {this.inputField(
                                "Loan originator organization NMLSR ID",
                                b.nmls,
                                (e) => this.props.updateBrokerInfo(e.target.value, "nmls")
                            )}
                        </Col>
                        <Col md={4} className="mb-4">
                            {this.inputField(
                                "State license ID",
                                b.licenseid,
                                (e) => this.props.updateBrokerInfo(e.target.value, "licenseid")
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="mb-2">
                            {this.state.editMode ? <AddressAutocomplete
                                id="brokerAddress"
                                border={true}
                                style={b.street === "" && this.state.companyInfo.companyAddressstreet === "" && this.props.mode !== 'document' ? { border: '1px solid #faad14' } : {}}
                                label={<div className="fontWeight500">Address</div>}
                                required={true}
                                name="addessline"
                                defaultValue={b.street}
                                onChange={(e) => this.props.updateBrokerInfo(e.target.value, "street")}
                                pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                placeholder="Please choose from the suggested addresses generated based on the input."
                                goodfeedback="Looks good!"
                                badfeedback="Please provide your street address."
                                size="md"
                                onCompletion={e => onBrokerAddressCompletion(e)}
                            /> : <div className="fontSize16">{b.street}</div>}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} className="mb-4">
                            {this.dropdownField(
                                "State",
                                b.state,
                                this.getStateOptions(),
                                (e) => this.props.updateBrokerInfo(e.target.value, "state")
                            )}
                        </Col>
                        <Col md={4} className="mb-4">
                            {this.inputField(
                                "City",
                                b.city,
                                (e) => this.props.updateBrokerInfo(e.target.value, "city"),
                                null,
                            )}
                        </Col>
                        <Col md={4} className="mb-4">
                            {this.inputField(
                                "Zip",
                                b.zip,
                                (e) => this.props.updateBrokerInfo(e.target.value, "zip"),
                                null,
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} className="mb-4">
                            {this.inputField(
                                "Loan originator name",
                                b.loanofficername,
                                (e) => this.props.updateBrokerInfo(e.target.value, "loanofficername")
                            )}
                        </Col>
                        <Col md={4} className="mb-4">
                            {this.inputField(
                                "Loan originator NMLSR ID",
                                b.loanofficernmls,
                                (e) => this.props.updateBrokerInfo(e.target.value, "loanofficernmls")
                            )}
                        </Col>
                        <Col md={4} className="mb-4">
                            {this.inputField(
                                "Loan originator state license ID",
                                b.loanofficerlicenseid,
                                (e) => this.props.updateBrokerInfo(e.target.value, "loanofficerlicenseid")
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="mb-4">
                            {this.inputField(
                                "Email",
                                b.email,
                                (e) => this.props.updateBrokerInfo(e.target.value, "email")
                            )}
                        </Col>
                        <Col md={6} className="mb-4">
                            {this.inputField(
                                "Phone",
                                b.phone,
                                (e) => {
                                    let phone = com.fixPhoneInput(e.target.value)
                                    this.props.updateBrokerInfo(phone, "phone")
                                }
                            )}
                        </Col>
                    </Row>
                </div>
            </div>
        )
    };

    handleScroll = () => {
        this.throttleScroll()
    };
    setOffsetTop = () => {
        const screenWidth = window.innerWidth || document.documentElement.clientWidth

        let offsetTop

        if (screenWidth >= 1200) {
            offsetTop = 63
        } else if (screenWidth >= 768) {
            offsetTop = 107
        }

        this.setState({ offsetTop })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.view !== this.props.view) {
            this.setState({ currentView: this.props.view || 'borrower' })
        }
    }


    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
        window.removeEventListener('resize', this.setOffsetTop)
    }

    convertIncome(who) {
        let whoseincome = this.props.application[who].occupation.income
        let all = ["base", "overtime", "bonuses", "commissions"]
        for (let t of all) {
            if (whoseincome[t + "monthly"] === "yearly") {
                let monthlyincome = Math.round(parseFloat(whoseincome[t]) / 12).toFixed(0)
                this.updateIncomeMonthly(monthlyincome, who, t)
                this.updateIncomeMonthly("monthly", who, t + "monthly")
            }
        }

        if (this.props.application[who].occupation.incomeorlossperiod === "yearly") {
            let monthlyincome = Math.round(parseFloat(this.props.application[who].occupation.incomeorloss) / 12).toFixed(0)
            this.props.changeOccupationInfoByValue(monthlyincome, who, "incomeorloss")
            this.props.changeOccupationInfoByValue("monthly", who, "incomeorlossperiod")
        } else if (this.props.application[who].occupation.incomeorlossperiod === "") {
            this.props.changeOccupationInfoByValue("monthly", who, "incomeorlossperiod")
        }

        let otherIncome = this.props.application[who].otheroccupations
        if (otherIncome !== null && otherIncome !== undefined) {
            for (let i = 0; i < otherIncome.length; i++) {
                let income = otherIncome[i]
                if (income.incomeorlossperiod === "yearly") {
                    let monthlyincome = Math.round(parseFloat(income.incomeorloss) / 12).toFixed(0)
                    this.props.changeBorrowerOtherOccupationValue(monthlyincome, i, who, "incomeorloss")
                    this.props.changeBorrowerOtherOccupationValue("monthly", i, who, "incomeorlossperiod")
                }
                for (let t of all) {
                    let income = otherIncome[i]
                    if (income[t + "monthly"] === "yearly") {
                        let monthlyincome = Math.round(parseFloat(income[t]) / 12).toFixed(0)
                        this.props.changeBorrowerOtherOccupationIncome(monthlyincome, i, who, t)
                        this.props.changeBorrowerOtherOccupationIncome("monthly", i, who, t + "monthly")
                    }
                }
            }
        }

    }

    componentDidMount() {
        this.props.updateStatus("initial")
        this.getLoanLimits()

        if (this.props.application.borrower.occupation.hasoccupation === null) {
            this.props.changeOccupationInfoByValue("employed", "borrower", "hasoccupation")
        }
        if (this.props.application.coborrower.occupation.hasoccupation === null) {
            this.props.changeOccupationInfoByValue("employed", "coborrower", "hasoccupation")
        }

        if (this.props.application.borrower.dependentsages === "") {
            if (this.props.application.borrower.numberofdependents !== "") {
                const numberOfDependents = parseInt(this.props.application.borrower.numberofdependents, 10);
                if (!isNaN(numberOfDependents) && numberOfDependents > 0) {
                    const newDependentAges = Array(numberOfDependents).fill(" ");
                    this.setState({ bodependentages: newDependentAges });
                }
            } else {
                this.setState({ bodependentages: [] });
            }
        } else {
            const newDependentAges = this.props.application.borrower.dependentsages.split(',').map(age => age.trim());
            this.setState({ bodependentages: newDependentAges});
        }

        if (this.props.application.coborrower.dependentsages === "") {
            if (this.props.application.coborrower.numberofdependents !== "") {
                const numberOfDependents = parseInt(this.props.application.coborrower.numberofdependents, 10);
                if (!isNaN(numberOfDependents) && numberOfDependents > 0) {
                    const newDependentAges = Array(numberOfDependents).fill(" ");
                    this.setState({ codependentages: newDependentAges });
                }
            } else {
                this.setState({ codependentages: [] });
            }
        } else {
            const newDependentAges = this.props.application.coborrower.dependentsages.split(',').map(age => age.trim());
            this.setState({ codependentages: newDependentAges});
        }


        if (this.props.handingOff) {
            this.setState({ loading: true })
            let state = deepcopy({ borrower: this.props.borrower, application: this.props.application })
            let previousState = JSON.parse(sessionStorage.getItem("originalstate"))
            let token = window.sessionStorage.getItem("ZeitroA")
            let pathname = window.location.pathname
            let loanid = pathname.substring(pathname.lastIndexOf('/') + 1)
            let { id } = state.borrower
            if (id === "")
                return
            return new Promise((resolve, reject) => {
                fetch("/los/borrowerstate/" + id + "/" + loanid, {
                    cache: 'no-cache',
                    method: 'GET',
                    headers: {
                        Authorization: "Bearer " + token,
                        Cache: "no-cache"
                    },
                }).then(response => {
                    if (!response.ok) {
                    } else {
                        response.json().then(js => {
                            if (js.Status !== "OK") {
                                console.log("State Update Error: " + js.Status)
                                reject('rejected')
                            } else {
                                //console.log("State Update Success: " + js.Status)
                                //debugger
                                mergeState(id, js.Text, state, previousState)
                                resolve('resolved')
                                this.getCompanyProfile()
                                this.getLOProfile()
                                if (this.props.application.borrower.email === "") {
                                    let email = sessionStorage.getItem("email")
                                    if (email !== null && email !== "undefined") {
                                        this.props.changeBorrowerInfo({target: {value: email}}, "borrower", "email")
                                    } else {
                                        this.props.changeBorrowerInfo({target: {value: ""}}, "borrower", "email")
                                    }
                                }
                            }
                        })
                    }
                }).catch(error => {
                })
            })
        } else {
            this.getCompanyProfile()
            this.getLOProfile()
            if (this.props.application.borrower.email === "") {
                let email = sessionStorage.getItem("email")
                if (email !== null && email !== "undefined") {
                    this.props.changeBorrowerInfo({target: {value: email}}, "borrower", "email")
                } else {
                    this.props.changeBorrowerInfo({target: {value: ""}}, "borrower", "email")
                }
            }

        }


        if (this.props.application.borrower.income.hasdividends === null) {
            this.props.updateIncomeAtttribute(false, "borrower", "hasdividends")
        }
        if (this.props.application.assetsandliabilities.ownedproperties === null) {
            this.props.clearBorrowerOwnedProperty()
        }

        // convert yearly income to monthly income
        this.convertIncome("borrower")
        this.convertIncome("coborrower")


        window.addEventListener('scroll', this.handleScroll)
        this.setOffsetTop()
        window.addEventListener('resize', this.setOffsetTop)
    }
    tabItems = [
        { key: 's1', title: 'S1. Borrower Information' },
        { key: '1a', title: "1a. Personal Information" },
        { key: '1b', title: '1b. Current Employment/Self-Employment and Income' },
        { key: '1c', title: '1c. Additional Employment/Self-Employment and Income' },
        { key: '1d', title: '1d. Previous Employment/Self-Employment and Income' },
        { key: '1e', title: '1e. Income from Other Sources' },
        { key: 's2', title: 'S2. Financial Information' },
        { key: '2a', title: '2a. Assets' },
        { key: '2b', title: '2b. Other Assets and Credits You Have' },
        { key: '2c', title: '2c. Liabilities – Credit Cards, Other Debts, and Leases that You Owe' },
        { key: '2d', title: '2d. Other Liabilities and Expenses' },
        { key: 's3', title: 'S3. Financial Information — Real Estate' },
        { key: '3a', title: '3a. Property You Own' },
        { key: '3b', title: '3b. Additional Property' },
        { key: '3c', title: '3c. Additional Property' },
        { key: 's4', title: 'S4. Loan and Property Information' },
        { key: '4a', title: '4a. Loan and Property Information' },
        { key: '4b', title: '4b. Other New Mortgage Loans on the Property' },
        { key: '4c', title: '4c. Rental Income on the Property' },
        { key: '4d', title: '4d. Gifts or Grants' },
        { key: 's5', title: 'S5. Declarations.' },
        { key: '5a', title: '5a. About this Property and Your Money for this Loan' },
        { key: '5b', title: '5b. About Your Finances' },
        { key: 's6', title: 'S6. Acknowledgments and Agreements' },
        { key: 's7', title: 'S7. Military Service' },
        { key: 's8', title: 'S8. Demographic Information' },
        { key: 's9', title: 'S9. Loan Originator Information' },
    ]
    render = () => {

        const items = [
            {
                label: (
                    <a
                        rel="noopener noreferrer"
                        onClick={() => {
                            this.setState({ editMode: true })
                            sessionStorage.setItem("edit", "false")
                        }}
                    >
                        editing
                    </a>
                ),
                key: "0",
            },
            {
                label: (
                    <a
                        rel="noopener noreferrer"
                        onClick={() => this.setState({ editMode: false })}
                    >
                        viewing
                    </a>
                ),
                key: "1",
            },
        ]
        const tabsChange = (key) => {
            document.getElementById(`${key}`).scrollIntoView()
            window.scrollTo({
                top: window.scrollY - 340,
            })
        }

        let borrowerName = this.props.application.borrower.firstname + " " + this.props.application.borrower.lastname
        let coborrowerName = this.props.application.coborrower.firstname !== "" ? this.props.application.coborrower.firstname + " " + this.props.application.coborrower.lastname : "Coborrower"

        const showSavingStatus = () => {
            switch (this.props.status) {
                case "saving":
                    return <div className="mr-3"><Spin active className="mr-1" />Saving</div>
                    break
                case "saved":
                    return <div className="mr-3">Change saved</div>
                    break
                default:
                    return ""
            }
        }
        const flattenArray = (data) => {
            let result = []
            const flatten = (items) => {
                items.forEach(item => {
                    const { children, title, ...rest } = item
                    result.push({ ...rest, title: title.substring(0, 2) })

                    if (children && children.length > 0) {
                        flatten(children)
                    }
                })
            }
            flatten(data)
            return result
        }
        const menuItems = () => {
            let list = [
                {
                    key: '1',
                    href: '#s1',
                    title: 'S1. Borrower Information',
                    children: [
                        {
                            key: '2',
                            href: '#1a',
                            title: '1a. Personal Information',
                        },
                        {
                            key: '3',
                            href: '#1b',
                            title: '1b. Current Employment/Self-Employment and Income',
                        },
                        {
                            key: '4',
                            href: '#1c',
                            title: '1c. Additional Employment/Self-Employment and Income',
                        },
                        {
                            key: '5',
                            href: '#1d',
                            title: '1d. Previous Employment/Self-Employment and Income',
                        },
                        {
                            key: '6',
                            href: '#1e',
                            title: '1e. Income from Other Sources',
                        },

                    ]
                },
                {
                    key: '7',
                    href: '#s2',
                    title: 'S2. Financial Information',
                    children: [
                        {
                            key: '8',
                            href: '#2a',
                            title: '2a. Assets',
                        },
                        {
                            key: '9',
                            href: '#2b',
                            title: '2b. Other Assets and Credits You Have',
                        },
                        {
                            key: '10',
                            href: '#2c',
                            title: '2c. Liabilities – Credit Cards, Other Debts, and Leases that You Owe',
                        },
                        {
                            key: '11',
                            href: '#2d',
                            title: '2d. Other Liabilities and Expenses',
                        },


                    ]
                },
                {
                    key: '12',
                    href: '#s3',
                    title: 'S3. Financial Information — Real Estate',
                    children: [
                        {
                            key: '13',
                            href: '#3a',
                            title: '3a. Property You Own',
                        },
                        {
                            key: '14',
                            href: '#3b',
                            title: '3b. Additional Property',
                        },
                        {
                            key: '15',
                            href: '#3c',
                            title: '3c. Additional Property',
                        },



                    ]
                },
                {
                    key: '16',
                    href: '#s4',
                    title: 'S4. Loan and Property Information',
                    children: [
                        {
                            key: '17',
                            href: '#4a',
                            title: '4a. Loan and Property Information',
                        },
                        {
                            key: '18',
                            href: '#4b',
                            title: '4b. Other New Mortgage Loans on the Property',
                        },
                        {
                            key: '19',
                            href: '#4c',
                            title: '4c. Rental Income on the Property',
                        },
                        {
                            key: '99',
                            href: '#4d',
                            title: '4d. Gifts or Grants',
                        },
                    ]
                },
                {
                    key: '20',
                    href: '#s5',
                    title: 'S5. Declarations.',
                    children: [
                        {
                            key: '21',
                            href: '#5a',
                            title: '5a. About this Property and Your Money for this Loan',
                        },
                        {
                            key: '22',
                            href: '#5b',
                            title: '5b. About Your Finances',
                        },
                    ]
                },
                {
                    key: '23',
                    href: '#s6',
                    title: 'S6. Acknowledgments and Agreements',

                },
                {
                    key: '24',
                    href: '#s7',
                    title: 'S7. Military Service',

                },
                {
                    key: '25',
                    href: '#s8',
                    title: 'S8. Demographic Information',

                },
                {
                    key: '26',
                    href: '#s9',
                    title: 'S9. Loan Originator Information (to be completed by the loan originator)',
                },
            ]
            return this.props.mode === 'document' ? flattenArray(list) : list
        }
        return (
            <>
            { this.state.loading ? <div className="p-4">
                    <Skeleton active />
                    <Skeleton active />
                    <Skeleton active />
                </div>
                :
                <div className="text-left">
                    {
                        this.props.mode !== 'document' && (
                            <div className="title1003 title1003-mobile d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center" style={{ fontSize: 16 }}>
                                    {this.props.application.hascoborrower === "withcoborrower" ? "Uniform Residential Loan Application -" : "Uniform Residential Loan Application"}
                                    {/* <div className="mt-4 d-flex justify-content-between align-items-center">
                    <a
                        className="mr-3"
                        style={this.state.currentView === "borrower" ? { color: "#325CEB", textDecoration:"underline"} : {}}
                        onClick = {() => this.setState({currentView: "borrower"})}
                    >
                        {this.props.application.borrower.firstname + " " + this.props.application.borrower.lastname}
                    </a>
                    {this.props.application.hascoborrower && <a
                        onClick = {() => this.setState({currentView: "coborrower"})}
                        style={this.state.currentView === "coborrower" ? { color: "#325CEB", textDecoration:"underline"} : {}}
                    >
                        {this.props.application.coborrower.firstname + " " + this.props.application.coborrower.lastname}
                    </a>}
                    </div> */}
                                    {this.props.application.hascoborrower === "withcoborrower" && <div className="ml-2">
                                        <Select
                                            style={{ fontWeight: 500, minWidth: 150 }}
                                            onChange={(e) => this.setState({ currentView: e })}
                                            options={[
                                                { label: borrowerName, value: "borrower" },
                                                { label: coborrowerName, value: "coborrower" }
                                            ]}
                                            value={this.state.currentView}
                                        />
                                    </div>}

                                </div>
                                <div className="editing-btn d-flex align-items-center">
                                    {showSavingStatus()}
                                    {this.props.handingOff && this.props.handingOffType === "newaccount" && <Button loading={this.state.handingOff} type="primary" className="mr-3" onClick={() => this.notifyBorrowerAboutNewAccount()}>Notify borrower</Button>}
                                    {this.props.handingOff && this.props.handingOffType === "newloan" && <Button loading={this.state.handingOff} type="primary" className="mr-3" onClick={() => this.notifyBorrowerAboutNewAccount()}>Notify borrower</Button>}
                                    <div>
                                        You are{" "}
                                        <Dropdown
                                            menu={{
                                                items,
                                            }}
                                        >
                                            <a onClick={(e) => e.preventDefault()}>
                                                <Space style={{ color: "#325CEB" }}>
                                                    {this.state.editMode ? "editing" : "viewing"}
                                                    <DownOutlined style={{ color: "#325CEB" }} />
                                                </Space>
                                            </a>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <div className="loanInterview-tabs-wrap">
                        <Tabs activeKey={this.state.activeKey} onChange={tabsChange}>
                            {
                                this.tabItems.map(item => (
                                    <Tabs.Tab title={item.key} key={item.key}></Tabs.Tab>
                                ))
                            }
                        </Tabs>
                    </div>
                    <Row style={{ height: "100%" }}>
                        <Col
                            className={
                                `expanded loans-left-anchor pl-0 ${this.props.mode !== 'document' ? 'col-9' : 'document-left-anchor'}`
                            }
                        >
                            {this.sectionS1()}
                            {this.section1a(this.state.currentView)}
                            {this.section1b(this.state.currentView)}
                            {this.section1c(this.state.currentView)}
                            {this.section1d(this.state.currentView)}
                            {this.section1e(this.state.currentView)}
                            {this.sectionS2()}
                            {this.section2a()}
                            {this.section2b()}
                            {this.section2c()}
                            {this.section2d(this.state.currentView)}
                            {this.sectionS3()}
                            {this.section3a(this.state.currentView)}
                            {this.section3b(this.state.currentView)}
                            {this.section3c(this.state.currentView)}
                            <div className="px-4 pb-2">Any more properties declared below will be considered as extra properties and recorded into the URLA Continuation Sheet. </div>
                            {this.section3Extra(this.state.currentView)}
                            {this.sectionS4()}
                            {this.section4a()}
                            {this.section4b()}
                            {this.section4c()}
                            {this.section4d()}
                            {this.sectionS5()}
                            {this.section5a(this.state.currentView)}
                            {this.section5b(this.state.currentView)}
                            {this.sectionS6()}
                            {this.sectionS7(this.state.currentView)}
                            {this.sectionS8(this.state.currentView)}
                            {this.sectionS9()}
                        </Col>
                        <Col
                            className={
                                `expanded loans-rigth-anchor ${this.props.mode !== 'document' ? 'col-3' : 'document-rigth-anchor pl-0 pr-0'}`
                            }
                        >
                            {
                                this.props.mode !== 'document' && (
                                    <div className="d-flex">
                                        {/* <Button
                        shape="circle"
                        onClick={() =>
                        this.setState({ expandedAnchor: !this.state.expandedAnchor })
                        }
                        size="large"
                        className="mt-4"
                        icon={
                        !this.state.expandedAnchor ? (
                            <LeftOutlined />
                        ) : (
                            <RightOutlined />
                        )
                        }
                        style={{ marginLeft: "-20px" }}
                    ></Button> */}
                                        <div style={{ color: "#6E6E70", marginLeft: 10, marginTop: 36 }}>
                                            QUICK GUIDE
                                        </div>
                                    </div>
                                )
                            }
                            <Anchor
                                affix={false}
                                offsetTop={this.state.offsetTop}
                                items={menuItems()}
                            />
                        </Col>
                    </Row>
                </div>
            }
            </>
        )
    };
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Interview)
)
