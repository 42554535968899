import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { service } from '../State'
import Container from 'react-bootstrap/Container'
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import * as com from "../Common.js"
import { tooltipFlat } from '../Common/Tooltip'
import { withRouter } from "react-router-dom";

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { pointsExplained } from './ProductPicker'
const mapStateToProps = (state) => {
    return {
        hascoborrower: state.application.hascoborrower,
        cohabiting: state.application.cohabiting,
        application: state.application,
        property: state.application.property,
        selection: state.application.selection,
    }
}

const mapDispatchToProps = (dispatch) => ({

    changeBorrowerInfo: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.value, who, verb))
    },
    changeBorrowerInfoCheck: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.checked, who, verb))
    },
    changePhoneInfo: (input, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(input, who, verb))
    },

    updatePreviousAddress: (event, who, verb, n) => {
        dispatch(act.UpdatePreviousAddress(event.target.value, who, verb, n))
    },
    addPreviousAddress: (event, who) => {
        dispatch(act.AddPreviousAddress(event.target.value, who,))
    },
    removePreviousAddress: (event, who, n) => {
        dispatch(act.RemovePreviousAddress(event.target.value, who, n))
    },
    clearPreviousAddress: (event, who) => {
        dispatch(act.ClearPreviousAddress(event.target.value, who))
    },
    changeMainProperty: (value, verb) => {
        dispatch(act.ChangeMainProperty(value, verb))
    },
    changeMainPropertyUnits: (event) => {
        dispatch(act.ChangeMainPropertyUnits(event.target.value))
    },
    changeMainPropertyYearBuilt: (event) => {
        dispatch(act.ChangeMainPropertyYearBuilt(event.target.value))
    },
    changeMainPropertyRefinanceYearAcquired: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceYearAcquired(event))
    },
    changeMainPropertyRefinanceOriginalCost: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceOriginalCost(event))
    },
    updateApplicationAttribute: (input, verb) => {
        dispatch(act.UpdateApplicationAttribute(input, verb))
    },
    updateSelectField: (input, verb) => {
        dispatch(act.UpdateSelectField(input, verb))
    },
    addSelectedLoanPrepaid: (service, chargename, period) => {
        dispatch(act.AddSelectedLoanPrepaid(service, chargename, period))
    },
    updatePaymentPeriod: (name, service, period) => {
        dispatch(act.UpdatePaymentPeriod(name, service, period))
    },
    updatePaymentAmount: (name, service, amount) => {
        dispatch(act.UpdatePaymentAmount(name, service, amount))
    },
    updatePrepaidDefault: (name, service, period, prepaidperiods, amount) => {
        dispatch(act.UpdatePrepaidDefault(name, service, period, prepaidperiods, amount))
    },
    addSelectedLoanEstimatedClosingCosts: (cc) => {
        dispatch(act.AddSelectedLoanEstimatedClosingCosts(cc))
    },


});


class LoanEstimate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ltv: 0,

            cltv: 0,
            hcltv: 0,

        }

        this.card = this.props.card

        //updatePrepaidDefault: (name, period, prepaidperiods, amount)
        let rate = this.props.card.base_rate / 100 / 360 * this.getLoanSize()
        let name = "Prepaid Interest"
        let service = "prepaids"
        let days = this.props.card.prepaiddays
        if ("undefined" === typeof days)
            days = 15
        if ("undefined" !== typeof this.card.lockstatus) {
            if (!this.hasPrepaidInterest()) {

                this.props.addSelectedLoanEstimatedClosingCosts(this.props.application.selection.estimatedclosingcosts)

                this.props.addSelectedLoanPrepaid(service, name, "daily")

                this.props.updatePaymentPeriod(name, service, days)

                this.props.updatePaymentAmount(name, service, rate)
            } else {
                this.props.updatePrepaidDefault(name, service, "daily", days, rate)

            }
        } else {
            this.props.updatePrepaidDefault(name, service, "daily", days, rate)

        }
    }
    componentDidMount() {

    }
    hasPrepaidInterest = () => {
        let cc = this.props.application.selection.estimatedclosingcosts
        if (null != this.card.estimatedclosingcosts)
            cc = this.card.estimatedclosingcosts

        let ret = false
        Object.keys(cc.prepaids).forEach(function (key) {

            if (cc.prepaids[key].paymentname === "Prepaid Interest" && cc.prepaids[key].amount !== 0) {
                ret = true
            }
        });
        return ret
    }


    canShopFor = () => {

        let cc = this.props.application.selection.estimatedclosingcosts
        if (null != this.card.estimatedclosingcosts)
            cc = this.card.estimatedclosingcosts

        let total = 0
        Object.keys(cc.canshopservices).forEach(function (key) {
            total += parseFloat(cc.canshopservices[key].estimateamount)
        });
        this.canShopForFee = total
        let process = (key, index) => {
            return <Row xs="auto" key={"canshop" + index}>
                <Col >{cc.canshopservices[key].servicename}</Col>
                <Col xs="auto">${com.commaizeFloat(cc.canshopservices[key].estimateamount)}</Col>
            </Row>
        }

        return (
            <div>
                <Row>
                    <Col xs="auto" className="roundedtab ml-2 border-bottom ">C. Services you can shop for:
                    </Col>
                    <Col className="tabajacent"></Col>
                    <Col xs="auto" className="tabajacent">
                        ${com.commaizeFloat(total)}
                    </Col>
                </Row>
                {Object.keys(cc.canshopservices).map(process)}
                <div>&nbsp;</div>
            </div>
        )

    }

    cantShopFor = () => {
        let cc = this.props.application.selection.estimatedclosingcosts

        if (null != this.card.estimatedclosingcosts)
            cc = this.card.estimatedclosingcosts

        if (this.card.FHAMIP != null) {

            let addFha = () => {
                let found = false

                cc.cannotshopservices.forEach(key => {
                    if (key.servicename === com.upfrontMI) {
                        key.estimateamount = this.card.FHAMIP.upfrontMIP
                        found = true
                    }
                })
                if (!found) {
                    let s = new service()
                    s.servicename = com.upfrontMI
                    s.estimateamount = this.card.FHAMIP.upfrontMIP
                    cc.cannotshopservices.push(s)
                }
            }

            addFha()

        } else {

            for (let i = 0; i < cc.cannotshopservices.length; i++) {
                if (cc.cannotshopservices[i].servicename === com.upfrontMI) {
                    cc.cannotshopservices = cc.cannotshopservices.splice(i, 1)
                    break
                }
            }

        }

        let total = 0
        Object.keys(cc.cannotshopservices).forEach(function (key) {
            total += parseFloat(cc.cannotshopservices[key].estimateamount)
        });
        this.cantShopForFee = total

        let process = (key, index) => {

            return <Row xs="auto" key={"canshopfor" + index}>
                <Col  >{cc.cannotshopservices[key].servicename}</Col>
                <Col xs="auto">${com.commaizeFloat(cc.cannotshopservices[key].estimateamount)}</Col>
            </Row>
        }

        return (
            <div>
                <Row >
                    <Col xs="auto" className="roundedtab ml-2 border-bottom ">B. Services you can't shop for:
                    </Col>
                    <Col className="tabajacent"></Col>
                    <Col xs="auto" className="tabajacent">
                        ${com.commaizeFloat(total)}
                    </Col>
                </Row>
                {Object.keys(cc.cannotshopservices).map(process)}
                <div>&nbsp;</div>
            </div>
        )
    }
    totalLoanCost = () => {
        return (
            <div>
                <Row >
                    <Col xs="auto" className="roundedtab ml-2 border-bottom ">D. Total Loan Costs (A+B+C):
                    </Col>
                    <Col className="tabajacent"></Col>
                    <Col xs="auto" className="tabajacent">
                        ${com.commaizeFloat((this.cantShopForFee + this.canShopForFee + this.originationFees).toFixed(2))}
                    </Col>
                </Row>

                <div>&nbsp;</div>
            </div>
        )

    }
    otherTotal = () => {
        return (
            <div>
                <Row >
                    <Col xs="auto" className="roundedtab ml-2 border-bottom ">I. Total Other Costs (E + F + G + H):
                    </Col>
                    <Col className="tabajacent"></Col>
                    <Col xs="auto" className="tabajacent">
                        ${com.commaizeFloat((this.taxFees + this.prepaidFees + this.initialEscrowAtClosingFee +
                            this.otherFees).toFixed(2)
                        )}
                    </Col>
                </Row>

                <div>&nbsp;</div>
            </div>
        )
    }

    totalClosing = () => {

        let DI = this.cantShopForFee + this.canShopForFee + this.originationFees + this.taxFees + this.prepaidFees + this.initialEscrowAtClosingFee +
            this.otherFees

        let lender_credit = this.card.closing_cost < 0 ? -this.card.closing_cost : 0

        this.totalclosingcosts = DI + lender_credit

        if (this.props.card.lender_credit <= 0) {
            this.totalclosingcosts = DI + this.props.card.lender_credit
        } else {
            this.totalclosingcosts = DI
        }
        let remaining_credits = 0
        if (this.totalclosingcosts < 0) {
            remaining_credits = this.totalclosingcosts
            this.totalclosingcosts = 0
        }

        return (
            <div>
                <Row >
                    <Col xs="auto" className="roundedtab ml-2 border-bottom ">J. Total Closing Costs:
                    </Col>
                    <Col className="tabajacent"></Col>
                    <Col xs="auto" className="tabajacent">
                        ${com.commaizeFloat(this.totalclosingcosts.toFixed(2))}
                    </Col>
                </Row>
                <Row>
                    <Col>D + I
                    </Col>
                    <Col xs="auto" >
                        ${com.commaizeFloat(DI.toFixed(2))}
                    </Col>
                </Row>
                {this.props.card.lender_credit > 0 ?
                    "" :
                    <Row>
                        <Col>Lender Credit (points)
                        </Col>
                        <Col xs="auto" >
                            -${com.commaizeFloat(-parseFloat(this.props.card.lender_credit).toFixed(2))}
                        </Col>
                    </Row>
                }
                {remaining_credits < 0 ?
                    <Row>
                        <Col>Remaining Lender Credit
                        </Col>
                        <Col xs="auto" >
                            -${com.commaizeFloat(-remaining_credits.toFixed(2))}
                        </Col>
                    </Row>

                    : ""}
                <div>&nbsp;</div>
            </div>
        )
    }

    taxes = () => {
        let cc = this.props.application.selection.estimatedclosingcosts

        if (null != this.card.estimatedclosingcosts)
            cc = this.card.estimatedclosingcosts
        let total = parseFloat(cc.recordingandgovernmentfees) + parseFloat(cc.transfertax)
        this.taxFees = total

        return (
            <div>
                <Row >
                    <Col xs="auto" className="roundedtab ml-2 border-bottom ">E. Taxes and other government fees:
                    </Col>
                    <Col className="tabajacent"></Col>
                    <Col xs="auto" className="tabajacent">
                        ${com.commaizeFloat(total)}
                    </Col>
                </Row>
                <Row>
                    <Col>Recording and government fees
                    </Col>

                    <Col xs="auto" >
                        ${com.commaizeFloat(cc.recordingandgovernmentfees)}
                    </Col>
                </Row>
                <Row>
                    <Col>Transfer tax
                    </Col>

                    <Col xs="auto" >
                        ${com.commaizeFloat(cc.transfertax)}
                    </Col>
                </Row>


                <div>&nbsp;</div>
            </div>
        )
    }

    originationCharges = () => {
        let lender_fee = parseFloat(this.props.card.lender_fee)
        //let broker_fee = Math.floor(0.01 * parseFloat(this.props.application.selection.loansize))
        let broker_fee = parseFloat(this.props.card.lender_credit)
        if (broker_fee > 0)
            this.originationFees = lender_fee + broker_fee
        else
            this.originationFees = lender_fee
        return (
            <div >
                <Row >
                    <Col xs="auto" className="roundedtab ml-2 border-bottom">A. Origination charges:
                    </Col>
                    <Col className="tabajacent"></Col>
                    <Col xs="auto" className="tabajacent">
                        ${com.commaizeFloat(this.originationFees.toFixed(2))}
                    </Col>
                </Row>
                <Row>
                    <Col>Lender origination fee
                    </Col>
                    <Col xs="auto" >
                        ${com.commaizeFloat(lender_fee)}
                    </Col>
                </Row>
                {broker_fee > 0 ?
                    <Row>
                        <Col>                     {tooltipFlat("Loan points", pointsExplained,
                            "auto", "")}

                        </Col>
                        <Col xs="auto" >
                            ${com.commaizeFloat(broker_fee.toFixed(2))}
                        </Col>
                    </Row> : ""}
                <div>&nbsp;</div>
            </div>
        )
    }
    prepaids = () => {
        let cc
        if ("undefined" !== typeof this.card.lockstatus) {
            cc = this.props.application.selection.selectedloan.estimatedclosingcosts
        } else {
            cc = this.props.application.selection.estimatedclosingcosts
        }

        let total = 0
        Object.keys(cc.prepaids).forEach(function (key) {
            total += Math.ceil(parseFloat(cc.prepaids[key].amount) * parseFloat(cc.prepaids[key].prepaidperiods))
        });

        this.prepaidFees = total

        let displayPrepaid = (item, index) => {
            return (
                <Row key={item.paymentname + index}>
                    <Col>{item.paymentname}
                    </Col>

                    <Col xs="auto" >
                        {parseInt(item.amount) === 0 ? "$0" : <div>
                            {item.prepaidperiods} {item.period === "daily" ? "days" : "months"} at ${com.commaizeFloat(item.amount.toFixed(2))} </div>}
                    </Col>
                </Row>
            )
        }

        return (
            <div>
                <Row >
                    <Col xs="auto" className="roundedtab ml-2 border-bottom ">F. Prepaids:
                    </Col>
                    <Col className="tabajacent">

                    </Col>
                    <Col xs="auto" className="tabajacent">
                        ${com.commaizeFloat(total.toFixed(2))}
                    </Col>
                </Row>
                {cc.prepaids.map(displayPrepaid)}

                <div>&nbsp;</div>
            </div>
        )
    }
    initialescrowatclosing = () => {
        let cc = this.props.application.selection.estimatedclosingcosts
        if ("undefined" !== typeof this.card.estimatedclosingcosts)
            cc = this.card.estimatedclosingcosts


        let total = 0
        Object.keys(cc.initialescrowpaymentsatclosing).forEach(function (key) {
            total += parseFloat(cc.initialescrowpaymentsatclosing[key].amount) * parseFloat(cc.initialescrowpaymentsatclosing[key].prepaidperiods)
        });


        this.initialEscrowAtClosingFee = total

        let displayPrepaid = (item, index) => {

            return (
                <Row>
                    <Col>{item.paymentname}
                    </Col>

                    <Col xs="auto" >
                        {parseInt(item.amount) === 0 ? "$0" : <div>
                            {item.prepaidperiods} months at ${com.commaizeFloat(item.amount)} </div>}
                    </Col>
                </Row>
            )
        }

        return (
            <div>
                <Row >
                    <Col xs="auto" className="roundedtab ml-2 border-bottom ">G. Initial Escrow Payments at Closing::
                    </Col>
                    <Col className="tabajacent">

                    </Col>
                    <Col xs="auto" className="tabajacent">
                        ${com.commaizeFloat(total.toFixed(2))}
                    </Col>
                </Row>
                {cc.initialescrowpaymentsatclosing.map(displayPrepaid)}

                <div>&nbsp;</div>
            </div>
        )
    }
    other = () => {
        let cc = this.props.application.selection.estimatedclosingcosts
        if ("undefined" !== typeof this.card.estimatedclosingcosts)
            cc = this.card.estimatedclosingcosts

        let total = 0
        Object.keys(cc.othercosts).forEach(function (key) {
            total += parseFloat(cc.othercosts[key].estimateamount)
        });
        this.otherFees = total
        let process = (key, index) => {

            return <Row xs="auto">
                <Col >{cc.othercosts[key].chargename}</Col>
                <Col xs="auto">${com.commaizeFloat(cc.othercosts[key].estimateamount)}</Col>
            </Row>
        }

        return (
            <div>
                <Row>
                    <Col xs="auto" className="roundedtab ml-2 border-bottom ">H. Other:
                    </Col>
                    <Col className="tabajacent "></Col>
                    <Col xs="auto" className="tabajacent ">
                        ${com.commaizeFloat(total)}
                    </Col>
                </Row>
                {Object.keys(cc.othercosts).map(process)}
                <div>&nbsp;</div>
            </div>
        )
    }
    cashtoclose = () => {
        let cc = this.props.application.selection.estimatedclosingcosts
        if ("undefined" !== typeof this.card.estimatedclosingcosts)
            cc = this.card.estimatedclosingcosts
        let getDownpayment = () => {

            if ("undefined" === typeof this.props.card.downpayment) {
                let customerfunds = 0

                switch (this.props.property.purpose) {
                    case "purchase":
                        customerfunds = this.props.selection.downpayment
                        break;
                    case "refinance":
                        customerfunds = this.props.selection.paydown
                        break;
                    case "cashoutrefinance":
                        customerfunds = this.props.selection.cashout
                        break;
                    default:
                        alert("should not see this!")
                        break;
                }
                return customerfunds
            }

            return this.props.card.downpayment + this.props.card.paydown
        }
        this.finalCashToClose = this.totalclosingcosts + parseFloat(cc.closingcostsfinanced) + getDownpayment() +
            parseFloat(cc.deposit) + parseFloat(cc.fundsforborrower) + parseFloat(cc.sellerscredits)
            + parseFloat(cc.adjustmentsothercredits)

        return (
            <div>
                <Row>
                    <Col xs="auto" className="roundedtab ml-2 border-bottom ">Calculating Cash to Close:
                    </Col>
                    <Col className="tabajacent "></Col>
                </Row>
                <Row>
                    <Col>Total closing costs (J)
                    </Col>
                    <Col xs="auto" >
                        ${com.commaizeFloat(this.totalclosingcosts.toFixed(2))}
                    </Col>
                </Row>
                <Row>
                    <Col>Closing costs financed
                    </Col>
                    <Col xs="auto" >
                        ${com.commaizeFloat(cc.closingcostsfinanced)}
                    </Col>
                </Row>
                <Row>
                    <Col>Downpayment/Funds from borrower
                    </Col>
                    <Col xs="auto" >
                        ${com.commaizeFloat(getDownpayment().toFixed(2))}
                    </Col>
                </Row>
                <Row>
                    <Col>Deposit
                    </Col>
                    <Col xs="auto" >
                        ${com.commaizeFloat(cc.deposit)}
                    </Col>
                </Row>
                <Row>
                    <Col>Funds for Borrower
                    </Col>
                    <Col xs="auto" >
                        ${com.commaizeFloat(cc.fundsforborrower)}
                    </Col>
                </Row>
                <Row>
                    <Col>Seller's Credits
                    </Col>
                    <Col xs="auto" >
                        ${com.commaizeFloat(cc.sellerscredits)}
                    </Col>
                </Row>
                <Row>
                    <Col>Adjustments/Other credits
                    </Col>
                    <Col xs="auto" >
                        ${com.commaizeFloat(cc.adjustmentsothercredits)}
                    </Col>
                </Row>
                <div>&nbsp;</div>
                <Row className="border-bottom border-dark">
                    <Col>
                    </Col>
                    <Col>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ fontWeight: 'bold' }}>
                        Cash to close:
                    </Col>
                    <Col xs="auto" style={{ fontWeight: 'bold' }}>
                        ${com.commaizeFloat(this.finalCashToClose.toFixed(2))}
                    </Col>
                </Row>
            </div>
        )
    }
    getLoanSize = () => {
        let card = this.props.card
        if ("undefined" !== typeof (card.loansize))
            return parseFloat(card.loansize)

        return parseFloat(this.props.application.selection.loansize)
    }
    render = () => {
        return <div className="py-2 px-0">
            <Row>
                <Col style={{ width: '70%' }}>
                    <h2 className="heading mb-4">Closing costs are what you pay for points, fees and services to close your loan.</h2>
                </Col>
            </Row>
            <Row>
                <Col xs="auto" className="px-0 mx-0">
                    {this.originationCharges()}
                    {this.cantShopFor()}
                    {this.canShopFor()}
                    {this.totalLoanCost()}
                    {this.taxes()}
                    {this.prepaids()}
                    {this.initialescrowatclosing()}
                    {this.other()}
                    {this.otherTotal()}
                    {this.totalClosing()}
                    {this.cashtoclose()}

                </Col>
                <Col xs="auto" className="p-5 ">
                    <div className="">
                        <h4>These are only initial estimates! <br /><br />The amounts shown on your official Loan Estimate may differ.<br /><br />
                            Please refer to our FAQ for information on Loan Estimate.
                        </h4>
                    </div>
                </Col>
            </Row>
        </div>
    }
}
class LoanDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ltv: 0,
            cltv: 0,
            hcltv: 0,

        }
        // calculate the set of tasks
        this.yearstohold = Math.min(parseInt(this.props.application.keepinghousefor), parseInt(this.props.card.Term))
        if (isNaN(this.yearstohold))
            this.yearstohold = 7
        this.card = this.props.card
    }


    getClosingCosts = (card) => {
        let cc = card.closing_cost
        if (cc < 0)
            cc = 0
        return cc
    }

    displaySelectedCard = () => {
        let card = this.props.card

        let getType = () => {

            if (card.amortization === "fixed" || card.MortgageAmortization === "fixed")
                return "Fixed rate"

            return "ARM " + card.Arm.fixedperiod + "/6"
        }
        let getLoanSize = () => {
            if ("undefined" !== typeof (card.loansize))
                return parseFloat(card.loansize)

            return parseFloat(this.props.application.selection.loansize)
        }
        let loansize = parseInt(getLoanSize())
        let interest10 = com.getClosedInterestPaid(0.01 * card.base_rate, loansize, card.Term, this.yearstohold)
        let interesttotal = com.getClosedInterestPaid(0.01 * card.base_rate, loansize, card.Term, card.Term)
        let purposeOfLoan = () => {
            switch (this.props.application.property.purpose) {
                case "purchase": {
                    return "Purchase"
                }
                case "refinance": {
                    return "Refinance to improve rate or term"
                }
                case "cashoutrefinance": {
                    return "Cash out refinance"
                }
                default: return "Unknown"
            }
        }

        let getDownpayment = () => {

            if ("undefined" === typeof this.props.card.downpayment) {
                let customerfunds = 0

                switch (this.props.property.purpose) {
                    case "purchase":
                        customerfunds = this.props.selection.downpayment
                        break;
                    case "refinance":
                        customerfunds = this.props.selection.paydown
                        break;
                    case "cashoutrefinance":
                        customerfunds = this.props.selection.cashout
                        break;
                    default:
                        return ''
                }
                return customerfunds
            }

            return this.props.card.downpayment + this.props.card.paydown
        }
        let getPaydown = () => {
            let cc = this.props.application.selection
            if (null !== cc.selectedloan)
                cc = cc.selectedloan
            switch (this.props.application.property.purpose) {
                case "purchase":
                    return <div> Downpayment<br /> ${com.commaizeFloat(getDownpayment().toFixed(2))} </div>

                case "refinance":
                    return <div> Paydown principal<br /> ${com.commaizeFloat(getDownpayment().toFixed(2))} </div>

                case "cashoutrefinance":
                    return <div> Cash out<br /> ${com.commaizeFloat(getDownpayment().toFixed(2))} </div>
                default:
                    alert("should not see this!")
                    return 0
            }
        }
        let getPMINeed = () => {
            let pmi = card.PMICost
            if ("undefined" !== typeof card.pmi) {
                pmi = card.pmi
            }
            return pmi
        }
        let getPMI = () => {
            let pmi = card.PMICost
            if ("undefined" !== typeof card.pmi) {
                pmi = card.pmi
            }
            if (0 === pmi)
                return "Not required"

            return <span>${com.commaizeFloat(pmi)} </span>
        }
        let getCurrentRate = () => {
            let rate = 0
            let loans = this.props.application.assetsandliabilities.loans
            for (let i = 0; i < loans.length; i++) {
                let cc = loans[i]

                if (cc.forsubjectproperty && this.props.application.property.purpose !== "purchase") {
                    rate = cc.rate
                }

            }
            return rate
        }
        let getAppraisal = () => {
            return parseFloat(this.props.application.property.appraisal)
        }
        let getLenderCredit = () => {
            if (card.closing_cost > 0)
                return 0
            return - parseFloat(card.closing_cost)
        }
        let getCurrentProgram = () => {
            switch (this.props.application.property.refinance.currentprogram) {
                case "fixed": return "Fixed rate"
                case "arm": return "ARM"
                case "baloon": return "Baloon"
                case "heloc": return "HELOC"
                case "negativeamortization": return "Negative Amortization"
                case "interestonly": return "Interest Only"
                case "noloan": return "No current loan"
                default: return "No current loan specified"
            }
        }
        let getPrepaidInterest = () => {
            return card.prepaid_interest
        }
        return <div className="mb-4 mt-2">
            <Container key={card.hash} className=" drop-shadow w-100 p-3 my-5 text-left">

                <Row>
                    <Col className="loancard pt-2 ">
                        <b>Purpose of Loan:</b><br />
                        {purposeOfLoan()}
                    </Col>
                    <Col className=" loancard pt-2">
                        <b>Loan amount:</b><br />
                        ${com.commaizeFloat(getLoanSize().toFixed(2))}
                    </Col>
                    <Col className=" loancard pt-2">
                        <b>Estimated Market Value:</b><br />
                        ${com.commaizeFloat(getAppraisal().toFixed(2))}
                    </Col>
                    {
                        this.props.application.property.purpose === "purchase" ?
                            <Col className="border-top loancard pt-2"><b>Purchase Price:</b><br />${com.commaize(this.props.application.property.salesprice)}</Col>
                            : ""
                    }
                    <Col className="border-bottom  loancard pt-2">
                        {getPaydown()}
                    </Col>
                </Row>
                {
                    (this.props.application.property.purpose !== "purchase") ?
                        <Row >
                            <Col xs="auto" className="border-bottom border-top loancard py-2" >
                                Current Program: {getCurrentProgram()}
                            </Col>

                            {getCurrentRate() !== 0 ?
                                <Col xs="auto" className="border-bottom border-top loancard py-2" >
                                    Current Rate: {getCurrentRate()}%
                                </Col> : ""}
                            <Col className="border-bottom border-top loancard py-2" >
                            </Col>

                        </Row>
                        : ""
                }
                <Row>
                    <Col className="border-bottom border-top loancard py-2" >
                        Subject property: {this.props.application.property.address}
                    </Col>
                </Row>

                <Row className=" text-left ">
                    <Col className="border-bottom border-top loancard pt-2 ">
                        <b>{getType()}</b><br />
                        <b>{card.Term} years</b>
                    </Col>
                    <Col className="border-bottom border-top loancard pt-2 ">
                        Rate:<br />
                        <b>{card.base_rate}%</b>
                    </Col>
                    <Col className="border-bottom border-top loancard pt-2 ">
                        APR:<br />
                        <b>{card.APR}%</b>
                    </Col>
                    <Col className="border-bottom border-top loancard pt-2 ">
                        Monthly Payment (P&amp;I):<br />
                        <b>${com.commaizeFloat(card.monthly_payment.toFixed(2))}</b>
                    </Col>
                    <Col className="border-bottom border-top loancard pt-2 ">
                        Closing Costs:<br />
                        <b>${com.commaizeFloat((this.getClosingCosts(card)).toFixed(2))}</b>
                    </Col>

                </Row>
                <Row className="text-left ">

                    <Col className="border-bottom  py-2 loancard">
                        Interest over<br />{this.yearstohold} years:<br />
                        <b>${com.commaizeFloat(interest10.toFixed(2))}</b>
                    </Col>
                    <Col className="border-bottom py-2 loancard">
                        Interest over<br />loan life:<br />
                        <b>${com.commaizeFloat(interesttotal.toFixed(2))}</b>
                    </Col>

                    {/* <Col className="border-bottom py-2 loancard">
                        Prepaid interest:<br />
                        <b>${com.commaizeFloat(getPrepaidInterest())}</b>
                    </Col> */}

                    <Col className="border-bottom py-2 loancard">
                        Mortgage Type:<br />
                        <b className="">{com.capitalize(card.Mtype.replace('_', ' ').toLowerCase().replace('fha', 'FHA'))}</b>
                    </Col>
                    <Col className="border-bottom py-2 loancard">
                        Points:<br />
                        <b className="capitalize">{card.points.toFixed(3)}</b>
                    </Col>

                </Row>
                <Row>
                    <Col className="border-bottom  loancard pt-2 ">
                        <b>Remaining lender credit:</b><br />
                        <b>${com.commaizeFloat(getLenderCredit().toFixed(2))} </b>
                    </Col>
                    {card.lender_fee ?
                        <Col className="border-bottom  loancard pt-2 ">
                            <b>Lender fee:</b><br />
                            <b>${com.commaizeFloat(card.lender_fee.toFixed(2))}</b>
                        </Col> : <Col></Col>
                    }



                    <Col className="border-bottom loancard pt-2 ">
                        <b>Minimal reserves:</b><br />
                        <b>{card.min_months_reserves} months</b>
                    </Col>

                    <Col className="border-bottom loancard pt-2 ">
                        <b>Prepaid interest:</b><br />
                        <b>${com.commaizeFloat(card.prepaid_interest.toFixed(2))} </b>
                    </Col>

                    <Col className="border-bottom loancard pt-2 ">
                        <b>Lock period:</b><br />
                        <b>{card.lockDay} days</b>
                    </Col>
                </Row>

                <Row>

                    {getPMINeed() !== 0 ?
                        <Col className=" loancard pt-2 ">
                            <b>PMI Insurance:</b><br />
                            <b>{getPMI()} </b>
                        </Col> : ""}
                    {card.FHAMIP != null ?

                        <Col xs="auto" className=" loancard pt-2 ">
                            <b>Upfront Mortgage Insurance Premium :</b><br />
                            <b>{card.FHAMIP.upfrontMIP} </b>
                        </Col> : ""}
                    {card.FHAMIP != null ?
                        <Col xs="auto" className=" loancard pt-2 ">
                            <b>Monthly Mortgage Insurance Premium :</b><br />
                            <b>{card.FHAMIP.monthlyMIP} </b>
                        </Col>

                        : ""}
                    <Col>
                    </Col>
                    <Col>
                    </Col>
                    <Col>
                    </Col>
                </Row>
            </Container>
        </div>

    }
    render() {

        return (
            <div className="mb-3 px-1 text-center">

                <Row>
                    <Col> {this.displaySelectedCard()} </Col>
                </Row>


            </div>
        )
    }
}
LoanDetails = withRouter(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(LoanDetails))
LoanEstimate = withRouter(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(LoanEstimate))
class Details extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {

    }

    componentWillUnmount() { }


    handleSubmit = e => {
        return true
    }


    render() {

        return (
            <Tabs className="border  border-top border-left border-right text-dard" transition={false}
                id="detalstabs" unmountOnExit={true} >
                <Tab eventKey="upfrontfees" title="Closing Cost Details" className="text-dark border" >
                    <LoanEstimate card={this.props.card} />
                </Tab>
                <Tab eventKey="loandetails" title="Overview" className="text-dark border" >
                    <LoanDetails card={this.props.card} />
                </Tab>


            </Tabs>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Details)

