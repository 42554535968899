import React, { useState, useEffect } from 'react';
import {
  Modal,
  Select,
  Input,
  Button,
  Spin,
  Typography,
  Radio,
  Upload
} from 'antd';
import {
  CloseOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import './FloatingFeedback.css'

const { TextArea } = Input;
const { Text } = Typography;

const FloatingFeedback = ({showFeedback, setShowFeedback}) => {

  const [isOpen, setIsOpen] = useState(true);
  const [feedbackType, setFeedbackType] = useState('feedback');
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedFeature, setSelectedFeature] = useState("");
  const [details, setDetails] = useState('');
  const [fileList, setFileList] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const productFeatureMap = {
    'Overall': ['Navigation bar', 'Account sign in/up', 'Other'],
    'Dashboard': ['Explore system features', 'My recent loans', 'Overview', 'Other'],
    'Loans': [
      'Loan pipeline',
      'Overview',
      '1003',
      'Borrower application',
      'Credit pull',
      'Rate recommendation',
      'Pre-qual letter',
      'Escrow info',
      'Document',
      'Appraisal info',
      'Closing',
      'Other'
    ],
    'Borrowers': ['Borrower list', 'Borrower information', 'Other'],
    'Rates': ['Filter tab', 'Buttons', 'Rate list', 'Rate line details', 'Other'],
    'GuidelineGPT': ['Guideline options', 'Chat history', 'Questions and answers', 'Other'],
    'My site': [
      'Basic information',
      'Manage widgets',
      'Manage website section',
      'URL and publish',
      'Site preview',
      'Other'
    ],
    'Management': [
      'Loans',
      'Lenders',
      'Call reports',
      'Customization',
      'Public site integration',
      'Communication setup',
      'Credit report settings',
      'User management',
      'Plans and billing',
      'Other'
    ],
    'Settings': ['Profile', 'Feature customization', 'Other'],
    'Quick create': ['Create loans', 'Create borrowers', 'Other']
  };

  useEffect(() => {
    if (showFeedback) {
      setIsOpen(true);
    }
  }, [showFeedback]);

  const handleSendFeedback = async (data) => {
    setLoading(true);
    let token = sessionStorage.getItem("ZeitroA");
    const formData = new FormData();
    
    if (data.fileList.length > 0) {
        data.fileList.forEach(file => {
            formData.append("upload", file.originFileObj, file.name);
        });
    }

    try {
        const response = await fetch("/los/userfeedback", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "X-Feedback-Type": data.feedbackType,
                "X-Selected-Product": data.selectedProduct,
                "X-Selected-Feature": data.selectedFeature,
                "X-Details": data.details,
            },
            body: formData,
        });

        if (response.status === 200) {
            setOpenSuccess(true);
            setIsOpen(false);
            setDetails('');
            setFileList([]);
            setSelectedFeature('');
            setSelectedProduct('');
        } else {
            setIsOpen(false);
            alert("Something went wrong. Please try again later.");
        }
    } catch (error) {
        console.error("/los/userfeedback error:", error);
    } finally {
      setLoading(false);
    }
  };


  const handleSubmit = () => {
    let data = {
      feedbackType,
      selectedProduct,
      selectedFeature,
      details,
      fileList
    }
    handleSendFeedback(data)

  }

  const handleChange = (info) => {
    setFileList(info.fileList);
  };

  const props = {
    onChange: handleChange,
    multiple: true,
    beforeUpload: () => false,
  };

  const isFormValid = () => {
    if (!selectedProduct && !selectedFeature && details.trim().length === 0) {
      return false;
    }
    return true;
  };

  if (!showFeedback) {
    return "";
  }

  return (
    <>
      <div className="feedback-trigger">


      <Button
        type="text"
        onClick={() => setIsOpen(true)}
        >
        Feedback
      </Button>
      <div>
        <Button type="text"  onClick={() => setIsOpen(!isOpen)} style={{width: 40}}>
            <MinusOutlined />
            </Button>
        <Button type="text"  onClick={() => setShowFeedback(false)} style={{width: 40, marginRight:20}}>
            <CloseOutlined />
            </Button>
      </div>
      </div>
      
      <Modal
        title="Feedback"
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        closeIcon={
          <div style={{ display: 'flex', gap: '0', marginRight:30 }}>
            <MinusOutlined style={{width: 40, color: 'black', fontWeight: 600  }} onClick={(e) => {
              e.stopPropagation();
              setIsOpen(false);
            }} />
            <CloseOutlined style={{width: 40, color: 'black', fontWeight: 600 }} onClick={() => setShowFeedback(false)} />
          </div>
        }
        className="feedback-modal"
        width={400}
        maskStyle={{ backgroundColor: 'transparent' }}
        style={{ position: 'fixed', right: 0, bottom: 0, borderRadius: '8px 0 0 0', filter: 'drop-shadow(-4px -4px 4px rgba(71, 72, 73, 0.20))' }}
        // bodyStyle={{ maxHeight: '80vh', overflow: 'auto' }}
      >
        <div className="feedback-content">
          <Radio.Group
            value={feedbackType}
            onChange={(e) => setFeedbackType(e.target.value)}
            className="feedback-type-selector"
          >
            <Radio.Button value="feedback">Feedback</Radio.Button>
            <Radio.Button value="issue">Issue</Radio.Button>
          </Radio.Group>

          <div className="feedback-form">
            <div className="form-section">
              <Text className="label">Product</Text>
              <Select
                placeholder="Select a product"
                style={{ width: '100%' }}
                value={selectedProduct}
                onChange={(value) => {
                  setSelectedProduct(value);
                  setSelectedFeature("");
                }}
                options={Object.keys(productFeatureMap).map(product => ({
                  label: product,
                  value: product
                }))}
                bordered={false}
                className="select-field"
              />
            </div>

            <div className="form-section">
              <Text className="label">Feature</Text>
              <Select
                placeholder="Select a feature"
                style={{ width: '100%' }}
                value={selectedFeature}
                disabled={!selectedProduct}
                onChange={(value) => setSelectedFeature(value)}
                options={selectedProduct ? productFeatureMap[selectedProduct].map(feature => ({
                  label: feature,
                  value: feature
                })) : []}
                bordered={false}
                className="select-field"
              />
            </div>

            <div className="form-section">
              <Text className="label">Enter details</Text>
              <TextArea
                placeholder="Enter details"
                value={details}
                onChange={(e) => setDetails(e.target.value)}
                bordered={false}
                className="textarea-field"
                autoSize={{ minRows: 4 }}
              />
            </div>

            <div className="form-section">
            <Upload {...props} fileList={fileList}>
                <Button type="link" style={{ paddingLeft:0 }}>Upload attachment(s)</Button>
              </Upload>
            </div>
          </div>

          <div className="submit-button-wrapper">
            <Button 
              type="primary" 
              onClick={handleSubmit}
              disabled={!isFormValid()}
            >
              {loading ? <Spin /> : "Submit"}
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        title=""
        open={openSuccess}
        onCancel={() => setOpenSuccess(false)}
        footer={null}
      >
        <div style={{ textAlign: 'center', padding: '60px 0' }}>
            <img src="/images/emailSent.svg" alt="Feedback success" style={{ width: '100px' }}/>
            <div className="mt-4" style={{ textAlign: 'left', color: '##000000'}}>Thank you for your feedback! We've received your submission and appreciate your help in improving our service. Our team will work on it as soon as possible.</div>
        </div>
      </Modal>
    </>
  );
};

export default FloatingFeedback;