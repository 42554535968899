import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import { Helmet } from 'react-helmet';
import { pdfjs } from 'react-pdf';
import Chat from './Chat';
import "./MortgageGPT.css";
import smartlookClient from "smartlook-client";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function MortgageGPT({fromLanding}) {

  useEffect(() => {
    smartlookClient.init('702c7d241073da6ad788698d48d75342c29fa91b')
    smartlookClient.record({
      emails: true,
      forms: true,
      numbers: true,
      ips: true,
    })
  },[])


  return (
    <div className="">
      <Chat fromLanding={fromLanding} />
    </div>
  );
}

export default MortgageGPT;
