import React, { useState } from "react";
import { Col, Row } from "antd";
import { Input } from "antd";

import { RealtorInfo } from "./types";

interface RealtorInformationProps {
  setRealtorInfo: (realtorInfo: RealtorInfo) => void;
  onRealtorInfoChange: () => void;
}

export const RealtorInformation = (props: RealtorInformationProps) => {
  const [realtorName, setRealtorName] = useState("");
  const [realtorLicenseNumber, setRealtorLicenseNumber] = useState("");
  const [realtorPhoneNumber, setRealtorPhoneNumber] = useState("");
  const [realtorEmail, setRealtorEmail] = useState("");

  return (
    <div>
      <Row style={{ display: "none" }}>
        <div>Realtor Information</div>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={6} className="d-flex justify-content-center">
          <div>Realtor Name</div>
        </Col>
        <Col span={6} className="d-flex justify-content-center">
          <div>License Number</div>
        </Col>
        <Col span={6} className="d-flex justify-content-center">
          <div>Email</div>
        </Col>
        <Col span={6} className="d-flex justify-content-center">
          <div>Phone Number</div>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
        <Col span={6}>
          <Input
            value={realtorName}
            onChange={(e) => {
              setRealtorName(e.target.value);
              props.setRealtorInfo({
                name: e.target.value,
                licenseNumber: realtorLicenseNumber,
                phone: realtorPhoneNumber,
                email: realtorEmail,
              });
              props.onRealtorInfoChange();
            }}
          />
        </Col>
        <Col span={6}>
          <Input
            value={realtorLicenseNumber}
            onChange={(e) => {
              setRealtorLicenseNumber(e.target.value);
              props.setRealtorInfo({
                licenseNumber: e.target.value,
                name: realtorName,
                phone: realtorPhoneNumber,
                email: realtorEmail,
              });
              props.onRealtorInfoChange();
            }}
          />
        </Col>
        <Col span={6}>
          <Input
            value={realtorEmail}
            onChange={(e) => {
              setRealtorEmail(e.target.value);
              props.setRealtorInfo({
                email: e.target.value,
                name: realtorName,
                licenseNumber: realtorLicenseNumber,
                phone: realtorPhoneNumber,
              });
              props.onRealtorInfoChange();
            }}
          />
        </Col>
        <Col span={6}>
          <Input
            value={realtorPhoneNumber}
            onChange={(e) => {
              setRealtorPhoneNumber(e.target.value);
              props.setRealtorInfo({
                phone: e.target.value,
                name: realtorName,
                licenseNumber: realtorLicenseNumber,
                email: realtorEmail,
              });
              props.onRealtorInfoChange();
            }}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ display: "none" }}>
        <div>
          Realtor: {realtorName}, {realtorLicenseNumber}, {realtorPhoneNumber},{" "}
          {realtorEmail}
        </div>
      </Row>
    </div>
  );
};
