import React from "react";

import CurrentStage from "../BorrowerStageStatus/NonHeader/CurrentStage";
import CurrentStatus from "../BorrowerStageStatus/NonHeader/CurrentStatus";
import { useSelector } from "react-redux";

export const Closing = () => {
  const application = useSelector((state: any) => state.application);
  const paths = window.location.pathname.split("/");
  const loanID = paths[paths.length - 1];
  return (
    <div className="mb-5  text-left px-5 ">
      <div className="text-left mt-4 mb-1 pt-2 pl-0 flex flex-row align-items-center">
        <div
          className="mr-4 col-1 pl-0 text-nowrap"
          style={{ fontWeight: 600, fontSize: 14 }}
        >
          Main Stage
        </div>
        <CurrentStage currentStage={application.currentStage} loanID={loanID} />
        <div className="col"></div>
      </div>
      <div className="text-left mt-1 mb-4 pt-2 pl-0 flex flex-row align-items-center">
        <div
          className="mr-4 col-1 pl-0"
          style={{ fontWeight: 600, fontSize: 14 }}
        >
          Status
        </div>
        <CurrentStatus
          currentStatus={application.currentStatus}
          currentStage={application.currentStage}
          loanID={loanID}
        />
        <div className="col"></div>
      </div>
    </div>
  );
};
