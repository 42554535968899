import moment from "moment";
import { AdvancedCheckFilterOptions } from "./AdvancedCheckSearchLogs";

/**
 * Get the todayness of a date
 * For example
 * 1. if the date is today, return 0
 * 2. if the date is yesterday, return 1
 * 3. if the date is last week, return 2
 * 4. if the date is more than a week ago, return 3
 * @param date
 * @returns
 */
const dateToTodayDiff = (date: string) => {
  const today = moment();
  const diffDays = today.diff(moment(date), "days");
  if (diffDays === 0) return 0;
  if (diffDays === 1) return 1;
  if (diffDays <= 7) return 2;
  return 3;
};

const todaynessToString = (todayness: number) => {
  if (todayness === 0) return "Today";
  if (todayness === 1) return "Yesterday";
  if (todayness === 2) return "Last week";
  return "More than a week ago";
};

export const groupLogsByDate = (
  logs: AdvancedCheckFilterOptions[]
): { logs: AdvancedCheckFilterOptions[]; todayness: string }[] => {
  // add the todayness to the logs
  const logsWithTodayness: (AdvancedCheckFilterOptions & {
    todayness: number;
  })[] = logs.map((log) => ({
    ...log,
    todayness: dateToTodayDiff(log.createdAt),
  }));
  // Group the logs by todayness
  const groupedLogs: Record<number, AdvancedCheckFilterOptions[]> =
    logsWithTodayness.reduce((acc, log) => {
      const todayness = log.todayness;
      acc[todayness] = [...(acc[todayness] || []), log];
      return acc;
    }, {});
  // convert the groupedLogs to an array
  const groupedLogsArray = Object.keys(groupedLogs).map(
    (todayness: string) => ({
      todayness: parseInt(todayness),
      logs: groupedLogs[todayness],
    })
  );
  // Sort the groupedLogsArray by todayness
  groupedLogsArray.sort((a, b) => {
    return a.todayness - b.todayness;
  });
  // convert the todayness to string
  return groupedLogsArray.map((log) => ({
    ...log,
    todayness: todaynessToString(log.todayness),
  }));
};
