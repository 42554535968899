import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as act from "../Store/actions";

import { getApplicableMenuItems, IInputItem } from "./consts";
import {
  compareToStep,
  CurrentStatusValues,
  CurrentStageValues,
  getCurrentFarthestStep,
  getMainMenuStep,
} from "./utils";
import { Button } from "antd";
import "./StepperMenu.css";
import {
  getStepCircleClassName,
  getSubMenuItemButtonStyle,
} from "./calculate_styles";

interface StepMenuProps {
  /**
   * The current stage of the application
   */
  currentStage: string;
  /**
   * The current status of the application
   */
  currentStatus: string;
  /**
   * Function to set the selected tab key, this function actually changes
   * the main content of the page which is specified by tabKey.
   */
  setSelectedTabKey: (tabKey: string) => void;
  /**
   * The selected tab key
   */
  selectedTabKey: string;
  /**
   * The data items
   */
  items: IInputItem[];
}

const StepperMenu = (props: StepMenuProps) => {
  const dispatch = useDispatch();
  const updateApplicationAttribute = (t, verb) => {
    dispatch(act.UpdateApplicationAttribute(t, verb));
  };
  const application = useSelector((state: any) => state.application);

  const currentStage = props.currentStage as unknown as CurrentStageValues;
  const currentStep = getCurrentFarthestStep(
    props.currentStatus as unknown as CurrentStatusValues
  );

  const [selectedStep, setSelectedStep] = useState("1-0");
  const [selectedTabKey, setSelectedTabKeyLocally] = useState("");

  useEffect(() => {
    setSelectedTabKeyLocally(props.selectedTabKey);
  }, [props.selectedTabKey]);

  const inputItems = props.items;
  // Set selected selected tab and the content of the page specified by tabKey
  // only once when the component is mounted.
  useEffect(() => {
    setSelectedStep(currentStep);
    let itemToFind = null;
    for (const item of getApplicableMenuItems(inputItems, currentStage)) {
      // Find main menu item if it exists
      if (item.step === currentStep) {
        console.log("found main menu item", item);
        itemToFind = item;
        break;
      }
      // If main menu item doesn't exist, find sub menu item if it exists
      if (item.subMenus) {
        const subMenuItem = item.subMenus.find(
          (subMenuItem) => subMenuItem.step === currentStep
        );
        if (subMenuItem) {
          console.log("found sub menu item", subMenuItem);
          itemToFind = subMenuItem;
          break;
        }
      }
    }

    if (itemToFind) {
      if (!application.visitedMenus.includes(itemToFind.tabKey)) {
        updateApplicationAttribute(
          [...application.visitedMenus, itemToFind.tabKey],
          "visitedMenus"
        );
      }
      setSelectedTabKeyLocally(itemToFind.tabKey);
      props.setSelectedTabKey(itemToFind.tabKey);
    } else {
      console.error(
        "No menu item found for step",
        currentStep,
        "won't set tab key"
      );
    }
  }, []);

  const getSubMenuItemButtonStyleMemo = useCallback(
    (tabKey: string) => {
      return getSubMenuItemButtonStyle(
        selectedTabKey,
        application.visitedMenus || [],
        tabKey
      );
    },
    [selectedTabKey]
  );

  return (
    <div className="stepper-menu-container pl-4 pt-3">
      {getApplicableMenuItems(inputItems, currentStage)
        .filter((item) => !item.hidden)
        .map((item) => (
          <div key={item.step} style={{ cursor: "pointer" }}>
            <div
              className="d-flex mt-2 align-items-center stepInfo"
              style={{
                ...(compareToStep(item.step, currentStep) <= 0
                  ? { color: "#325CEB" }
                  : {}),
                ...(getMainMenuStep(item.step) === 0
                  ? { background: "#D6E2FB", borderRadius: 6 }
                  : {}),
              }}
              onClick={() => {
                if (!application.visitedMenus.includes(item.tabKey)) {
                  updateApplicationAttribute(
                    [...application.visitedMenus, item.tabKey],
                    "visitedMenus"
                  );
                }
                setSelectedTabKeyLocally(item.tabKey);
                props.setSelectedTabKey(item.tabKey);
                sessionStorage.setItem("borrowermenu", item.tabKey);
                setSelectedStep(item.step);
              }}
            >
              {getMainMenuStep(item.step) === 0 ? (
                <img src="/images/stepper-menu-home.svg" alt="home" />
              ) : (
                <div className={getStepCircleClassName(item.step, currentStep)}>
                  {getMainMenuStep(item.step) < getMainMenuStep(currentStep) ? (
                    <img
                      src="/images/stepper-menu-check-mark.svg"
                      alt="check"
                    />
                  ) : (
                    getMainMenuStep(item.step)
                  )}
                </div>
              )}
              <div style={{ marginLeft: 10 }}>
                <Button
                  style={{
                    height: "41px",
                    padding: 0,
                    border: "none",
                    fontWeight: "600 !important",
                  }}
                  type="text"
                  className="m-0 selectionoffset w-100 main-menu-item"
                >
                  {" "}
                  {item.name}
                </Button>
              </div>
            </div>
            <div className="text-left pl-2 ml-1 mt-2">
              {item.subMenus
                ?.filter((subMenuItem) => !subMenuItem.hidden)
                .map((subMenuItem) => {
                  return (
                    <div
                      className="d-flex align-items-center"
                      onClick={() => {
                        if (
                          !application.visitedMenus.includes(subMenuItem.tabKey)
                        ) {
                          updateApplicationAttribute(
                            [...application.visitedMenus, subMenuItem.tabKey],
                            "visitedMenus"
                          );
                        }
                        setSelectedTabKeyLocally(subMenuItem.tabKey);
                        props.setSelectedTabKey(subMenuItem.tabKey);
                        sessionStorage.setItem(
                          "borrowermenu",
                          subMenuItem.tabKey
                        );
                        setSelectedStep(subMenuItem.step);
                      }}
                    >
                      <div
                        className={
                          compareToStep(subMenuItem.step, currentStep) <= 0
                            ? "stepLine stepLineCurrent"
                            : "stepLine"
                        }
                      ></div>
                      <div
                        className={
                          "ml-3 subMenuItem" +
                          (subMenuItem.tabKey === selectedTabKey
                            ? " selected"
                            : "")
                        }
                      >
                        <Button
                          type="text"
                          className="m-0 selectionoffset w-100"
                        >
                          <div className="w-100 d-flex flex-row align-items-center justify-content-between">
                            <div
                              style={getSubMenuItemButtonStyle(
                                selectedTabKey,
                                application.visitedMenus || [],
                                subMenuItem.tabKey
                              )}
                            >
                              {subMenuItem.name}
                            </div>
                            {subMenuItem.icon && <div>{subMenuItem.icon}</div>}
                          </div>
                        </Button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        ))}
    </div>
  );
};

export default StepperMenu;
