import React from "react";
import { startCase } from "lodash";
import { AdvancedCheckFilterOptions } from "./AdvancedCheckSearchLogs";

interface AdvancedCheckLogFullResultProps {
  info: AdvancedCheckFilterOptions;
}

/**
 * This component is used to display the full result of a search log.
 * It is used in the AdvancedCheckSearchLogs component as a tooltip.
 */
export const AdvancedCheckLogFullResult = ({
  info,
}: AdvancedCheckLogFullResultProps) => {
  return (
    <div>
      {Object.entries(info)
        .filter(([key, _]) => key !== "todayness")
        .map(([key, value]) => (
          <div key={key}>
            <span>{startCase(key)}:&nbsp;</span>
            <span>{value}</span>
          </div>
        ))}
    </div>
  );
};
