import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import _ from 'lodash';
import { Link, withRouter } from "react-router-dom";
import * as com from "./Common.js"
import Tasks from './Tasks/Tasks'
import DocumentChecklist from './DocumentChecklist/DocumentChecklist'
import { connect } from 'react-redux'
import * as act from "./Store/actions"
import * as brwr from "./Store/borrower"
import * as prgr from "./Store/progress"
import { mergeState } from "./Store"
import DocumentManager from './Documents/DocumentManager.js'
import FormPreview from './FormPreview'
import CreditReport from './newCreditReport'
import LoanSummary from "./LoanSummary/LoanSummary";
import UnderwritingChecklist from "./UnderwritingChecklist/UnderwritingChecklist"
import Comment from "./Comment/Comment"
import DocumentsVerification from './DocumentVerification/DocumentsVerification'
import { application, State, UR_LoanProcessor, UR_Owner } from './State.js'
import { UpdateChangeLastTouch } from "./Store/progress"
import { UpdateApplicationAttribute } from "./Store/actions";
import Interview1003View from "./Interview1003View/Interview1003View";
import PrequalV2 from "./Interview/Prequal/PrequalV2.tsx";
import Prequal from "./Interview/Prequal";
import ProductPicker from "./Interview/ProductPicker";
import "./Borrower.css"
import { HomeFilled, RightOutlined, DownloadOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Radio, Dropdown, Space, Menu, Divider, Spin, Select, Skeleton, Progress, Modal, Table } from 'antd';
import { LoadingOutlined, ExclamationCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import HardMoneyLoanOverview, { getLoanAmount as getHardMoneyLoanLoanAmount, getLTV as getHardMoneyLoanLTV } from "./HardMoneyLoan/HardMoneyLoan/Overview";
import HardMoneyLoanBorrower from "./HardMoneyLoan/HardMoneyLoan/Borrower";
import HardMoneyLoanPropertyAndLoan from "./HardMoneyLoan/HardMoneyLoan/PropertyAndLoan";
import HardMoneyLoanExperience from "./HardMoneyLoan/HardMoneyLoan/Experience";
import HardMoneyLoanDeclarations from "./HardMoneyLoan/HardMoneyLoan/Declarations";
import HardMoneyLoanViewMode from "./HardMoneyLoan/HardMoneyLoan/ViewMode"
import { checkAccess } from "./Auth";
import InterviewBorrowerView from "./InterviewBorrowerView";
import PlanModal from "./planModal";
import CurrentStatus from "./BorrowerStageStatus/Header/CurrentStatus";
import CurrentStage from "./BorrowerStageStatus/Header/CurrentStage";
import StepperMenu from "./StepperMenu/StepperMenu";
import { Closing } from "./Closing/Closing";
import AutoProgress from './DocumentChecklist/AutoProgress';
const merge = require('deepmerge')
const deepcopy = require('deepcopy');
const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray

const mapStateToProps = (state) => {
    return {
        application: state.application,
        property: state.application.property,
        hardmoney: state.application.hardmoneyloan,
        progress: state.progress,
        borrower: state.borrower,
        subscriptionReducer: state.subscriptionReducer.currentInfo
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateChangeApplication: (application) => {
        dispatch(act.updateApplication(application))
    },
    changeBorrowerId: (id) => {
        dispatch(brwr.ChangeBorrowerId(id))
    },
    changeBorrowerLoanId: (id) => {
        dispatch(brwr.ChangeBorrowerLoanId(id))
    },

    changeBorrowerName: (name) => {
        dispatch(brwr.ChangeBorrowerName(name))
    },
    updateChangeProgress: (tab, index) => {
        dispatch(prgr.UpdateChangeProgress(tab, index))
    },
    updateChangeProgressType: (tab) => {
        dispatch(prgr.UpdateChangeProgressType(tab))
    },
    updateChangeLastTouch: (t) => {
        dispatch(UpdateChangeLastTouch(t))
    },
    updateApplicationAttribute: (t, verb) => {
        dispatch(UpdateApplicationAttribute(t, verb))
    },
})

const StatusSelect = Object.entries(com.loanStatusMap).map(([key, value]) => ({ label: value, value: key }))

class Borrower extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isModalOpen: false,
            firstname: "",
            lastname: "",
            id: "",
            isLP: false,
            render: this.renderEmpty,
            remoteCoborrowers: [],
            selectedTabKey: "loanSummary",
            isPrequal: false,
            showRates: false,
            loadingPdf: false,
            url: "",
            download: "",
            declarationsWarning: false,
            editMode: true,
            monthlyIncome: 0,
            monthlyExpense: 0,
            loading: false,
            showDownloadModal: false,
            dataSource: [],
            controllers: {},
        }

        if (this.props.progress.stage === "")
            this.props.updateChangeProgress(0, "interview")

        this.aref = React.createRef()
        this.handleSelectedTabKeyChange = this.handleSelectedTabKeyChange.bind(this)
        const queryParams = new URLSearchParams(this.props.location.search)
        this.handingOff = queryParams.get('handingoff')
        this.handingOffType = queryParams.get('type')
    }

    renderEmpty = () => (<div></div>)

    checkIfIsLP() {
        this.setState({ loading: true })
        const token = sessionStorage.getItem("ZeitroA")
        return fetch('/los/checkifislp', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "X-LoanID": this.props.match.params["id"],
            },
        }).then(
            response => {
                if (response.status !== 200) {
                    console.warn("Error post /los/checkifislp:", response.status)
                    this.setState({ loading: false })
                    alert("Something went wrong, please try again later.")
                }
                return response.json();
            }
        ).then(pr => {
            if (pr.Error !== "") {
                console.warn(pr.Error)
                alert("Something went wrong, please try again later.")
            } else {
                this.setState({ isLP: pr.IsLP }, () => {
                    this.reload()
                    this.getRemoteCoborrowers()
                    this.calcDTI()
                })
            }
            this.setState({ loading: false })
        }).catch((err) => {
            this.setState({ loading: false })
            console.error("CheckIfIsLP error:", err)
            alert("Something went wrong, please try again later.")
        })
    }

    componentDidMount = () => {
        const selectedKeys = sessionStorage.getItem('borrowermenu')
        if (selectedKeys) {
            this.setState({
                selectedTabKey: selectedKeys,
            })
        }
        if (this.handingOff !== null) {
            this.setState({ selectedTabKey: "1003View" })
        }

        // Wait for checkIfIsLP to complete before proceeding
        this.checkIfIsLP().then(() => {
            const id = this.props.match.params["id"]
            const { declarations } = this.props.application.borrower
            // id is loan id
            this.setState(
                {
                    id: id,
                    declarationsWarning: Object.values(declarations).includes('yes')
                },
                () => {
                    this.checkIfIsLP()
                }
            )
        })
    }

    componentDidUpdate(prevProps) {
        const { income } = this.props.application.borrower.occupation
        const prevIncome = prevProps.application.borrower.occupation.income
        // listen to the change of income and call preDTI calculation
        if (!_.isEqual(income, prevIncome)) {
            console.log('Income has changed:', income.base, prevIncome.base)
            setTimeout(() => {
                this.calcDTI()
            }, 2000)
        }
    }

    calcDTI() {
        const token = sessionStorage.getItem("ZeitroA")
        fetch('/data/getPreDTI', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "Content-Type": "application/json",
                "X-Borrower": this.props.borrower.id,
                "X-Loan": this.state.id,
            },

        }).then(
            response => {
                if (response.status !== 200) {
                    console.warn("Error post /data/getPreDTI:", response.status)
                    return;
                }
                response.json().then(pr => {
                    if (typeof pr["Error"] !== "undefined") {
                        console.warn(pr.Error)
                    } else {
                        if (pr.dtiData.length > 0) {
                            this.setState({ monthlyIncome: pr.dtiData[0].monthly_income, monthlyExpense: pr.dtiData[0].monthly_payment })
                        }
                    }
                })
            }
        ).catch((err) => {
            this.setState({ loading: false })
            alert("Network error")
        });
    }

    handleSelectedTabKeyChange(val) {
        this.setState({ selectedTabKey: val })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            const newid = this.props.match.params.id
            this.forceStateSynchronization()
            this.setState(
                { id: newid, remoteCoborrowers: [] },
                () => {
                    this.checkIfIsLP()
                }
            )
        }
    }

    handleSubtabTabSelect = (customerID) => {
        this.forceStateSynchronization().then(() => {
            this.reload(customerID)
        })
    }

    reload = (customer_id) => {
        if (typeof customer_id === 'undefined') {
            customer_id = ""
            this.setState({ render: this.renderEmpty })
        }

        const token = sessionStorage.getItem("ZeitroA")
        let body = { ID: this.state.id, CustomerID: customer_id, isOwner: checkAccess([UR_Owner]) }
        let url = '/los/borrower'
        if (this.state.isLP) {
            url = '/los/lp/borrower'
            body = { LoanID: this.state.id, CustomerID: customer_id }
        }
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.warn('Looks like there was a problem. Status Code: ', response.status)
                    return
                }
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        console.warn("Error: " + js.Text)
                    } else {
                        let state = JSON.parse(js.Borrower.State)
                        let serstate
                        if ("{}" === js.Borrower.State) {
                            state = JSON.parse((() => { let l = new State(); return l.toJson() })())
                        }
                        try {
                            const applicationInitialState = JSON.parse((() => { let l = new application(); return l.toJson() })())
                            const app = merge(applicationInitialState, state.application, { arrayMerge: overwriteMerge })
                            state.application = app
                        } catch (x) {
                            // just keep it
                        }
                        sessionStorage.removeItem("state")
                        sessionStorage.removeItem("originalstate")
                        serstate = JSON.stringify(state)
                        sessionStorage.setItem("state", serstate)
                        sessionStorage.setItem("originalstate", serstate)
                        this.props.updateChangeApplication({ ...state.application })
                        this.setState({
                            firstname: js.Borrower.FirstName,
                            lastname: js.Borrower.LastName,
                            isPrequal: state.application.property.purpose === "purchase" && state.application.property.hascontract === false,
                            isHardMoneyLoan: state.application.product.mortgageapplied === "hardmoneyloan",
                            showRates: state.application.scre !== 0 ? true : false,
                        })
                        this.props.changeBorrowerId(js.Borrower.ID)
                        this.props.changeBorrowerLoanId(js.Borrower.LoanID)
                        this.props.changeBorrowerName(js.Borrower.FirstName + " " + js.Borrower.LastName)
                        if (state.progress != null) {
                            this.props.updateChangeProgress(state.progress.interview.max, "max")
                            this.props.updateChangeProgress(state.progress.interview.step, "interview")
                            this.props.updateChangeLastTouch(state.progress.lasttouch)
                        } else {

                            this.props.updateChangeProgress(0, "max")
                            this.props.updateChangeProgress(0, "interview")
                        }

                        sessionStorage.removeItem("edit")

                        const index = this.state.remoteCoborrowers.findIndex(person => person.ID === js.Borrower.ID)
                        if (index === -1) {
                            const { ID, FirstName, LastName, ContactPhone, Email } = js.Borrower
                            this.setState(
                                {
                                    remoteCoborrowers: [...this.state.remoteCoborrowers, { ID, LastName, FirstName, ContactPhone, Email, isMain: true }],
                                    render: this.renderTabs
                                }
                            )
                        }
                    }
                })
            }
        ).catch(function (err) {
            console.error('Fetch Error :-S', err);
        })
    }

    getRemoteCoborrowers = () => {
        const token = sessionStorage.getItem("ZeitroA")
        const body = { ID: this.state.id }
        fetch('/los/remotecoborrowers', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.warn('Looks like there was a problem. Status Code: ', response.status)
                    return
                }
                // Examine the text in the response
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        console.warn("Error: " + js.Text)
                    } else {
                        if (js.RemoteCoborrowers !== null) {
                            for (let i = 0; i < js.RemoteCoborrowers.length; i++) {
                                const index = this.state.remoteCoborrowers.findIndex(person => person.ID === js.RemoteCoborrowers[i])
                                if (index === -1) {
                                    const { ID, LastName, FirstName, ContactPhone, Email } = js.RemoteCoborrowers[i]
                                    this.setState(
                                        {
                                            remoteCoborrowers: [...this.state.remoteCoborrowers, { ID, LastName, FirstName, ContactPhone, Email, isMain: false }]
                                        }
                                    )
                                }
                            }
                            this.setState({ render: this.renderTabs })
                        }
                    }
                })
            }
        )
    }

    forceStateSynchronization = () => {
        const state = deepcopy({ borrower: this.props.borrower, application: this.props.application })
        const previousState = JSON.parse(sessionStorage.getItem("originalstate"))
        const token = window.sessionStorage.getItem("ZeitroA")
        const { id } = state.borrower
        if (id === "") return
        return new Promise((resolve, reject) => {
            fetch("/los/borrowerstate/" + id, {
                cache: 'no-cache',
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache"
                },
            }).then(response => {
                if (!response.ok) {
                    console.warn("Auth fetch error")
                    sessionStorage.removeItem("ZeitroA")
                    window.location.href = "/"
                } else {
                    response.json().then(js => {
                        if (js.Status !== "OK") {
                            console.warn("State Update Error: ", js.Status)
                            reject('rejected')
                        } else {
                            //console.log("State Update Success: " + js.Status)
                            //debugger
                            mergeState(id, js.Text, state, previousState)
                            resolve('resolved')
                        }
                    })
                }
            }).catch(error => {
                console.error("error: ", error)
            })
        })
    }

    onselect = (key, e) => {
        const mainBorrower = this.state.remoteCoborrowers.find(person => person.isMain)
        if (mainBorrower.ID !== this.props.borrower.id) {
            this.forceStateSynchronization().then(() => {
                this.reload(mainBorrower.ID)
            })
        }
        this.props.updateChangeProgressType(key)
    }

    renderTabs = () => {
        let ID = ""
        try {
            ID = this.state.remoteCoborrowers[0].ID
        } catch (x) {

        }

        const getItem = (label, key, icon, children, type, disabled) => {
            return { key, icon, children, label, type, disabled }
        }

        let firstItems = [
            getItem('Loan summary', 'loanSummary'),
            getItem('1003', '1003View', <Progress type="circle" percent={75} strokeWidth={9} className="progress-percentage" style={{display: "none"}}/>),
            getItem('Interview recordings', 'borrowerView'),
            this.props.application.property.purpose === "purchase" && getItem('Pre-qual letter', 'prequalLetter', null, null, null, !this.state.showRates)
        ]

        firstItems.push(getItem('Rates', 'rates', null, null, null, !this.state.showRates))

        let items = [
            ...firstItems,
            getItem('Documents', 'documentChecklist'),
            // getItem('Documents', 'documents', null, [
            //     getItem('Uploaded by borrower', 'uploadedByBorrower'),
            //     getItem('Uploaded by you', 'uploadedByYou'),
            // ]),
            this.props.application.property.propertytype === "mobilehome" && getItem('Underwriting checklist', 'UWList'),
            getItem('Tasks', 'tasks'),
            getItem('Communications', 'comment'),
            getItem('Credit report', 'creditReport'),
            getItem('FNM 3.4', 'mismo'),
            // getItem('Doc Verification', 'docVerification'),
        ]

        const hasIcon = () => {
            if (this.state.declarationsWarning) {
                return <ExclamationCircleOutlined style={{ color: '#ff4d4f', marginLeft: '-24px !important' }} />;
            }
            return null
        }

        if (this.state.isHardMoneyLoan) {
            items = [
                getItem('Loan summary', 'loanSummary'),
                getItem('Loan interview', 'sub3', null, [
                    getItem('Borrower', 'hardMoneyLoanBorrower'),
                    getItem('Property and loan', 'hardMoneyLoanProperty'),
                    getItem('Experience', 'hardMoneyLoanExperience'),
                    getItem('Declarations', 'hardMoneyLoanDeclarations', hasIcon()),
                ]),
                getItem('Documents', 'documentChecklist'),
                // getItem('Documents', 'documents', null, [
                //     getItem('Uploaded by borrower', 'uploadedByBorrower'),
                //     getItem('Uploaded by you', 'uploadedByYou'),
                // ]),
                getItem('Tasks', 'tasks'),
                getItem('Underwriting checklist', 'UWList'),
                getItem('Communications', 'comment')
            ]
        }

        items.push(getItem('Closing', 'closing'))

        const changeTab = (key) => {
            this.setState({ selectedTabKey: key })
            sessionStorage.setItem("borrowermenu", key)
        }

        const renderTab = () => {
            const { isHardMoneyLoan, selectedTabKey, editMode } = this.state
            const { loan_id, id } = this.props.borrower
            if (isHardMoneyLoan) {
                const getStep = () => {
                    if (com.CurrentStatus[this.props.application.currentStatus] > 100) {
                        return 2
                    }
                    return 1
                }

                switch (selectedTabKey) {
                    case "loanSummary":
                        return <HardMoneyLoanOverview step={getStep()} checkInterview={() => this.handleSelectedTabKeyChange("hardMoneyLoanBorrower")} />
                    case "hardMoneyLoanBorrower":
                        return <div><HardMoneyLoanViewMode editMode={editMode} changeMode={changeMode} /><div className="py-4 loan-interView-wrap"><HardMoneyLoanBorrower finish={borrowerFinish} editMode={editMode} /></div></div>
                    case "hardMoneyLoanProperty":
                        return <div><HardMoneyLoanViewMode editMode={editMode} changeMode={changeMode} /><div className="py-4 loan-interView-wrap"><HardMoneyLoanPropertyAndLoan finish={propertyFinish} editMode={editMode} /></div></div>
                    case "hardMoneyLoanExperience":
                        return <div><HardMoneyLoanViewMode editMode={editMode} changeMode={changeMode} /><div className="py-4 loan-interView-wrap"><HardMoneyLoanExperience finish={experienceFinish} editMode={editMode} /></div></div>
                    case "hardMoneyLoanDeclarations":
                        return <div><HardMoneyLoanViewMode editMode={editMode} changeMode={changeMode} /><div className="py-4 loan-interView-wrap"><HardMoneyLoanDeclarations finish={declarationsFinish} editMode={editMode} onSelect={onChangeSelect} /></div></div>
                    case "documentChecklist":
                        return <DocumentChecklist key={this.state.id} loanid={loan_id} />
                    case "tasks":
                        return <Tasks key={this.state.id} loanid={loan_id} changeTab={changeTab} />
                    case "UWList":
                        return <UnderwritingChecklist loanid={loan_id} handleReject={() => handleStatusChange(this.state.id, "loandenied")} handleApprove={() => handleStatusChange(this.state.id, "conditionallyapproved")} />
                    case "comment":
                        return <Comment loanid={loan_id} />
                    case "closing":
                        return <Closing />
                    default:
                        return <HardMoneyLoanOverview step={getStep()} checkInterview={() => this.handleSelectedTabKeyChange("hardMoneyLoanBorrower")} />
                }
            } else {
                switch (selectedTabKey) {
                    case "loanSummary":
                        return <LoanSummary key={ID} borrowerid={id} />;
                    case "1003View":
                        return <Interview1003View key={this.state.id} borrowerid={id} handingOff={this.handingOff} handingOffType={this.handingOffType}/>;
                    case "borrowerView":
                        return <InterviewBorrowerView />;
                    case "prequalLetter":
                        let ref = React.createRef();
                        return <div className="m-4"><PrequalV2 borrowerid={id} loanid={loan_id} ref={ref} /></div>;
                    case "rates":
                        let ratesRef = React.createRef();
                        return <div className="m-4"><ProductPicker borrowerid={id} loanid={loan_id} ref={ratesRef} /></div>;
                    case "documentChecklist":
                        return <DocumentChecklist key={this.state.id} loanid={loan_id} />;
                    case "tasks":
                        return <Tasks key={this.state.id} loanid={loan_id} changeTab={changeTab} />;
                    case "creditReport":
                        return <CreditReport />;
                    case "mismo":
                        return <FormPreview form='mismo34' />;
                    case "UWList":
                        return <UnderwritingChecklist loanid={loan_id} handleReject={() => handleStatusChange(this.state.id, "loandenied")} handleApprove={() => handleStatusChange(this.state.id, "conditionallyapproved")} />;
                    case "comment":
                        return <Comment loanid={loan_id} />;
                    case "closing":
                        return <Closing />;
                    default:
                        return <div>Please select a tab</div>;
                }
            }
        }

        const borrowerFinish = (val) => {
            const d = new Date()
            this.props.updateChangeLastTouch(d.toLocaleString())
            this.handleSelectedTabKeyChange("hardMoneyLoanProperty")
        };
        const propertyFinish = (val) => {
            const d = new Date()
            this.props.updateChangeLastTouch(d.toLocaleString())
            this.handleSelectedTabKeyChange("hardMoneyLoanExperience")
        };
        const experienceFinish = (val) => {
            const d = new Date()
            this.props.updateChangeLastTouch(d.toLocaleString())
            this.handleSelectedTabKeyChange("hardMoneyLoanDeclarations")
        };
        const declarationsFinish = (val) => {
            const d = new Date()
            this.props.updateChangeLastTouch(d.toLocaleString())
            // submit
            this.props.updateApplicationAttribute("ApplicationFinished", "currentStatus")

        };
        const onChangeSelect = (val) => {
            console.log(val)
            const declarationsWarning = val.some(item => item.select === 'yes')
            this.setState({ declarationsWarning })
        }

        const getLoanPurpose = () => {
            const { isHardMoneyLoan, isPrequal } = this.state
            if (isHardMoneyLoan) {
                return "Hard money loan"
            }
            if (isPrequal) {
                return "Pre-qualification"
            }

            const { purpose } = this.props.application.property
            if (purpose === "purchase") {
                return "Purchase"
            } else if (purpose === "cashoutrefinance") {
                return "Cash-out refinance"
            } else {
                return "Refinance"
            }
        }

        const getBorrowerName = () => {
            const { hascoborrower, borrower, coborrower } = this.props.application
            const bName = `${borrower.firstname} ${borrower.lastname}`
            if (hascoborrower === "withcoborrower" && coborrower.firstname !== "" && coborrower.lastname !== "") {
                return `${bName} & ${coborrower.firstname} ${coborrower.lastname}`
            }
            return bName
        }

        const getLTV = () => {
            if (this.state.isHardMoneyLoan) {
                return getHardMoneyLoanLTV(this.props)
            }
            if (this.props.application.selection.loansize === "") {
                return "-"
            }

            const { salesprice } = this.props.application.property
            const { loansize } = this.props.application.selection
            return Math.floor(100 * com.safeParseInt(loansize) / parseFloat(salesprice)) + "%"
        }

        const getDTI = () => {
            const { monthlyIncome, monthlyExpense } = this.state
            if (monthlyIncome !== 0 && monthlyExpense !== 0) {
                return Math.round(monthlyIncome / monthlyExpense) + "%"
            }
            return "-"
        }

        const handleStatusChange = (loanID, newStatus) => {
            const token = sessionStorage.getItem("ZeitroA")
            const body = { LoanID: loanID, Key: "status", Value: newStatus, IsAdmin: checkAccess([UR_Owner]) ? true : false }
            fetch('/los/updatepipeline', {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache"
                }
            }).then(response => {
                if (response.status !== 200) {
                    console.warn('Looks like there was a problem. Status Code: ', response.status)
                    // this.setState({ showErrorModal: true})
                    return
                }
                this.props.updateApplicationAttribute(newStatus, "currentStatus");
            })
                .catch(err => {
                    console.warn('Fetch Error :-S', err)
                })
        }

        const handleCurrentStatusChange = (loanID, newCurrentStatus) => {
            const token = sessionStorage.getItem("ZeitroA")
            const body = {
                LoanID: loanID,
                Key: "currentStatus",
                Value: newCurrentStatus,
                IsAdmin: checkAccess([UR_Owner]) ? true : false,
            }
            fetch("/los/updatepipeline", {
                method: "POST",
                body: JSON.stringify(body),
                    headers: {
                        Authorization: "Bearer " + token,
                        Cache: "no-cache",
                    },
                }).then(response => {
                    if (response.status !== 200) {
                        console.warn("Looks like there was a problem. Status Code: ", response.status)
                        // this.setState({ showErrorModal: true})
                        return
                    }
                    console.log(newCurrentStatus)
                    this.props.updateApplicationAttribute(newCurrentStatus, "currentStatus")
                    //   handleStatus(loanID, newStatus)
                }).catch(err => {
                    console.error("Fetch Error :-S", err)
                })
        }

        const handleCurrentStageChange = (loanID, newCurrentStage) => {
            const token = sessionStorage.getItem("ZeitroA")
            const body = {
                LoanID: loanID,
                Key: "currentStage",
                Value: newCurrentStage,
                IsAdmin: checkAccess([UR_Owner]) ? true : false,
            }
            fetch("/los/updatepipeline", {
                method: "POST",
                body: JSON.stringify(body),
                    headers: {
                        Authorization: "Bearer " + token,
                        Cache: "no-cache",
                    },
                }).then(response => {
                    if (response.status !== 200) {
                        console.warn("Looks like there was a problem. Status Code: ", response.status)
                        // this.setState({ showErrorModal: true})
                        return
                    }
                    console.log(newCurrentStage)
                    this.props.updateApplicationAttribute(newCurrentStage, "currentStage")
                    //   handleStatus(loanID, newStatus)
                }).catch(err => {
                    console.error("Fetch Error :-S", err)
                })
        }

        const getLoanAmount = () => {
            if (this.state.isHardMoneyLoan) {
                return getHardMoneyLoanLoanAmount(this.props)
            }

            const { loansize } = this.props.application.selection
            if (loansize === "") {
                return "-"
            }

            return "$" + com.commaize(loansize)
        }

        const changeMode = (val) => {
            this.setState({ editMode: val })
        }

        const dataItems = [
            {
                label: <div className="" onClick={() => { gen1003Form(); this.setState({ showDownloadModal: true }) }} >
                    {this.state.loadingPdf ? <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} /> : "PDF 1003"}
                </div>,
                key: '1',
            },
            {
                label: <div onClick={() => {
                    this.setState({ selectedTabKey: "mismo" })
                    sessionStorage.setItem("borrowermenu", "mismo")
                }}>
                    FNM 3.4
                </div>,
                key: '2',
            }
        ]

        const genExtra = (type, suffix) =>{
            let idx = 1
            if (type === "extraRE"){
                idx = 3
            }

            const { borrower, coborrower } = this.props.application
            const boCount = borrower[suffix]?.length || 0
            const coCount = coborrower[suffix]?.length || 0
            const maxCount = boCount > coCount ? boCount : coCount
            for(let i = idx; i < maxCount; i++) {
                this.getForm1003(type, i)
            }
        }

        const gen1003Form = () => {
            const { hascoborrower, borrower, coborrower } = this.props.application
            if (hascoborrower === "withcoborrower") {
                Promise.all([
                    this.getForm1003("borrower"),
                    this.getForm1003("coborrower"),
                    (borrower.ownedproperties.length > 3 || coborrower.ownedproperties.length > 3) && genExtra("extraRE", "ownedproperties"),
                    (borrower.otheroccupations?.length > 1 || coborrower.otheroccupations?.length > 1) && genExtra("extraAdditionalEmp", "otheroccupations"),
                    (borrower.previousoccupations?.length > 1 || coborrower.previousoccupations?.length > 1) && genExtra("extraPrevEmp", "previousoccupations"),
                ]).then(() => {
                    
                }).catch((error) => {
                    
                })
            } else {
                Promise.all([
                    this.getForm1003("borrower"),
                    borrower.ownedproperties.length > 3 && genExtra("extraRE", "ownedproperties"),
                    borrower.otheroccupations?.length > 1 && genExtra("extraAdditionalEmp", "otheroccupations"),
                    borrower.previousoccupations?.length > 1 && genExtra("extraPrevEmp", "previousoccupations"),
                ]).then(() => {
                    
                }).catch((error) => {
                    
                })
            }
        }

        const borrowerTopBar = () => {
            const st = { fontSize: 18, color: "#6E6E70", marginRight: 10 }
            return (
                <div className="borrowerHeader">
                    <a hidden ref={this.aref} href={this.state.url} download={this.state.download}>&nbsp;</a>
                    <div className="borrowerStatusHeader">
                        <CurrentStage
                            currentStage={this.props.application.currentStage}
                            loanID={this.state.id}
                            handleStageChange={handleCurrentStageChange}
                        />
                        <CurrentStatus
                            currentStatus={this.props.application.currentStatus}
                            currentStage={this.props.application.currentStage}
                            loanID={this.state.id}
                            handleStatusChange={handleCurrentStatusChange}
                        />

                        <Select
                            onChange={(e) => {handleStatusChange(this.state.id, e)}}
                            style={{ display: "none" }}
                            className="borrowerHeaderStatus"
                            defaultValue={this.props.application.currentStatus}
                            value={this.props.application.currentStatus}
                            options={StatusSelect}
                        />
                        {!this.state.isHardMoneyLoan && (
                            <div style={{ gridColumn: 2, marginRight: 20, display: "flex" }}>
                                <Dropdown menu={{ items: dataItems }}>
                                    <Button type='primary' style={{ height: 38 }}>
                                        {this.state.loadingPdf ? (
                                            <Spin indicator={<LoadingOutlined style={{ fontSize: 20, color: "white", marginLeft: 18, marginRight: 18 }} spin />} />
                                        ) : "Export"}
                                        <DownOutlined />
                                    </Button>
                                </Dropdown>
                            </div>
                        )}
                    </div>
                    <div className="borrowerLoanInfo">
                        <Link className="borrowerHeader-link" to={"/app/borrowers/" + this.props.borrower.id}>
                            <div className="borrowerLoanInfoItem" >
                                <div className="borrowerLoanInfoItemTitle">Borrower</div>
                                <div className="borrowerLoanInfoItemValue">
                                    {getBorrowerName()}
                                </div>
                            </div>
                        </Link>
                        <div className="borrowerLoanInfoItem">
                            <div className="borrowerLoanInfoItemTitle">Purpose</div>
                            <div className="borrowerLoanInfoItemValue">
                                {getLoanPurpose()}
                            </div>
                        </div>
                        <div className="borrowerLoanInfoItem">
                            <div className="borrowerLoanInfoItemTitle">Address</div>
                            <div className="borrowerLoanInfoItemValue">
                                {this.props.application.property.address}
                            </div>
                        </div>
                        <div className="borrowerLoanInfoItem">
                            <div className="borrowerLoanInfoItemTitle">DTI</div>
                            <div className="borrowerLoanInfoItemValue">{getDTI()}</div>
                            <div className="borrowerLoanInfoItemTitle">LTV</div>
                            <div className="borrowerLoanInfoItemValue">{getLTV()}</div>
                            <div className="borrowerLoanInfoItemTitle">Loan Amount</div>
                            <div className="borrowerLoanInfoItemValue">{getLoanAmount()}</div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="borrower-container">
                <div className="borrower-top-bar-container">
                    {borrowerTopBar()}
                </div>
                <div className="borrow-content-wrap">
                    <StepperMenu
                        currentStatus={this.props.application.currentStatus}
                        currentStage={this.props.application.currentStage}
                        setSelectedTabKey={this.handleSelectedTabKeyChange}
                        items={items}
                        selectedTabKey={this.state.selectedTabKey}
                    />
                    <div style={{ width: "100%", maxWidth: "calc(100% - 220px)" }}>
                        <div className="interview-tabpane flex-grow-1">
                            {renderTab()}
                        </div>
                        <PlanModal isModalOpen={this.state.isModalOpen} closeModal={() => {this.setState({ isModalOpen: false })}} />
                    </div>
                    <PlanModal isModalOpen={this.state.isModalOpen} closeModal={() => {this.setState({ isModalOpen: false })}} />
                </div>
            </div>
        )
    }
    fileFormat = (type) => {
        const nameMap = {
            "extraRE": "Property",
            "extraAdditionalEmp": "Additional Employment",
            "extraPrevEmp": "Previous Employment"
        }
        return nameMap[type]
    }
    getForm1003 = (forwhom, idx) => {
        let filename = '';
        if (forwhom === "borrower") {
            filename = `${this.props.application.borrower.firstname} ${this.props.application.borrower.lastname}_Form1003`
        } else if (forwhom === "coborrower") {
            filename = `${this.props.application.coborrower.firstname} ${this.props.application.coborrower.lastname}_Form1003`
        } else {
            filename = `Continuation Sheet_Extra ${this.fileFormat(forwhom)} - ${forwhom === "extraRE" ? idx - 2 : idx}`
        }
        const controller = new AbortController()
        const signal = controller.signal
        const requestKey = `${forwhom}${idx ? '_' + idx : ''}`
        this.setState(prevState => ({
            controllers: {
                ...prevState.controllers,
                [requestKey]: controller,
            },
            dataSource: prevState.dataSource.some(item => item.key === requestKey)
                ? prevState.dataSource.map(item =>
                    item.key === requestKey ? { ...item, status: 'downloading' } : item
                )
                : [
                    ...prevState.dataSource,
                    {
                        key: requestKey,
                        filename: filename,
                        status: 'downloading',
                    },
                ],
        }))
        
        return new Promise((resolve, reject) => { // Wrap the fetch logic in a promise
            let disposition;
            let token = com.getUserToken();
            this.setState({ loadingPdf: true });
            fetch('/borrower/getform1003', {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache",
                    "X-Borrower": this.props.borrower.id,
                    "X-Loan": this.props.borrower.loan_id,
                    "X-Forwhom": forwhom,
                    "X-ExtraIdx": forwhom === "borrower" || forwhom === "coborrower" ? "" : idx
                },
                signal: signal,
            }).then(response => {
                disposition = response.headers.get("Content-Disposition").split('=')
                return response.body
            })
                .then(body => {
                    const reader = body.getReader()
                    return new ReadableStream({
                        start(controller) {
                            function pump() {
                                return reader.read().then(({ done, value }) => {
                                    if (done) {
                                        controller.close();
                                        return;
                                    }
                                    controller.enqueue(value);
                                    return pump();
                                });
                            }
                            return pump();
                        }
                    });
                })
                .then(stream => new Response(stream))
                .then(r => r.blob())
                .then(blob => {
                    let download = disposition.slice(1).join().replace(/"/g, '')
                    const url = window.URL.createObjectURL(blob)
                    this.setState({ url: url, download: `${filename}.pdf` })
                    this.aref.current.click()
                    this.setState(prevState => {
                        const updatedDataSource = prevState.dataSource.map(item => 
                            item.key === requestKey ? { ...item, status: 'success' } : item
                        )
        
                        const hasDownloading = updatedDataSource.some(item => item.status === 'downloading')
        
                        return {
                            dataSource: updatedDataSource,
                            loadingPdf: hasDownloading,
                        }
                    })

                    resolve()
                })
                .catch((err) => {
                    this.setState(prevState => {
                        const updatedDataSource = prevState.dataSource.map(item =>
                            item.key === requestKey ? 
                                { ...item, status: err.name === 'AbortError' ? 'canceled' : 'fail' } 
                                : item
                        )
        
                        const hasDownloading = updatedDataSource.some(item => item.status === 'downloading')
        
                        return {
                            dataSource: updatedDataSource,
                            loadingPdf: hasDownloading,
                        }
                    })
                    console.log("Error in getForm1003:", err)
                    reject(err)
                })
        })
    }
    formatStatusText = (status, key) => {
        switch (status) {
            case 'success':
              return 'Successful'
            case 'fail':
              return <span>Failed. Please <span style={{ color: '#325CEB', fontWeight: 500, cursor: 'pointer', textDecorationLine: 'underline' }} onClick={() => this.handleRetryDownload(key)}>retry</span></span>
            case 'canceled':
              return 'Canceled';
            default:
              return 'Downloading';
        }
    }
    formatStatusIcon = (status) => {
        switch (status) {
            case 'success':
              return <img src='/images/download-success.svg' />
            case 'fail':
              return <img src='/images/download-fail.svg' />
            case 'downloading':
              return (
                <AutoProgress 
                    type="circle"
                    showInfo={false}
                    percent="auto"
                    strokeWidth={10}
                    trailColor="#BDCBFB" 
                    strokeColor="#325CEB"
                    size={26}
                />
              )
            default:
              return '';
        }
    }
    handleCancelDownload = (key) => {
        this.setState(prevState => {
            const controller = prevState.controllers[key]
            if (controller) {
                controller.abort()
            }
            return {
                dataSource: prevState.dataSource.map(item =>
                    item.key === key ? { ...item, status: 'canceled' } : item
                ),
                controllers: Object.keys(prevState.controllers)
                .filter(k => k !== key)
                .reduce((acc, k) => {
                    acc[k] = prevState.controllers[k]
                    return acc
                }, {}),
            }
        })
    }
    handleRetryDownload = (key) => {
        const record = this.state.dataSource.find(item => item.key === key)
        if (record) {
            const [forwhom, idx] = record.key.split('_')
            this.getForm1003(forwhom, idx)
            .catch(err => {
                
            })
        }
    }
    handleCancelAll = () => {
        Object.values(this.state.controllers).forEach(controller => {
            controller.abort()
        })
        this.setState(prevState => ({
            controllers: {},
            dataSource: prevState.dataSource.map(item =>
                item.status === 'downloading' ? { ...item, status: 'canceled' } : item
            ),
        }))
    }
    render() {
        const columns = [
            {
              title: 'File name',
              dataIndex: 'filename',
              key: 'filename',
              width: '55%',
              render: (text, record) => (
                <div style={{ fontWeight: 500, display: 'flex', alignItems: 'center', gap: 4 }}>
                    <div style={{
                        lineHeight: '20px',
                        whiteSpace: 'normal',
                        wordBreak: 'break-all',
                    }}>{text}</div>
                    {record.status !== 'canceled' && record.status !== 'success' && (
                        <CloseCircleOutlined
                            style={{ color: '#6E6E70', cursor: 'pointer' }}
                            onClick={() => this.handleCancelDownload(record.key)}
                        />
                    )}
                </div>
              )
            },
            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status',
              render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {record.status !== 'canceled' && <div className="download-status-icon">{this.formatStatusIcon(text)}</div>}
                    <div className="download-status-text">{this.formatStatusText(text, record.key)}</div>
                </div>
              )
            }
        ]
        const noDownloading = this.state.dataSource.every(item => item.status !== 'downloading')
        const footer = noDownloading
            ? [
                <Button
                    className='download-status-btn status-close'
                    type='primary'
                    onClick={() => this.setState({ showDownloadModal: false, dataSource: [] })}
                >
                    Close
                </Button>
            ] : [
                <Button
                    className='download-status-btn status-cancel'
                    type='border'
                    onClick={() => this.handleCancelAll()}
                >
                    Cancel all
                </Button>
            ]
        if (this.state.loading) {
            return <div className="p-5">
                <Skeleton active />
            </div>
        }

        return (
            <div>
                {this.state.render()}
                <Modal
                    className='borrower-download-status-modal'
                    maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
                    // destroyOnClose
                    maskClosable={false}
                    width={566}
                    closable={false}
                    open={this.state.showDownloadModal}
                    centered={true}
                    title=""
                    onCancel={() => {
                        this.setState({ showDownloadModal: false, dataSource: [] })
                    }}
                    footer={footer}
                >
                    <div className='download-status-modal-title'>Download</div>
                    <div className='download-status-modal-content'>
                        <div className="download-status-modal-tips">Downloading files may take up to 1 minute. Please keep this tab open.</div>
                        <div>
                            <Table
                                className="download-status-table"
                                pagination={false}
                                columns={columns}
                                dataSource={this.state.dataSource}
                            />
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Borrower))
