/**
 * Includes all types, enums, and interfaces for the SearchLogs component
 */

export enum LogType {
  ADVANCED = "advanced",
  FAST = "fast",
  MASSIVE = "massive",
  GPS = "gps",
}
