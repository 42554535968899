import * as com from "../Common.js";

/**
 * Compare two steps to determine if the target step is greater than the   step
 * @param targetStep - The target step in the format of "1-0"
 * @param comparedStep - The compared step in the format of "1-0"
 * @returns 0 if the steps are equal, 1 if the target step is greater, -1 if the compared step is greater
 */
export const compareToStep = (targetStep: string, comparedStep: string) => {
  if (comparedStep === targetStep) {
    return 0;
  }
  const targetLevels = targetStep.split("-").map((level) => parseInt(level));
  const comparedLevels = comparedStep
    .split("-")
    .map((level) => parseInt(level));
  if (comparedLevels[0] > targetLevels[0]) {
    return -1;
  }
  if (
    comparedLevels[0] === targetLevels[0] &&
    comparedLevels[1] > targetLevels[1]
  ) {
    return -1;
  }
  return 1;
};

/**
 * Get the main menu step from the given step
 * @param step - The step in the format of "1-0"
 * @returns The main menu step in the format of "1"
 */
export const getMainMenuStep = (step: string): number => {
  return parseInt(step.split("-")[0]);
};

export type CurrentStatusValues = keyof typeof com.CurrentStatus;
export type CurrentStageValues = keyof typeof com.CurrentStage;

const statusToStepMap: Record<CurrentStatusValues, string> = {
  // Application
  ApplicationStarted: "1-1",
  ApplicationFinished: "1-8",
  PrequalSent: "1-9",

  // Processing
  InContract: "2-0",
  OpenEscrow: "2-1",
  ConditionsRequested: "2-2",
  ConditionsReviewed: "2-2",
  OrderAppraisal: "2-4",
  TitleSearch: "2-5",
  PreApproved: "2-5",

  // Closing
  SubmittedToUnderwriting: "3-0",
  ConditionalApproval: "3-0",
  ClearToClose: "3-0",
  DocsOut: "3-0",
  DocsSigned: "3-0",
  LoanFunded: "3-0",

  // Archived (400-499)
  ApplicationAbandoned: "3-0",
  ApplicationRejected: "3-0",
  PreApprovalDenied: "3-0",
  FileClosedForIncompleteness: "3-0",
  ApplicationWithdrawn: "3-0",
  ApplicationDenied: "3-0",
  ApprovedButNotAccepted: "3-0",
  Other: "3-0",
};

export const getCurrentFarthestStep = (currentStatus: CurrentStatusValues) => {
  if (currentStatus in statusToStepMap) {
    return statusToStepMap[currentStatus];
  }
  return "1-0";
};
