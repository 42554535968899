import React, { useState } from 'react';

const Terms = () => {
  const [hasAgreed, setHasAgreed] = useState(false);

  return (
    <div className="terms-container">

        <p>By clicking "I Agree," you ("Customer") agree to be bound by the terms and conditions of this Software as a Service Agreement (this "Agreement") with Zeitro Inc., a California corporation ("Provider"), governing your access to and use of the Software Services provided by Provider.</p>

        <p><strong>WHEREAS,</strong>Provider owns the Zeitro Software Platform, including all modifications, improvements, and derivative works created during the term of the Agreement and provides Software Services to allow Customer to use the Zeitro Software Platform;</p>
        <p><strong>WHEREAS,</strong>Customer desires to access the Software Services, and Provider desires to provide Customer access to the Software Services, subject to the terms and conditions of this Agreement.</p>
        <p><strong>NOW, THEREFORE,</strong>in consideration of the mutual covenants, terms, and conditions set forth herein, the Parties agree as follows:</p>

        <p><strong>1. Definitions</strong></p>
        <ul>
            <li><p><strong>"Aggregated Statistics"</strong> means data and information related to Customer's use of the Software Services that is used by Provider in an aggregate and anonymized manner, including to compile statistical and performance information related to the provision and operation of the Software Services</p></li>
            <li><p><strong>"Authorized User"</strong>means Customer's employees, consultants, contractors, and agents (i) who are authorized by Customer to access and use the Software Services under the rights granted to Customer pursuant to this Agreement and (ii) for whom access to the Software Services has been purchased hereunder.</p></li>
            <li><p><strong>"CCPA"</strong>means the California Consumer Privacy Act (Assembly Bill 375), as amended.
            </p></li>
            <li><p><strong>"Customer Data"</strong>means information, data, and other content, in any form or medium, that is submitted, posted, or otherwise transmitted by or on behalf of Customer or an Authorized User through the Software Services.
            </p></li>
            <li><p><strong>"Documentation"</strong>means Provider's manuals, handbooks, and guides relating to the Software Services provided by Provider to Customer either electronically or in hard copy form.
            </p></li>
            <li><p><strong>"Provider IP"</strong>means the Software Services, the Documentation, and any and all intellectual property provided to Customer or any Authorized User in connection with the foregoing. For the avoidance of doubt, Provider IP includes Aggregated Statistics and any information, data, or other content derived from Provider's monitoring of Customer's access to or use of the Software Services, but does not include Customer Data.</p></li>
            <li><p><strong>"Software Services"</strong> means the software-as-a-service offering described in Exhibit A.
            </p></li>
            <li><p><strong>"Third-Party Products"</strong>means any third-party products described in Exhibit A provided with or incorporated into the Software Services.</p></li>
        </ul>

        <p><strong>2. Access and Use.</strong></p>
        <p><strong>a) Provision of Access.</strong>Subject to and conditioned on Customer's payment of Fees and compliance with all the terms and conditions of this Agreement, Provider hereby grants Customer a non-exclusive, non-transferable (except in compliance with Section 12(g)) right to access and use the Software Services during the Term, solely for use by Authorized Users in accordance with the terms and conditions herein. Such use is limited to Customer's internal use. Provider shall provide to Customer the necessary passwords and network links or connections to allow Customer to access the Software Services.</p>
        <p><strong>b) Documentation License</strong>Subject to the terms and conditions contained in this Agreement, Provider hereby grants to Customer a non-exclusive, non-sublicensable, non-transferable (except in compliance with Section 12(g)) license to use the Documentation during the Term solely for Customer's internal business purposes in connection with its use of the Software Services.</p>
        <p><strong>c) Use Restrictions.</strong>Customer shall not use the Software Services for any purposes beyond the scope of the access granted in this Agreement. Customer shall not at any time, directly or indirectly, and shall not permit any Authorized Users to:</p>
        <ul>
            <li><p>(i) copy, modify, or create derivative works of the Software Services or Documentation, in whole or in part;</p></li>
            <li><p>(ii) rent, lease, lend, sell, license, sublicense, assign, distribute, publish, transfer, or otherwise make available the Software Services or Documentation;</p></li>
            <li><p>(iii) reverse engineer, disassemble, decompile, decode, adapt, or otherwise attempt to derive or gain access to any software component of the Software Services, in whole or in part;</p></li>
            <li><p>(iv) remove any proprietary notices from the Software Services or Documentation; or</p></li>
            <li><p>(v) use the Software Services or Documentation in any manner or for any purpose that infringes, misappropriates, or otherwise violates any intellectual property right or other right of any person, or that violates any applicable law.</p></li>
        </ul>
        <p><strong>3. d) Reservation of Rights.</strong>Provider reserves all rights not expressly granted to Customer in this Agreement. Except for the limited rights and licenses expressly granted under this Agreement, nothing in this Agreement grants, by implication, waiver, estoppel, or otherwise, to Customer or any third party any intellectual property rights or other right, title, or interest in or to the Provider IP.</p>
        <p><strong>e) Suspension.</strong> Notwithstanding anything to the contrary in this Agreement, Provider may temporarily suspend Customer's and any Authorized User's access to any portion or all of the Software Services if:</p>
        <ul>
            <li><p>(i) Provider reasonably determines that:</p></li>
            <ul>
                <li><p>(A) there is a threat or attack on any of the Provider IP;</p></li>
                <li><p>(B) Customer's or any Authorized User's use of the Provider IP disrupts or poses a security risk to the Provider IP or to any other customer or vendor of Provider;</p></li>
                <li><p>(C) Customer, or any Authorized User, is using the Provider IP for fraudulent or illegal activities;</p></li>
                <li><p>(D) subject to applicable law, Customer has ceased to continue its business in the ordinary course, made an assignment for the benefit of creditors or similar disposition of its assets, or become the subject of any bankruptcy, reorganization, liquidation, dissolution, or similar proceeding; or</p></li>
                <li><p>(E) Provider's provision of the Software Services to Customer or any Authorized User is prohibited by applicable law;</p></li>
            </ul>
            <li><p>(ii) any vendor of Provider has suspended or terminated Provider's access to or use of any third-party services or products required to enable Customer to access the Software Services; or</p></li>
            <li><p>(iii) in accordance with Section 5(a)(iii) (any such suspension described in subclause (i), (ii), or (iii), a “Service Suspension”).</p></li>
        </ul>

        <p>4. Provider shall use commercially reasonable efforts to provide written notice of any Software Service Suspension to Customer and to provide updates regarding resumption of access to the Services following any Service Suspension. Provider shall use commercially reasonable efforts to resume providing access to the Software Services as soon as reasonably possible after the event giving rise to the Service Suspension is cured. Provider will have no liability for any damage, liabilities, losses (including any loss of data or profits), or any other consequences that Customer or any Authorized User may incur as a result of a Service Suspension.</p>
        <p><strong>f) Aggregated and Derived Data.</strong>Notwithstanding anything to the contrary in this Agreement, Provider shall have the right to:</p>
        <ul>
            <li><p>(i) monitor Customer's use of the Services;</p></li>
            <li><p>(ii) collect, compile, and analyze Customer Data to create Aggregated Statistics, insights, trends, and other derived data ("Derived Data");</p></li>
            <li><p>(iii) use, modify, distribute, and commercialize Aggregated Statistics and Derived Data for any purpose;</p></li>
            <li><p>(iv) share Aggregated Statistics and Derived Data with third parties, including partners, affiliates, and customers; and</p></li>
            <li><p>(v) retain Aggregated Statistics and Derived Data after termination of this Agreement.</p></li>
        </ul>

        <p>5. Provider will ensure that Aggregated Statistics and any public use of Derived Data does not identify Customer or contain Customer's Confidential Information in a form that could identify Customer. Provider will maintain appropriate technical and organizational measures to protect Customer Data in accordance with applicable law.</p>

        <p><strong>g) Licensing and Regulatory Compliance Requirements.</strong></p>
        <p>Customer warrants and represents that it:</p>
        <ul>
            <li><p>(i) maintains a valid and current Nationwide Multistate Licensing System & Registry ("NMLS") license;</p></li>
            <li><p>(ii) holds all required state and federal licenses necessary for its operations;</p></li>
            <li><p>(iii) is in good standing with all applicable regulatory authorities;</p></li>
            <li><p>(iv) will maintain such licenses and standing throughout the Term.</p></li>
        </ul>

        <p><strong>6. Verification and Reporting.</strong> Customer shall:</p>
        <ul>
            <li><p>(i) provide Provider with its current NMLS ID upon execution of this Agreement;</p></li>
            <li><p>(ii) submit proof of valid licensing annually or upon Provider's request;</p></li>
            <li><p>(iii) notify Provider within three (3) business days of any:</p>
                <ul>
                    <li><p>(A) change in licensing status,</p></li>
                    <li><p>(B) regulatory enforcement actions,</p></li>
                    <li><p>(C) material compliance violations,</p></li>
                    <li><p>(D) investigations by regulatory authorities;</p></li>
                </ul>
            </li>
            <li><p>(iv) maintain and provide upon request documentation demonstrating compliance;</p></li>
            <li><p>(v) submit to periodic compliance verification as reasonably requested by Provider.</p></li>
        </ul>

        <p><strong>7. Compliance Monitoring.</strong> Provider reserves the right to:</p>
        <ul>
            <li><p>(i) verify Customer's licensing status through NMLS or other regulatory databases;</p></li>
            <li><p>(ii) conduct periodic compliance audits;</p></li>
            <li><p>(iii) request additional documentation or certifications;</p></li>
            <li><p>(iv) monitor Customer's regulatory status and standing.</p></li>
        </ul>

        <p><strong>8. Non-Compliance and License Revocation.</strong></p>
        <p>Immediate Termination Events:</p>
        <ul>
            <li><p>(i) revocation, suspension, or expiration of NMLS license;</p></li>
            <li><p>(ii) loss of good standing with regulatory authorities;</p></li>
            <li><p>(iii) material violation of applicable laws or regulations;</p></li>
            <li><p>(iv) failure to maintain required licenses.</p></li>
        </ul>

        <p><strong>9. Upon occurrence of any Immediate Termination Event:</strong></p>
        <ul>
            <li><p>(i) Customer's rights to access and use the Services automatically terminate;</p></li>
            <li><p>(ii) Provider may immediately suspend or terminate access without notice;</p></li>
            <li><p>(iii) Customer must cease all use of the Services;</p></li>
            <li><p>(iv) Customer remains liable for all Fees accrued through termination date.</p></li>
        </ul>

        <p><strong>10. Reinstatement.</strong> Customer may request reinstatement upon:</p>
        <ul>
            <li><p>(i) providing proof of license reinstatement;</p></li>
            <li><p>(ii) demonstrating restoration of good standing;</p></li>
            <li><p>(iii) paying any outstanding Fees;</p></li>
            <li><p>(iv) satisfying any additional requirements imposed by Provider.</p></li>
        </ul>

        <p><strong>11. Indemnification.</strong> Customer shall indemnify and hold Provider harmless from any claims, losses, or damages arising from:</p>
        <ul>
            <li><p>(i) Customer's failure to maintain required licenses;</p></li>
            <li><p>(ii) violations of applicable regulations;</p></li>
            <li><p>(iii) misrepresentation of licensing status.</p></li>
        </ul>

        <p><strong>12. h) CCPA.</strong> Except as expressly set forth in the Agreement, Provider shall not:</p>
        <ul>
            <li><p>(a) have, derive or exercise any rights or benefits regarding Customer information (or Your Personal Information),</p></li>
            <li><p>(b) Sell Your Personal Information, or</p></li>
            <li><p>(c) collect, retain, share or use Your Personal Information except as necessary for the sole purpose of performing the Software Services.</p></li>
        </ul>

        <p>13. Provider agrees to refrain from taking any action that would cause any transfers of Your Personal Information, either to Us or from Us, to qualify as a Sale of Personal Information under the CCPA. Provider understands and will comply with the restrictions set forth in this Section and the applicable requirements of the CCPA. For the purposes of this Section, Provider is a Service Provider and the terms "Personal Information", "Sell", "Sale", and "Service Provider" shall have the same meanings as in the CCPA.</p>

        <p><strong>14. Customer Responsibilities.</strong></p>
        <p><strong>a) General.</strong> Customer is responsible and liable for all uses of the Software Services and Documentation resulting from access provided by Customer, directly or indirectly, whether such access or use is permitted by or in violation of this Agreement. Without limiting the generality of the foregoing, Customer is responsible for all acts and omissions of Authorized Users, and any act or omission by an Authorized User that would constitute a breach of this Agreement if taken by Customer will be deemed a breach of this Agreement by Customer. Customer shall use reasonable efforts to make all Authorized Users aware of this Agreement's provisions as applicable to such Authorized User's use of the Software Services, and shall cause Authorized Users to comply with such provisions.</p>

        <p><strong>b) Third-Party Products.</strong> Provider may from time to time make Third-Party Products available to Customer. For purposes of this Agreement, such Third-Party Products are subject to their own terms and conditions and the applicable flow-through provisions referred to in Exhibit A. If Customer does not agree to abide by the applicable terms for any such Third-Party Products, then Customer should not install or use such Third-Party Products.</p>

        <p><strong>c) GuidelineGPT Services.</strong> The GuidelineGPT feature ("GuidelineGPT") is provided as part of the Services solely as a reference tool. By using GuidelineGPT, User acknowledges and agrees to the following:</p>
        <ul>
            <li><p>(i) all information provided through GuidelineGPT is sourced from publicly available industry guidelines, including but not limited to Fannie Mae, Freddie Mac, FHA, VA, and USDA guidelines ("Industry Guidelines");</p></li>
            <li><p>(ii) Provider makes no representations or warranties regarding the accuracy, completeness, or reliability of any information provided through GuidelineGPT;</p></li>
            <li><p>(iii) Customer assumes sole responsibility for verifying all information obtained through GuidelineGPT against current Industry Guidelines before application or implementation;</p></li>
            <li><p>(iv) Customer acknowledges that Industry Guidelines are subject to change, and information provided through GuidelineGPT may not reflect the most current versions of such guidelines;</p></li>
            <li><p>(v) Provider shall not be held liable for any damages, losses, or consequences arising from:</p>
                <ul>
                    <li><p>(A) the use of or reliance upon information provided by GuidelineGPT,</p></li>
                    <li><p>(B) any inaccuracies, errors, or omissions in the information provided,</p></li>
                    <li><p>(C) the accuracy or reliability of any additional guidelines incorporated into GuidelineGPT, or</p></li>
                    <li><p>(D) any decisions or actions taken by Customer based on information provided through GuidelineGPT.</p></li>
                </ul>
            </li>
        </ul>

        <p><strong>15. Service Levels</strong></p>
        <p><strong>a) Service Levels.</strong> Subject to the terms and conditions of this Agreement, Provider shall use commercially reasonable efforts to make the Software Services available in accordance with the service levels agreed upon, orally or in written, between the Parties.</p>

        <p><strong>16. Fees and Payment.</strong></p>
        <p><strong>a) Fees.</strong> Customer shall pay Provider the fees ("Fees") as set forth in Exhibit A without offset or reduction. Customer shall make all payments hereunder in US dollars on or before the due date set forth in Exhibit A. If Customer fails to make any payment when due, without limiting Provider's other rights and remedies:</p>
        <ul>
            <li><p>(i) Provider may charge interest on the past due amount at the rate of 1.5% per month calculated daily and compounded monthly or, if lower, the highest rate permitted under applicable law;</p></li>
            <li><p>(ii) Customer shall reimburse Provider for all costs incurred by Provider in collecting any late payments or interest, including attorneys' fees, court costs, and collection agency fees; and</p></li>
            <li><p>(iii) if such failure continues for 30 days or more, Provider may suspend Customer's and its Authorized Users' access to any portion or all of the Software Services until such amounts are paid in full.</p></li>
        </ul>

        <p><strong>17. b) Taxes.</strong> All Fees and other amounts payable by Customer under this Agreement are exclusive of taxes and similar assessments. Customer is responsible for all sales, use, and excise taxes, and any other similar taxes, duties, and charges of any kind imposed by any federal, state, or local governmental or regulatory authority on any amounts payable by Customer hereunder, other than any taxes imposed on Provider's income.</p>

        <p><strong>c) Payment Terms, Collection Process, and Auditing Rights.</strong> All invoices issued by Provider are due within thirty (30) days of the invoice date. If any missed or past due payments are not timely paid, Provider reserves the right to:</p>
        <ul>
            <li><p>(i) require immediate payment of all outstanding amounts;</p></li>
            <li><p>(ii) suspend services per Section 2(e);</p></li>
            <li><p>(iii) report payment defaults to credit bureaus;</p></li>
            <li><p>(iv) pursue legal remedies for collection.</p></li>
        </ul>

        <p><strong>18. Auditing Rights and Required Records.</strong>Customer agrees to maintain complete and accurate records in accordance with generally accepted accounting principles during the Term and for a period of TWO years after the termination or expiration of this Agreement with respect to matters necessary for accurately determining amounts due hereunder. Provider may, at its own expense, on reasonable prior notice, periodically inspect and audit Customer's records with respect to matters covered by this Agreement, provided that if such inspection and audit reveals that Customer has underpaid Provider with respect to any amounts due and payable during the Term, Customer shall promptly pay the amounts necessary to rectify such underpayment, together with interest in accordance with Section 5(a). Such inspection and auditing rights will extend throughout the Term of this Agreement and for a period of TWO years after the termination or expiration of this Agreement.</p>

        <p><strong>19. Confidential Information.</strong></p>
        <p>From time to time during the Term, either Party may disclose or make available to the other Party information about its business affairs, products, confidential intellectual property, trade secrets, third-party confidential information, and other sensitive or proprietary information, whether orally or in written, electronic, or other form or media, and whether or not marked, designated, or otherwise identified as "confidential" (collectively, "Confidential Information"). Confidential Information does not include information that, at the time of disclosure is:</p>
        <ul>
            <li><p>(a) in the public domain;</p></li>
            <li><p>(b) known to the receiving Party at the time of disclosure;</p></li>
            <li><p>(c) rightfully obtained by the receiving Party on a non-confidential basis from a third party; or</p></li>
            <li><p>(d) independently developed by the receiving Party.</p></li>
        </ul>

        <p>20. The receiving Party shall not disclose the disclosing Party's Confidential Information to any person or entity, except to the receiving Party's employees who have a need to know the Confidential Information for the receiving Party to exercise its rights or perform its obligations hereunder. Notwithstanding the foregoing, each Party may disclose Confidential Information to the limited extent required:</p>
        <ul>
            <li><p>(i) in order to comply with the order of a court or other governmental body, or as otherwise necessary to comply with applicable law, provided that the Party making the disclosure pursuant to the order shall first have given written notice to the other Party and made a reasonable effort to obtain a protective order; or</p></li>
            <li><p>(ii) to establish a Party's rights under this Agreement, including to make required court filings.</p></li>
        </ul>

        <p>21. On the expiration or termination of the Agreement, the receiving Party shall promptly return to the disclosing Party all copies, whether in written, electronic, or other form or media, of the disclosing Party's Confidential Information, or destroy all such copies and certify in writing to the disclosing Party that such Confidential Information has been destroyed. Each Party's obligations of non-disclosure with regard to Confidential Information are effective as of the Effective Date and will expire five years from the date first disclosed to the receiving Party; provided, however, with respect to any Confidential Information that constitutes a trade secret (as determined under applicable law), such obligations of non-disclosure will survive the termination or expiration of this Agreement for as long as such Confidential Information remains subject to trade secret protection under applicable law.</p>

        <p><strong>22. Intellectual Property Ownership.</strong></p>
        <p><strong>a) Provider IP.</strong> Customer acknowledges that, as between Customer and Provider, Provider owns all right, title, and interest, including all intellectual property rights, in and to the Provider IP; and, with respect to Third-Party Products, the applicable third-party providers own all right, title, and interest, including all intellectual property rights, in and to the Third-Party Products.</p>

        <p><strong>b) Customer Data.</strong> Customer acknowledges and agrees that, while Customer retains ownership of Customer Data, Provider has broad rights to use such data as set forth herein. Customer hereby grants to Provider a non-exclusive, a perpetual, irrevocable, transferable, sublicensable, worldwide, royalty-free license to collect, process, reproduce, distribute, modify, analyze, combine with other data, create derivative works from, and otherwise use and exploit Customer Data for any business purpose, including but not limited to:</p>
        <ul>
            <li><p>(i) providing, maintaining, and improving the Services;</p></li>
            <li><p>(ii) developing new products, features, and services;</p></li>
            <li><p>(iii) training and improving machine learning models and algorithms;</p></li>
            <li><p>(iv) conducting research and development;</p></li>
            <li><p>(v) creating and distributing industry insights and analysis;</p></li>
            <li><p>(vi) marketing and promotional activities;</p></li>
            <li><p>(vii) use and display Customer Data incorporated within the Aggregated Statistics; and</p></li>
            <li><p>(viii) any other legitimate business purpose as determined by Provider.</p></li>
        </ul>

        <p><strong>23. c) Feedback.</strong> If Customer or any of its employees or contractors sends or transmits any communications or materials to Provider by mail, email, telephone, or otherwise, suggesting or recommending changes to the Provider IP, including without limitation, new features or functionality relating thereto, or any comments, questions, suggestions, or the like ("Feedback"), Provider is free to use such Feedback irrespective of any other obligation or limitation between the Parties governing such Feedback. Customer hereby assigns to Provider on Customer's behalf, and on behalf of its employees, contractors, and/or agents, all right, title, and interest in, and Provider is free to use, without any attribution or compensation to any party, any ideas, know-how, concepts, techniques, or other intellectual property rights contained in the Feedback, for any purpose whatsoever, although Provider is not required to use any Feedback.</p>

        <p><strong>d) License Grant.</strong> Customer hereby grants to Provider a worldwide, non-exclusive, royalty-free license to use Customer's name, logo, trademarks, service marks, and other brand elements ("Customer Brand Assets") for the following purposes:</p>
        <p>Permitted Usage Contexts:</p>
        <ul>
            <li><p>(i) within the Zeitro system and platform interface;</p></li>
            <li><p>(ii) in Provider's rate pricing system and comparison tools;</p></li>
            <li><p>(iii) on Provider's public-facing website and digital properties;</p></li>
            <li><p>(iv) in marketing and promotional materials;</p></li>
            <li><p>(v) in sales presentations and pitch decks;</p></li>
            <li><p>(vi) in customer testimonials and case studies;</p></li>
            <li><p>(vii) on public-facing customer lists and partnership announcements;</p></li>
            <li><p>(viii) in annual reports and investor presentations;</p></li>
            <li><p>(ix) at industry events and conferences;</p></li>
            <li><p>(x) in press releases and media communications.</p></li>
        </ul>

        <p><strong>24. Scope of Usage Rights.</strong> Provider may:</p>
        <ul>
            <li><p>(i) display Customer Brand Assets to indicate Customer's use of the Services;</p></li>
            <li><p>(ii) include Customer in public-facing customer lists and testimonials;</p></li>
            <li><p>(iii) reference Customer as a client in business development activities;</p></li>
            <li><p>(iv) use Customer Brand Assets in marketing and promotional campaigns;</p></li>
            <li><p>(v) include Customer in market positioning and competitive analysis;</p></li>
            <li><p>(vi) feature Customer in growth strategy presentations;</p></li>
            <li><p>(vii) display Customer Brand Assets in product demonstrations.</p></li>
        </ul>

        <p><strong>25. Usage Standards.</strong> Provider shall:</p>
        <ul>
            <li><p>(i) use Customer Brand Assets in accordance with any brand guidelines provided by Customer;</p></li>
            <li><p>(ii) maintain the integrity and quality standards of Customer Brand Assets;</p></li>
            <li><p>(iii) not modify or alter Customer Brand Assets without prior written consent;</p></li>
            <li><p>(iv) not use Customer Brand Assets in a manner that could damage Customer's reputation.</p></li>
        </ul>

        <p><strong>26. Survival.</strong> The rights granted under this section shall survive the termination or expiration of this Agreement with respect to materials created or distributed prior to such termination or expiration.</p>

        <p><strong>27. Customization Requests and Modifications.</strong></p>
        <p><strong>a) Customization Requests.</strong> Customer may submit requests for customizations to the Services ("Customization Requests"), which may include:</p>
        <ul>
            <li><p>(i) interface modifications;</p></li>
            <li><p>(ii) feature enhancements;</p></li>
            <li><p>(iii) workflow adjustments;</p></li>
            <li><p>(iv) integration requirements;</p></li>
            <li><p>(v) reporting modifications;</p></li>
            <li><p>(vi) data presentation changes.</p></li>
        </ul>

        <p><strong>28. b) Provider Response.</strong> Provider shall:</p>
        <ul>
            <li><p>(i) review all Customization Requests in good faith;</p></li>
            <li><p>(ii) make commercially reasonable efforts to accommodate Customization Requests;</p></li>
            <li><p>(iii) provide Customer with an estimated timeline and cost, if applicable;</p></li>
            <li><p>(iv) maintain sole discretion in determining feasibility and implementation priority.</p></li>
        </ul>

        <p><strong>29. c) Limitations and Exclusions.</strong> Provider explicitly reserves the right to:</p>
        <ul>
            <li><p>(i) decline any Customization Request;</p></li>
            <li><p>(ii) modify the scope of requested customizations;</p></li>
            <li><p>(iii) implement customizations in a manner different from that requested;</p></li>
            <li><p>(iv) incorporate customizations into the general product offering;</p></li>
            <li><p>(v) determine the priority and timeline of implementation.</p></li>
        </ul>

        <p><strong>30. d) Payment Terms.</strong> Customer acknowledges and agrees that:</p>
        <ul>
            <li><p>(i) payment obligations for the Services are independent of and not contingent upon:</p>
                <ul>
                    <li><p>(A) implementation of any Customization Request,</p></li>
                    <li><p>(B) timeline of customization delivery,</p></li>
                    <li><p>(C) specific manner of customization implementation;</p></li>
                </ul>
            </li>
            <li><p>(ii) all Fees remain due and payable regardless of customization status;</p></li>
            <li><p>(iii) additional fees may apply for approved customizations;</p></li>
            <li><p>(iv) custom development work may require separate agreements.</p></li>
        </ul>

        <p><strong>31. e) Intellectual Property.</strong> All customizations, modifications, and enhancements to the Services:</p>
        <ul>
            <li><p>(i) remain the exclusive property of Provider;</p></li>
            <li><p>(ii) may be implemented for other customers;</p></li>
            <li><p>(iii) may be incorporated into Provider's standard offering;</p></li>
            <li><p>(iv) do not create any ownership or intellectual property rights for Customer.</p></li>
        </ul>

        <p><strong>32. f) Support and Maintenance.</strong> Provider shall:</p>
        <ul>
            <li><p>(i) maintain implemented customizations as part of the Services;</p></li>
            <li><p>(ii) reserve the right to modify or remove customizations with notice;</p></li>
            <li><p>(iii) not be obligated to maintain customizations that conflict with core product development.</p></li>
        </ul>

        <p><strong>33. Warranty Disclaimer.</strong></p>
        <p>THE PROVIDER IP IS PROVIDED "AS IS" AND PROVIDER MAKES NO WARRANTY OF ANY KIND THAT THE PROVIDER IP, OR ANY PRODUCTS OR RESULTS OF THE USE THEREOF, WILL MEET CUSTOMER'S OR ANY OTHER PERSON'S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM, OR OTHER SOFTWARE SERVICES, OR BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE, OR ERROR FREE.</p>

        <p><strong>34. Indemnification.</strong></p>
        <p><strong>a) Provider Indemnification.</strong></p>
        <ul>
            <li><p>Provider shall indemnify, defend, and hold harmless Customer from and against any losses, damages, liabilities ("Losses") incurred by Customer resulting from any third-party claim, suit, action, or proceeding ("Third-Party Claim") that the Software Services, or any use of the Software Services in accordance with this Agreement, infringes or misappropriation such third party's intellectual property rights, provided that Customer promptly notifies Provider in writing of the claim, cooperates with Provider, and allows Provider sole authority to control the defense and settlement of such claim against Customer.</p></li>
            <li><p>If such a claim is made or appears possible, Customer agrees to permit Provider, at Provider's sole discretion, to:</p>
                <ul>
                    <li><p>(A) modify or replace the Software Services, or component or part thereof, to make it non-infringing, or</p></li>
                    <li><p>(B) obtain the right for Customer to continue use.</p></li>
                </ul>
            </li>
            <li><p>If Provider determines that neither alternative is reasonably available, Provider may terminate this Agreement, in its entirety or with respect to the affected component or part, effective immediately on written notice to Customer.</p></li>
        </ul>

        <p><strong>35. b) Customer Indemnification.</strong> Customer shall indemnify, hold harmless, and, at Provider's option, defend Provider from and against any Losses resulting from any Third-Party Claim that the Customer Data, or any use of the Customer Data in accordance with this Agreement, infringes or misappropriates such third party's intellectual property rights and any Third-Party Claims based on Customer's or any Authorized User's:</p>
        <ul>
            <li><p>(i) negligence or willful misconduct;</p></li>
            <li><p>(ii) use of the Software Services in a manner not authorized by this Agreement;</p></li>
            <li><p>(iii) use of the Software Services in combination with data, software, hardware, equipment, or technology not provided by Provider or authorized by Provider in writing; or</p></li>
            <li><p>(iv) modifications to the Software Services not made by Provider, provided that Customer may not settle any Third-Party Claim against Provider unless Provider consents to such settlement, and further provided that Provider will have the right, at its option, to defend itself against any such Third-Party Claim or to participate in the defense thereof by counsel of its own choice.</p></li>
        </ul>

        <p><strong>36. c) Sole Remedy.</strong> THIS SECTION 10 SETS FORTH CUSTOMER'S SOLE REMEDIES AND PROVIDER'S SOLE LIABILITY AND OBLIGATION FOR ANY ACTUAL, THREATENED, OR ALLEGED CLAIMS THAT THE SOFTWARE SERVICES INFRINGE, MISAPPROPRIATE, OR OTHERWISE VIOLATE ANY INTELLECTUAL PROPERTY RIGHTS OF ANY THIRD PARTY. IN NO EVENT WILL PROVIDER'S LIABILITY UNDER THIS SECTION 10 EXCEED THE AMOUNT PAID BY CUSTOMER HEREUNDER IN THE 12 MONTHS PRECEDING THE INCIDENT OR SERIES OF RELATED INCIDENTS, PROVIDED THAT IN NO EVENT WILL CUSTOMER'S AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT EXCEED THE TOTAL FEES PAID IN THIS AGREEMENT.</p>

        <p><strong>37. Limitations of Liability.</strong></p>
        <p>IN NO EVENT WILL PROVIDER BE LIABLE UNDER OR IN CONNECTION WITH THIS AGREEMENT UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE, FOR ANY:</p>
        <ul>
            <li><p>(a) CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED, OR PUNITIVE DAMAGES;</p></li>
            <li><p>(b) INCREASED COSTS, DIMINUTION IN VALUE OR LOST BUSINESS, PRODUCTION, REVENUES, OR PROFITS;</p></li>
            <li><p>(c) LOSS OF GOODWILL OR REPUTATION;</p></li>
            <li><p>(d) USE, INABILITY TO USE, LOSS, INTERRUPTION, DELAY, OR RECOVERY OF ANY DATA, OR BREACH OF DATA OR SYSTEM SECURITY; OR</p></li>
            <li><p>(e) COST OF REPLACEMENT GOODS OR SOFTWARE SERVICES, IN EACH CASE REGARDLESS OF WHETHER PROVIDER WAS ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE. IN NO EVENT WILL PROVIDER'S AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE EXCEED ONE TIME THE TOTAL AMOUNTS PAID TO PROVIDER UNDER THIS AGREEMENT.</p></li>
        </ul>

        <p><strong>38. Term and Termination.</strong></p>
        <p><strong>a) Term.</strong> The initial term of this Agreement begins on the Effective Date and, unless terminated earlier pursuant to this Agreement's express provisions, will continue in effect until ONE year from such date (the "Initial Term"). This Agreement will automatically renew for up to TWO additional successive one-year terms unless earlier terminated pursuant to this Agreement's express provisions or either Party gives the other Party written notice of non-renewal at least 60 days prior to the expiration of the then-current term (each a "Renewal Term" and together with the Initial Term, the "Term").</p>

        <p>Usage Rights: Customer's right to access and use the Services continues as long as:</p>
        <ul>
            <li><p>(i) Customer remains current on all payment obligations;</p></li>
            <li><p>(ii) Customer remains in compliance with this Agreement;</p></li>
            <li><p>(iii) the Agreement has not been terminated.</p></li>
        </ul>

        <p>39. Usage rights are contingent upon timely payment of Fees. Provider may suspend access rights for non-payment per Section 5(c).</p>

        <p>Post-Termination: Upon termination, Customer shall immediately cease use of the Services. Customer shall pay all outstanding Fees accrued through the effective date of termination. This Section 12(a) and Sections 1, 5, 6, 7, 8, 9, 10, and 13 survive any termination or expiration of this Agreement.</p>

        <p><strong>b) Termination.</strong> In addition to any other express termination right set forth in this Agreement:</p>
        <ul>
            <li><p>Provider may terminate this Agreement, effective on a written notice to Customer, if Customer:</p>
                <ul>
                    <li><p>(A) fails to pay any amount when due hereunder, and such failure continues more than 30 days after Provider's delivery of written notice thereof; or</p></li>
                    <li><p>(B) breaches any of its obligations under Section 2(c) or Section 6;</p></li>
                </ul>
            </li>
            <li><p>Either Party may terminate this Agreement, effective on written notice to the other Party, if the other Party materially breaches this Agreement, and such breach:</p>
                <ul>
                    <li><p>(A) is incapable of cure; or</p></li>
                    <li><p>(B) being capable of cure, remains uncured 30 days after the non-breaching Party provides the breaching Party with written notice of such breach; or</p></li>
                </ul>
            </li>
            <li><p>Either Party may terminate this Agreement, effective immediately upon written notice to the other Party, if the other Party:</p>
                <ul>
                    <li><p>(A) becomes insolvent or is generally unable to pay, or fails to pay, its debts as they become due;</p></li>
                    <li><p>(B) files or has filed against it, a petition for voluntary or involuntary bankruptcy or otherwise becomes subject, voluntarily or involuntarily, to any proceeding under any domestic or foreign bankruptcy or insolvency law;</p></li>
                    <li><p>(C) makes or seeks to make a general assignment for the benefit of its creditors; or</p></li>
                    <li><p>(D) applies for or has appointed a receiver, trustee, custodian, or similar agent appointed by order of any court of competent jurisdiction to take charge of or sell any material portion of its property or business.</p></li>
                </ul>
            </li>
        </ul>

        <p><strong>40. c) Effect of Expiration or Termination.</strong> Upon expiration or earlier termination of this Agreement, Customer shall immediately discontinue use of the Provider IP and, without limiting Customer's obligations under Section 6, Customer shall delete, destroy, or return all copies of the Provider IP and certify in writing to the Provider that the Provider IP has been deleted or destroyed. No expiration or termination will affect Customer's obligation to pay all Fees that may have become due before such expiration or termination or entitle Customer to any refund.</p>

        <p><strong>d) Survival.</strong> This Section 12(d) and Sections 1, 5, 6, 7, 8, 9, 10, and 13 survive any termination or expiration of this Agreement.</p>

        <p><strong>41. Miscellaneous.</strong></p>
        <p><strong>a) Entire Agreement.</strong> This Agreement, together with any other documents incorporated herein by reference and all related Exhibits, constitutes the sole and entire agreement of the Parties with respect to the subject matter of this Agreement and supersedes all prior and contemporaneous understandings, agreements, and representations and warranties, both written and oral, with respect to such subject matter. In the event of any inconsistency between the statements made in the body of this Agreement, the related Exhibits, and any other documents incorporated herein by reference, the following order of precedence governs:</p>
        <ul>
            <li><p>(i) first, this Agreement, excluding its Exhibits;</p></li>
            <li><p>(ii) second, the Exhibits to this Agreement as of the Effective Date; and</p></li>
            <li><p>(iii) third, any other documents incorporated herein by reference.</p></li>
        </ul>

        <p><strong>42. b) Notices.</strong> All notices, requests, consents, claims, demands, waivers, and other communications hereunder (each, a "Notice") must be in writing and addressed to Provider at its principal place of business and to Customer at the contact information provided by Customer during the sign-up process or as updated by Customer from time to time. All Notices must be delivered by personal delivery, nationally recognized overnight courier (with all fees pre-paid), or email (with confirmation of transmission), or certified or registered mail (in each case, return receipt requested, postage prepaid). Except as otherwise provided in this Agreement, a Notice is effective only:</p>
        <ul>
            <li><p>(i) upon receipt by the receiving Party; and</p></li>
            <li><p>(ii) if the Party giving the Notice has complied with the requirements of this Section.</p></li>
        </ul>

        <p><strong>43. c) Force Majeure.</strong> In no event shall Provider be liable to Customer, or be deemed to have breached this Agreement, for any failure or delay in performing its obligations under this Agreement (except for any obligations to make payments), if and to the extent such failure or delay is caused by any circumstances beyond Provider's reasonable control, including but not limited to acts of God, flood, fire, earthquake, OTHER POTENTIAL DISASTER(S) OR CATASTROPHE(S), SUCH AS EPIDEMICS, explosion, war, terrorism, invasion, riot or other civil unrest, strikes, lockouts, power outages, power grid failures, internet outages, government-mandated business closures, labor stoppages or slowdowns or other industrial disturbances, or passage of law or any action taken by a governmental or public authority, including imposing an embargo.</p>

        <p><strong>d) Amendment and Modification; Waiver.</strong> No amendment to or modification of this Agreement is effective unless it is in writing and signed by an authorized representative of each Party. No waiver by any Party of any of the provisions hereof will be effective unless explicitly set forth in writing and signed by the Party so waiving. Except as otherwise set forth in this Agreement:</p>
        <ul>
            <li><p>(i) no failure to exercise, or delay in exercising, any rights, remedy, power, or privilege arising from this Agreement will operate or be construed as a waiver thereof; and</p></li>
            <li><p>(ii) no single or partial exercise of any right, remedy, power, or privilege hereunder will preclude any other or further exercise thereof or the exercise of any other right, remedy, power, or privilege.</p></li>
        </ul>

        <p><strong>44. e) Severability.</strong> If any provision of this Agreement is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability will not affect any other term or provision of this Agreement or invalidate or render unenforceable such term or provision in any other jurisdiction. Upon such determination that any term or other provision is invalid, illegal, or unenforceable, the Parties shall negotiate in good faith to modify this Agreement so as to affect their original intent as closely as possible in a mutually acceptable manner in order that the transactions contemplated hereby be consummated as originally contemplated to the greatest extent possible.</p>

        <p><strong>f) Governing Law; Submission to Jurisdiction.</strong> This Agreement is governed by and construed in accordance with the internal laws of the State of California without giving effect to any choice or conflict of law provision or rule that would require or permit the application of the laws of any jurisdiction other than those of the State of California. Any legal suit, action, or proceeding arising out of or related to this Agreement or the licenses granted hereunder may be instituted in the federal courts of the United States or the state courts of the State of California in each case located in Santa Clara County, and each Party irrevocably submits to the jurisdiction of such courts in any such suit, action, or proceeding.</p>

        <p><strong>g) Assignment.</strong> Customer may not assign any of its rights or delegate any of its obligations hereunder, in each case whether voluntarily, involuntarily, by operation of law or otherwise, without the prior written consent of Provider. Any purported assignment or delegation in violation of this Section will be null and void. No assignment or delegation will relieve the assigning or delegating Party of any of its obligations hereunder. This Agreement is binding upon and inure to the benefit of the Parties and their respective permitted successors and assigns.</p>

        <p><strong>h) Export Regulation.</strong> Customers shall comply with all applicable federal laws, regulations, and rules, and complete all required undertakings (including obtaining any necessary export license or other governmental approval), that prohibit or restrict the export or re-export of the Software Services or any Customer Data outside the US.</p>

        <p><strong>i) Equitable Relief.</strong> Each Party acknowledges and agrees that a breach or threatened breach by such Party of any of its obligations under Section 6 or, in the case of Customer, Section 2(c), would cause the other Party irreparable harm for which monetary damages would not be an adequate remedy and agrees that, in the event of such breach or threatened breach, the other Party will be entitled to equitable relief, including a restraining order, an injunction, specific performance, and any other relief that may be available from any court, without any requirement to post a bond or other security, or to prove actual damages or that monetary damages are not an adequate remedy. Such remedies are not exclusive and are in addition to all other remedies that may be available at law, in equity, or otherwise.</p>

        <p><strong>j) Agreement Acceptance and Electronic Consent.</strong></p>
        <p><strong>Click-Through Acceptance.</strong> By clicking "I Agree," checking the acceptance box, accessing, or using any part of the Services, Customer:</p>
        <ul>
            <li><p>(i) expressly acknowledges having read this entire Agreement;</p></li>
            <li><p>(ii) represents having the authority to bind Customer to this Agreement;</p></li>
            <li><p>(iii) unconditionally accepts and agrees to all terms and conditions;</p></li>
            <li><p>(iv) consents to be legally bound by all terms of this Agreement;</p></li>
            <li><p>(v) waives any defense based on lack of written signature.</p></li>
        </ul>

        <p><strong>45. Ongoing Acceptance.</strong> Customer acknowledges and agrees that:</p>
        <ul>
            <li><p>(i) continued use of the Services constitutes ongoing acceptance of this Agreement;</p></li>
            <li><p>(ii) each use of the Services reaffirms Customer's acceptance;</p></li>
            <li><p>(iii) all terms remain binding regardless of method of acceptance;</p></li>
            <li><p>(iv) all authorized users are bound by these terms.</p></li>
        </ul>

        <p><strong>46. Agreement Updates and Modifications.</strong> Provider reserves the right to:</p>
        <ul>
            <li><p>(i) update or modify this Agreement at any time;</p></li>
            <li><p>(ii) require acceptance of updated terms for continued Service access;</p></li>
            <li><p>(iii) implement changes upon notice as specified herein.</p></li>
        </ul>

        <p>47. Customer acknowledges and agrees that:</p>
        <ul>
            <li><p>(iv) continued use after updates constitutes acceptance of modified terms;</p></li>
            <li><p>(v) regular review of terms is Customer's responsibility;</p></li>
            <li><p>(vi) Provider may require explicit click-through acceptance of updates;</p></li>
            <li><p>(vii) failure to accept updated terms may result in Service suspension.</p></li>
        </ul>

        <p><strong>48. Electronic Agreement.</strong> Customer expressly agrees that:</p>
        <ul>
            <li><p>(i) this Agreement may be accepted electronically;</p></li>
            <li><p>(ii) click-through acceptance has the same legal effect as a written signature;</p></li>
            <li><p>(iii) electronic acceptance is valid and enforceable;</p></li>
            <li><p>(iv) no physical or digital signature beyond click-through is required.</p></li>
        </ul>

        <p><strong>49. Acceptance Language.</strong> The following language shall appear at all click-through acceptance points:</p>
        <p><em>"By clicking 'I Agree' or by accessing or using any part of Zeitro's services, you acknowledge that you have read, understand, and agree to be bound by all terms and conditions of the Zeitro Service Agreement. You represent that you have the authority to bind your organization to these terms. Your acceptance and continued use of Zeitro services constitutes ongoing agreement to all terms."</em></p>

        <p>Evidence of Acceptance. Provider shall maintain records of:</p>
        <ul>
            <li><p>(i) date and time of acceptance;</p></li>
            <li><p>(ii) method of acceptance;</p></li>
            <li><p>(iii) user credentials associated with acceptance;</p></li>
            <li><p>(iv) version of Agreement accepted.</p></li>
        </ul>

        <p><strong>50. k) Counterparts.</strong> This Agreement may be executed in counterparts, each of which is deemed an original, but all of which together are deemed to be one and the same agreement.</p>

        <p><strong>51. Regulatory Compliance</strong></p>
        <p><strong>a) Regulatory Compliance Obligations.</strong> Customer acknowledges and accepts sole responsibility for:</p>
        <ul>
            <li><p>(i) compliance with all applicable federal mortgage regulations, including but not limited to:</p>
                <ul>
                    <li><p>(A) Real Estate Settlement Procedures Act (RESPA),</p></li>
                    <li><p>(B) Truth in Lending Act (TILA),</p></li>
                    <li><p>(C) TILA-RESPA Integrated Disclosure (TRID) requirements,</p></li>
                    <li><p>(D) Home Mortgage Disclosure Act (HMDA),</p></li>
                    <li><p>(E) Equal Credit Opportunity Act (ECOA),</p></li>
                    <li><p>(F) Fair Housing Act (FHA),</p></li>
                    <li><p>(G) Secure and Fair Enforcement for Mortgage Licensing Act (SAFE Act),</p></li>
                    <li><p>(H) Consumer Financial Protection Bureau (CFPB) regulations;</p></li>
                </ul>
            </li>
            <li><p>(ii) compliance with all state-specific mortgage regulations;</p></li>
            <li><p>(iii) any additional regulatory requirements that may be enacted during the Term.</p></li>
        </ul>

        <p><strong>52. b) Record Retention and Documentation.</strong> Customer shall:</p>
        <ul>
            <li><p>(i) maintain all required mortgage documentation for prescribed retention periods;</p></li>
            <li><p>(ii) ensure compliance with federal and state record retention requirements;</p></li>
            <li><p>(iii) implement appropriate document management systems;</p></li>
            <li><p>(iv) maintain audit trails of all regulatory disclosures;</p></li>
            <li><p>(v) preserve all required supporting documentation.</p></li>
        </ul>

        <p><strong>53. c) Regulatory Disclosures.</strong> Customer is solely responsible for:</p>
        <ul>
            <li><p>(i) providing all required disclosures to consumers;</p></li>
            <li><p>(ii) ensuring timing and delivery of disclosures meets regulatory requirements;</p></li>
            <li><p>(iii) maintaining evidence of disclosure delivery;</p></li>
            <li><p>(iv) updating disclosures as required by regulatory changes;</p></li>
            <li><p>(v) compliance with electronic disclosure requirements.</p></li>
        </ul>

        <p><strong>54. d) Compliance Verification.</strong> Customer shall:</p>
        <ul>
            <li><p>(i) maintain internal compliance monitoring systems;</p></li>
            <li><p>(ii) conduct regular compliance audits;</p></li>
            <li><p>(iii) provide compliance certifications upon Provider's request;</p></li>
            <li><p>(iv) document compliance procedures and controls;</p></li>
            <li><p>(v) maintain records of compliance training and oversight.</p></li>
        </ul>

        <p><strong>55. e) Provider's Role and Limitations.</strong> Customer expressly acknowledges that:</p>
        <ul>
            <li><p>(i) Provider's services are tools to assist Customer's operations;</p></li>
            <li><p>(ii) Provider makes no representations regarding regulatory compliance;</p></li>
            <li><p>(iii) use of services does not guarantee regulatory compliance;</p></li>
            <li><p>(iv) Provider is not responsible for Customer's regulatory compliance;</p></li>
            <li><p>(v) Provider has no duty to monitor Customer's compliance.</p></li>
        </ul>

        <p><strong>56. f) Regulatory Updates.</strong> Customer shall:</p>
        <ul>
            <li><p>(i) monitor regulatory changes affecting their business;</p></li>
            <li><p>(ii) adapt practices to comply with new regulations;</p></li>
            <li><p>(iii) bear all costs associated with regulatory compliance;</p></li>
            <li><p>(iv) implement required changes regardless of service capabilities.</p></li>
        </ul>

        <p><strong>57. g) Indemnification.</strong> Customer shall indemnify and hold Provider harmless from:</p>
        <ul>
            <li><p>(i) any regulatory compliance violations;</p></li>
            <li><p>(ii) claims arising from non-compliance;</p></li>
            <li><p>(iii) regulatory enforcement actions;</p></li>
            <li><p>(iv) costs of defending against compliance-related claims;</p></li>
            <li><p>(v) penalties or fines imposed by regulatory authorities.</p></li>
        </ul>

        <p><strong>58. h) Breach and Termination.</strong> Material breach includes:</p>
        <ul>
            <li><p>(i) significant regulatory violations;</p></li>
            <li><p>(ii) loss of required licenses or approvals;</p></li>
            <li><p>(iii) failure to maintain compliance systems;</p></li>
            <li><p>(iv) misrepresentation of compliance status.</p></li>
        </ul>

        <p><strong>59. Zeitro Marketplace Terms</strong></p>
        <p><strong>a) Marketplace Participation.</strong> Provider may, at its sole discretion, list Customer as a participating provider in the Zeitro Marketplace ("Marketplace"). Listing in the Marketplace is not guaranteed, and Provider reserves the right to determine which Customers are included, excluded, or ranked. Customer acknowledges that participation in the Marketplace is subject to the terms and conditions set forth in this Agreement.</p>

        <p><strong>b) Background Checks and Eligibility.</strong> By agreeing to these Terms and creating an account on the Zeitro Marketplace, Customers explicitly authorize Zeitro to conduct background checks through third-party providers (including but not limited to LexisNexis, Clear, or similar verification services) as part of the user verification process. Such background checks may include, but are not limited to, verification of identity, criminal history, credit history, and other relevant background information. Zeitro maintains sole discretion to:</p>
        <ul>
            <li><p>(i) determine the scope and extent of background checks conducted;</p></li>
            <li><p>(ii) evaluate the results of any background check;</p></li>
            <li><p>(iii) make final determinations regarding marketplace eligibility based on background check results;</p></li>
            <li><p>(iv) deny access to the marketplace or terminate existing marketplace privileges based on background check findings.</p></li>
        </ul>

        <p>60. Customer acknowledges that their continued access to and participation in the Zeitro Marketplace is contingent upon satisfactory background check results, as determined by Zeitro in its sole discretion.</p>

        <p><strong>c) Listing and Ranking Criteria.</strong> Provider shall have sole discretion in determining:</p>
        <ul>
            <li><p>(i) eligibility for Marketplace listing;</p></li>
            <li><p>(ii) inclusion, exclusion, and ranking of listed Customers;</p></li>
            <li><p>(iii) criteria and methodology used for Marketplace rankings.</p></li>
        </ul>

        <p>61. Ranking factors may include:</p>
        <ul>
            <li><p>(A) Customer's Zeitro service usage level;</p></li>
            <li><p>(B) Customer's user ratings and reviews;</p></li>
            <li><p>(C) Customer's regulatory compliance and standing;</p></li>
            <li><p>(D) Customer's performance metrics and service quality;</p></li>
            <li><p>(E) Other criteria determined by Provider.</p></li>
        </ul>

        <p><strong>62. d) Fraudulent or Misrepresentative Listings.</strong> Provider reserves the right to remove any Customer from the Marketplace for:</p>
        <ul>
            <li><p>(i) fraudulent or misleading listing information;</p></li>
            <li><p>(ii) inaccurate representation of rates, fees, or loan officer compensation;</p></li>
            <li><p>(iii) any other form of cheating, false advertisement, or bait-and-switch tactics.</p></li>
        </ul>

        <p>63. Customer shall be solely liable for any misrepresentation or misconduct, and Provider shall not be held responsible.</p>

        <p><strong>e) Lead Distribution.</strong> Leads (applicants/borrowers) received through the Marketplace are non-exclusive and may be referred to multiple Customers. Provider makes no guarantee or representation regarding the quality, quantity, or exclusivity of leads.</p>

        <p><strong>f) Marketplace Fees.</strong> Provider reserves the right to charge a fee for Marketplace listing at any time. Customers shall be notified of any fee changes with at least 30 days' advance notice.</p>

        <p><strong>g) Customer Obligations.</strong> Customers listed in the Marketplace shall:</p>
        <ul>
            <li><p>(i) act in good faith towards consumers;</p></li>
            <li><p>(ii) represent rates, fees, and loan officer compensation accurately;</p></li>
            <li><p>(iii) refrain from any form of cheating, false advertisement, or bait-and-switch tactics.</p></li>
        </ul>

        <p>64. Customers shall be solely responsible and liable for any violations of these obligations.</p>

        <p><strong>h) Suspension and Removal.</strong> Provider reserves the right to suspend or remove any Customer from the Marketplace at any time, with or without cause. Removal from the Marketplace does not terminate the underlying Agreement, but Customer's Marketplace-related rights will cease.</p>

    </div>

  );
};

export default Terms;