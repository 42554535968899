import React, { useState, useEffect, useRef } from 'react'
import { useImmer } from "use-immer"
import { Form, Input, Button, Col, Row, Modal, Table, Tag, Select, Radio, Checkbox, Popover, Typography } from 'antd'
import './index.css'
import { SearchOutlined, CheckOutlined, FormOutlined } from '@ant-design/icons'
import "bootstrap/dist/css/bootstrap.min.css"
import { capitalize, divideStringByCapitalLetter, getLoInfo, getUserId, splitByCapitalLetter } from '../../Common'
import { checkAccess } from '../../Auth'
import { UR_Owner } from '../../State'
import { useSelector, useDispatch } from 'react-redux'
import { is } from 'date-fns/locale'
const { Paragraph } = Typography
function SendSuccessComponent(props) {
    const { closeModal } = props

    return (

        <div style={{ textAlign: "center" }}>
            <div>
                <img src='/images/checkEmail2.png'></img>
            </div>
            <div style={{ marginTop: "20px", fontSize: '36px', fontWeight: 600, color: "#222222" }}>
                Invitation sent successfully

            </div>
            <div style={{ marginTop: "20px", fontSize: '16px', color: "#6E6E70" }}>
                We will let you know once the user join you on Zeitro.

            </div>
            <div style={{ marginTop: "50px" }}>

                <Button style={{ height: '44px', width: "200px" }} onClick={() => {
                    closeModal(1)



                }}>Done</Button>
                <Button onClick={() => {
                    closeModal(2)



                }} type='primary' style={{ marginLeft: "20px", height: '44px', minWidth: "200px" }}>Invite next user</Button>
            </div>

        </div>

    )
}



export default function UserManagement(props) {
    const { showUser, toLoans } = props

    const [form] = Form.useForm()
    const [support] = Form.useForm()
    const [dataSource, setDataSource] = useImmer([])
    const [isModalOpen, setIsModalOpen] = useImmer(false)
    const [addLoading, setAddLoading] = useImmer(false)
    const [isDisableModalOpen, setIsDisableModalOpen] = useImmer(false)
    const [disablledLoading, setDisablledLoading] = useImmer(false)
    const [isTooltipModalOpen, setIsTooltipModalOpen] = useImmer(false)
    const [currentUser, setCurrentUser] = useImmer({})
    const [loading, setLoading] = useImmer(false)
    const [searchedText, setSearchedText] = useImmer('')
    const [showRoleList, setShowRoleList] = useImmer(false)
    const [inviteOrEdit, setInviteOrEdit] = useImmer(true)
    const [isSameNMLS, setIsSameNMLS] = useImmer(false)
    const [isSameEmail, setIsSameEmail] = useImmer(false)
    const [sendSuccess, setSendSuccess] = useImmer(false)
    const [isConfirm, setSsConfirm] = useImmer(false)
    const [currentId, setCurrentId] = useImmer('')
    const [currentData, setCurrentData] = useImmer({})
    const [currentInfo, setCurrentInfo] = useState({})
    const [isSupportModalOpen, setSupportModalOpen] = useState(false)
    const [isUpgradeLOSuccessModalOpen, setIsUpgradeLOSuccessModalOpen] = useState(false)
    const [messageReceivedModalOpen, setMessageReceivedModalOpen] = useState(false)
    const [open, setOpen] = useState(false)
    const tableRef = useRef(null)
    const titleRef = useRef(null)
    const { TextArea } = Input
    const subscription = useSelector(state => {

        return state.subscriptionReducer.currentInfo
    })
    const dispatch = useDispatch()
    const { first } = useSelector(state => {


        return state.manager
    })
    useEffect(async () => {
        setIsModalOpen(showUser)
        getList()
        // setLoading(true)

        // let token = sessionStorage.getItem("ZeitroA")

        // const response = await fetch('/los/getcurrentsubscription', {
        //     method: 'get',
        //     headers: {
        //         Authorization: "Bearer " + token,
        //         Cache: "no-cache"
        //     }
        // })
        const { customerID, loanOfficers, plan, stripeCustomer, recurringInterval } = subscription

        setCurrentInfo({
            customerID,
            loanOfficers,
            plan,
            stripeCustomer,
            recurringInterval
        })
        // setCurrentInfo({
        //     ...currentInfo,
        //     plan: {

        //     }
        // })
        // setLoading(false)

    }, [])

    const RoleDataSource = [
        {
            title: (<div className='UserSource'>Assign loans</div>),
            Executive: 'Assign loans to LOs & Processors',
            Admin: "Assign loans to LOs & Processors ",
            Manager: "Assign loans to LOs & Processors",
            Loan: 'Assign loans to Processors',
            Processor: "-"

        },
        {
            title: (<div className='UserSource'>View and edit loans</div>),
            Executive: 'Full access to all loans in company',
            Admin: "Full access to all loans in company",
            Manager: "Limited access* to all loans in company",
            Loan: 'Access to loans assigned to the user',
            Processor: "Access to loans assigned to the user"

        },
        {
            title: (<div className='UserSource'> Create and view borrowers</div>),
            Executive: 'Create and view all borrowers in company',
            Admin: "Create and view all borrowers in company",
            Manager: "Create and view all borrowers in company",
            Loan: `Create and view the user's borrowers`,
            Processor: "View the user's borrowers"

        },
        {
            title: (<div className='UserSource'>Use Guideline GPT</div>),
            Executive: true,
            Admin: true,
            Manager: true,
            Loan: true,
            Processor: true

        },
        {
            title: (<div className='UserSource'>Update my site</div>),
            Executive: true,
            Admin: true,
            Manager: true,
            Loan: true,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Use pricing engine</div>),
            Executive: true,
            Admin: true,
            Manager: true,
            Loan: true,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Set up personal landing page</div>),
            Executive: true,
            Admin: true,
            Manager: true,
            Loan: true,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Turn on/off automatic prequal letter generation for borrower application</div>),
            Executive: true,
            Admin: true,
            Manager: true,
            Loan: true,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Turn on/off soft credit pull feature for borrower application</div>),
            Executive: true,
            Admin: true,
            Manager: true,
            Loan: true,
            Processor: false

        },
        {
            title: (<div className='UserSource'>View own usage of features with usage based billing</div>),
            Executive: true,
            Admin: true,
            Manager: true,
            Loan: true,
            Processor: false

        },
        {
            title: (<div className='UserSource'>View all users' usage of features with usage based billing</div>),
            Executive: true,
            Admin: true,
            Manager: true,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>View employee accounts with partial information (name, NMLS, status)</div>),
            Executive: true,
            Admin: true,
            Manager: true,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>View all employee accounts and related account info</div>),
            Executive: true,
            Admin: true,
            Manager: false,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>View and update the lender setup</div>),
            Executive: true,
            Admin: true,
            Manager: false,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Create and view call reports</div>),
            Executive: true,
            Admin: true,
            Manager: false,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>View and update communication set-up</div>),
            Executive: true,
            Admin: true,
            Manager: false,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Set up credit report</div>),
            Executive: true,
            Admin: true,
            Manager: false,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Set up public site integration</div>),
            Executive: true,
            Admin: true,
            Manager: false,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Adjust company customization settings</div>),
            Executive: true,
            Admin: true,
            Manager: false,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Create/disable employee accounts</div>),
            Executive: true,
            Admin: true,
            Manager: false,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Manage employee's access permissions (excluding creating Admin roles)</div>),
            Executive: true,
            Admin: true,
            Manager: false,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>View and manage disabled accounts</div>),
            Executive: true,
            Admin: true,
            Manager: false,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Creating General Admin/Executive Admin</div>),
            Executive: true,
            Admin: false,
            Manager: false,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Update subscription payment methods</div>),
            Executive: true,
            Admin: false,
            Manager: false,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Cancel all Zeitro accounts</div>),
            Executive: true,
            Admin: false,
            Manager: false,
            Loan: false,
            Processor: false

        },
    ]
    const RoleColumns = [
        {
            title: '',
            dataIndex: 'title',
            width: 300,
            ellipsis: true,
            key: 'title',
        },
        {
            title: <div className='tableHeader_one'>Executive Admin</div>,
            dataIndex: 'Executive',
            align: 'center',
            key: 'Executive',
            render: (text) => <>{typeof text === 'string' ? <span style={{ color: "#6E6E70" }}>{text}</span> : text ? <img style={{width: 26, height: 26}} src='/images/role-permission-check.svg' /> : <></>}</>,

        },
        {
            align: 'center',
            title: <div className='tableHeader_one'>General Admin</div>,
            dataIndex: 'Admin',
            key: 'Admin',
            render: (text) => <>{typeof text === 'string' ? <span style={{ color: "#6E6E70" }}>{text}</span> : text ? <img style={{width: 26, height: 26}} src='/images/role-permission-check.svg' /> : <></>}</>,

        },
        {
            title: <div className='tableHeader_one'>Manager</div>,
            align: 'center',
            dataIndex: 'Manager',
            key: 'Manager',
            render: (text) => <>{typeof text === 'string' ? <span style={{ color: "#6E6E70" }}>{text}</span> : text ? <img style={{width: 26, height: 26}} src='/images/role-permission-check.svg' /> : <></>}</>,

        },
        {
            title: <div className='tableHeader_one'>Loan Officer</div>,
            align: 'center',

            dataIndex: 'Loan',
            key: 'Loan',
            render: (text) => <>{typeof text === 'string' ? <span style={{ color: "#6E6E70" }}>{text}</span> : text ? <img style={{width: 26, height: 26}} src='/images/role-permission-check.svg' /> : <></>}</>,

        },
        {
            title: <div className='tableHeader_one'>Processor</div>,
            align: 'center',
       
            dataIndex: 'Processor',
            key: 'Processor',
            render: (text) => <>{typeof text === 'string' ? <span style={{ color: "#6E6E70" }}>{text}</span> : text ? <img style={{width: 26, height: 26}} src='/images/role-permission-check.svg' /> : <></>}</>,

        },

    ]
    let columns = [
        {
            title: 'Email',
            dataIndex: 'Email',
            key: 'Email',
            fixed: 'left',
            // width: 120,
            render: (text) => <Paragraph style={{marginBottom: 0}} ellipsis={{ rows: 1, tooltip: text }}>{text}</Paragraph>,
            filteredValue: [searchedText],
            onFilter: (value, record) => {
                return (
                    String(record.Email).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.FullName).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.Nmls).toLowerCase().includes(value.toLowerCase())

                )
            },
            className: "loansFixedColumn",
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.Email.localeCompare(b.Email),
        },
        {
            title: 'Full Name',
            dataIndex: 'FullName',
            key: 'FullName',
            width: 150,
            fixed: 'left',
            sorter: (a, b) => {
                if (a.Email < b.Email) {
                    return -1
                }
                if (a.Email > b.Email) {
                    return 1
                }
                return 0
            },
            render: (text) => <>{text}</>,
            className: "loansFixedColumn"
        },
        {
            title: 'Role',
            dataIndex: 'Roles',
            key: 'Roles',
            width: 180,
            render: (text) => <Paragraph style={{marginBottom: 0}} ellipsis={{ rows: 1, tooltip: text?.join(',') }}>{text?.join(',')}</Paragraph>,
            className: "loansFixedColumn"
        },
        {
            title: 'NMLS',
            dataIndex: 'Nmls',
            key: 'Nmls',
            width: 150,
            render: (text) => <Paragraph style={{marginBottom: 0}} ellipsis={{ rows: 1, tooltip: text }}>{text}</Paragraph>,
            sorter: (a, b) => {
                if (a.Nmls < b.Nmls) {
                    return -1
                }
                if (a.Nmls > b.Nmls) {
                    return 1
                }
                return 0
            },
            className: "loansFixedColumn",
        },
        {
            title: 'Account Tier',
            dataIndex: 'AccountTier',
            width: 150,
            key: 'AccountTier',
            render: (text) => <>{capitalize(text)}</>,
            className: "loansFixedColumn",
            sorter: (a, b) => a.AccountTier.localeCompare(b.AccountTier),
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            className: "loansFixedColumn",
            width: 150,
            render: (text, _) => <>
                <div style={{display: 'flex'}}>
                    {getStatus(text) !== undefined && getStatus(text)}
                </div>
            </>,
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            className: "loansFixedColumn",
            width: 96,
            render: (text, _) => <>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className='Status'>
                    {

                        _.Status === 'disabled' ? <>
                            <div></div>
                            <div></div></> : <>
                            <div style={{width: 24, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                <img src='/images/user-management-edit.svg' onClick={() => {
                                    setSendSuccess(false)
                                    const data = {
                                        ...Object.fromEntries(
                                            Object.entries(_).map(([key, value]) => [key.toLowerCase(), value])
                                        ),
                                        roles: _.Roles,
                                    }

                                    setCurrentId(_.ID)
                                    setCurrentData(data)
                                    form.setFieldsValue(data)
                                    setIsModalOpen(true)
                                    setIsSameNMLS(false)
                                    setIsSameEmail(false)
                                    setInviteOrEdit(false)

                                }} />
                            </div>



                            <Popover
                                overlayClassName='Popover'
                                style={{ marginLeft: '10px', }}
                                content={<a onClick={() => {
                                    setIsDisableModalOpen(true)
                                    setCurrentUser(_)
                                }}>
                                    <div >Disable</div>


                                </a>}
                                placement="bottomLeft"
                                trigger="click"

                            >
                                {!_.Roles.includes('Owner') && <img style={{cursor: 'pointer'}} src='/images/user-management-more.svg' />}
                            </Popover>


                        </>

                    }

                </div>
            </>,
        },
    ]
    const getStatus = (item) => {

        const statusDom = {
            active: <div className='status-active upgrade-tag'>Active</div>,
            disabled: <div className='status-disabled upgrade-tag'>Disabled</div>,
            invited: <div className='status-invited upgrade-tag'>Invited</div>,

        }
        return statusDom[item]
    }
    const scrollToTop = () => {
        if (titleRef.current) {
            titleRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
        }
    }
    const scrollToTable = () => {
        if (tableRef.current) {
            tableRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
        }
    }
    const handleCheckboxChange = (checkedValues) => {
        const latestValue = checkedValues.length ? checkedValues[checkedValues.length - 1] : ''
        form.setFieldsValue({ accounttier: latestValue })
    }
    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
        }
    }

    const recurringInterval = {
        free: '',
        monthly: "This user will be removed from your billing cycle.",
        annual: "This user will be removed from your billing cycle, and you will be receiving a prorated refund for the remaining months on this user's subscription. "
    }
    const Tooltip = {
        free: 'You will no longer be charged for this user. ',
        monthly: "You will no longer be charged for this user. You will receive a prorated refund for the remaining months of your annual billing cycle for this user. ",
        annual: "You will no longer be charged for this user. You will receive a prorated refund for the remaining months of your annual billing cycle for this user."
    }
    return (
        <>
            <div className='user-management-wrap'>
                <div className='text-left'>
                    <div className='user-management-topbar'>
                        <div>
                            <div style={{display: 'flex', alignItems: 'center', gap: 24, marginBottom: 4}}>
                                <div className='user-management-title'>User Management</div>
                                <Tag color='#D6E2FB' style={{ fontSize: 16, color: '#1F3988', fontWeight: 600, height: '27px', lineHeight: '24px', textAlign: "center", borderRadius: 6 }}>{!currentInfo.plan ? 'Starter' : capitalize(currentInfo.plan)}</Tag>
                            </div>
                            <div className='user-management-subtitle'>Invite your team members to explore Zeitro</div>
                        </div>
                        <Button className='user-management-support' onClick={() => {
                            setSupportModalOpen(true)
                        }}>Support</Button>
                    </div>
                </div>
                <div className='user-management-search'>
                    <Button className='user-management-add' type='primary' onClick={() => {

                        setIsModalOpen(true)
                        setInviteOrEdit(true)
                        setSendSuccess(false)
                        setCurrentData({})
                        form.resetFields()
                    }}>Add user</Button>
                    <Input
                        className='user-management-input'
                        placeholder="Search by email, name or nmls"
                        allowClear
                        onChange={(e) => {
                            setSearchedText(e.target.value)
                        }}
                        prefix={<SearchOutlined />}
                        size="large"
                        style={{ width: 310 }}
                    />
                </div>
                <div>
                    <Table
                        loading={loading}
                        className="user-management-table"
                        columns={columns}
                        dataSource={dataSource}
                        showSorterTooltip={false}
                        scroll={{ x: 970 }}
                    />
                </div>
                <Modal
                    className='role-permission-modal'
                    width={1000} 
                    open={showRoleList}
                    footer={null}
                    onCancel={() => {
                        setShowRoleList(false)
                    }
                    }>
                    <div className='role-permission-modal-wrap' id='role_modal'>
                        <div className='role-permission-modal-top'><img style={{height: 20}} src='/images/zeitrologo.png'/></div>
                        <div className='role-permission-modal-content'>
                            <div ref={titleRef} className='role-permission-modal-title'>Access Permission for Role</div>
                            <div style={{ maxWidth: '600px', fontSize: 16, color: "#6E6E70", margin: '0 auto 26px', textAlign: "center", lineHeight: '30px', letterSpacing: '0.2px' }}>
                                <div>Zeitro offers five user roles, each inheriting all permissions from lower roles.</div>
                                <div>The diagram provides an overview of each role's permissions.</div>
                            </div>
                            <div style={{ marginBottom: 46, textAlign: "center" }}>
                                <img style={{maxWidth: '100%'}} src='/images/permissions-table.svg' />
                            </div>
                            <div style={{ fontSize: 16, color: "#325CEB", textAlign: "center", fontWeight: 600, lineHeight: '30px', marginBottom: 28 }}><span style={{cursor: 'pointer'}} onClick={scrollToTable}>View Table for Full Details</span></div>
                            <div style={{ textAlign: 'center', color: "#6E6E70", fontSize: 16, lineHeight: 'normal', letterSpacing: '0.2px', marginBottom: 48 }}>The table below summarizes the access permissions for each role.</div>
                            <Table
                                ref={tableRef}
                                columns={RoleColumns}
                                dataSource={RoleDataSource}
                                className="allRolesTable"
                                showSorterTooltip={false}
                                pagination={false}
                                scroll={{ x: 848 }} 
                                sticky
                            ></Table>
                            <div style={{ fontSize: 16, fontWeight: 600, color: "#325CEB", marginTop: 60, textAlign: "center", lineHeight: '24px'}}>
                                <span style={{cursor: 'pointer'}} onClick={scrollToTop}>Back to top</span>
                            </div>
                            <div style={{ fontSize: 16, fontWeight: 500, color: "#000", textAlign: 'center', lineHeight: 'normal', letterSpacing: '0.2px', marginTop: 24 }}>For more questions, please <span style={{color: '#325CEB', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => setSupportModalOpen(true)}>contact Zeitro.</span></div>
                        </div>
                    </div>
                </Modal>
                <Modal open={open} okText='Go to setup' width={436} onCancel={() => {
                    setOpen(false)
                }}

                    footer={<div className='d-flex justify-content-between align-items-center'>
                        <Button type='link' style={{ fontWeight: 600, fontSize: 16, fontFamily: "Inter" }} onClick={() => {
                            setOpen(false)
                        }}>Cancel</Button>
                        <Button type='primary' style={{ fontWeight: 600, fontSize: 16, fontFamily: "Inter", height: 44, width: 133 }} onClick={() => {
                            setOpen(false)
                            dispatch({ type: "OPEN", data: true })
                            dispatch({ type: "STEP", data: 0 })
                            dispatch({ type: "SHOW_ACHIEVEMENT", data: true })
                            dispatch({ type: "SHOW_LENDERS", data: false })
                            dispatch({ type: "SHOW_PRICiNG", data: false })
                            dispatch({ type: "selectLenders", data: true })
                        }}>Go to setup</Button>
                    </div>}
                >
                    <div style={{ marginTop: 25, fontWeight: 400, fontSize: 16, fontFamily: "Inter", padding: 25 }}>

                        You haven't completed the initial setup for your team. They won't be able to use the platform's features until it's done. Would you like to set it up now?
                    </div>

                </Modal>
                <Modal
                    className='management-user-modal'
                    width={786} 
                    open={isModalOpen} 
                    footer={null} 
                    keyboard
                    maskClosable 
                    onCancel={() => { 
                        setIsModalOpen(false)
                        setIsSameEmail(false)
                        setIsSameNMLS(false)
                    }}>
                    {

                        sendSuccess ? <div className='User_modal'> <SendSuccessComponent closeModal={(e) => {
                         
                          
                            if (e === 1) {
                                form.resetFields()
                                setIsModalOpen(false)
                                if (!first) {
                                    setOpen(true)
                                }
                            } else {
                                form.resetFields()
                                setSendSuccess(false)
                                if (!first) {
                                    setOpen(true)
                                    setIsModalOpen(false)
                                }
                            }


                        }}></SendSuccessComponent></div> : <div className='User_modal'>

                            <div>
                                {
                                    inviteOrEdit ?
                                        <div>
                                            <div className='management-user-modal-title'>Invite users on your team</div>
                                            <div className='management-user-modal-subtitle'>Send an invitation to members on your team via email.</div>
                                        </div>
                                        : <div className='management-user-modal-title'>Edit user's information</div>
                                }
                                <Form
                                    onFinish={onFinish}
                                    form={form}
                                    requiredMark={false}
                                    style={{ marginTop: '20px' }}
                                    layout='vertical'
                                    validateMessages={validateMessages}
                                >
                                    <Row>
                                        <Col span={11}>
                                            <Form.Item
                                                className='management-user-modal-form-item'
                                                name="firstname"
                                                label="First name"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input className='management-user-modal-input' placeholder="Enter first name" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={11} push={2}>
                                            <Form.Item
                                                className='management-user-modal-form-item'
                                                name="lastname"
                                                label="Last name"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input className='management-user-modal-input' placeholder="Enter last name" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item
                                                className='management-user-modal-form-item'
                                                name="email"
                                                hidden={!inviteOrEdit && currentData.roles && currentData.roles.includes(UR_Owner)}
                                                label="Email address"
                                                rules={[
                                                    {
                                                        required: true,
                                                        type: 'email',
                                                        validateTrigger: ['onSubmit']
                                                    },
                                                ]}
                                            >
                                                <Input className='management-user-modal-input' status={isSameEmail ? 'error' : null} placeholder="Enter loan officer email address" />

                                            </Form.Item>
                                            {isSameEmail && <div style={{ color: 'red', marginBottom: 8 }}>The email address is already in use. Please use a different one.</div>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24} >
                                            <Form.Item
                                                className='management-user-modal-form-item'
                                                hidden={currentData.roles && currentData.roles.includes(UR_Owner)}
                                                name="roles"
                                                label={<><span>Role</span><span style={{ display: 'inline-block', marginLeft: 12, fontFamily: 'Inter', fontSize: 11, color: '#325CEB', fontWeight: 600, lineHeight: '14px', textDecorationLine: 'underline', cursor: 'pointer'}} onClick={() => { setShowRoleList(true) }}>Access permissions for each role?</span></>}
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Select className='management-user-modal-select' placeholder="Select Role" options={[
                                                    {
                                                        label: "Loan officer",
                                                        value: "LoanOfficer"

                                                    },
                                                    {
                                                        label: "Account owner",
                                                        value: "Owner"

                                                    },
                                                    {
                                                        label: "Loan processor",
                                                        value: "LoanProcessor"

                                                    },
                                                    {
                                                        label: "Price engine user",
                                                        value: "PriceEngineUser"

                                                    }

                                                ]} />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={24}>
                                            <Form.Item
                                                className='management-user-modal-form-item'
                                                name="nmls"
                                                label={<> <span>NMLS number</span>{!inviteOrEdit && <span style={{ display: 'inline-block', fontFamily: 'Inter', fontSize: 13, fontWeight: 400, color: "#6E6E70", marginLeft: "10px", lineHeight: 'normal', letterSpacing: '0.2px' }}>(Required for all roles other than processors)</span>}</>}

                                            >
                                                <Input className='management-user-modal-input' status={isSameNMLS ? 'error' : null} placeholder="Enter loan officer NMLS number" />
                                            </Form.Item>
                                            {isSameNMLS && <div style={{ color: 'red', marginBottom: 8 }}>Each NMLS number is allowed to be associated with only one Zeitro account. Please verify the accuracy of NMLS numbers provided.</div>}

                                        </Col>
                                    </Row>

                                    {

                                        (currentInfo.plan === "enterprise") && <Row>
                                            <Col span={24} >
                                                <Form.Item
                                                    className='management-user-modal-form-item'
                                                    name="accounttier">
                                                    <Checkbox.Group onChange={handleCheckboxChange}>
                                                        <Checkbox className='management-user-checkBox' value="free"> <span style={{fontFamily: 'Inter', color: '#222', fontSize: 16}}>Free User</span> </Checkbox>
                                                        <Checkbox className='management-user-checkBox' value="paid"> <span style={{fontFamily: 'Inter', color: '#222', fontSize: 16}}>Paid User</span> </Checkbox>
                                                    </Checkbox.Group>
                                                </Form.Item>
                                            </Col>
                                            
                                        </Row>
                                    }
                                    {

                                        (!inviteOrEdit && currentInfo.plan === "business") && <Row>
                                            {currentData.accounttier === 'paid' ? <></> : <Col span={24} >
                                            <Form.Item
                                                    name="accounttier">
                                                    <Checkbox.Group onChange={handleCheckboxChange}>
                                                        <Checkbox value="free"> <span style={{fontFamily: 'Inter', color: '#222', fontSize: 16}}>Free user</span> </Checkbox>
                                                        <Popover
                                                            style={{ marginLeft: '10px', }}
                                                            content={
                                                                <div>You will start getting charged once this user creates their account.</div>
                                                            }
                                                            placement="right"
                                                            trigger="hover"

                                                        >
                                                            <Checkbox value="paid"> <span style={{fontFamily: 'Inter', color: '#222', fontSize: 16}}>Paid user</span> </Checkbox>
                                                        </Popover>

                                                    </Checkbox.Group>

                                                </Form.Item>
                                            </Col>
                                            }
                                        </Row>
                                    }

                                    <Button loading={isConfirm} type='primary' htmlType="submit" style={{ width: '100%', height: 50, fontFamily: 'Inter', fontSize: 16, fontWeight: 600, lineHeight: '20px', marginTop: 24 }}>{inviteOrEdit ? 'Send invite' : 'Confirm'}</Button>
                                </Form>
                            </div>

                        </div>

                    }


                </Modal >

                <Modal 
                    width={786} 
                    className='user-management-disabled-modal'
                    open={isDisableModalOpen} 
                    footer={[
                        <Button className='user-management-disabled-btn cancel-btn' onClick={() => {

                            setIsDisableModalOpen(false)

                        }}>Cancel</Button>,
                        <Button className='user-management-disabled-btn confirm-btn' loading={disablledLoading} type='primary'
                            onClick={async () => {
                                setDisablledLoading(true)
                                let token = sessionStorage.getItem("ZeitroA")
                                const response = await fetch('/los/disablealoanofficer', {

                                    method: 'POST',
                                    headers: {
                                        Authorization: "Bearer " + token,
                                        Cache: "no-cache"
                                    },
                                    body: JSON.stringify({
                                        loanOfficerID: currentUser.ID
                                    })

                                })
                                console.log(response.status)
                                const { status } = response

                                // const a = await response.json()

                                if (status !== 200) {
                                    setDisablledLoading(false)
                                    alert('something went wrong, please try later')
                                    return
                                }
                                getList()
                                setIsDisableModalOpen(false)
                                setIsTooltipModalOpen(true)
                                setDisablledLoading(false)

                            }}
                        >Confirm</Button>
                    ]} 
                    onCancel={() => setIsDisableModalOpen(false)}
                >

                    <div style={{ fontFamily: 'Inter', fontSize: 36, color: '#222', fontWeight: 700, lineHeight: '44px', textAlign: 'center', marginBottom: 74 }}>
                        Disable account?
                    </div>
                    <div style={{ fontFamily: 'Inter', fontSize: 16, color: '#000', maxWidth: '558px', lineHeight: '22px', letterSpacing: '0.5px', margin: '0 auto', paddingLeft: 20 }}>
                        <div>Are you sure you want to disable <span style={{ color: '#1630AC', fontWeight: 600 }}>{currentUser.FirstName + currentUser.LastName}</span>’s account?</div>
                        <div style={{ marginTop: '20px' }} > <span style={{ fontWeight: 700 }}>This action is irreversible</span> and should only be done if this user is leaving your team permanently.</div>
                        <div style={{ marginTop: '20px' }}>Once disabled, the account will become inactive and no longer accessible to the user, but their loan data will be securely archived and accessible to you. You can also reassign their loans to other active users.</div>
                        <div style={{ marginTop: '20px' }}>{
                            recurringInterval[currentInfo.recurringInterval === '' ? 'free' : currentInfo.recurringInterval]

                        }</div>
                    </div>
                </Modal >
                <Modal
                    width={568}
                    className='user-management-disabled-confirm-modal'
                    open={isTooltipModalOpen}
                    centered
                    closable={false}
                    footer={[
                        <Button className='user-management-disabled-confirm-btn reassign-btn' onClick={() => {

                            toLoans()

                        }}>Reassign loans</Button>,
                        <Button type='primary' className='user-management-disabled-confirm-btn ok-btn'
                            onClick={() => {
                                setIsTooltipModalOpen(false)

                            }}
                        >OK</Button>
                    ]} 
                    onCancel={() => {
                        setIsTooltipModalOpen(false)
                    }}
                >

                    <div style={{height: '286px', fontFamily: 'Inter', fontSize: 16, color: '#000', lineHeight: '22px', letterSpacing: '0.5px', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '10px', paddingBottom: 24}}>
                        <div><span style={{ color: '#1630AC', fontWeight: 600 }}>{currentUser.FirstName + currentUser.LastName}</span>’s account has been disabled. </div>
                        <div>{Tooltip[currentInfo.recurringInterval === '' ? 'free' : currentInfo.recurringInterval]}</div>
                        <div>To reassign the loan officer's loans to other active users, please go to<span style={{ fontWeight: 600 }}> Management {'>'} Loans.</span></div>
                    </div>

                </Modal>

                <Modal 
                    width={786}
                    className='help-modal'
                    open={isSupportModalOpen} 
                    footer={null} 
                    onCancel={() => {
                        setSupportModalOpen(false)
                    }}
                >
                    <div className='help-modal-content'>
                        <div className='help-modal-content-title'>How can we help?</div>
                        <div className='help-modal-content-tips'>For inquiries regarding billing, payments, product feedback, or any issues encountered while using Zeitro, we're here to assist you promptly. </div>

                        <Form
                            className='help-modal-form'
                            onFinish={onSupportFinish}
                            form={support}
                            style={{ marginTop: '20px' }}
                            layout='vertical'
                            validateMessages={validateMessages}

                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        className='help-modal-form-item'
                                        name="message"
                                        label="Message"
                                    >
                                        <TextArea
                                            className='help-modal-form-item-textarea'
                                            placeholder="Please leave us a message..." 
                                            style={{ 
                                                height: '250px',
                                                resize: 'none'
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <div style={{fontFamily: 'Inter', color: '#6E6E70', width: '100%', textAlign: 'center', marginTop: 20, lineHeight: '20px', letterSpacing: '0.2px'}}>Alternatively, you could also email us at <span style={{color: '#325CEB'}}>contact@zeitro.com</span></div>
                            </Row>

                            <div style={{width: '100%', display: 'flex', justifyContent: 'center', gap: '48px'}}>
                                <Button className='help-modal-btn cancel-btn' onClick={() => {
                                    setSupportModalOpen(false)

                                }}>Cancel</Button>
                                <Button className='help-modal-btn send-btn' type='primary' htmlType="submit">Send Message</Button>

                            </div>
                        </Form>
                    </div>


                </Modal>
                <Modal open={messageReceivedModalOpen} footer={null} onCancel={() => {
                    setMessageReceivedModalOpen(false)
                }}>
                    <div className='text-center p-5'>
                        <div><img src='/images/emailSent.svg' /></div>
                        <div className='User_modal_title my-3'>We've received your message</div>
                        <div className='title-5 text-center'>Thank you for reaching out. </div>
                        <div className='title-5 text-center'>We will get back to you via email as soon as possible.</div>
                        <Button type='primary' size='large' className='mt-4' onClick={() => setMessageReceivedModalOpen(false)}>Done</Button>
                    </div>
                </Modal>
                <Modal open={isUpgradeLOSuccessModalOpen} footer={null} closable={false}>
                    <div className='text-center p-5'>
                        <img className='my-4' src='/images/congratulation.png' style={{ height: 80 }} />
                        <div>You have upgraded the loan officer to a Paid User Tier.</div>
                        <div>Your billing amount has been updated accordingly.</div>
                        <Button type='primary' size='large' style={{ width: 200 }} className='mt-4' onClick={() => {
                            setIsUpgradeLOSuccessModalOpen(false)
                            getList()
                        }}>OK</Button>
                    </div>
                </Modal>
            </div >

        </>
    )
    async function getList() {
        try {
            let token = sessionStorage.getItem("ZeitroA")
            setLoading(true)
            const response = await fetch('/los/loanofficerlist', {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache",
                    'X-Image-Free': 'true'
                }
            })
            const { LoanOfficers } = await response.json()
            let docs = LoanOfficers.map(s => {
                return {
                    ...s,
                    FullName: s.FirstName + " " + s.LastName
                }
            }).sort((a, b) => {

                const aValue = a.Status === 'disabled'
                const bValue = b.Status === 'disabled'

                if (aValue === bValue) return 0 // 如果两者value属性都是（或都不是）'disable'，保持原有顺序

                // 将value为'disable'的元素排在后面
                return aValue ? 1 : -1

            })
            setDataSource([...docs])
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }


    }
    function onSupportFinish(val) {
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/los/customersupporttoslack', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
            body: JSON.stringify({
                Message: val.message
            })
        }).then(async response => {
            if (!response.ok) {
                return
            }
        })
        setSupportModalOpen(false)
        setMessageReceivedModalOpen(true)
    }
    async function onFinish(val) {

        setSsConfirm(true)
        let token = sessionStorage.getItem("ZeitroA")
        let roles = val.roles
        if (typeof roles === 'string') {
            roles = [roles]
        }

        let tosend = {
            email: val.email.toLowerCase(),
            firstname: val.firstname,
            lastname: val.lastname,
            password: val.password,
            phone: val.phone,
            nmls: val.nmls,
            roles: roles,
            accounttier: val.accounttier,
        }
        if (inviteOrEdit) {
            try {

                const response = await fetch('/los/createloaccount', {
                    method: 'POST',
                    headers: {

                        Cache: "no-cache",
                        'Content-Type': 'application/json;charset=utf-8',
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify(tosend)
                })
                if (response.ok === false) {
                    alert('Something went wrong, please try later.')
                    return
                }

                const { Text, Status } = await response.json()
                if (Status === 'Error') {
                    Text.includes('NMLS') ? setIsSameNMLS(true) : setIsSameNMLS(false)
                    Text.includes('Email already exists') ? setIsSameEmail(true) : setIsSameEmail(false)
                    setInviteOrEdit(true)

                } else {
                    form.resetFields()
                    getList()

                    setSendSuccess(true)
                }
            } catch (error) {
                console.log(error)
            }
        } else {

            try {

                const response = await fetch('/los/updatelo', {
                    method: 'POST',
                    headers: {

                        Cache: "no-cache",
                        'Content-Type': 'application/json;charset=utf-8',
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({ ...tosend, id: currentId })
                })

                if (response.status !== 200) {
                    alert('Something went wrong, please try later')
                    setAddLoading(false)
                    return
                }

                const { Text, Status } = await response.json()

                if (Status === 'Error') {
                    Text.includes('NMLS') ? setIsSameNMLS(true) : setIsSameNMLS(false)
                    Text.includes('loanofficers_email_key') ? setIsSameEmail(true) : setIsSameEmail(false)


                } else {
                    form.resetFields()
                    getList()

                    if (currentInfo.plan !== "enterprise") {

                        if (val.accounttier === 'paid') {
                            setUpgrade(val.id, token)
                        }
                        getList()
                    }
                    setIsModalOpen(false)
                }
            } catch (error) {
                console.log(error)
            }

            setAddLoading(false)
        }
        setSsConfirm(false)



    }
    //
    //合并两个数组


    async function setUpgrade() {
        const token = sessionStorage.getItem("ZeitroA")


        if (!token || !currentId) {
            console.error("Token or currentId is missing or invalid.")
            return
        }

        try {

            const response = await fetch('/los/upgradealoanofficer', {
                method: 'post',
                headers: {
                    Authorization: "Bearer " + token,
                    'Cache-Control': 'no-cache',
                },
                body: JSON.stringify({ loanOfficerID: currentId })
            })

            if (response.ok) {
                setIsUpgradeLOSuccessModalOpen(true)
            } else {

                console.error('Request failed with status ' + response.status)
            }

            // 优化3: 使用 const 声明且因未使用响应数据 a，所以这里移除该赋值操作
            // const a = await response.json();
        } catch (error) {
            // 异常处理优化: 错误处理和用户反馈
            console.error('Fetch error: ', error)
            // 可以在这里添加更多的错误处理逻辑，如用户提示等
        }
    }

}