import React, { useState, useEffect, useRef } from 'react';
import { useImmer } from "use-immer";
import { Form, Input, Button, Col, Row, Modal, Table, Tag, Select, Radio, Popover, Tooltip } from 'antd';
import { SearchOutlined, CheckOutlined, FormOutlined } from '@ant-design/icons';
import { splitByCapitalLetter } from '../../../Common';
import './index.css'
export default function UserModal(props) {
    const { isModalOpen, closeModal, loanOfficers } = props
    const [dataSource, setDataSource] = useImmer([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const getStatus = (item) => {
        const statusDom = {
            active: <div className='status-active upgrade-tag'>Active</div>,
            disabled: <div className='status-disabled upgrade-tag'>Disabled</div>,
            invited: <div className='status-invited upgrade-tag'>Invited</div>,

        }
        return statusDom[item]


    }
    let columns = [
        {
            title: 'Email',
            dataIndex: 'Email',
            key: 'Email',
            fixed: 'left',

            render: (text) => <>{text}</>,

            onFilter: (value, record) => {
                return (
                    String(record.Email).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.FullName).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.Nmls).toLowerCase().includes(value.toLowerCase())

                );
            },
        },
        {
            title: 'Full Name',
            dataIndex: 'FullName',
            key: 'FullName',
            fixed: 'left',
            sorter: (a, b) => {
                const firstLetterA = a.LastName[0].toUpperCase()
                const firstLetterB = b.LastName[0].toUpperCase()
                if (firstLetterA < firstLetterB) {
                    return -1
                }
                if (firstLetterA > firstLetterB) {
                    return 1
                }
                return 0
            },
            render: (text) => <>{text}</>,
        },
        {
            title: 'Role',
            dataIndex: 'Roles',
            key: 'Roles',
            width: 200,
            render: (text) => (
                <Tooltip title={splitByCapitalLetter(text?.join(', '))}>
                    <div style={{
                        width: "200px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis"
                    }}>
                        {splitByCapitalLetter(text?.join(', '))}
                    </div>
                </Tooltip>
            ),
            sorter: (a, b) => {
                const lenA = a.Roles.length
                const lenB = b.Roles.length
                if (lenA < lenB) {
                    return -1
                }
                if (lenA > lenB) {
                    return 1
                }
                return 0
            },
        },
        {
            title: 'NMLS',
            dataIndex: 'Nmls',
            key: 'Nmls',
            render: (text) => <>{text}</>,
            sorter: (a, b) => {
                if (a.Nmls < b.Nmls) {
                    return -1;
                }
                if (a.Nmls > b.Nmls) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'Account Tier',
            dataIndex: 'AccountTier',
            width: 100,
            key: 'AccountTier',
            width: 170,
            render: (text) => <>{text}</>,
            sorter: (a, b) => {
                if (a.AccountTier < b.AccountTier) {
                    return -1;
                }
                if (a.AccountTier > b.AccountTier) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            sorter: (a, b) => {
                if (a.Status < b.Status) {
                    return -1;
                }
                if (a.Status > b.Status) {
                    return 1;
                }
                return 0;
            },
            render: (text, _) => <>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                    {getStatus(text)}
                </div>
            </>,
        },
    ];
    useEffect(() => {
        if (loanOfficers === undefined) return

        getList()
    }, [isModalOpen])
    useEffect(() => {
        console.log(dataSource)
        const fixedSelectedRowKeys = dataSource
            .filter(record =>
                record.Roles.includes('Owner') || (record.Roles.length === 1 && record.Roles[0] === 'LoanProcessor')
            )
            .map(record => record.ID)
        
        setSelectedRowKeys(fixedSelectedRowKeys)
    }, [dataSource])
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys)
        },
        getCheckboxProps: (record) => (
            {
                disabled: record.Roles.includes('Owner') || (record.Roles.length === 1 && record.Roles[0] === 'LoanProcessor'), // Column configuration not to be checked
                name: record.ID,
        }),
    }
    const getRowClassName = (record) => {

        if (record.Roles.includes('Owner')) {
            return 'highlight-row'; // 当条件满足时，返回高亮类名
        }
        if (record.Roles.length === 1 && record.Roles[0] === 'LoanProcessor') {
            return 'unselectable-row'; // 当条件满足时，返回高亮类名
        }
        return ''; // 条件不满足时，返回空字符串（不添加额外样式）
    };
    // classNames={{
    //     content: 'modal_content',
    //     body: 'modal_body'
    // }}
    const footer = [
        <Button style={{maxWidth: 300}} className="upgrade-btn-cancel" onClick={() => {
            closeModal(false)
        }}>Cancel</Button>,
        <Button style={{maxWidth: 300}} className="upgrade-btn-confirm" type="primary" onClick={() => {
            closeModal(selectedRowKeys)
        }}>Upgrade</Button>
    ]
    return (<>
        <Modal 
            okText='Upgrade'
            className='upgrade-user-modal'
            width={1024}
            open={isModalOpen}
            footer={footer}
            onCancel={() => {
                closeModal(false)
            }
            } onOk={() => {
                closeModal(selectedRowKeys)
            }}>
            
            <div >

                <div>
                    <div style={{ textAlign: 'center', marginBottom: '20px' }}>

                        <div style={{ fontSize: 36, fontWeight: 700, color: '#222', lineHeight: '44px' }}>Select users to upgrade</div>
                        <div style={{maxWidth: '628px', margin: '12px auto 22px', textAlign: 'center', color: '#222', lineHeight: '#222', fontSize: 16 }}>
                            <div>There are {dataSource.length} active users that can be upgraded to Paid User tier.</div>
                            <div>Please review the list of users and deselect anyone that you don’t wish to upgrade.</div>
                        </div>
                    </div>

                    <Table
                        rowSelection={{
                            type: 'checkbox',
                            selectedRowKeys,
                            ...rowSelection,
                            renderCell: (checked, record, index, originNode) => {
                                if (record.Roles.includes('Owner')) {
                                    return <Popover
                                        overlayClassName='upgrade-user-popover'
                                        arrow={false}
                                        content={<div>The admin must be a paid user.</div>}
                                        trigger="hover"
                                        placement="bottomLeft"
                                    >
                                        {originNode}

                                    </Popover>
                                } else  if (record.Roles.length === 1 && record.Roles[0] === 'LoanProcessor') {
                                    return <Popover 
                                        overlayClassName='upgrade-user-popover'
                                        arrow={false}
                                        content={<div>Processors are always free and cannot be upgraded.</div>}
                                        trigger="hover"
                                        placement="bottomLeft"
                                    >
                                        {originNode}
                                    </Popover>
                                } else {

                                    return originNode
                                }{

                                    return originNode
                                }




                            }
                        }}
                        rowClassName={getRowClassName}
                        rowKey='ID'
                        className='userModalTable'
                        columns={columns}
                        dataSource={dataSource}
                        showSorterTooltip={false}
                        pagination={false}
                        scroll={{
                            x: true, y: true
                        }}
                    />
                </div>

            </div>

        </Modal >

    </>)
    async function getList() {
        try {
            let token = sessionStorage.getItem("ZeitroA")

            const response = await fetch('/los/loanofficerlist', {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache",
                    "X-Image-Free": "true",
                }
            })
            const { LoanOfficers } = await response.json()
            let docs = LoanOfficers.map(s => {
                return {
                    ...s,
                    FullName: s.FirstName + " " + s.LastName
                }
            }).filter((e) => e.Status !== 'disabled').sort((a, b) => {
                const aIsOwner = a.Roles.includes('Owner');
                const bIsOwner = b.Roles.includes('Owner');
                const aIsOnlyLoanProcessor = a.Roles.length === 1 && a.Roles[0] === 'LoanProcessor';
                const bIsOnlyLoanProcessor = b.Roles.length === 1 && b.Roles[0] === 'LoanProcessor';
                
                if (aIsOwner && !bIsOwner) return -1;
                if (!aIsOwner && bIsOwner) return 1;
                
                if (aIsOnlyLoanProcessor && !bIsOnlyLoanProcessor) return 1;
                if (!aIsOnlyLoanProcessor && bIsOnlyLoanProcessor) return -1;
                
                const aValue = a.Status === 'disabled';
                const bValue = b.Status === 'disabled';
            
                if (aValue === bValue) return 0;
            
                return aValue ? 1 : -1;
            })

            // Filter out loan processors from the default selection
            const selectedKeys = loanOfficers
                .filter(item => {
                    const user = docs.find(doc => doc.ID === (item.ID || item));
                    return user && !(user.Roles.length === 1 && user.Roles[0] === 'LoanProcessor');
                })
                .map(item => item.ID || item);

            setSelectedRowKeys(selectedKeys);
            setDataSource([...docs])

        } catch (error) {
            // setLoading(false)
        }


    }
}
