import React, { useEffect, useState, useRef } from 'react'
import { Drawer, Skeleton } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

export default React.forwardRef(function ChatHistory(props, ref) {
    const { currentThreadID, openChatHistory, setOpenChatHistory, getHistoryList, setShowHistory, getList } = props

    const [loadingChatList, setLoadingChatList] = useState(true)
    const [chatList, setChatList] = useState([])
    const [curId, setCurId] = useState('')
    const [isHovered, setIsHovered] = useState(false)

    React.useImperativeHandle(ref, () => ({
        refreshChatList: getChatList,
        //Expose chatList to the Chat() function and implement the first use of GuidelineGPT's logEvent
        getChatList: () => chatList
    }))

    const handleMouseEnter = () => {
        setIsHovered(true)
    }

    const handleMouseLeave = () => {
        setIsHovered(false)
    }

    const getChatList = async () => {
        setLoadingChatList(true)
        let token = sessionStorage.getItem('ZeitroA')
        try {
            const response = await fetch('/los/mortgagegpt/chatlist', {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + token,
                    Cache: 'no-cache',
                },
            })

            if (!response.ok) {
                setLoadingChatList(false)
                throw new Error(`HTTP error! Status: ${response.status}`)
            }

            const data = await response.json()
            setLoadingChatList(false)
            setChatList(data.chat_list)
            getList(data.chat_list)
        } catch (error) {
            setLoadingChatList(false)
            console.error('Fetch Error:', error)
        }
    }

    useEffect(() => getChatList(), [])

    const renderChatList = () => {
        const groupedData = chatList.reduce((acc, item) => {
            const date = new Date(item[1])
            let group
            if (date.toLocaleDateString() === new Date().toLocaleDateString()) {
                group = 'Today'
            } else if (date > new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)) {
                group = 'Last week'
            } else {
                group = 'Past 30 days'
            }
            if (!acc[group]) {
                acc[group] = []
            }
            acc[group].push({
                threadId: item[0],
                date: date.toLocaleDateString(),
                titleFallback: item[2],
                title: item[2],
                type: item[4] === '' ? 'FannieMae' : item[4],
            })
            return acc
        }, {})

        const handleClick = (threadId, type) => {
            setCurId(threadId)
            getHistoryList(threadId, type)
            setShowHistory(false)
        }

        return (
            <div className="text-left" style={{ padding: '30px 0' }}>
                {Object.entries(groupedData).map(([group, items], groupIndex) => (
                    <div key={group} style={{ marginBottom: groupIndex < Object.entries(groupedData).length - 1 ? '30px' : '0' }}>
                        <div className="title-5" style={{ marginBottom: '8px', padding: '0 14px' }}>{group}</div>
                        <div>
                            {items.map((item, index) => (
                                <div
                                    key={index}
                                    className={item.threadId === curId ? "chatHistoryTitleCurrent" : "chatHistoryTitle"}
                                    onClick={() => handleClick(item.threadId, item.type)}
                                    style={{ padding: '12px 14px', cursor: 'pointer', transition: 'background-color 0.3s ease' }}
                                >
                                    <div className='chatHistoryItemTitle'>{item.title}</div>
                                    <div className='chatHistoryItemType'>
                                        <img src="/images/pdfType.svg" alt="PDF" style={{ marginRight: '5px' }} />
                                        {item.type}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div className={`chat-history ${isHovered ? 'show-scrollbar' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {loadingChatList ? (
                <div style={{ padding: '28px 16px' }}>
                    <Skeleton active paragraph={{ rows: 10 }} />
                </div>
            ) : chatList.length === 0 ? (
                <div
                    style={{
                        background: '#EAEFFD',
                        color: '#1F2890',
                        borderRadius: '8px',
                        padding: '15px',
                        fontSize: 14,
                        fontFamily: 'Inter',
                    }}
                >
                    You don't have any chats yet. Select a guideline to start your conversation.
                </div>
            ) : (
                <div>{renderChatList()}</div>
            )}
        </div>
    )
})
