import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Empty, Alert, Skeleton } from 'antd';


import "react-datepicker/dist/react-datepicker.css";

import 'bootstrap/dist/css/bootstrap.min.css';

import { connect } from 'react-redux'
import * as com from "./Common"
import PlanModal from "./planModal";
import { checkAccess, checkAloneAccess } from "./Auth";
import { UR_LoanProcessor, UR_Owner } from "./State";
import { Link } from 'react-router-dom'

const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
        borrower: state.borrower,
        subscriptionReducer: state.subscriptionReducer.currentInfo,
    }
}

const mapDispatchToProps = (dispatch) => ({
});
class FormPreview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            monthlyUsage: 0,
            preview: [],
            renderer: <Skeleton active/>,
            isModalOpen: false,
        }
        this.prefix = "data:image/png;base64,"
        this.downloadref = React.createRef()
    }

    componentDidMount() {
        this.reload()
    }

    download = () => {
        const status = com.getStatus(com.getLoId(), this.props.subscriptionReducer.loanOfficers)
        if (status !== undefined) {
            if (status === 'free' && !checkAloneAccess([UR_LoanProcessor])) {
                if (this.state.monthlyUsage >= 3) {
                    this.setState({ isModalOpen: true })
                    return
                }
            }
        }
        this.downloadref.current.click()
        this.recordmismousage()
    }

    renderMismoCount = () => {
        const status = com.getStatus(com.getLoId(), this.props.subscriptionReducer.loanOfficers)
        let initialMsg = "Please note that the maximum number of monthly MISMO exports for free users is 3, and you have currently used "+this.state.monthlyUsage + ". "

        let msg
        if (this.props.subscriptionReducer.plan==="starter" && status !== undefined && status === 'free') {
            if (checkAccess([UR_Owner])) {
                msg = <div>
                    <div>{initialMsg}</div>
                    <Link to="/app/management">Upgrade to the business plan to unlock unlimited MISMO exports.</Link>
                </div>
            } else {
                msg = <div>
                    <div>{initialMsg}</div>
                    <div>Please contact your account admin to upgrade to the business plan to unlock unlimited MISMO exports.</div>
                </div>            
            }
        } else if (this.props.subscriptionReducer.plan !=="starter" && status !== undefined && status === 'free') {
            msg = <div>
                <div>{initialMsg}</div>
                <div>Please contact your account admin to upgrade your account to unlock unlimited MISMO exports.</div>
            </div>            
        } else {
            msg = <div>{initialMsg}</div>
        }

        if (this.props.subscriptionReducer.plan==="starter" && !checkAloneAccess([UR_LoanProcessor]) || (status !== undefined && status === 'free' && !checkAloneAccess([UR_LoanProcessor]))) {

            return <div className="mx-5"><Alert message={msg} type="info" showIcon className="mt-3"/></div>

        }
        return ""
    }

    recordmismousage = () => {
        let token = sessionStorage.getItem("ZeitroA")
        fetch(`/los/recordmismousage/${this.props.borrower.loan_id}`, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(response => {
            if (response.status !== 200) {
                console.log(response)
                return;
            }
        })
    }


    reload = () => {
        let token = sessionStorage.getItem("ZeitroA")
        let { id } = this.props.borrower

        let url
        let headers = {
            Authorization: "Bearer " + token,
            Cache: "no-cache",
            'X-Loan': this.props.borrower.loan_id
        }
        if (this.props.form === '1003') {
            url = `/los/get${this.props.form}/b64/${id}`
        } else if (this.props.form === 'undefined') {
            this.setState({
                renderer:
                    (<div>
                        <p style={{fontSize: '25px'}}>This information is not available yet.</p>
                    </div>)
            })
            return
        } else if (this.props.form === 'mismo34') {
            fetch(`/los/getmismothreefour/${this.props.borrower.loan_id}`, {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache"
                }
            }).then(response => {
                if (response.status !== 200) {
                    this.setState({renderer: null})
                    alert('Looks like there was a problem. Please ask Zeitro team for help.')
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    return;
                }
                response.json().then(json => {
                    try {
                        this.setState({ monthlyUsage: json.MonthlyUsage })
                        let obj = JSON.parse(atob(json.Document))
                        if (obj.Errors || obj.Error) {
                            if (obj.Error && obj.Error === "Data.Text.Internal.Fusion.Common.index: Index too large") {
                                obj.Error = "Credit info has not been provided yet."
                            } else if (obj.Error && obj.Error.includes("invalid address line")) {
                                obj.Error = "Property address has not been provided yet."
                            }
                            this.setState({
                                renderer:
                                    (
                                        <div className="m-5"><Empty description={
                                            <div>
                                              Document is not available yet
                                            </div>
                                          }/>
                                          {obj.Error  && <Alert message={obj.Error} type="warning" showIcon className="mt-3"/>}
                                          {obj.Errors  && obj.Errors.map(e => <Alert message={e} type="warning" showIcon className="mt-3"/>)}
                                          </div>
                                    )
                            })
                        }
                    }
                    catch (e) {
                        this.setState({
                            renderer:
                            // ( current < loansubmitted ? 
                            //         <div className='forms-preview text-center'>
                            //             <h4 className="mt-3 mb-5">Customer application is not submitted yet</h4>
                            //             <div style={{width: '80%', marginLeft: 'auto', marginRight: 'auto'}}>
                            //                 The interview must reach the last page, and application must be submitted. Even if the borrower or yourself went back, and made an edit, one has to step through all pages to the end to ensure consistency of the application.
                            //             </div>
                            //         </div>
                            //         :
                                    <div className='forms-preview'>
                                        <h4 className="mt-3 mb-5">Please click below to download FNM 3.4 file</h4>
                                        <a ref={this.downloadref} hidden href={`data:application/xml;base64,${json.Document}`} download={this.props.borrower.fullname + " " + this.props.form}> </a>
                                        <div onClick={this.download} className='documentDownload'><i style={{ fontSize: '70px', top: '15px', left: '47%' }} className="fas fa-file-download"></i></div>
                                    </div>
                                // )
                        })
                    }
                })
            })
        } else {
            url = `/los/getform/b64/${id}`
            headers['X-form'] = this.props.form
        }

        fetch(url, {
            method: 'GET',
            headers
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    return;
                }
                response.json().then(json => {
                    let doc = json.Document
                    if (this.props.borrower.id !== id) {
                        this.reload()
                    } else {
                        this.setState({ preview: doc.pngPagesBase64 })
                        this.setState({
                            renderer:
                                (
                                    <div className='forms-preview'>
                                        <a ref={this.downloadref} hidden href={`data:application/pdf;base64,${doc.documentBase64}`} download={this.props.borrower.fullname + " " + this.props.form}> </a>
                                        <div onClick={this.download} className='forms-download documentDownload'><i style={{ fontSize: '40px' }} className="fas fa-file-download"></i></div>
                                        {this.state.preview.map((page, index) =>
                                            <img key={index} alt="view" src={this.prefix + page} />
                                        )}
                                    </div>
                                )
                        })
                    }

                });
            }
        )
            .catch(function (err) {
                console.log('Fetch Error :-S', err);
            });
    }

    render() {

        return (
            <div className="text-center mt-3 bluish">
                {this.renderMismoCount()}
                {this.state.renderer}
                <PlanModal isModalOpen={this.state.isModalOpen} closeModal={() => {
                    this.setState({ isModalOpen: false })
                }} isMismo={true} ></PlanModal>
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormPreview)
