import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Form, Input, Button, Col, Row, Modal, Table, Tag, Select, Radio } from 'antd';
import { LeftOutlined, } from '@ant-design/icons';
import UserModal from '../userModal';
import { mapFullNameAbbr, mapCountry } from '../../../Common'

import './index.css'
import {
    Elements,
    CardElement,
    PaymentElement,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getStripePublicKey } from '../../../Billing/Prices';


function Information(props, ref) {
    const { getInfo, payID, loanOfficers, billingFrequency, stripeCustomerID, userNum } = props
  
    const [form] = Form.useForm();
    const elements = useElements();
    const stripe = useStripe();

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
        }
    }
    useImperativeHandle(ref, () => ({
        click() {

            document.getElementById('submit').click()
        },

    }))

    return (

        <div className='cardInfo'>
            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>Enter your payment details</div>
            <Form
                onFinish={onFinish}
                form={form}
                style={{ marginTop: '20px' }}
                layout='vertical'
                validateMessages={validateMessages}

            >

                <Row>
                    <Col span={20}>
                        <Form.Item

                            name="Card "
                            label="Card information"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <CardNumberElement />
                        </Form.Item>
                    </Col>

                </Row>
                <Row>
                    <Col span={9}>
                        <Form.Item

                            name="Expiration Date "

                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Expiration Date!'
                                },
                            ]}
                        >
                            <CardExpiryElement></CardExpiryElement>
                        </Form.Item>

                    </Col>
                    <Col span={9} offset={2}>
                        <Form.Item

                            name="cvc "

                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your CVC!'
                                },
                            ]}
                        >
                            <CardCvcElement />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{marginTop:"8%"}}>
                    <Col span={20}>
                        <Form.Item

                            name="Name "
                            label="Name on Card"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input placeholder="Enter cardholder’s name" style={{ height: '44px' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={9}>
                        <Form.Item
                            className='labelItem'
                            name="Country or region"
                            label="Country or region"

                        >
                            <Select placeholder='United States' options={
                                Object.entries(mapCountry)
                                .map(([value, label]) => ({
                                    label: label.charAt(0).toUpperCase() + label.slice(1),
                                    value
                                }))
                                .sort((a, b) => a.label.localeCompare(b.label))
                            }></Select>
                        </Form.Item>

                    </Col>
                    <Col span={9} offset={2}>
                        <Form.Item
                            label={<div style={{ opacity: 0 }}>12345</div>}
                            name="num"


                        >
                            <Input placeholder="97712" style={{ height: '44px' }} />
                        </Form.Item>
                    </Col>
                </Row>
                {/* style={{ opacity: 0 }} */}
                <Button type="primary" htmlType="submit" id='submit' style={{ opacity: 0 }} >
                    Submit
                </Button>

            </Form>


        </div>

    )

    async function onFinish(val) {
        getInfo({
            back: false,
            loading: true,
            to: false
        })
        try {

            if (!stripe || !elements) {
                // Stripe.js has not yet loaded.
                return;
            }
            const cardElement = elements.getElement(CardNumberElement);
            console.log(cardElement);
            if (!cardElement) {
                // Handle the case where the card element isn't found
                alert('Please enter your card information')
                return;
            }
            const result = await stripe.createPaymentMethod({
                type: "card",
                card: cardElement,
            });
            console.log(result);
            if (result.error) {
                // Handle errors
                console.error(result.error.message);
                getInfo({
                    back: false,
                    loading: false,
                    to: false
                })
                alert(result.error.message)

                return
            }
            const { setupIntent } = await stripe
                .confirmCardSetup(payID, {
                    payment_method: result.paymentMethod.id,
                })
            let token = sessionStorage.getItem("ZeitroA")
            getInfo({
                back: false,
                loading: true,
                to: false
            })
            try {
                const response = await fetch('/los/subscribetobusinessplan', {
                    method: 'post',
                    headers: {
                        Authorization: "Bearer " + token,
                        Cache: "no-cache"
                    },
                    body: JSON.stringify({
                        paymentMethodId: setupIntent.payment_method,
                        billingFrequency: billingFrequency.toLowerCase(),
                        selectedLoanOfficerIDs: userNum > 1 ? loanOfficers : loanOfficers.map(item => item.ID),
                        stripeCustomerID: stripeCustomerID

                    })
                })

                if (response.status !== 200) {
                    alert('Something went wrong, please try later')
                    getInfo({
                        back: false,
                        loading: false,
                        to: true
                    })
                    return
                } else {
                  
                    getInfo({
                        back: true,
                        loading: false,
                        to: false
                    })
                }
            } catch (error) {
                alert('Something went wrong, please try later')
                getInfo({
                    back: false,
                    loading: false,
                    to: true

                })
            }

        } catch (error) {

            getInfo({
                back: false,
                loading: false,
                to: false
            })
        }

    }
}
const NewInformation = forwardRef(Information)
export default function Upgrade(props) {
    const { back, loanOfficers, stripeCustomer, userNumber } = props
    const [isChecked, setIsChecked] = useState(true)
    const [userNum, setUserNum] = useState(1)
    const [isPay, setIsPay] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [ModalOpen, setModalOpen] = useState(false)
    const [continueLoading, setContinueLoading] = useState(false)
    const [payLoading, setPayLoading] = useState(false)
    const [payID, setPayId] = useState('')
    const [stripeCustomerID, setStripeCustomerID] = useState('')
    const [CurrentLoanOfficers, setCurrentLoanOfficers] = useState([])

    const stripePromise = loadStripe(
        getStripePublicKey()
    );
    const info = useRef(null)

    useEffect(() => {

        if (userNumber === undefined) return
        setUserNum(userNumber)

    }, [
        userNumber
    ])
    useEffect(() => {

        if (loanOfficers === undefined) return
        console.log(loanOfficers,777);
        setCurrentLoanOfficers(loanOfficers)


    }, [
        loanOfficers
    ])
    const clickContinue = async () => {
        setContinueLoading(true)
        let token = sessionStorage.getItem("ZeitroA")
        const response = await fetch('/los/stripe/getorcreatesetupintent', {

            method: 'post',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
            body: JSON.stringify({
                customerId: stripeCustomer?.customer.id

            })

        })


        const { client_secret, customer } = await response.json()
        setPayId(client_secret)
        // setStripeCustomerID(customer.id)
        // console.log(a);

        setContinueLoading(false)

        setIsPay(true)
    }
    const options = {
        // passing the client secret obtained from the server
        clientSecret: payID,
    };
    return (


        <div style={{padding: '0 10px'}}>
            <div className='plansAndPricing-back-btn'><LeftOutlined style={{fontSize: 12}} /> <span onClick={() => {
                back()
            }}>Back</span></div>
            <Row gutter={16} className='upgradeRow'>
                <Col lg={15} span={24}>
                    <div className='plansAndPricing-leftCard'>
                        {isPay ? <Elements
                            stripe={stripePromise}
                            options={options}
                        ><NewInformation userNum={userNum} stripeCustomerID={stripeCustomerID} ref={info} payID={payID} loanOfficers={CurrentLoanOfficers} billingFrequency={isChecked ? 'Annually' : 'Monthly'} getInfo={(e) => {
                            if (e.to) {
                                back()
                                return
                            }

                            if (e.back) {
                                setModalOpen(true)

                            } else {
                                setPayLoading(e.loading)
                            }

                        }} ></NewInformation></Elements> :
                            <div>

                                <div className='lansAndPricing-leftCard-plan' style={{ display: 'flex', justifyContent: "space-between" }}><div>Selected <span style={{ color: "#325CEB" }}>{CurrentLoanOfficers.length}</span> Users to Upgrade</div>       {


                                    userNum > 1 ? <div style={{ color: '#325CEB', cursor: "pointer", textDecorationLine: 'underline' }} onClick={() => {

                                        setIsModalOpen(true)

                                    }}>Manage selected users</div> : <></>
                                }</div>
                                <div className='lansAndPricing-leftCard-tips'>You will be billed based on the amount of active Paid Tier users each subscription cycle.</div>
                                <div className='lansAndPricing-leftCard-plan'>Billing frequency</div>
                                <div className='lansAndPricing-leftCard-radio' style={isChecked ? { border: '2px solid #325CEB', borderRadius: 5 } : { border: '1px solid #D9D9D9', borderRadius: 10 }} onClick={() => {
                                    setIsChecked(true)


                                }}>
                                    <div className='radioContent'>

                                        <div style={{fontSize: 16, color: '#000', lineHeight: '24px', display: 'flex', alignItems: 'baseline'}}>  <Radio checked={isChecked}></Radio><span style={{ margin: '0 8px' }}>Annually</span> <span className='breaks'>Save 10%</span></div>
                                        <div style={{display: 'flex', alignItems: 'flex-start', color: '#222', fontSize: 14}}> <span style={{ fontSize: 16, color: '#000', fontWeight: 700, marginRight: 10 }}> $89</span> per user per month</div>

                                    </div>
                                    <div style={{fontSize: 12, color: '#707070', paddingLeft: 34, marginTop: 12, letterSpacing: '0.5px'}}>You’ll pay $1068 for each user per year, which will save you 10% in costs.</div>

                                </div>
                                <div onClick={() => {
                                    setIsChecked(false)
                                }} className='lansAndPricing-leftCard-radio' style={!isChecked ? { border: '2px solid #325CEB', borderRadius: 5 } : { border: '1px solid #D9D9D9', borderRadius: 10 }}>

                                    <div className='radioContent'>

                                        <div style={{fontSize: 16, color: '#000', lineHeight: '24px', display: 'flex', alignItems: 'baseline'}}>  <Radio checked={!isChecked}></Radio><span style={{ margin: '0 8px' }}>Monthly</span>  </div>
                                        <div style={{display: 'flex', alignItems: 'flex-start', color: '#222', fontSize: 14}}> <span style={{ fontSize: 16, color: '#000', fontWeight: 700, marginRight: 10 }}> $99</span> per user per month</div>

                                    </div>

                                </div>
                                {/* <div className='upgradeRadio' style={{ borderLeft: '10px solid #325CEB' }}>
                                    <div className='radioContentOther'>Included in Zeitro Business Plan</div>
                                    <div className='radioContent'>

                                        <div>  <span style={{ fontWeight: 'bold' }}>Credit Soft Pull</span> </div>
                                        <div> <span style={{ fontWeight: 'bold' }}> $22</span> pull. Billed Month</div>


                                    </div>
                                    <div className='radioContentOther'>Your loan officers will have the option to turn this off.</div>

                                </div> */}

                            </div>
                        }
                    </div>
                </Col>
                <Col lg={9} span={24}>
                    <div className='plansAndPricing-rightCard'>
                        <div className='plansAndPricing-rightCard-title'> Order Summary </div>
                        <div className='lansAndPricing-rightCard-plan' style={{margin: '14px 0 19px'}}>
                            <div>Zeitro Business Plan </div>
                            <div> ${getUSD()} USD</div>
                        </div>
                        <div className='lansAndPricing-rightCard-plan-details'>
                            <div>
                                <div style={{marginBottom: 7}}>{CurrentLoanOfficers.length} user{CurrentLoanOfficers.length > 1 ? 's' : ''}</div>
                                <div>{isChecked ? 'Annual subscription' : "Monthly subscription"}</div>
                            </div>
                            <div style={{fontSize: 10, color: '#707070'}}>{getDom()}</div>
                        </div>
                        <div style={{ color: '#707070', fontSize: 13, lineHeight: '18px', paddingBottom: 22, borderBottom: '1px solid #CCC' }}>
                            By clicking Continue, you are authorizing future charges if you invite additional Paid Tier users.
                        </div>

                        <div className='lansAndPricing-rightCard-plan' style={{margin: '22px 0 38px'}}>
                            <div>Today’s total</div>
                            <div> ${getUSD()} USD</div>
                        </div>
                        {
                            isPay ? <Button loading={payLoading} type="primary" className='lansAndPricing-rightCard-btn' onClick={() => {

                                info.current.click()
                                // setPayLoading(true)

                                // setTimeout(() => {
                                //     setPayLoading(false)
                                // }, 1000)
                            }}>Pay</Button> :

                                <Button loading={continueLoading} type="primary" className='lansAndPricing-rightCard-btn' onClick={() => { clickContinue() }}>Continue</Button>
                        }
                        <div style={{ color: "#70707", marginTop: '16px', fontSize: 10, lineHeight: '14px' }}>
                            Local taxes may apply based on your billing information. Final charges may vary and will be detailed in your email invoice. By clicking Continue, you authorize us to: 1) securely store your payment method; 2) update the billing amount based on the number of Paid Tier users and the usage of the Automated Soft Credit Pull feature; 3) automatically charge you at the end of every billing cycle.
                        </div>
                    </div>
                </Col>

            </Row>
            <Modal open={ModalOpen} footer={null} onCancel={() => {
                back()
                setModalOpen(false)
            }}>
                <div style={{
                    display: 'grid',
                    placeItems: 'center',
                    textAlign: 'center'
                }}>

                    <div>
                        <div>
                            <img src='/images/crown.svg'></img>

                        </div>
                        <div style={{ fontSize: '24px', fontWeight: "bold",marginTop:"18px" }}>Congratulation! </div>
                        <div style={{ fontSize: '16px', fontWeight: "400",    width: '250px' }}>You’ve successfully subscribed to the business plan. </div>
                        <Button onClick={() => {
                          
                            back()
                            setModalOpen(false)
                        }} type='primary' style={{ width: '100%', height: "44px", marginTop: '37px' }}>OK</Button>

                    </div>
                </div>


            </Modal>
            <UserModal isModalOpen={isModalOpen} loanOfficers={CurrentLoanOfficers} closeModal={(e) => {
                console.log(!e);
                if (!e) {

                    setIsModalOpen(false)
                    return
                }
                setCurrentLoanOfficers(e)
                // setUserNum(e.length)
                setIsModalOpen(false)


            }}></UserModal>
        </div>
    )

    function getDom() {

        return (
            <>
                <div style={{ color: '#707070' }}>
                    {isChecked ? <div className='Dom'> $89 x {CurrentLoanOfficers.length} user{CurrentLoanOfficers.length > 1 ? 's' : ''} x 12 months</div> : <div className='Dom'>

                        $99*{CurrentLoanOfficers.length} user{CurrentLoanOfficers.length > 1 ? 's' : ''}
                    </div>}

                </div>

            </>

        )

    }
    function getDate() {
        const today = new Date(); // 获取当前日期时间

        if (isChecked) {
            // 创建一个新的日期对象，年份增加1，其他日期和时间部分保持与today相同
            const nextYearToday = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
            return nextYearToday.toDateString()

        } else {
            const nextMonthToday = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());
            return nextMonthToday.toDateString()
        }


    }
    function getUSD() {
        if (isChecked) {
            return (CurrentLoanOfficers.length * 89 * 12).toFixed(2)

        } else {
            return (CurrentLoanOfficers.length * 99).toFixed(2)

        }

    }

}