import React from 'react';
import { Modal, Button } from 'antd';
import { Link } from 'react-router-dom';
import './MortgageGPT.css';

const LimitReachedModal = ({ isOpen, onClose }) => {
    return (
        <Modal
            open={isOpen}
            onCancel={onClose}
            footer={null}
            width={480}
            centered
            closable={true}
            maskClosable={true}
        >
            <div className="guidelinegpt-limit-reached">
                <div style={{ 
                    fontSize: '21px',
                    lineHeight: '40px',
                    fontWeight: 600,
                    color: '#1A1A1C',
                    marginBottom: '12px'
                }}>
                    Daily Limit Reached
                </div>

                <p style={{
                    color: '#6E6E70',
                    fontSize: '14px',
                    lineHeight: '20px',
                    marginBottom: '24px'
                }}>
                    You've reached your daily limit of 10 questions. 
                    Sign up or log in to continue using GuidelineGPT.
                </p>

                <div style={{
                    marginBottom: '32px'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px'
                    }}>
                        {[
                            'Unlimited GuidelineGPT',
                            '1003 PDF & FNM 3.4 exports',
                            'AI-assisted documents management',
                            'Customizable personal website and more...'
                        ].map((feature, index) => (
                            <div key={index} style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '12px'
                            }}>
                                <img 
                                    src="/images/checkmark.svg" 
                                    alt="Checkmark"
                                    style={{ 
                                        width: 20,
                                        height: 20,
                                        color: '#1E4620'
                                    }}
                                />
                                <span style={{
                                    color: '#1A1A1C'
                                }}>{feature}</span>
                            </div>
                        ))}
                    </div>
                </div>

                <div style={{ 
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Button 
                        type="primary"
                        size="large"
                        block
                        style={{
                            height: '48px',
                            fontSize: '16px',
                            fontWeight: 600,
                            backgroundColor: '#3B3BC7',
                            border: 'none'
                        }}
                        onClick={() => window.location.href = '/signup'}
                    >
                        Create account
                    </Button>
                    
                    <Button 
                        type="link"
                        size="large"
                        block
                        onClick={() => window.location.href = '/'}
                    >
                        Already have an account? Log in
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default LimitReachedModal;
