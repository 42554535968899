import React, { useState, useEffect, useRef } from 'react'
// import { jwtDecode } from "jwt-decode"
import jwt_decode from "jwt-decode"
import { throttle } from 'lodash'

import { RightOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import DividerWithText from "./Common/components/DividerLineWithText"
import { useStatsigClient } from "@statsig/react-bindings/src";
import { updateStatsigUser } from "./statsig/statsigMethod"

function LoginPage({ setLoading }) {
    const [showLine, setLine] = useState(false)
    const [userEmail, setUserEmail] = useState("")
    const [showChooseCompany, setShowChooseCompany] = useState(false)
    const [showAccountNotFound, setShowAccountNotFound] = useState(false)
    const [company, setCompany] = useState([
    ])
    const { client } = useStatsigClient();


    const renderGoogleBtn = () => {
        google.accounts.id.renderButton(
            document.getElementById("googleSignin"),
            {
                width: '400',
                text: 'Sign up with Google',

                size: 'large',
                shape: 'pill',

                logo_alignment: 'center',
            }
        )
        google.accounts.id.prompt()
        setLine(true)

    }

    const handleGoogleCallback = (response) => {

        let userObject = jwt_decode(response.credential)
        setLoading(true)
        setUserEmail(userObject.email)

        fetch("/los/googlesignin", {
            method: "POST",
            body: JSON.stringify(userObject),
            headers: {
                "Content-Type": "application/json",
                "X-GOOGLETOKEN": response.credential,

            },
        })
            .then((response) => {
                if (response.status !== 200) {
                    alert(
                        "Sorry, but we are unable to sign you in using your Google account at this time. Please consider using alternative sign-in methods."
                    )
                    setLoading(false)
                    return
                }
                response.json().then((js) => {
                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                        alert('Sorry, but we are unable to sign you in using your Google account at this time. Please consider using alternative sign-in methods.')
                        // setMsg('')
                        // setShow(true)
                        sessionStorage.removeItem("ZeitroA")
                        sessionStorage.removeItem("state")
                        setLoading(false)
                    } else {
                        const { Claims } = js
                        if (!Claims) {
                            setShowAccountNotFound(true)

                        } else if (Claims.length === 1) {
                            sessionStorage.setItem("ZeitroA", Claims[0].Claim)
                            const payload = jwt_decode(Claims[0].Claim)
                            updateStatsigUser(client, userObject.email.toLowerCase().trim(), payload.customerid)
                            client.logEvent("sign_in_with_google", payload.customerid, {
                                signin_method: "google",
                                item_name: "lo_signin",
                                timestamp: new Date().getTime(),
                                customerid: payload.customerid,
                                email: userObject.email.toLowerCase().trim()
                            });
                            window.location.href = "/app/welcome"

                        } else {
                            setShowChooseCompany(true)
                            setCompany(Claims)
                        }

                        // history.push('/application/myloans')
                        setLoading(false)
                    }
                })
            })
            .catch(function (err) {
                console.log("Fetch Error :-S", err)
            })
    }

    useEffect(() => {
        /* global google */
        google.accounts.id.initialize({
            client_id: "265683149831-9a27eaupjqjt6vh5vuaa54bfld627fre.apps.googleusercontent.com",
            callback: handleGoogleCallback,
        })
        renderGoogleBtn()
        const handleResize = throttle((event) => {
            renderGoogleBtn()
        }, 200)
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => {
            setLine(false)
            window.removeEventListener('resize', handleResize)
        }

    }, [])

    const displayAccountNotFoundModal = () => {
        if (showAccountNotFound) {
            return <Modal
                keyboard={false}
                className="chooseCompany-modal"
                title='Account not found'
                open={showAccountNotFound}
                footer={null}
                onCancel={() => {
                    setShowAccountNotFound(false)
                }}
            >
                <div className="py-4">
                    <div >The email address you used to sign in with Google is not associated with any accounts in our system. </div>
                    <div className="mt-2">Please <a href="https://lo.zeitro.com/signup">sign up a new account</a>, or use a different email address.</div>
                </div>
            </Modal>
        }
        return ""
    }

    return <div>
        {showLine ? <DividerWithText text="or sign in with" /> : <></>}
        <div className=" mt-2" style={{ margin: "auto", width: "400px" }}>

            <div id="googleSignin" >

            </div>
            <Modal
                keyboard={false}
                className="chooseCompany-modal"
                title='Join the following orginizations'
                open={showChooseCompany}
                footer={null}
                onCancel={() => {
                    // this.editEmail('')
                    setShowChooseCompany(false)
                }
                }>
                {
                    (
                        <>
                            <div className="ft-500">is associated with accounts at the following orginizations, please select one to log in.</div>
                            <div className="chooseCompany-list">
                                {
                                    company.map((item, index) => (
                                        <div key={index} className="chooseCompany" onClick={() => {
                                            sessionStorage.setItem("ZeitroA", item.Claim)
                                            const payload = jwt_decode(item.Claim)
                                            updateStatsigUser(client, userEmail.toLowerCase().trim(), payload.customerid)
                                            client.logEvent("sign_in_with_google", payload.customerid, {
                                                //the following fields can be customized
                                                signin_method: "google",
                                                item_name: "lo_signin",
                                                timestamp: new Date().getTime(),
                                                customerid: payload.customerid,
                                                email: userEmail.toLowerCase().trim()
                                            });
                                            window.location.href = "/app/welcome"
                                            setShowChooseCompany(false)
                                        }}>
                                            <div>{item.CompanyName}</div>
                                            <RightOutlined />
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    )
                }
            </Modal>
            {displayAccountNotFoundModal()}
        </div>
    </div>
}

export default LoginPage