import React, { Component } from "react";
import { connect } from "react-redux";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { Button, Card, Select } from 'antd';
import { Form, Spinner } from "react-bootstrap"

import "./LoanSummary.css";
import "../App.css";
import * as com from "../Common";
import * as act from "../Store/actions";
import { calculateEmploymentIncome, calculateDividendIncome, calculateOtherIncome } from '../Interview/Income';
import { checkAccess } from "../Auth";
import { UR_LoanProcessor } from "../State";
import CurrentStage from "../BorrowerStageStatus/NonHeader/CurrentStage";
import CurrentStatus from "../BorrowerStageStatus/NonHeader/CurrentStatus";


const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateApplicationAttribute: (t, verb) => {
        dispatch(act.UpdateApplicationAttribute(t, verb))
    },
    updateSelectedLoanAttribute: (t, verb) => {
        dispatch(act.UpdateSelectedLoanAttribute(t, verb))
    },
})

const manufacturedHomeTypeMap = {
    "SingleWide": "Single Wide",
    "DoubleWide": "Double Wide",
    "TripleWide": "Triple Wide",
}

const parkLocationMap = {
    "Park": "Park",
    "LandorHome": "Land/home",
    "CoopPark": "Co-op park",
    "SharedPark": "Shared park",
}

export default class LoanOverview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentLoanProcessor: { ID: "" },
            allLoanProcessors: [],
            loading: false,
        }
    }
    componentDidMount() {
        this.getLoanProcessor()
    }

    getRow = (label1, d1, label2, d2, classname) => {
        return <div className={classname ? classname + " row" : "row"} >
            <div className="col px-0" style={{ marginTop: 22 }}>
                <div className=" newLabel">{label1}</div>
                <div className="newLabelText">{d1}</div>
            </div>
            <div className="col" style={{ marginTop: 22 }}>
                <div className="newLabel">{label2 ? label2 : ""}</div>
                <div className="newLabelText">{d2 ? d2 : ""}</div>
            </div>
        </div>
    }

    displayApplicant = (who) => {
        if (this.props.application[who].firstname === "") {
            return (
                <div>{who === "coborrower" ? "Coborrower" : "Borrower"} information has not been provided yet.</div>
            )
        }

        return (
            <div >
                {this.getRow("First name", this.props.application[who].firstname, "Last name", this.props.application[who].lastname)}
                {this.getRow("Address", com.getPrimaryAddress(who, this.props.application), "Citizenship", this.props.application[who].citizenship !== null ? this.props.application[who].citizenship : "-", "mt-2")}
            </div>
        )
    };
    showButton = (s) => {
        if (this.props.borrowerid != null) return ""
        return s
    };
    getLoanProcessor = () => {
        this.setState({ loading: true })
        let token = sessionStorage.getItem("ZeitroA")
        const paths = window.location.pathname.split('/')
        fetch('/los/getloanprocessor', {
            body: JSON.stringify({ LoanID: paths[paths.length - 1] }),
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status)
                    this.setState({ loading: false })
                    return
                }
                response.json().then(js => {

                    this.setState({
                        allLoanProcessors: js.AllLP,
                        currentLoanProcessor: js.CurrentLP,
                    })
                    this.setState({ loading: false })
                })
            }
        )
            .catch(function (err) {
                this.setState({ loading: false })
                console.log('Fetch Error :-S', err)
            })
    }
    updateLoanProcessor = (id) => {
        let token = sessionStorage.getItem("ZeitroA")
        const paths = window.location.pathname.split('/')
        this.setState({
            currentLoanProcessor: {
                ID: id
            }
        })
        fetch('/los/updateloanprocessor', {
            body: JSON.stringify({ LoanID: paths[paths.length - 1], LoanProcessorID: id }),
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status)
                    return
                }
            }
        )
            .catch(function (err) {
                console.log('Fetch Error :-S', err)
            })
    }
    displayLoanProcessor = () => {
        let options = [<option value="unassigned"> Unassigned</option>,

        ]
        this.state.allLoanProcessors.forEach(x => {
            options.push(<option value={x.ID}> {x.FirstName + " " + x.LastName}</option>)
        })
        return <div className="mt-2 title-5-bold">
            Assign this loan to
            <div>

                <Select style={{ width: 200, marginTop: 10 }} defaultValue={this.state.currentLoanProcessor.ID !== "" ? this.state.currentLoanProcessor.ID : "unassigned"} onChange={(e) => {
                    this.updateLoanProcessor(e)
                }} value={this.state.currentLoanProcessor.ID !== "" ? this.state.currentLoanProcessor.ID : "unassigned"}>
                    {options}
                    {/* <option value="unassigned"> Unassigned</option> */}
                </Select>
            </div>
            {/* <Form.Control
                style={{ width: "200px", marginTop: 5 }}
                as="select"
                defaultValue={typeof this.state.currentLoanProcessor.ID !== "" ? this.state.currentLoanProcessor.ID : "unassigned"}
                onChange={(e) => {
                    this.updateLoanProcessor(e.target.value)
                }}
            >
                {options}
            </Form.Control> */}
        </div>
    }
    displayApplicants = () => {
        let out = []
        out.push(this.displayApplicant("borrower"))

        if (
            this.props.application.hascoborrower === "withcoborrower" ||
            this.props.application.hascoborrower === "multiple"
        ) {
            out.push(this.displayApplicant("coborrower"))
        }
        return out
    };
    displayLoan = () => {
        if (this.props.application.property.appraisal === "" && this.props.application.property.salesprice === "") {
            return <div>Loan information has not been provided yet.</div>
        }

        let isPreapproval = () => {
            return (
                false === this.props.application.property.hascontract &&
                this.props.application.property.purpose === "purchase"
            )
        }
        let getAddress = () => {
            let st = this.props.application.property.address
            if (isPreapproval()) return "TBD"
            return st
        }

        let purpose
        switch (this.props.application.property.purpose) {
            case "refinance": {
                purpose = "refinance loan"
                break
            }
            case "cashoutrefinance": {
                purpose = "cash out refinance loan"
                break
            }
            default:
            case "purchase": {
                purpose = "purchase loan"
                break
            }
        }

        if (isPreapproval()) {
            purpose = "preapproval application"
        }
        let propertyType = () => {
            switch (this.props.application.property.propertytype) {
                case "singlefamilyattached":
                    return "Single family attached"
                case "singlefamilydetached":
                    return "Single family detached"
                case "condominium":
                    return "Condominium attached"
                case "condominiumdetached":
                    return "Condominium detached"
                case "pudattached":
                    return "PUD attached"
                case "puddetached":
                    return "PUD detached"
                case "twotofourfamily":
                    return "2 to 4 Unit Multifamily"
                case "mobilehome":
                    return "Manufactured home"
                default:
                    return "Unknown property type"
            }
        }
        let occupancy = () => {
            switch (this.props.application.property.occupancy) {
                case "principal":
                    return "Primary residence"
                case "secondhome":
                    return "Second home"
                case "investment":
                    return "Investment property"
                default:
                    return "-"
            }
        }

        return (
            <div className="mt-3">
                {this.getRow("Application type", purpose, "Property location", getAddress())}
                {this.getRow("Property type", propertyType(), "Occupancy", occupancy(), "mt-2")}
                {this.getRow("Expected home value", "$" + com.commaizeFloat(this.props.application.property.appraisal), "Purchase price", "$" + com.commaizeFloat(this.props.application.property.salesprice), "mt-2")}
                {!!this.props.application.selection.downpayment && this.getRow("Loan amount", "$" + com.commaizeFloat(this.props.application.property.salesprice - this.props.application.selection.downpayment), "Down payment", "$" + com.commaizeFloat(this.props.application.selection.downpayment), "mt-2")}
            </div>
        )
    };
    displayMobileHomeInfo = () => {
        if (this.props.application.property.propertytype !== "mobilehome") return ""
        let mh = this.props.application.property.mobilehomeinfo

        let getWidth = () => {
            if (!mh.widthft) return "-"
            let f = mh.widthft + " ft"
            let i = !mh.widthinch ? "" : mh.widthinch + " inch"
            return f + " " + i
        }
        let getLength = () => {
            if (!mh.lengthft) return "-"
            let f = mh.lengthft + " ft"
            let i = !mh.lengthinch ? "" : mh.lengthinch + " inch"
            return f + " " + i
        }
        return <div className="mt-5 text-wrap">
            <Card type="inner" title='Manufactured home info'>
                <div className="mt-3">
                    {this.getRow("Manufactured home park full name", mh.mobilehomeparkfullname, "Monthly space rent", "$" + com.commaize(mh.leasedSpaceRentAmount))}
                    {this.getRow("Park Address", mh.parkAddress, "Space number", mh.space || "-")}
                    {this.getRow("Year built", mh.year, "Type", manufacturedHomeTypeMap[mh.mobilehometype], "mt-2")}
                    {this.getRow("Width", getWidth(), "Length", getLength(), "mt-2")}
                    {this.getRow("Manufacturer make", mh.manufacturerMake || "-", "Manufacturer model", mh.manufacturerModel || "-")}
                    {this.getRow("Condition", mh.mobilehomedescription, "Location", parkLocationMap[mh.mobilehomelocation])}
                    
                    {this.props.application.property.purpose === "ratetermrefinance" && this.getRow("Original purchase price", "$" + com.commaize(mh.originalpurchaseprice), "", "", "mt-2")}
                    {this.props.application.property.purpose === "cashoutrefinance" && this.getRow("Original purchase price", "$" + com.commaize(mh.originalpurchaseprice), "Cash out amount requested", "$" + com.commaize(mh.cashOutRequested), "mt-2")}
                </div>
                {mh.debtsToBePaid.length > 0 && <div>
                    <div className="title-5-bold mt-2">Debts to be paid:</div>
                    <ul>{mh.debtsToBePaid.map(d => <li>{d.description}: ${com.commaize(d.amount)}</li>)}</ul></div>}
            </Card>

        </div>
    }

    getEmploymentStatus = (who) => {
        let tmp = []

        if (this.props.application[who].occupation.hasoccupation === null) {
            return <div>{who === "coborrower" ? "Coborrower" : "Borrower"} information has not been provided yet.</div>
        }

        switch (this.props.application[who].occupation.hasoccupation) {

            case "unemployed": default:
                tmp.push(this.getRow("Employment", "Unemployed"))
                break
            case "retired":
                tmp.push(this.getRow("Employment", "Retired"))
                break

            case "employed":
                tmp.push(<div>
                    {this.getRow("Employer", this.props.application[who].occupation.employername, "Position", this.props.application[who].occupation.positiontitle, "mb-2")}
                    {this.getRow("Monthly income", "$" + com.commaizeFloat(calculateEmploymentIncome(this.props.application, who).toFixed(0)))}
                </div>)

                if (this.props.application[who].otheroccupations !== null && this.props.application[who].otheroccupations.length > 0) {
                    tmp.push(<div className="title-5-bold">Also employed at:</div>)
                    for (let i = 0; i < this.props.application[who].otheroccupations.length; i++) {
                        let o = this.props.application[who].otheroccupations[i]
                        tmp.push(<div><b>{o.employername}</b>, at {o.employeraddress} as <b>{o.positiontitle}</b> </div>)
                    }
                }
                break

        }
        if (calculateDividendIncome(this.props.application, who) > 0) {
            tmp.push(<div>Gross <b>monthly</b> dividends: <b>${com.commaizeFloat(calculateDividendIncome(this.props.application, who).toFixed(2))}</b> (yearly dividends of  <b>${com.commaizeFloat((12 * calculateDividendIncome(this.props.application, who)).toFixed(2))}</b>)</div>)
        }
        if (calculateOtherIncome(this.props.application, who) > 0) {
            tmp.push(<div>Gross <b>monthly</b> other income: <b>${com.commaizeFloat(calculateOtherIncome(this.props.application, who).toFixed(2))}</b> (yearly amount of  <b>${com.commaizeFloat((12 * calculateOtherIncome(this.props.application, who)).toFixed(2))}</b>)</div>)
        }
        return (
            <div className="mt-3">
                {tmp}
            </div>

        )
    }
    displayIncome = () => {
        if (this.props.application.borrower.occupation.hasoccupation === null) {
            return <div>Employment information has not been provided yet.</div>
        }

        let statuses = []
        statuses.push(this.getEmploymentStatus("borrower"))
        if (this.props.application.hascoborrower === "withcoborrower" ||
            this.props.application.hascoborrower === "multiple") {
            statuses.push(this.getEmploymentStatus("coborrower"))
        }
        return statuses
    }

    displayAssets = () => {
        let btn = <Button className="ml-4 my-1 py-0"
            href={"/app/interview/main/assets"} size="sm" variant="outline-primary"> Edit</Button>
        let out = []
        let totalbanks = [0, 0, 0]
        let totalbrokoerages = [0, 0, 0]
        let getname = (b) => {
            return this.props.application[b].firstname + " " + this.props.application[b].lastname
        }


        this.props.application.assetsandliabilities.accounts &&
            this.props.application.assetsandliabilities.accounts.forEach(x => {
                let attribution = x.attribution == null ? 0 : x.attribution - 1
                if (x.balance !== "") {
                    totalbanks[attribution] += parseFloat(x.balance)
                }
            })
        if (totalbanks[0] + totalbanks[1] + totalbanks[2] > 0) {
            out.push(<div className="mt-4 font-weight-bold">Bank balances:</div>)
            if (totalbanks[0] > 0) {
                out.push(<div>{getname("borrower")}: <b>${com.commaizeFloat(totalbanks[0].toFixed(2))}</b> </div>)
            }
            if (totalbanks[1] > 0) {
                out.push(<div>{getname("coborrower")}: <b>${com.commaizeFloat(totalbanks[1].toFixed(2))}</b></div>)
            }
            if (totalbanks[2] > 0) {
                out.push(<div>Joint accounts: <b>${com.commaizeFloat(totalbanks[2].toFixed(2))}</b></div>)
            }
        }
        this.props.application.assetsandliabilities.brokerages &&
            this.props.application.assetsandliabilities.brokerages.forEach(x => {
                let attribution = x.attribution == null ? 0 : x.attribution - 1
                if (x.balance !== "") {
                    totalbrokoerages[attribution] += parseFloat(x.balance)
                }
            })

        if (totalbrokoerages[0] + totalbrokoerages[1] + totalbrokoerages[2] > 0) {
            out.push(<div className="mt-4 font-weight-bold">Brokerage accounts balances:</div>)
            if (totalbrokoerages[0] > 0) {
                out.push(<div>{getname("borrower")}: <b>${com.commaizeFloat(totalbrokoerages[0].toFixed(2))}</b></div>)
            }
            if (totalbrokoerages[1] > 0) {
                out.push(<div>{getname("coborrower")}: <b>${com.commaizeFloat(totalbrokoerages[1].toFixed(2))}</b></div>)
            }
            if (totalbrokoerages[2] > 0) {
                out.push(<div>Joint accounts: <b>${com.commaizeFloat(totalbrokoerages[2].toFixed(2))}</b></div>)
            }
        }

        btn = <Button className="ml-4 my-1 py-0"
            href={"/app/interview/main/22"} size="sm" variant="outline-primary"> Edit</Button>
        if (this.props.application.assetsandliabilities.gifts != null && this.props.application.assetsandliabilities.gifts.length > 0) {

            out.push(<div className="mt-4 font-weight-bold">Gifts:</div>)
            let gifts = this.props.application.assetsandliabilities.gifts
            let totalgifts = [0, 0, 0]
            for (let i = 0; i < gifts.length; i++) {
                let attribution = gifts[i].attribution == null ? 0 : gifts[i].attribution - 1
                totalgifts[attribution] += parseFloat(gifts[i].value)
            }
            if (totalgifts[0] > 0) {
                out.push(<div>{getname("borrower")}: <b>${com.commaizeFloat(totalgifts[0].toFixed(2))}</b></div>)
            }
            if (totalgifts[1] > 0) {
                out.push(<div>{getname("coborrower")}: <b>${com.commaizeFloat(totalgifts[1].toFixed(2))}</b></div>)
            }
            if (totalgifts[2] > 0) {
                out.push(<div>Joint ownership: <b>${com.commaizeFloat(totalgifts[2].toFixed(2))}</b></div>)
            }
        }
        if (this.props.application.assetsandliabilities.otherassets != null && this.props.application.assetsandliabilities.otherassets.length !== 0) {
            out.push(<div className="mt-4 font-weight-bold">Other assets:</div>)
            let other = this.props.application.assetsandliabilities.otherassets
            let totalother = [0, 0, 0]
            for (let i = 0; i < other.length; i++) {
                let attribution = other[i].attribution == null ? 0 : other[i].attribution - 1
                totalother[attribution] += parseFloat(other[i].value)
            }
            if (totalother[0] > 0) {
                out.push(<div>{getname("borrower")}: <b>${com.commaizeFloat(totalother[0].toFixed(2))}</b></div>)
            }
            if (totalother[1] > 0) {
                out.push(<div>{getname("coborrower")}: <b>${com.commaizeFloat(totalother[1].toFixed(2))}</b></div>)
            }
            if (totalother[2] > 0) {
                out.push(<div>Joint ownership: <b>${com.commaizeFloat(totalother[2].toFixed(2))}</b></div>)
            }
        }
        if (out.length === 0) {
            out.push(<div>No assets was added.</div>)
        }

        return out
    }

    render() {
        let total = null
        let monthlypayment = 0
        let rate = null
        let remainingbalance = 0
        let totalloans = 0
        let totalhelocs = 0
        let totalhelocbalance = 0
        let totalhelocpayment = 0

        let calculateMortgage = () => {
            if (null == total) {
                total = 0
                let loans = this.props.application.assetsandliabilities.loans
                if (loans === null) {
                    return
                }
                for (let i = 0; i < loans.length; i++) {
                    let loan = loans[i]
                    if (loan.correction !== "none") continue
                    if (loan.creditloantype !== null) {
                        if (!(loan.creditloantype.toLowerCase().indexOf("mortgage") >= 0 ||
                            loan.creditloantype.toLowerCase().indexOf("homeequity") >= 0 ||
                            loan.creditloantype.toLowerCase().indexOf("realestate") >= 0)) continue
                        if (loan.creditloantype.toLowerCase().indexOf("homeequity") >= 0) {
                            totalhelocs += 1
                            totalhelocbalance += parseFloat(loan.remainingbalance)
                            totalhelocpayment += parseFloat(loan.monthlypayment)
                            continue
                        }
                    }

                    if (rate == null)
                        rate = loan.rate
                    remainingbalance += parseFloat(loan.remainingbalance)
                    totalloans += 1
                    monthlypayment += parseFloat(loan.monthlypayment)
                }
                // now go over manual loans
                loans = this.props.application.assetsandliabilities.properties
                for (let i = 0; loans != null && i < loans.length; i++) {
                    let loan = loans[i]
                    if (loan.ownedfreeandclear === true) continue


                    remainingbalance += parseFloat(loan.liens)
                    totalloans += 1
                    monthlypayment += parseFloat(loan.payment)
                }
                loans = this.props.application.otherliens
                for (let i = 0; loans != null && i < loans.length; i++) {
                    let loan = loans[i]
                    if (loan.balance === "")
                        continue

                    remainingbalance += parseFloat(loan.balance)
                    totalloans += 1
                    monthlypayment += parseFloat(loan.monthly)
                }
            }
        }
        calculateMortgage()
        let totalMortgagePayments = () => {
            return monthlypayment
        }
        let countMortgages = () => {
            return totalloans
        }
        let mortgageBalance = () => {

            if (remainingbalance === "")
                remainingbalance = 0
            return remainingbalance
        }
        let exportToPDF = async () => {
            // 获取目标div元素
            const element = document.getElementById('exportableDiv')
            const pdf = new jsPDF('p', 'pt', 'a4')

            const scale = window.devicePixelRatio > 1 ? window.devicePixelRatio : 2
            let width = element.offsetWidth
            let height = element.offsetHeight
            const canvas = document.createElement('canvas')
            canvas.width = width * scale
            canvas.height = height * scale

            const pdfCanvas = await html2canvas(element, {
                useCORS: true,
                scale: scale,
                width,
                height,
                x: 0,
                y: 0,
            })
            const imgData = pdfCanvas.toDataURL('image/png')
            if (height > 14400) {
                const ratio = 14400 / height
                height = 14400
                width = width * ratio
            }
            height = height * pdf.internal.pageSize.getWidth() / width
            width = pdf.internal.pageSize.getWidth() - 60
            const imgProps = pdf.getImageProperties(imgData)
            const pdfWidth = pdf.internal.pageSize.getWidth()
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
            let heightLeft = pdfHeight

            const pageHeight = pdf.internal.pageSize.getHeight()
            let position = 60
            pdf.addImage(imgData, 'PNG', 30, position, width, height)
            heightLeft -= pageHeight
            while (heightLeft >= 0) {
                position = heightLeft - pageHeight
                pdf.addPage()
                pdf.addImage(imgData, 'PNG', 30, position, width, height)
                heightLeft -= pageHeight
            }
            if (this.props.application.hascoborrower === "withcoborrower" || this.props.application.hascoborrower === "alone") {
                // pdf.addImage(imgDataUrl, 'png', 30, 60, width, height)
                await pdf.save("Loan Summary", { returnPromise: true })
            }
        }
        const paths = window.location.pathname.split('/');
        const loanID = paths[paths.length - 1];
        return (
            this.state.loading ? <Spinner animation="border" /> :
                <div className="mb-5  text-left px-5 " >
                    <div className="top_info">
                        <div>
                            <div className="title-3">Loan Summary</div>
                            <div className="title-5">Quick review applicant's information and loan information</div>
                        </div>
                        <Button onClick={exportToPDF} className='downloadButton'>Download loan summary as PDF</Button></div>
                    <div className="text-left mt-4 mb-1 pt-2 pl-0 flex flex-row align-items-center">
                        <div className="mr-4 col-1 pl-0" style={{ fontWeight: 600, fontSize: 14 }}>Main Stage</div>
                        <CurrentStage
                            currentStage={this.props.application.currentStage}
                            loanID={loanID}
                        />
                        <div className="col"></div>
                    </div>
                    <div className="text-left mt-1 mb-4 pt-2 pl-0 flex flex-row align-items-center">
                        <div className="mr-4 col-1 pl-0"  style={{ fontWeight: 600, fontSize: 14 }}>Status</div>
                        <CurrentStatus
                            currentStatus={this.props.application.currentStatus}
                            currentStage={this.props.application.currentStage}
                            loanID={loanID}
                        />
                        <div className="col"></div>
                    </div>
                    <div className="text-left mt-4 mb-4 pt-2">
                        {!checkAccess([UR_LoanProcessor]) && <div className="">
                            <div className="title-4">
                                Loan Processor
                            </div>
                            {this.displayLoanProcessor()}
                        </div>}
                        <div id="exportableDiv">
                            <div className="mt-5 text-wrap">
                                <Card type="inner" title={this.props.application.hascoborrower === "withcoborrower" ||
                                    this.props.application.hascoborrower === "multiple"
                                    ? "Applicants"
                                    : "Applicant"}>

                                    {this.displayApplicants()}
                                </Card>
                                {/* <div className="title-4">
                  {this.props.application.hascoborrower === "withcoborrower" ||
                    this.props.application.hascoborrower === "multiple"
                    ? "Applicants"
                    : "Applicant"}
                  :
                </div> */}

                            </div>


                            <div className="mt-5 text-wrap">
                                <Card type="inner" title='Loan'>
                                    {this.displayLoan()}
                                </Card>
                                {/* <div className="text-left title-4">Loan</div> */}

                            </div>
                            {this.displayMobileHomeInfo()}
                            <div className="mt-5 text-wrap">
                                <Card type="inner" title='Income'>
                                    {this.displayIncome()}
                                </Card>
                                {/* <div className="text-left title-4">Income</div>
                                {this.displayIncome()} */}
                            </div>
                            <div className="mt-5 text-wrap">
                                <Card type="inner" title='Assets'>
                                    {this.displayAssets()}
                                </Card>
                                {/* <div className="text-left title-4">Assets</div>
                                {this.displayAssets()} */}
                            </div>
                            <div className="mt-5 text-wrap">
                                <Card type="inner" title='Expenses'>
                                    {(mortgageBalance() === 0 || totalMortgagePayments() === 0) ? <div>No existing mortgages.</div> :
                                        <div className="mt-3">
                                            {this.getRow("Monthly mortgage payments", "$" + com.commaizeFloat(totalMortgagePayments()), "Total balance", "$" + com.commaizeFloat(mortgageBalance()))}
                                        </div>
                                    }
                                </Card>
                                {/* <div className="text-left title-4">Expenses</div> */}
                                {/* {(mortgageBalance() === 0 || totalMortgagePayments() === 0) ? <div>No existing mortgages.</div> :
                                    <div className="mt-3">
                                        {this.getRow("Monthly mortgage payments", "$" + com.commaizeFloat(totalMortgagePayments()), "Total balance", "$" + com.commaizeFloat(mortgageBalance()))}
                                    </div>
                                } */}
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}
LoanOverview = connect(
    mapStateToProps,
    mapDispatchToProps,
    null
)(LoanOverview)
