import { AdvancedCheckFilterOptions } from "./AdvancedCheckSearchLogs";
import { LogType } from "./types";

export const getSearchLogResponse = async (
  logType: LogType
): Promise<Response> => {
  const token = sessionStorage.getItem("ZeitroA");
  const response = await fetch(`/los/search_logs/?logType=${logType}`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      Cache: "no-cache",
      "Content-Type": "application/json",
    },
  });
  return response;
};

/**
 * Save the advanced check search log to the database.
 * This function will save every field in the log, even if it's empty.
 * @param log - The advanced check search log to save
 */
export const saveAdvancedCheckSearchLog = async (
  log: AdvancedCheckFilterOptions
) => {
  const token = sessionStorage.getItem("ZeitroA");
  console.log("Saving advanced check search log", log);
  const response = await fetch(`/los/search_logs/`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      Cache: "no-cache",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      logType: LogType.ADVANCED,
      log,
    }),
  });
  if (response.status !== 200) {
    console.error("Failed to save advanced check search log");
  } else {
    console.log("Advanced check search log saved");
  }
};
