import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Personal, { Personal1, Personal2 } from './Personal'
import Finances, { MoreFinances } from './Finances'
import Expenses from './Expenses'
import Income, { IncomeDetails } from './Income'
import Property, { MoreProperty } from './Property'
import Coborrower from './Coborrower'
import RemoteCoborrowers from './RemoteCoborrowers'
import PreChoice from './PreChoice'
import Liabilities from './Liabilities'
import MoreLiabilities from './MoreLiabilities'
import AlimonyAndMore from './AlimonySolar'
import Loan from './Loan'
import Race from './Race'
//import Form1003 from "./Form1003"
import Declarations from './Declarations'
import Introduction from './Introduction'
import { withRouter } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import PersonalReview from './PersonalReview'
import LiabilitiesReview from './LiabilitiesReview'
import IncomeReview from './IncomeReview'
import { OtherInfoPersonal2 } from "./AllOtherInfo";


import ProductPicker from './ProductPicker'
import * as act from "../Store/actions"
import { connect } from 'react-redux'
import { UpdateChangeProgress, UpdateChangeVisitedStage, UpdateChangeLastTouch } from "../Store/progress"
import { getCurrentState } from '../Store'
import * as com from "../Common.js"
import { UR_LoanProcessor } from "../State";

/*
const title = <h2><img className="pb-2 mr-3" alt="" style={{ width: "auto", height: "36px" }} src="/stamp.png" />Please sign the authorization form</h2>
const signedtitle = <h2 className=" heading"><img className="pb-2 mr-3" alt="" style={{ width: "auto", height: "36px" }} src="/stamp.png" />Authorization form is signed</h2>
*/
const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
        hascoborrower: state.application.hascoborrower,
        borrower: state.borrower
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateChangeProgress: (step, stage) => {
        dispatch(UpdateChangeProgress(step, stage))
    },
    updateChangeLastTouch: (t) => {
        dispatch(UpdateChangeLastTouch(t))
    },    
    updateChangeVisitedStage: (step, stage) => {
        dispatch(UpdateChangeVisitedStage(step, stage))
    },
    updateApplicationAttribute: (input, verb) => {
        dispatch(act.UpdateApplicationAttribute(input, verb))
    },
});
class SubInterview extends Component {
    returnTrue = () => true
    returnFalse = () => false
    personalCoborrower = () => {
        return this.props.application.hascoborrower === "withcoborrower" ||
            (this.props.application.hascoborrower === "multiple" && this.props.application.married)

    }
    returnTruePurchase = () => {        
        return this.props.application.property.purpose === "purchase"
    }    

    notRemote = () => {
        return this.props.application.hascoborrower !== "remote"
    }

    coborrowerNotCohabiting = () => {

        return this.props.application.hascoborrower === "withcoborrower" && !this.props.application.cohabiting
    }
    coborrowerNotJoint = () => {
        return this.props.application.hascoborrower === "withcoborrower" && !this.props.application.married
    }
    coborrowerNotJointPurchase = () => {
        if( this.props.application.property.purpose !== "purchase")
            return false        
        return this.props.application.hascoborrower === "withcoborrower" && !this.props.application.married
    }    

    checkExpenses = () => {

        return !(this.props.application.property.purpose === "purchase" && this.props.application.borrower.presentaddressownership === "rentfree")
    }
    checkPreChoice = () => {

        if (this.props.application.hascoborrower === "remote")
            return false
        return  true
    }


    checkCoExpenses = () => {

        if (this.props.application.hascoborrower === "alone")
            return false
        if (this.props.application.hascoborrower === "remote")
            return false
        if (this.props.application.hascoborrower === "multiple" && this.props.application.married)
            return false
        if (this.props.application.hascoborrower === "withcoborrower" && this.props.application.married)
            return false

        return !(this.props.application.property.purpose === "purchase" &&
            this.props.application.coborrower.presentaddressownership === "rentfree")
    }
    remoteCoborrowers = () => {
        return this.props.hascoborrower === "multiple"
    }
    returnProperty = () => {
        let ret = this.props.application.hascoborrower !== "remote"
        return ret
    }
    returnProductPicker = () => {
        return this.props.hascoborrower !== "remote"
    }
    returnMoreProperty = () => {
        return this.props.hascoborrower !== "remote"
    }

    getURLIndex = (section) => {
        let newIndex = 0
        if ("undefined" != typeof com.stageToNumber[section]) {
            newIndex = com.stageToNumber[section]
        } else {
            try {
                newIndex = parseInt(section);
            } catch (x) {
                newIndex = 0;
            }
        }

        return newIndex
    }


    checkAuthorizationValidity = v => {
        try {
            v = v.replace('null', '{}') // temp hack

            let js = JSON.parse(v)
 
            if (this.props.application.hascoborrower === "alone" || this.props.application.hascoborrower === "remote") {
                return this.props.application.borrower.socialsecurity === js.application.borrower.socialsecurity
            }
            if (this.props.application.hascoborrower === "withcoborrower") {
                return this.props.application.borrower.socialsecurity === js.application.borrower.socialsecurity &&
                    this.props.application.coborrower.socialsecurity === js.application.coborrower.socialsecurity
            }

            if (this.props.application.hascoborrower === "multiple") {
                if (this.props.application.married) {
                    return this.props.application.borrower.socialsecurity === js.application.borrower.socialsecurity &&
                        this.props.application.coborrower.socialsecurity === js.application.coborrower.socialsecurity
                } else {
                    return this.props.application.borrower.socialsecurity === js.application.borrower.socialsecurity
                }
            }
  
            return false

        } catch (x) {
            return false
        }
    }
    constructor(props) {
        super(props);
        this.form = React.createRef();

        this.wizard = []
        // {
        //     let r = React.createRef()
        //     this.wizard.push([<Introduction borrowerid={this.props.borrowerid}  ref={r} self={this.form} />, this.returnTrue, r])
        // }
        {
            let r = React.createRef()
            this.wizard.push([<Coborrower ref={r} self={this.form} />, this.notRemote, r])
        }
        {
            let r = React.createRef()
            this.wizard.push([<RemoteCoborrowers ref={r} self={this.form} />, this.remoteCoborrowers, r])
        }
        {
            let r = React.createRef() // 2
            this.wizard.push([<Personal borrowerid={this.props.borrowerid} who={com.borrower} ref={r} self={this.form} />, this.returnTrue, r])
        }
        {
            let r = React.createRef()
            this.wizard.push([<Personal borrowerid={this.props.borrowerid} who={com.coborrower} ref={r} self={this.form} />, this.personalCoborrower, r])
        }
        // {
        //     let r = React.createRef()
        //     this.wizard.push([<Personal2 who={com.borrower} ref={r} self={this.form} />, this.returnTrue, r])
        // }
        // {
        //     let r = React.createRef()
        //     this.wizard.push([<Personal2 who={com.coborrower} ref={r} self={this.form} />, this.personalCoborrower, r])
        // }        
        {
            let r = React.createRef() // 4
            this.wizard.push([<Property ref={r} self={this.form} borrowerid={this.props.borrowerid} />, this.returnProperty, r])
        }
        {
            let r = React.createRef()
            this.wizard.push([<PersonalReview ref={r} self={this.form} borrowerid={this.props.borrowerid} />, this.returnTrue, r])
        }       
        {
            let r = React.createRef() // 6 income
            this.wizard.push([<IncomeDetails borrowerid={this.props.borrowerid}  who={com.borrower} ref={r} self={this.form} />, this.returnTrue, r])
        }
        {
            let r = React.createRef()
            this.wizard.push([<Income who={com.borrower} ref={r} self={this.form} />, this.returnTrue, r])
        }        
        {
            let r = React.createRef()
            this.wizard.push([<IncomeDetails borrowerid={this.props.borrowerid}  who={com.coborrower} ref={r} self={this.form} />, this.personalCoborrower, r])
        }
        {
            let r = React.createRef()
            this.wizard.push([<Income who={com.coborrower} ref={r} self={this.form} />, this.personalCoborrower, React.createRef()])
        }                
        {
            let r = React.createRef() // 10 finance
            this.wizard.push([<Finances who={com.borrower} ref={r} self={this.form} />, this.returnTrue, r])
        }
        {
            let r = React.createRef()
            this.wizard.push([<MoreFinances who={com.borrower} ref={r} self={this.form} />, this.returnTruePurchase, r])
        }
        {
            let r = React.createRef()
            this.wizard.push([<Finances who={com.coborrower} ref={r} self={this.form} />, this.coborrowerNotJointPurchase, r])
        }
        {
            let r = React.createRef()
            this.wizard.push([<MoreFinances who={com.coborrower} ref={r} self={this.form} />, this.coborrowerNotJoint, r])
        }
        {
            let r = React.createRef()
            this.wizard.push([<IncomeReview ref={r} self={this.form} borrowerid={this.props.borrowerid} />, this.returnTrue, r])
        }  
        {
            let r = React.createRef() // 15 liability
            this.wizard.push([<Personal1 who={com.borrower} borrowerid={this.props.borrowerid} ref={r} self={this.form} />, this.returnTrue, r])
        }
        /*
        {
            let rf = React.createRef()
            this.wizard.push([<Form1003 ref={rf} title={title} signedtitle={signedtitle} borrowerid={this.props.borrowerid} checkValidity={this.checkAuthorizationValidity} filename="authorization.pdf" input="/borrower/form/authorization" poke="/borrower/checksignedform/authorization" download="/borrower/formpdf/authorization" output="/borrower/signform/authorization" self={this.form} />, this.returnFalse, rf])
        }
        */
        {
            let r = React.createRef()
            this.wizard.push([<Liabilities clearValidate={this.clearValidate} checkValidate={this.checkValidate} who={com.borrower} ref={r} self={this.form} borrowerid={this.props.borrowerid} />, this.returnTrue, r])
        }
        {
            let r = React.createRef()
            this.wizard.push([<Liabilities clearValidate={this.clearValidate} checkValidate={this.checkValidate} who={com.coborrower} ref={r} self={this.form} />, this.coborrowerNotJoint, r])
        }
        {
            let r = React.createRef()
            this.wizard.push([<MoreLiabilities who={com.borrower} ref={r} self={this.form} />, this.returnTrue, r])
        }
        {
            let r = React.createRef()
            this.wizard.push([<AlimonyAndMore who={com.borrower} ref={r} self={this.form} />, this.returnTrue, r])
        }
        {
            let r = React.createRef()
            this.wizard.push([<AlimonyAndMore who={com.coborrower} ref={r} self={this.form} />, this.personalCoborrower, r])
        }
        {
            let r = React.createRef()
            this.wizard.push([<Expenses who={com.borrower} ref={r} self={this.form} />, this.checkExpenses, r])
        }
        {
            let r = React.createRef()
            this.wizard.push([<Expenses who={com.coborrower} ref={r} self={this.form} />, this.checkCoExpenses, r])
        }
        {
            let r = React.createRef()
            this.wizard.push([<LiabilitiesReview ref={r} self={this.form} borrowerid={this.props.borrowerid} />, this.returnTrue, r])
        } 
        {
            let r = React.createRef() // 24 prequal?
            this.wizard.push([<PreChoice ref={r} self={this.form} borrowerid={this.props.borrowerid} />, this.checkPreChoice, r])
        }
        {
            let r = React.createRef() // 25 choice
            this.wizard.push([<ProductPicker ref={r} self={this.form} borrowerid={this.props.borrowerid} />, this.returnProductPicker, r])
        }
        {
            let r = React.createRef()
            this.wizard.push([<MoreProperty who={com.borrower} ref={r} self={this.form} />, this.returnMoreProperty, r])
        }
        {  
            let r = React.createRef() // 27 Declaration
            this.wizard.push([<OtherInfoPersonal2 who={com.borrower} ref={r} self={this.form} />, this.returnTrue, r])
        }
        {
            let r = React.createRef()
            this.wizard.push([<OtherInfoPersonal2 who={com.coborrower} ref={r} self={this.form} />, this.personalCoborrower, r])
        } 
        {
            let r = React.createRef()
            this.wizard.push([<Declarations ref={r} self={this.form} />, this.returnTrue, r])
        }
        {
            let r = React.createRef()
            this.wizard.push([<Race ref={r} self={this.form} />, this.returnTrue, r])
        }
        {
            let r = React.createRef()
            this.wizard.push([<Loan borrowerid={this.props.borrowerid} ref={r} self={this.form} />, this.returnTrue, r])
        }


        let index = 0;
        if (this.props.progress.stage === "interview") {
            index = Math.min(this.wizard.length - 1, parseInt(this.props.progress.interview.step))
        }

        let section = this.props.match.params["section"]
        this.state = {
            section: section,
            handingoff: false,
        }

        if (null == section) {

        } else {
            index = this.getURLIndex(section)
        }
  
        for (; ;) {
            let check = this.wizard[index][1]
            if (check())
                break;
            index += 1

            if (index === this.wizard.length - 1) //end of line
                break;
        }
  
        this.state = {
            currentIndex: index,
            currentPane: this.wizard[index][0],

            hiddenNext: (index === this.wizard.length - 1),
            validated: false
        }
        this.timer = 0
        this.props.updateChangeProgress(index, "interview")

    }
    componentDidMount = () => {
        window.document.addEventListener('checkwalkback', this.onTouch);
    }
    componentWillUnmount = () => {
        window.document.removeEventListener('checkwalkback', this.onTouch);
    }

    onTouch = () => {
        let d = new Date()        
            
        this.props.updateChangeLastTouch( d.toUTCString() )

        if (this.state.currentIndex < this.props.progress.interview.max && this.state.currentIndex <= this.wizard.length - 1) {
            this.props.updateChangeProgress(this.state.currentIndex , "max")
        }
    }
    oninit = (callback) => {
        this.submit = callback
    }
    update = newindex => {
        for (; ;) {
            let check = this.wizard[newindex][1]
            if (check())
                break;
            newindex += 1
        }

        this.setState({ currentIndex: newindex });
        this.setState({ validated: false });
        this.setState({ currentPane: this.wizard[newindex][0], hiddenNext: (newindex === this.wizard.length - 1) });
        this.props.updateChangeProgress(newindex, "interview")

        if (typeof this.props.progress.interview.max === "undefined" || this.props.progress.interview.max < newindex)
            this.props.updateChangeProgress(newindex, "max")
    }
    previous = () => {
        let newindex = Math.max(this.state.currentIndex - 1, 0);
        for (; ;) {
            let check = this.wizard[newindex][1]
            if (check())
                break;
            newindex -= 1
            if (newindex === 0) //end of line
                break;
        }

        if (!this.wizard[newindex][1]())
            newindex = this.state.currentIndex

        this.update(newindex)
        window.scrollTo(0, 0);
        if ("undefined" === typeof this.props.borrowerid)
            this.props.history.push('/app/interview/main/' + newindex)
    }
    next = () => {
        let newindex = Math.min(this.state.currentIndex + 1, this.wizard.length - 1);
        for (; ;) {
            let check = this.wizard[newindex][1]
            if (check())
                break;
            newindex += 1

            if (newindex === this.wizard.length - 1) //end of line
                break;
        }
        this.setState({ currentIndex: newindex, hiddenNext: (newindex === this.wizard.length - 1) });
        this.setState({ currentPane: this.wizard[newindex][0] });
        this.props.updateChangeProgress(newindex, "interview")

        if (typeof this.props.progress.interview.max === "undefined" || this.props.progress.interview.max < newindex)
            this.props.updateChangeProgress(newindex, "max")

        if ("undefined" === typeof this.props.borrowerid)
            this.props.history.push('/app/interview/main/' + newindex)

        switch (newindex) {
            case com.stageToNumber.start + 2: {
                if (!this.props.progress.interview.interviewstarted) {
                    this.props.updateChangeVisitedStage("interviewstarted")
                    this.notifyLO("interviewstarted")
                }
                break
            }
            case com.stageToNumber.choice: {
                if (!this.props.progress.interview.productselectionstarted) {
                    this.props.updateChangeVisitedStage("productselectionstarted")
                    this.notifyLO("productselectionstarted")
                }
                break
            }
            case com.stageToNumber.end: {
                if (!this.props.progress.interview.interviewfinished) {
                    this.props.updateChangeVisitedStage("interviewfinished")
                    this.notifyLO("interviewfinished")
                }
                break
            }
            default:
                break
        }
        window.scrollTo(0, 0);
    }
    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true;
    }
    clearValidate = () => {
        this.setState({ validated: false })
    }
    
    checkValidate = (event) => {
    
        if (this.form.current.reportValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
           
            let els = document.getElementsByClassName("zeitro-radio")
            for (let i = 0; i < els.length; i++) {
                let inp = els[i].previousSibling

                if (!inp.validity.valid)
                    els[i].classList.add("in-valid");
                else
                    els[i].classList.replace("in-valid", "totally-valid");
            }

            els = document.getElementsByClassName("zeitro-checkbox")
            for (let i = 0; i < els.length; i++) {
                
                let inp = els[i].previousSibling
              
                if (!inp.validity.valid)
                    els[i].classList.add("in-valid");
                else
                    els[i].classList.replace("in-valid", "totally-valid");

            }
            this.setState({ validated: true })
            return false
        }
        return true        
    }
    handleSubmit = (event) => {
        if (this.props.role === UR_LoanProcessor) {
            event.preventDefault();
            event.stopPropagation();


            let newindex = Math.min(this.state.currentIndex + 1, this.wizard.length - 1);
            for (; ;) {
                let check = this.wizard[newindex][1]
                if (check())
                    break;
                newindex += 1
    
                if (newindex === this.wizard.length - 1) //end of line
                    break;
            }
            this.setState({ currentIndex: newindex, hiddenNext: (newindex === this.wizard.length - 1) });
            this.setState({ currentPane: this.wizard[newindex][0] });
            if ("undefined" === typeof this.props.borrowerid)
                this.props.history.push('/app/interview/main/' + newindex)
    
            window.scrollTo(0, 0);

            return true
        }
        if(!this.checkValidate(event)) {
            event.preventDefault();
            event.stopPropagation();       
            return false     
        }

        let child = this.wizard[this.state.currentIndex][2].current
        if (child && child.handleSubmit) {
            if (!child.handleSubmit(event)) {
                event.preventDefault();
                event.stopPropagation();
                this.setState({ validated: true })
                return false;
            }
        }
        getCurrentState({ application: this.props.application, borrower: this.props.borrower }, sessionStorage.getItem("originalstate"))

        event.preventDefault();
        this.setState({ validated: false })
        event.stopPropagation();
        this.next()
        return true
    }
    rollback = () => {
        let index = 0
        for (; ;) {
            let check = this.wizard[index][1]
            if (check())
                break
            index += 1
        }

        this.setState({ currentIndex: index });
        this.setState({ currentPane: this.wizard[index][0], validated: false, hiddenNext: false, });
        this.props.updateChangeProgress(0, "interview")
        window.scrollTo(0, 0);


        if ("undefined" === typeof this.props.borrowerid)
            this.props.history.push('/app/interview/main/' + 0)
    }
    isRollbackHidden = () => {
        let index = 0
        for (; ;) {
            let check = this.wizard[index][1]
            if (check())
                break
            index += 1
        }
        return this.state.currentIndex === index
    }
    isPreviousHidden = () => {
        let index = 0
        for (; ;) {
            let check = this.wizard[index][1]
            if (check())
                break
            index += 1
        }
        return this.state.currentIndex === index
    }
    componentDidMount() {
    }
    toolbar = () => {
        let showButton = (amin, amax, text) => {
            let limit = this.props.progress.interview.max
            let disabled = false

            if (amin > limit) {
                disabled=true
                //bg="dis"
            }
            let setPane = () => {
                this.setState({ currentIndex: amin, currentPane: this.wizard[amin][0], hiddenNext: (amin === this.wizard.length - 1) })
            }


            return <Button disabled={disabled} variant="light" onClick={setPane}>{text}</Button>
        }
        let isPreapproval = false === this.props.application.property.hascontract &&
              this.props.application.property.purpose === "purchase"

        if (this.props.borrowerid)
            return (

                <ButtonToolbar className="ml-3 pt-2 pb-4" aria-label="Toolbar with button groups">
                    <ButtonGroup>
                        {showButton(0, com.stageToNumber.personal, "Loan Purpose")}
                        {showButton(com.stageToNumber.personal, com.stageToNumber.property, "Personal")}
                        {showButton(com.stageToNumber.property, com.stageToNumber.income, "Property")}
                        {showButton(com.stageToNumber.income, com.stageToNumber.assets, "Income")}
                        {showButton(com.stageToNumber.assets, com.stageToNumber.liabilities, "Finance")}
                        {showButton(com.stageToNumber.liabilities, com.stageToNumber.choice, "Liabilities")}
                        {isPreapproval ? showButton(com.stageToNumber.prequal, com.stageToNumber.choice, "Pre Qual"):""}
                        {(this.props.application.hascoborrower !== "remote") ? showButton(com.stageToNumber.choice, com.stageToNumber.transaction, "Explore Rates") : ""}

                        {(this.props.application.hascoborrower !== "remote") ? showButton(com.stageToNumber.transaction, com.stageToNumber.declarations, "Transaction") : ""}
                        {showButton(com.stageToNumber.declarations, com.stageToNumber.end, "Declarations")}

                    </ButtonGroup>
                </ButtonToolbar>
            )
        return ""
    }
    interviewDisabled = () => {
        if ("undefined" !== typeof this.props.borrowerid) {
            return sessionStorage.getItem("edit") !== "true"
        }
        let current = com.CurrentStatus[this.props.application.currentStatus]
        if (current < 200)
            return false
        if (this.state.currentIndex === this.wizard.length - 1)
            return false
        return true

    }
    handoff = e => {
        let token = sessionStorage.getItem("ZeitroA")
        let id = this.props.borrowerid
        this.setState({handingoff: true})
        let body = {token: id}
        fetch('/borrower/handoff', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(
            response => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Status Code: ' +
                        response.status)
                    this.setState({handingoff: false})
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    this.setState({handingoff: false})         
                    alert(js.Text)
                });
            }
        ).catch((err) => {
            console.log('Fetch Error :', err);
        });            
    }

    render() {
        let isChecked = sessionStorage.getItem("edit") === "true"
        let onEdit = (e) => {            
            sessionStorage.setItem("edit", e.target.checked)
            this.forceUpdate()
        }
        return (
            <div key={this.props.match.params["section"]} id="envelope" className="text-center mt-0 mb-5 pb-5 text-nowrap" style={{backgroundColor: '#f7f9fc'}}>
                {this.toolbar()}

                <Form key={this.props.location} autoComplete="off" ref={this.form} id="personalform" noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                    <fieldset disabled={this.interviewDisabled()}>
                        <Row className="ml-0 " style={{backgroundColor: '#f7f9fc'}}>
                            <Col className="text-center m-0 px-0">
                                {this.state.currentPane}
                            </Col>
                        </Row>
                    </fieldset>

                    <Row className="text-center " style={{backgroundColor: '#f7f9fc'}}>

                        <Col  className="text-left">
                            {this.interviewDisabled() ?
                                <OverlayTrigger
                                    trigger={['click', 'hover']} rootClose
                                    overlay={(props) => {
                                        let p = { ...props, 'show': 'true' }
                                        return (
                                            <Popover id="years-tooltip"  {...p} >
                                                <Popover.Content> {
                                                
                                                ("undefined" !== typeof this.props.borrowerid) ? 
                                                "Editing is disabled": 
                                                "The application has been submitted. Please contact your loan officer if you want to make changes."}

                                            </Popover.Content>
                                            </Popover>
                                        )
                                    }}
                                >
                                    <i style={{ color: '#d2a83e' }} className="fas fa-2x fa-lock ml-4"></i>
                                </OverlayTrigger>
                                : <div>&nbsp;</div>}
                        </Col>
                        <Col className="text-right" xs="auto">
                            <Button variant="primary" id="previous" hidden={this.isPreviousHidden()} className="mr-3" onClick={this.previous} > Previous</Button>
                        </Col>
                        <Col className="text-left" xs="auto">
                            <Button variant="primary" id="next" type="submit" hidden={this.state.hiddenNext} className="text-center ml-3"  >&nbsp;&nbsp;Next&nbsp;&nbsp;</Button>
                        </Col>
                        <Col  >
                            <div hidden={false && this.state.currentIndex === 0}>&nbsp;</div>
                        </Col>

                    </Row>
                    {("undefined" !== typeof this.props.borrowerid) ?
                    <div className="viewport  mt-5">
                    <Row className="text-left" style={{position: 'relative'}}>
                        <Col xs="auto" style={{marginTop:'11px'}}>
                            Last change: {this.props.progress.lasttouch != null ? this.props.progress.lasttouch : "unknown"}
                        </Col>
                        <Col   style={{marginTop:'11px'}}>

                        <Form.Group controlId="enableedit" className="text-left d-block text-nowrap " >
                                <Form.Check type="checkbox" className="mx-1 px-0"
                                    defaultChecked={isChecked}

                                    onChange={onEdit}
                                    label="Enable editing"></Form.Check>
                        </Form.Group> 
                        </Col>
                        <Col hidden={this.props.application.currentStatus !== 'ApplicationStarted' || this.props.role === UR_LoanProcessor } style={{position: 'relative'}} className="text-right">
                        <Button onClick={this.handoff} style={{right: '0px', marginTop: '5px'}}> 
                        {
                            this.state.handingoff ? <img alt="wait" className="loader" style={{width: '24px', opacity: '1.0', marginRight:'12px'}}  src={com.logo ? `data:image/png;base64, ${com.logo}` : "/logo.png"}></img> : <span>&nbsp;</span>
                        } Hand off to borrower</Button>
                        </Col>
                    </Row> </div> : "" }             
                </Form>
            </div>
        )
    }

    notifyLO = what => {
        let token = sessionStorage.getItem("ZeitroA")
        let tosend =
            JSON.stringify({
                notification: what,
            })
        fetch('/borrower/notifylo', {
            method: 'POST',
            body: tosend,
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status)
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
  
                });
            }
        ).catch((err) => {
            console.log('Fetch Error :', err);
        });
    }
}
SubInterview = withRouter(connect(mapStateToProps, mapDispatchToProps)(SubInterview))

class Interview extends Component {

    shouldComponentUpdate = () => {

        return true
    }
    render = () => <SubInterview {...this.props} key={this.props.match.params["section"]} />
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Interview))