import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {
    Tabs,
    DatePicker,
    Button,
    Checkbox,
    Tooltip,
    Select,
    Switch,
    Modal,
    Divider,
    Form,
    Input,
    Space,
    Collapse,
    InputNumber,
    Slider,
    Col,
    Row
} from 'antd';
import {
    PlusCircleOutlined,
    CaretDownOutlined,
    QuestionCircleOutlined
} from '@ant-design/icons';
import "./Common.css";
import "./Experience.css";
import * as com from "../../Common"
import { connect } from 'react-redux'
import moment from 'moment';
import * as act from "../../Store/actions"
import GoogleAutocomplete from '../../Common/GoogleAutocomplete/GoogleAutocomplete'
import {PropertyTypeOptions} from './constants'
const _ = require('lodash');

const OwnershipOptions = [
    {
        label: 'xxx',
        value: 'xxx',
    },
    {
        label: 'xxx',
        value: 'xx',
    },
]

const propertyTypeLabelMap = {
    "singlefamilyattached": "Single Family Residence (Attached)",
    "singlefamilydetached": "Single Family Residence",
    "twofamily": "2 Unit Family",
    "threefamily": "3 Unit Family",
    "fourfamily": "4 Unit Family",
    "multistory": "5+ Multifamily",
    "condominium": "Condo",
    "townhouse": "Townhouse",
    "mixeduse": "Mixed Use",
    "commercial": "Commercial",
  }

const mapStateToProps = (state) => {
    return {
        property: state.application.property,
        application: state.application,
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateHardMoneyLoanAttribute: (t, verb) => {
        dispatch(act.UpdateHardMoneyLoanAttribute(t, verb));
    },
    changeMainPropertyAttribute: (t, verb) => {
        dispatch(act.ChangeMainPropertyAttribute(t, verb));
    },
    updateHasCoborrower: (val) => {
        dispatch(act.UpdateHasCoborrower(val))
    },
    updateProduct: (v, verb) => {
        dispatch(act.UpdateProduct(v, verb))
    },
    changeBorrowerInfo: (v, verb) => {
        dispatch(act.ChangeBorrowerInfo(v, "borrower", verb))
    },
    changeCoBorrowerInfo: (v, verb) => {
        dispatch(act.ChangeBorrowerInfo(v, "coborrower", verb))
    },
});


export default function Experience(props) {
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectId, setSelectId] = useState('');
    const [loanLimits, setLoanLimits] = useState(null)
    const [stateOptions, setStateOptions] = useState([])
    const [btnDisable, setBtnDisable] = useState(false)
    const loanList = [
        {
            id: '1',
            name: 'Construction',
            No: 'CON3500365',
            address: '350 Warren St, Jersey City, NJ 07302',
            editTime: '02/11/2023',
            loanMount: '$500,000'
        },
        {
            id: '2',
            name: 'Construction',
            No: 'CON3500365',
            address: '350 Warren St, Jersey City, NJ 07302',
            editTime: '02/11/2023',
            loanMount: '$500,000'
        },
        {
            id: '3',
            name: 'Construction',
            No: 'CON3500365',
            address: '350 Warren St, Jersey City, NJ 07302',
            editTime: '02/11/2023',
            loanMount: '$500,000'
        }
    ]
    const options = [
        {
            value: true,
            label: 'Yes'
        },
        {
            value: false,
            label: 'No'
        }
    ]
    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
        }
    }
    const showModal = () => {
        setIsModalOpen(true);
    }

    const onFinishFailed = (errorInfo) => {
        let failedCardIdx = {}
        errorInfo.errorFields.forEach((item) => {
            failedCardIdx[item.name[0]] = [...(failedCardIdx[item.name[0]] || []), item.name[1]]
        })
        Object.keys(failedCardIdx).forEach((key) => {
            let list = form.getFieldValue(key)
            failedCardIdx[key].forEach((idx) => {
                list[idx].open = true
            })
            form.setFieldsValue({
                key: list,
            })
        })
    }

    const onFinish = (values) => {
        if (typeof values.rentalpropertyexperiences === "undefined" || values.rentalpropertyexperiences.length === 0) {
            props.updateHardMoneyLoanAttribute([], "rentalpropertyexperiences")
        }
        if (typeof values.propertyflippingexperiences === "undefined" || values.propertyflippingexperiences.length === 0) {
            props.updateHardMoneyLoanAttribute([], "propertyflippingexperiences")
        }
        if (values.propertyflippingexperiences && values.propertyflippingexperiences.length > 0) {
            let flipVals = values.propertyflippingexperiences.map(e => {
                let c = Object.fromEntries(
                    Object.entries(e).filter(([key]) => key !== 'open')
                )
                c.purchaseprice = String(c.purchaseprice)
                c.salesprice = String(c.salesprice)
                c.profitorloss = String(c.profitorloss)
                let ds = new Date(c.datesold)
                c.datesold = c.datesold ? dayjs(ds).format('MM/DD/YYYY') : ''
                let pd = new Date(c.purchasedate)
                c.purchasedate = c.purchasedate ? dayjs(pd).format('MM/DD/YYYY') : ''
                c.rehabbudget = c.rehabbudget ? String(c.rehabbudget) : ''
                return c
            })
            props.updateHardMoneyLoanAttribute(flipVals, "propertyflippingexperiences")
        }
        props.updateHardMoneyLoanAttribute(values.propertyflippingexperiences.length, "timesofflippingaproperty")
        if (values.rentalpropertyexperiences && values.rentalpropertyexperiences.length > 0) {
            let rentalVals = values.rentalpropertyexperiences.map(e => {
                let c = Object.fromEntries(
                    Object.entries(e).filter(([key]) => key !== 'open')
                )
                c.purchaseprice = String(c.purchaseprice)
                c.monthlyrent = String(c.monthlyrent)
                c.profitorloss = String(c.profitorloss)
                let ds = new Date(c.datesold)
                c.datesold = c.datesold ? dayjs(ds).format('MM/DD/YYYY') : ''
                let pd = new Date(c.purchasedate)
                c.purchasedate = c.purchasedate ? dayjs(pd).format('MM/DD/YYYY') : ''
                return c
            })
            props.updateHardMoneyLoanAttribute(rentalVals, "rentalpropertyexperiences")
        }
        props.updateHardMoneyLoanAttribute(values.rentalpropertyexperiences.length, "timesofpurchasingarentalproperty")
        props.finish()
    };
    const inputFormatter = (value) => {
        return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const inputParser = (value) => {
        return value.replace(/\\s?|(,*)/g, '')
    }
    const disabledDate = (current) => {
        return current && current > dayjs().endOf('day');
    };
    const clickCollapse = (index, key) => {
        const list = form.getFieldValue(key)
        list[index].open = !list[index].open
        form.setFieldsValue({
            key: list,
        })
        console.log(form.getFieldValue(key))
    }
    const done = (index, key) => {
        const fieldPaths =[
            [key, index, 'streetline1'],
            [key, index, 'state'],
            [key, index, 'county'],
            [key, index, 'zip'],
            [key, index, 'ownershipontitle'],
            [key, index, 'propertytype'],
            [key, index, 'purchaseprice'],
            [key, index, 'purchasedate'],
            [key, index, 'salesprice'],
            [key, index, 'datesold'],
            [key, index, 'rehabbudget'], // Optional field, include if you want to validate it as well
            [key, index, 'profitorloss'],
        ]
        if (key === 'rentalpropertyexperiences') {
            fieldPaths.push([key, index, 'tenantoccupied'])
            fieldPaths.push([key, index, 'monthlyrent']) 
        }
        form.validateFields(fieldPaths).then(() => {
            const list = form.getFieldValue(key)
            list[index].open = false
            form.setFieldsValue({
                key: list,
            })
        })
    }
    const addSelect = () => {
        setIsModalOpen(false);
        const list = form.getFieldValue()
        props.finish({ ...list, selectLoanId: selectId })
    }
    const skip = () => {
        setIsModalOpen(false);
        const list = form.getFieldValue()
        props.finish(list)
    }
    const onChangeSelect = (id) => {
        setSelectId(id)
    }
    const onFieldsChange = () => {
        continueDisabled()
    }
    const continueDisabled = () => {
        const property = form.getFieldValue('propertyflippingexperiences')
        const rental = form.getFieldValue('rentalpropertyexperiences')

        // not sure about this part, do we need at least 1 experience?
        // just valifate the form for now
        // if (property === undefined || property.length === 0 || rental === undefined || rental.length === 0) {
        //     setBtnDisable(true)
        // } else {
        //     setBtnDisable(false)
        // }
        setBtnDisable(false)
    }
    const getAddress = (val, index, key) => {
        const list = form.getFieldValue(key)
        list[index].zip = com.getSubaddress(val.address_components, "postal_code", "long_name")
        list[index].state = com.getSubaddress(val.address_components, "administrative_area_level_1", "long_name")
        list[index].county = com.getSubaddress(val.address_components, "administrative_area_level_2", "long_name")
        form.setFieldsValue({
            key: list,
        })
    }
    const fetchLoanLimits = () => {
        fetch("/data/loanlimits")
            .then((res) => {
                if (!res.ok) {
                    console.log(res);
                    throw Error(res.statusText);
                }
                return res.json();
            })
            .then((res) => {
                setLoanLimits(res)
                setStateOptions(Object.keys(res).map(item => ({ value: com.capitalize(item), label: com.capitalize(item) })))
            })
            .catch((err) => {
                console.error(err);
            });
    }
    const onChangeState = (val, index, key) => {
        const list = form.getFieldValue(key)
        list[index].county = undefined
        list[index].countyList = val ? Object.keys(loanLimits[val.toUpperCase()]).map(item => ({ value: com.capitalize(item), label: com.capitalize(item) })) : []
        form.setFieldsValue({
            key: list,
        })
    }
    const onChangeZip = (e, index, key) => {
        const list = form.getFieldValue(key)
        list[index].zip = e.target.value.replace(/\-/g, "")
        form.setFieldsValue({
            key: list,
        })
    }
    useEffect(() => {
        fetchLoanLimits()
    }, []);
    const getInitialValue = () => {
        let val = { rentalpropertyexperiences: undefined, propertyflippingexperiences: undefined }
        let rental = _.cloneDeep(props.application.hardmoneyloan.rentalpropertyexperiences)
        if (rental.length > 0) {
            for (let i = 0; i < rental.length; i++) {
                rental[i].datesold = rental[i].datesold ? dayjs(rental[i].datesold, "MM/DD/YYYY") : ''
                rental[i].purchasedate = rental[i].purchasedate ? dayjs(rental[i].purchasedate, "MM/DD/YYYY") : ''
                rental[i].open = false
            }
        }
        val.rentalpropertyexperiences = rental

        let flip = _.cloneDeep(props.application.hardmoneyloan.propertyflippingexperiences)
        if (flip.length > 0) {
            for (let i = 0; i < flip.length; i++) {
                flip[i].datesold = flip[i].datesold ? dayjs(flip[i].datesold, "MM/DD/YYYY") : ''
                flip[i].purchasedate = flip[i].purchasedate ? dayjs(flip[i].purchasedate, "MM/DD/YYYY") : ''
                flip[i].open = false
            }
        }
        val.propertyflippingexperiences = flip

        return val
    }
    const formItemMode = (formItem, index, fields, key, type) => {
        const list = form.getFieldValue(key)
        if (formItem && props.editMode) {
            return formItem
        } else {
            if (type === 'Amount') {
                return <div style={{ fontSize: '16px' }}>{list[index][fields] && list[index][fields] !== '' ? `$ ${inputFormatter(list[index][fields])}` : '--'}</div>
            } else if (type === 'date') {
                return <div style={{ fontSize: '16px' }}>{list.length && list[index][fields] ? dayjs(list[index][fields]).format('MM/DD/YYYY') : '--'}</div>
            }
            if (fields === 'propertytype') {
                return <div style={{ fontSize: '16px' }}>{list.length && list[index][fields] ? propertyTypeLabelMap[list[index][fields]] : '--'}</div>
            }
            if (fields === 'tenantoccupied') {
                return <div style={{ fontSize: '16px' }}>{list.length && list[index][fields] ? list[index][fields] === 'true' ? 'Yes' : 'No' : '--'}</div>
            }
            return <div style={{ fontSize: '16px' }}>{list.length && list[index][fields] ? list[index][fields] : '--'}</div>
        }
    }
    // useEffect(() => {
    //     form.resetFields()
    //     console.log(form.getFieldValue())
    // }, [props.editMode])
    return (
        <div className="application-form text-left">
            <Form
                form={form}
                initialValues={getInitialValue()}
                name="dynamic_form_complex"
                layout='vertical'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onFieldsChange={onFieldsChange}
                validateMessages={validateMessages}
                size="large"
            >
                <Form.List name="propertyflippingexperiences">
                    {(fields, { add, remove }) => (
                        <>
                            <div className='form-title'>Property Flipping Experience</div>
                            {fields.map((field, index) => (
                                <div key={field.key} className={`experience-collapse ${form.getFieldValue('propertyflippingexperiences')[index]?.open ? 'collapse-open' : 'collapse-close'}`}>
                                    <div className='experience-collapse-title' onClick={() => clickCollapse(index, 'propertyflippingexperiences')}><span className='collapse-title-name'>Track Record {index + 1}: {form.getFieldValue('propertyflippingexperiences')[index].streetline1}</span><CaretDownOutlined className='experience-collapse-icon' /></div>
                                    <Row gutter={30}>
                                        <Col span={24}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'streetline1']}
                                                label="Property address"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                {formItemMode(<GoogleAutocomplete placeholder="Street address" getPlace={(val) => getAddress(val, index, 'propertyflippingexperiences')} />, index, 'streetline1', 'propertyflippingexperiences')}
                                            </Form.Item>
                                        </Col>
                                        {/* <Col span={24}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'Apt']}
                                                style={{marginBottom: '10px'}}
                                            >
                                                <Input placeholder="Apt, suite, etc (Optional)" />
                                            </Form.Item>
                                        </Col> */}
                                        <Col span={24} sm={8}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'state']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: ''
                                                    },
                                                ]}
                                            >
                                                {
                                                    formItemMode(
                                                        <Select
                                                            allowClear
                                                            options={stateOptions}
                                                            onChange={(val) => onChangeState(val, index, 'propertyflippingexperiences')}
                                                            placeholder="Select your state"
                                                        />,
                                                        index,
                                                        'state',
                                                        'propertyflippingexperiences'
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} sm={8}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'county']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: ''
                                                    },
                                                ]}
                                            >
                                                {
                                                    formItemMode(
                                                        <Select
                                                            allowClear
                                                            options={form.getFieldValue('propertyflippingexperiences')[index].countyList}
                                                            placeholder="Select your county"
                                                        />,
                                                        index,
                                                        'county',
                                                        'propertyflippingexperiences'
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} sm={8}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'zip']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: ''
                                                    },
                                                ]}
                                            >
                                                {
                                                    formItemMode(
                                                        <Input
                                                            type='text'
                                                            style={{ width: '100%' }}
                                                            placeholder="Zip Code"
                                                            onChange={(e) => onChangeZip(e, index, 'propertyflippingexperiences')}
                                                        />,
                                                        index,
                                                        'zip',
                                                        'propertyflippingexperiences'
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={30}>
                                        <Col span={24} sm={12}>
                                            <Form.Item
                                                {...field}
                                                label="Owner on title"
                                                name={[field.name, 'ownershipontitle']}
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                {
                                                    formItemMode(
                                                        <Input />,
                                                        index,
                                                        'ownershipontitle',
                                                        'propertyflippingexperiences'
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} sm={12}>
                                            <Form.Item
                                                {...field}
                                                label="Property type"
                                                name={[field.name, 'propertytype']}
                                                tooltip={{
                                                    title: 'Property Type',
                                                    icon: <QuestionCircleOutlined className="tips-icon" />,
                                                }}
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                {
                                                    formItemMode(
                                                        <Select
                                                            allowClear
                                                            options={PropertyTypeOptions}
                                                        />,
                                                        index,
                                                        'propertytype',
                                                        'propertyflippingexperiences'
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={30}>
                                        <Col span={24} sm={12}>
                                            <Form.Item
                                                {...field}
                                                label="Purchase price"
                                                name={[field.name, 'purchaseprice']}
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                {
                                                    formItemMode(
                                                        <InputNumber
                                                            prefix="$"
                                                            style={{ width: '100%' }}
                                                            max={10000000}
                                                            min={0}
                                                            precision={0}
                                                            formatter={inputFormatter}
                                                            parser={inputParser}
                                                        />,
                                                        index,
                                                        'purchaseprice',
                                                        'propertyflippingexperiences',
                                                        'Amount'
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} sm={12}>
                                            <Form.Item
                                                {...field}
                                                label="Purchase date"
                                                name={[field.name, 'purchasedate']}
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                {
                                                    formItemMode(
                                                        <DatePicker style={{ width: '100%' }} className='closing-date' disabledDate={disabledDate} placeholder="MM/DD/YYYY" format="MM/DD/YYYY" />,
                                                        index,
                                                        'purchasedate',
                                                        'propertyflippingexperiences',
                                                        'date'
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={30}>
                                        <Col span={24} sm={12}>
                                            <Form.Item
                                                {...field}
                                                label="Sales price"
                                                name={[field.name, 'salesprice']}
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                {
                                                    formItemMode(
                                                        <InputNumber
                                                            prefix="$"
                                                            style={{ width: '100%' }}
                                                            max={10000000}
                                                            min={0}
                                                            formatter={inputFormatter}
                                                            parser={inputParser}
                                                        />,
                                                        index,
                                                        'salesprice',
                                                        'propertyflippingexperiences',
                                                        'Amount'
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} sm={12}>
                                            <Form.Item
                                                {...field}
                                                label="Date sold"
                                                name={[field.name, 'datesold']}
                                                rules={[
                                                    {
                                                        required: false
                                                    },
                                                ]}
                                            >
                                                {
                                                    formItemMode(
                                                        <DatePicker style={{ width: '100%' }} className='closing-date' disabledDate={disabledDate} placeholder="MM/DD/YYYY" format="MM/DD/YYYY" />,
                                                        index,
                                                        'datesold',
                                                        'propertyflippingexperiences',
                                                        'date'
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {
                                        props.editMode && (
                                            <Form.Item className='experience-operate'>
                                                <Button className='remove item-btn' onClick={() => remove(field.name)}>Delete</Button>
                                                <Button className='done item-btn' type="primary" onClick={() => done(index, 'propertyflippingexperiences')}>Done</Button>
                                            </Form.Item>
                                        )
                                    }
                                </div>
                            ))}
                            {
                                props.editMode && (
                                    <Form.Item>
                                        <Button
                                            className='experience-add-btn'
                                            size="large"
                                            onClick={() => {
                                                add({ open: true, countyList: [] })
                                            }}>
                                            <PlusCircleOutlined className='add-icon' />
                                            Add a property flipping experience
                                        </Button>
                                    </Form.Item>
                                )
                            }
                        </>
                    )}
                </Form.List>
                <Form.List name="rentalpropertyexperiences">
                    {(fields, { add, remove }) => (
                        <>
                            <div className='form-title'>Rental Property Experience</div>
                            {fields.map((field, index) => (
                                <div key={field.key} className={`experience-collapse ${form.getFieldValue('rentalpropertyexperiences')[index]?.open ? 'collapse-open' : 'collapse-close'}`}>
                                    <div className='experience-collapse-title' onClick={() => clickCollapse(index, 'rentalpropertyexperiences')}><span className='collapse-title-name'>Track Record {index + 1}: {form.getFieldValue('rentalpropertyexperiences')[index].streetline1}</span><CaretDownOutlined className='experience-collapse-icon' /></div>
                                    <Row gutter={30}>
                                        <Col span={24}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'streetline1']}
                                                label="Property address"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                {formItemMode(<GoogleAutocomplete placeholder="Street address" getPlace={(val) => getAddress(val, index, 'rentalpropertyexperiences')} />, index, 'streetline1', 'rentalpropertyexperiences')}
                                            </Form.Item>
                                        </Col>
                                        {/* <Col span={24}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'Apt']}
                                                style={{marginBottom: '10px'}}
                                            >
                                                <Input placeholder="Apt, suite, etc (Optional)" />
                                            </Form.Item>
                                        </Col> */}
                                        <Col span={24} sm={8}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'state']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: ''
                                                    },
                                                ]}
                                            >
                                                {
                                                    formItemMode(
                                                        <Select
                                                            allowClear
                                                            options={stateOptions}
                                                            onChange={(val) => onChangeState(val, index, 'rentalpropertyexperiences')}
                                                            placeholder="Select your state"
                                                        />,
                                                        index,
                                                        'state',
                                                        'rentalpropertyexperiences'
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} sm={8}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'county']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: ''
                                                    },
                                                ]}
                                            >
                                                {
                                                    formItemMode(
                                                        <Select
                                                            allowClear
                                                            options={form.getFieldValue('rentalpropertyexperiences')[index].countyList}
                                                            placeholder="Select your county"
                                                        />,
                                                        index,
                                                        'county',
                                                        'rentalpropertyexperiences'
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} sm={8}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'zip']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: ''
                                                    },
                                                ]}
                                            >
                                                {
                                                    formItemMode(
                                                        <Input
                                                            type='number'
                                                            style={{ width: '100%' }}
                                                            placeholder="Zip Code"
                                                            min={0}
                                                            onChange={(e) => onChangeZip(e, index, 'rentalpropertyexperiences')}
                                                        />,
                                                        index,
                                                        'zip',
                                                        'rentalpropertyexperiences'
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={30}>
                                        <Col span={24} sm={12}>
                                            <Form.Item
                                                {...field}
                                                label="Owner on title"
                                                name={[field.name, 'ownershipontitle']}
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                {
                                                    formItemMode(
                                                        <Input />,
                                                        index,
                                                        'ownershipontitle',
                                                        'rentalpropertyexperiences'
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} sm={12}>
                                            <Form.Item
                                                {...field}
                                                label="Property type"
                                                name={[field.name, 'propertytype']}
                                                tooltip={{
                                                    title: 'Property Type',
                                                    icon: <QuestionCircleOutlined className="tips-icon" />,
                                                }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing',
                                                    },
                                                ]}
                                            >
                                                {
                                                    formItemMode(
                                                        <Select
                                                            allowClear
                                                            options={PropertyTypeOptions}
                                                        />,
                                                        index,
                                                        'propertytype',
                                                        'rentalpropertyexperiences'
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={30}>
                                        <Col span={24} sm={12}>
                                            <Form.Item
                                                {...field}
                                                label="Purchase price"
                                                name={[field.name, 'purchaseprice']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing',
                                                    },
                                                ]}
                                            >
                                                {
                                                    formItemMode(
                                                        <InputNumber
                                                            prefix="$"
                                                            style={{ width: '100%' }}
                                                            max={10000000}
                                                            min={0}
                                                            precision={0}
                                                            formatter={inputFormatter}
                                                            parser={inputParser}
                                                        />,
                                                        index,
                                                        'purchaseprice',
                                                        'rentalpropertyexperiences',
                                                        'Amount'
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} sm={12}>
                                            <Form.Item
                                                {...field}
                                                label="Purchase date"
                                                name={[field.name, 'purchasedate']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing',
                                                    },
                                                ]}
                                            >
                                                {
                                                    formItemMode(
                                                        <DatePicker style={{ width: '100%' }} className='closing-date' disabledDate={disabledDate} placeholder="MM/DD/YYYY" format="MM/DD/YYYY" />,
                                                        index,
                                                        'purchasedate',
                                                        'rentalpropertyexperiences',
                                                        'date'
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={30}>
                                        <Col span={24} sm={12}>
                                            <Form.Item
                                                {...field}
                                                label="Tenant occupied (Yes/No)"
                                                name={[field.name, 'tenantoccupied']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing',
                                                    },
                                                ]}
                                            >
                                                {
                                                    formItemMode(
                                                        <Select
                                                            allowClear
                                                            options={options}
                                                        />,
                                                        index,
                                                        'tenantoccupied',
                                                        'rentalpropertyexperiences'
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} sm={12}>
                                            <Form.Item
                                                {...field}
                                                label="Monthly rent"
                                                name={[field.name, 'monthlyrent']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing',
                                                    },
                                                ]}
                                            >
                                                {
                                                    formItemMode(
                                                        <InputNumber
                                                            prefix="$"
                                                            style={{ width: '100%' }}
                                                            max={10000000}
                                                            min={0}
                                                            formatter={inputFormatter}
                                                            parser={inputParser}
                                                        />,
                                                        index,
                                                        'monthlyrent',
                                                        'rentalpropertyexperiences',
                                                        'Amount'
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={30}>
                                        <Col span={24} sm={12}>
                                            <Form.Item
                                                {...field}
                                                label="Date sold"
                                                name={[field.name, 'datesold']}
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: 'Missing',
                                                    },
                                                ]}
                                            >
                                                {
                                                    formItemMode(
                                                        <DatePicker style={{ width: '100%' }} className='closing-date' disabledDate={disabledDate} placeholder="MM/DD/YYYY" format="MM/DD/YYYY" />,
                                                        index,
                                                        'datesold',
                                                        'rentalpropertyexperiences',
                                                        'date'
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {
                                        props.editMode && (
                                            <Form.Item className='experience-operate'>
                                                <Button className='remove item-btn' onClick={() => remove(field.name)}>Delete</Button>
                                                <Button className='done item-btn' type="primary" onClick={() => done(index, 'rentalpropertyexperiences')}>Done</Button>
                                            </Form.Item>
                                        )
                                    }
                                </div>
                            ))}

                            {
                                props.editMode && (
                                    <Form.Item>
                                        <Button
                                            className='experience-add-btn'
                                            size="large"
                                            onClick={() => {
                                                add({ open: true })
                                            }}>
                                            <PlusCircleOutlined className='add-icon' />
                                            Add a rental property experience
                                        </Button>
                                    </Form.Item>
                                )
                            }
                        </>
                    )}
                </Form.List>
                {
                    props.editMode && (
                        <Row>
                            <Form.Item style={{ width: '100%' }}>
                                <Button type="primary" className={`common-btn continue-btn ${btnDisable ? 'disabled' : ''}`} disabled={btnDisable} htmlType="submit">
                                    Continue
                                </Button>
                            </Form.Item>
                        </Row>
                    )
                }
            </Form>
            <Modal
                className='experience-modal'
                open={false} // disable this function temporarily
                title="Save your time"
                footer={[
                    <Button key="submit" className='add-select' type="primary" size="large" onClick={addSelect}>
                        Add selected
                    </Button>,
                    <Button type="link" className='skip-step' onClick={skip}>Skip this step</Button>
                ]}
                width={767}
            >
                <div className='experience-modal-content'>
                    <div className='content-title'>
                        We noticed that you had 1 new loan since your last application, would you like to add it as your property investment experience?
                    </div>
                    <div className='content-sub-title'>
                        You may always edit your information anytime during the application process.
                    </div>
                    <div className='experience-modal-card-list'>
                        {
                            loanList.map(loan =>
                                <div className={`experience-modal-card-item ${loan.id === selectId ? 'select' : ''}`} key={loan.id} onClick={() => onChangeSelect(loan.id)}>
                                    <div className='loan-check'></div>
                                    <div className='loan-content'>
                                        <div style={{ fontSize: '16px', fontWeight: 'bold', color: '#333', marginBottom: '4px', lineHeight: 'normal' }}>{loan.name} | {loan.No}</div>
                                        <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#1F3988', marginBottom: '14px' }}>Repaid</div>
                                        <div style={{ fontSize: '14px', lineHeight: '24px', fontWeight: 'bold', color: '#333', marginBottom: '6px' }}>{loan.address}</div>
                                        <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#6E6E70', paddingBottom: '12px', borderBottom: '1px solid #ECECEC' }}>Last edit at {loan.editTime}</div>
                                        <div className='loan-amount'><div>Loan amount:</div><div style={{ fontWeight: 'bold' }}>{loan.loanMount}</div></div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </Modal>
        </div>
    )
}

Experience = connect(mapStateToProps, mapDispatchToProps)(Experience);
