import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { CSSTransition } from "react-transition-group";

import { LogType } from "./types";
import "./AdvancedCheckSearchLogs.css";
import { startCase } from "lodash";
import { getSearchLogResponse } from "./db_utils";
import Title from "antd/es/typography/Title";
import { Divider, Popover, Tooltip } from "antd";
import { AdvancedCheckLogFullResult } from "./AdvancedCheckLogFullResult";
import { CloseOutlined } from "@ant-design/icons";
import { AdvancedCheckLogLongResult } from "./AdvancedCheckLogLongResult";
import { groupLogsByDate } from "./advanced_check_utils";
import moment from "moment";

const COMPONENT_NAME = "advancedCheckSearchHistoryWrap";

interface AdvancedCheckSearchLogsProps {
  showSearchLogs: boolean;
  // Control the visibility of the search history: controlled by GMCCProgramEngine.js
  setShowSearchLogs: (show: boolean) => void;
  // This ref can be used to set the current slide of the carousel in the parent component: GMCCProgramEngine.js
  carouselRef: React.MutableRefObject<any>;
  // Set the current step of the parent component: GMCCProgramEngine.js
  setCurrentStep: (step: number) => void;
  // Set the showPropertyInfo state in the parent component: GMCCProgramEngine.js
  setShowPropertyInfo: (show: boolean) => void;
  setFilterOptions: {
    setPropertyAddress: (propertyAddress: string) => void;
    setDisplayedAddress: (displayedAddress: string) => void;
    setPropertyState: (propertyState: string) => void;
    setPropertyCounty: (propertyCounty: string) => void;
    setPurpose: (purpose: string) => void;
    setOccupancy: (occupancy: string) => void;
    setPurchasePrice: (purchasePrice: string) => void;
    setDownPayment: (downPayment: string) => void;
    setFico: (fico: string) => void;
    setIncome: (income: string) => void;
    setMonthDebt: (monthDebt: string) => void;
    setEmployment: (employment: string) => void;
    setDocumentLevel: (documentLevel: string) => void;
    setDocVerification: (docVerification: string) => void;
    setLiquidassets: (liquidassets: string) => void;
    setAgeUnder59: (ageUnder59: string) => void;
    setNonliquidassets: (nonliquidassets: string) => void;
    setForeignVisa: (foreignVisa: string) => void;
    setFirstTimeHomeBuyer: (firstTimeHomeBuyer: string) => void;
    setFirstGenHomeBuyer: (firstGenHomeBuyer: string) => void;
    setPropertywithMortgage: (propertywithMortgage: string) => void;
    setHasListProperty: (hasListProperty: string) => void;
    setSufficientEquity: (sufficientEquity: string) => void;
    setTotalDebts: (totalDebts: string) => void;
    setIsRentalFlow: (isRentalFlow: string) => void;
    setRentalIncome: (rentalIncome: string) => void;
  };
}

export interface AdvancedCheckFilterOptions {
  propertyAddress: string;
  displayedAddress: string;
  propertyState: string;
  propertyCounty: string;
  purpose?: string;
  occupancy?: string;
  purchasePrice?: string;
  downPayment?: string;
  fico?: string;
  income?: string;
  monthDebt?: string;
  employment?: string;
  documentLevel?: string;
  docVerification?: string;
  liquidassets?: string;
  ageUnder59?: string;
  nonliquidassets?: string;
  foreignVisa?: string;
  firstTimeHomeBuyer?: string;
  firstGenHomeBuyer?: string;
  propertywithMortgage?: string;
  hasListProperty?: string;
  sufficientEquity?: string;
  totalDebts?: string;
  isRentalFlow?: string;
  rentalIncome?: string;
  createdAt: string;
}

const fieldToSetterNameMap = {
  propertyAddress: "setPropertyAddress",
  displayedAddress: "setDisplayedAddress",
  propertyState: "setPropertyState",
  propertyCounty: "setPropertyCounty",
  purpose: "setPurpose",
  occupancy: "setOccupancy",
  purchasePrice: "setPurchasePrice",
  downPayment: "setDownPayment",
  fico: "setFico",
  income: "setIncome",
  monthDebt: "setMonthDebt",
  employment: "setEmployment",
  documentLevel: "setDocumentLevel",
  docVerification: "setDocVerification",
  liquidassets: "setLiquidassets",
  ageUnder59: "setAgeUnder59",
  nonliquidassets: "setNonliquidassets",
  foreignVisa: "setForeignVisa",
  firstTimeHomeBuyer: "setFirstTimeHomeBuyer",
  firstGenHomeBuyer: "setFirstGenHomeBuyer",
  propertywithMortgage: "setPropertywithMortgage",
  hasListProperty: "setHasListProperty",
  sufficientEquity: "setSufficientEquity",
  totalDebts: "setTotalDebts",
  isRentalFlow: "setIsRentalFlow",
  rentalIncome: "setRentalIncome",
};

/**
 * Get the setter function for a given field
 * @param setFilterOptions - The setter functions for the filter options
 * @returns The setter function for the given field
 */
const getFieldSetter = (
  setFilterOptions: AdvancedCheckSearchLogsProps["setFilterOptions"],
  field: keyof AdvancedCheckFilterOptions
) => {
  return (value: string) => {
    setFilterOptions[fieldToSetterNameMap[field]](value);
  };
};

/**
 * Fields grouped by the number of steps they take to set, for example,
 * propertyAddress, displayedAddress, propertyState are set in the first step,
 * while propertyCounty is set in the second step, because it depends on propertyState.
 */
const getFieldsToSetGroups = () => {
  const P1Fields = [
    "propertyAddress",
    "displayedAddress",
    "propertyState",
    "purpose",
    "occupancy",
    "purchasePrice",
    "downPayment",
    "fico",
    "income",
    "monthDebt",
    "employment",
    "documentLevel",
    "liquidassets",
    "ageUnder59",
    "foreignVisa",
    "firstTimeHomeBuyer",
    "firstGenHomeBuyer",
    "propertywithMortgage",
    "hasListProperty",
    "totalDebts",
  ];
  const P2Fields = [
    "propertyCounty", // Depends on propertyState
    "docVerification", // Depends on documentLevel and employment
    "nonliquidassets", // Depends on ageUnder59
    "sufficientEquity", // Depends on hasListProperty
    "isRentalFlow", // Depends on occupancy
  ];
  const P3Fields = [
    "rentalIncome", // Depends on isRentalFlow
  ];
  return [P1Fields, P2Fields, P3Fields];
};

/**
 * Stressed address: bold the street name, and gray the rest
 * @param address - The address to be stressed
 * @returns The stressed address
 */
const StressedAddress = ({ address }: { address: string }) => {
  const maxLength = 35;
  const truncatedAddress =
    address.length > maxLength ? address.slice(0, maxLength) + "..." : address;
  const street = truncatedAddress.split(",")[0];
  const rest = truncatedAddress.slice(street.length).trim();
  return (
    <span>
      <span style={{ fontWeight: "bold" }}>{street}</span>
      <span style={{ color: "gray" }}>{rest}</span>
    </span>
  );
};

export const AdvancedCheckSearchLogs = forwardRef(
  (props: AdvancedCheckSearchLogsProps, ref) => {
    // Transition group ref
    const nodeRef = useRef(null);

    /* ---- Log related states ---- */
    // Get/Set the selected log index
    const [selectedLogIndex, setSelectedLogIndex] = useState<string>("");
    // Get/Set to get the newest logs, then group them by date
    const [groupedLogs, setGroupedLogs] = useState<
      { logs: AdvancedCheckFilterOptions[]; todayness: string }[]
    >([]);
    // TODO: Delete this after testing
    const [logs, setLogs] = useState<AdvancedCheckFilterOptions[]>([]);

    const fetchLogs = async () => {
      setSelectedLogIndex("");
      const response = await getSearchLogResponse(LogType.ADVANCED);
      const responseData: {
        type: string;
        logs: AdvancedCheckFilterOptions[];
      } = await response.json();
      // Sort the logs by createdAt in descending order
      responseData.logs.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      // Group the logs by date
      const groupedLogs = groupLogsByDate(responseData.logs);
      setGroupedLogs(groupedLogs);

      // TODO: Delete this after testing
      setLogs(responseData.logs);
    };

    useImperativeHandle(ref, () => ({
      fetchLogs,
    }));

    const handleLogClick = (
      dateIndex: number,
      logIndex: number,
      log: AdvancedCheckFilterOptions
    ) => {
      // Reset the carousel to the first slide (filters slide)
      props.carouselRef.current.goTo(0);
      // Set the current step of the first slide (filters slide) to 0
      props.setCurrentStep(0);
      
      // Set the selected log index locally
      setSelectedLogIndex(`${dateIndex}-${logIndex}`);

      // Set all filter options in groups
      const fieldsToSetGroups = getFieldsToSetGroups();
      fieldsToSetGroups.forEach((fields, i) => {
        // Delay the setting of each group of fields by 100ms * the index of the group
        setTimeout(() => {
          fields.forEach((field) => {
            // Only set the field if it exists in the log
            if (log[field] !== undefined) {
              getFieldSetter(
                props.setFilterOptions,
                field as keyof AdvancedCheckFilterOptions
              )(log[field]);
            }
          });
        }, 100 * i);
      });
      setTimeout(() => {
        props.setShowPropertyInfo(true);
      }, 100 * fieldsToSetGroups.length);
    };

    useEffect(() => {
      fetchLogs();
    }, []);

    return (
      <CSSTransition
        in={props.showSearchLogs}
        timeout={{ enter: 300, exit: 100 }}
        classNames={COMPONENT_NAME}
        nodeRef={nodeRef}
        unmountOnExit
        mountOnEnter
      >
        <div className={COMPONENT_NAME} ref={nodeRef}>
          <div className="advancedCheckSearchHistoryHeader">
            <h4>Recent searches</h4>
            <div>
              <CloseOutlined
                onClick={() => props.setShowSearchLogs(false)}
                rev={null}
              />
            </div>
          </div>
          <Divider style={{ margin: "3px 0" }} />
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            {groupedLogs.map((group, dateIndex) => (
              <div key={`advanced-check-search-history-date-${dateIndex}`}>
                {/* Todayness */}
                <div className="advancedCheckSearchHistoryTodayness">
                  {group.todayness}:&nbsp;
                  {moment(group.logs[0].createdAt).format("MMM DD, YYYY")}
                </div>
                {/* Logs */}
                {group.logs.map((log, logIndex) => (
                  <div
                    key={`advanced-check-search-history-item-${dateIndex}-${logIndex}`}
                    onClick={() => handleLogClick(dateIndex, logIndex, log)}
                    className={`advancedCheckSearchHistoryItem ${
                      selectedLogIndex === `${dateIndex}-${logIndex}`
                        ? "selected"
                        : ""
                    }`}
                  >
                    {/* Show short result (just address) */}

                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <StressedAddress address={log.displayedAddress} />
                      </div>
                      <Popover
                        content={<AdvancedCheckLogFullResult info={log} />}
                        title="Search details"
                      >
                        <img
                          src={"/images/ellipsis.svg"}
                          alt="ellipsis"
                          style={{ height: "1em" }}
                        />
                      </Popover>
                    </div>
                  </div>
                ))}
                {/* Divider */}
                <Divider style={{ margin: "3px 0" }} />
              </div>
            ))}
          </div>
        </div>
      </CSSTransition>
    );
  }
);
