import { chain } from "lodash";
import { ReactNode } from "react";

import * as com from "../Common.js";

import { CurrentStageValues } from "./utils";

interface SubMenuItem {
  name: string;
  step: string;
  tabKey: string;
  disabled?: boolean; // Whether the step is disabled/unclickable
  hidden?: boolean; // Whether the step is hidden, can be used for testing
  icon?: ReactNode | (() => ReactNode);
}

interface MenuItem {
  name: string;
  step: string;
  tabKey: string; // has to be unique and non-empty
  subMenus?: SubMenuItem[];
  hidden?: boolean; // Whether the step is hidden, can be used for testing
}

// The overview menu item is always the first item in the menu
const overviewMenuItem: MenuItem = {
  name: "OVERVIEW",
  step: "0-0",
  tabKey: "loanSummary",
};

const closingMenuItem: MenuItem = {
  name: "CLOSING",
  step: "3-0",
  tabKey: "closing",
};

/**
 * The list of menu items in the stepper menu
 *
 * The step is a string in the format of "x-y" where x is the main menu item and y is the sub menu item.
 * This list contains all the menu items, including the hidden/non-applicable ones.
 */
export const allMenuItems: MenuItem[] = [
  overviewMenuItem,
  {
    name: "APPLICATION",
    step: "1-0",
    tabKey: "1003View",
    subMenus: [
      {
        name: "1003",
        step: "1-1",
        tabKey: "1003View",
      },
      {
        name: "Borrower application",
        step: "1-2",
        tabKey: "borrowerView",
      },
      {
        name: "Borrower",
        step: "1-3",
        tabKey: "hardMoneyLoanBorrower",
      },
      {
        name: "Property and loan",
        step: "1-4",
        tabKey: "hardMoneyLoanProperty",
      },
      {
        name: "Experience",
        step: "1-5",
        tabKey: "hardMoneyLoanExperience",
      },
      {
        name: "Declarations",
        step: "1-6",
        tabKey: "hardMoneyLoanDeclarations",
      },
      {
        name: "Credit pull",
        step: "1-7",
        tabKey: "creditReport",
      },
      {
        name: "Rate recommendation",
        step: "1-8",
        tabKey: "rates",
      },
      {
        name: "Pre-qual letter",
        step: "1-9",
        tabKey: "prequalLetter",
      },
    ],
  },
  {
    name: "PROCESSING",
    step: "2-0",
    tabKey: "documentChecklist",
    subMenus: [
      {
        name: "Escrow info",
        step: "2-1",
        tabKey: "escrowInfo",
        hidden: true,
      },
      {
        name: "Documents",
        step: "2-2",
        tabKey: "documentChecklist",
      },
      {
        name: "Underwriting checklist",
        step: "2-3",
        tabKey: "UWList",
      },
      {
        name: "Appraisal info",
        step: "2-4",
        tabKey: "appraisalInfo",
        hidden: true,
      },
      {
        name: "Pre-approval letter",
        step: "2-5",
        tabKey: "preapprovalLetter",
        hidden: true,
      },
      {
        name: "Tasks",
        step: "2-6",
        tabKey: "tasks",
      },
      {
        name: "Communications",
        step: "2-7",
        tabKey: "comment",
      },
      {
        name: "FNM 3.4",
        step: "2-8",
        tabKey: "mismo",
      },
    ],
  },
];

export interface IInputItem {
  key: string;
  label: string;
  icon?: ReactNode;
  children?: IInputItem[];
  type?: string;
  disabled?: boolean;
}

/**
 * Get the applicable menu items based on the data input, different loan types have different data.
 * @param items - The data items
 */
export const getApplicableMenuItems = (
  items: IInputItem[],
  currentStage: CurrentStageValues
): MenuItem[] => {
  const flattenedItems = chain(items)
    .map((item) => {
      if (item.children) {
        return item.children;
      }
      return [item];
    })
    .flatten()
    .value();

  // Initialize the applicable items with the overview menu item
  const applicableItems: MenuItem[] = [overviewMenuItem];

  // Go through all the menu items and add the applicable sub menu items to the applicable items
  allMenuItems.forEach((item) => {
    const applicableSubMenuItems = [];
    if (item.subMenus) {
      item.subMenus.forEach((subMenuItem) => {
        const matchingSourceItem = flattenedItems.find(
          (flattenedItem) => flattenedItem.key === subMenuItem.tabKey
        );
        if (!!matchingSourceItem) {
          let itemToAdd = subMenuItem;
          if (matchingSourceItem.hasOwnProperty("disabled")) {
            const disabled = matchingSourceItem.disabled;
            itemToAdd = { ...subMenuItem, disabled };
          }
          if (matchingSourceItem.icon) {
            itemToAdd = { ...itemToAdd, icon: matchingSourceItem.icon };
          }
          applicableSubMenuItems.push(itemToAdd);
        }
      });
    }
    if (applicableSubMenuItems.length > 0) {
      applicableItems.push({ ...item, subMenus: applicableSubMenuItems });
    }
  });
  if (currentStage === "Closing") {
    applicableItems.push(closingMenuItem);
  }
  return applicableItems;
};
