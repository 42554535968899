import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useState,
  useEffect,
} from "react";
import {
  getOriginalFilePath,
  getTemplateFilePath,
  keyToProgram,
} from "./programs";
import { IProgram, LoanOfficerInfo, RealtorInfo } from "./types";
import { Button, Row, Typography } from "antd";

const { Paragraph } = Typography;

interface FlyerGeneratorProps {
  program: IProgram;
  showLOInfo: boolean;
  loInfo: LoanOfficerInfo;
  showAddressInfo: boolean;
  addressInfo?: string;
  showRealtorInfo: boolean;
  realtorInfo?: RealtorInfo;
}

export const FlyerGenerator = forwardRef((props: FlyerGeneratorProps, ref) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [previewImage, setPreviewImage] = useState("");
  const imageSrc = getTemplateFilePath(
    props.program.order,
    props.program.fileName
  );
  // Set to true when redrawing the image
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    drawTextAndPreview();
  }, [
    props.program,
    props.showRealtorInfo,
    props.showAddressInfo,
    props.showLOInfo,
  ]);

  const drawTextAndPreview = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      console.log("redrawing");
      setLoading(true);
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);

      const disclaimer1 = `All special programs are subject to underwriting approval verification, guideline change without advance notice.`;
      const disclaimer2 = `Contact me to clarify your qualification of the below program for this address.This does not represent credit decision.`;

      const centerX = canvas.width / 2;

      const fontFamily = "Inter";

      if (props.showRealtorInfo) {
        // Realtor info in left half
        const lStartX = 100;
        const lStartY = 130;
        const lLineHeight = 24;
        ctx.textAlign = "left";
        ctx.font = `600 20px ${fontFamily}`;
        ctx.fillStyle = "black";

        const leftContents = [
          "Realtor",
          props.realtorInfo?.name,
          "ID# " + props.realtorInfo?.licenseNumber,
          props.realtorInfo?.email,
          props.realtorInfo?.phone,
        ];
        leftContents.forEach((content, index) => {
          ctx.fillText(content, lStartX, lStartY + index * lLineHeight);
        });

        // LO info in right half
        const RightStartingX = 100;
        const RightStartingY = 130;
        const rightLineHeight = 24;
        ctx.textAlign = "right";
        ctx.font = `600 20px ${fontFamily}`;
        ctx.fillText(
          "Loan Officer",
          canvas.width - RightStartingX,
          RightStartingY
        );
        ctx.fillText(
          props.loInfo.name,
          canvas.width - RightStartingX,
          RightStartingY + rightLineHeight
        );
        ctx.fillText(
          "NMLS# " + props.loInfo.nmls,
          canvas.width - RightStartingX,
          RightStartingY + 2 * rightLineHeight
        );
        ctx.fillText(
          props.loInfo.email,
          canvas.width - RightStartingX,
          RightStartingY + 3 * rightLineHeight
        );
        ctx.fillText(
          props.loInfo.phone,
          canvas.width - RightStartingX,
          RightStartingY + 4 * rightLineHeight
        );
      } else {
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = `600 36px ${fontFamily}`;
        ctx.fillStyle = "black";
        ctx.fillText(props.loInfo.name, centerX, 150);
        ctx.font = `600 24px ${fontFamily}`;
        ctx.fillStyle = "black";
        ctx.fillText("NMLS# " + props.loInfo.nmls, centerX, 190);
        ctx.fillText(
          props.loInfo.email + " | " + props.loInfo.phone,
          centerX,
          220
        );
      }

      if (props.showAddressInfo) {
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = `600 30px ${fontFamily}`;
        ctx.fillStyle = "#5D94FF";
        ctx.fillText(props.addressInfo, centerX, 260);
      }

      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.font = `400 12px ${fontFamily}`;
      ctx.fillStyle = "black";
      ctx.fillText(disclaimer1, centerX, 295);
      ctx.fillText(disclaimer2, centerX, 309);

      const imageURL = canvas.toDataURL();
      setPreviewImage(imageURL);
      setLoading(false);
    };
  };

  const downloadFlyer = () => {
    if (!(loading || !previewImage)) {
      const link = document.createElement("a");
      //link.download = `${props.program.order}_${props.program.fileName}`;
      link.download = `${props.program.order.toString().padStart(2, "0")}_${
        props.program.fileName
      }`;
      link.href = previewImage;
      link.click();
    }
  };

  useImperativeHandle(ref, () => ({
    drawTextAndPreview,
    downloadFlyer,
  }));

  return (
    <div className="flex flex-column align-items-center">
      <Row className="justify-content-between">
        <Paragraph>
          <strong>Flyer Preview</strong>
        </Paragraph>
        <Button
          disabled={loading || !previewImage}
          onClick={() => downloadFlyer()}
          style={{ display: "none" }}
        >
          Download
        </Button>
      </Row>
      <Row className="justify-content-center">
        {loading && <div>Loading...</div>}
        {!loading && (
          <img
            className="w-100"
            src={
              props.showRealtorInfo || props.showAddressInfo || props.showLOInfo
                ? previewImage
                : getOriginalFilePath(
                    props.program.order,
                    props.program.fileName
                  )
            }
          />
        )}
      </Row>
      <canvas style={{ display: "none" }} ref={canvasRef}></canvas>
    </div>
  );
});

export default FlyerGenerator;
