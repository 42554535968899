import React, { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Modal, Tag, Switch, Button, Radio, DatePicker, InputNumber, Space, Select } from 'antd'
import dayjs from 'dayjs'
import { CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import Chart from "react-apexcharts"
import * as com from "../../Common.js";
import * as echarts from "echarts";
import './CompareProductModal.css'
export default function CompareProductModal(props) {
    const {
        open, tableData, cancelSelect, onCancel
    } = props
    const chartRef=useRef(null)
    const chartInstanceRef = useRef(null)
    const [newTableData, setNewTableData] = useState([])
    const [colorsArr, setColorsArr] = useState([])
    const [showChart, setShowChart] = useState(true)
    useEffect(()=>{
        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.dispose()
            }
        }
    },[])
    useEffect(() => {
        if (showChart) {
            setTimeout(() => {
                if (newTableData.length > 0 && colorsArr.length > 0) {
                    initializeChart(newTableData, colorsArr)
                }
            }, 0)
        }
    }, [newTableData, colorsArr, showChart])
    const getAmortization = (value) => {
        switch (value) {
            case 'arm56':
                return 'ARM 5/6'
            case 'arm76':
                return 'ARM 7/6'
            case 'arm106':
                return 'ARM 10/6'
            default:
                return 'Fixed'
        }
    }
    useEffect(() => {
        if (open) {
            const formatTableData = tableData.map(data => {
                const monthlyRate = (data.base_rate / 100) / 12; // Convert annual rate to monthly
                const totalPayments = (data.Term) * 12; // Total number of monthly payments
                const monthlyPayment = data.loanAmount * monthlyRate * Math.pow(1 + monthlyRate, totalPayments) / (Math.pow(1 + monthlyRate, totalPayments) - 1);

                let remainingBalance = data.loanAmount;
                let totalInterestPaid = 0;
                const yearlyInterestExpense = [];

                for (let month = 1; month <= totalPayments; month++) {
                    const interestForMonth = remainingBalance * monthlyRate; // Interest for this month
                    totalInterestPaid += interestForMonth; // Cumulative interest
                    const principalForMonth = monthlyPayment - interestForMonth; // Principal portion of this payment
                    remainingBalance -= principalForMonth; // Reduce balance by principal portion
            
                    // At the end of each year, add the total interest to the array
                    if (month % 12 === 0) {
                        const year = new Date().getFullYear() + Math.floor(month / 12); // Calculate the year dynamically
                        yearlyInterestExpense.push({
                            year: year,
                            totalExpenses: totalInterestPaid // Sum the interest of this year
                        });
                    }
                }
                return {
                    ...data,
                    Lender: data.Lender.replace(/([a-z])([A-Z])/g, '$1 $2'),
                    loanAmount: `$${com.commaize(data.loanAmount)}`,
                    Term: `${data.Term} year`,
                    MortgageAmortization: getAmortization(data.MortgageAmortization),
                    monthly_payment: `$${com.commaize(data.monthly_payment)}`,
                    rate: `${data.rate}%`,
                    APR: `${data.APR}%`,
                    closing_cost: `$${com.commaize(data.closing_cost)}`,
                    PMICost: `${data.PMICost && data.PMICost !== 0 ? 'YES' : 'NO'}`,
                    prepaid_interest: `$${com.commaize(data.prepaid_interest)}`,
                    annualExpenses: yearlyInterestExpense
                }
            })
            console.log(formatTableData)
            setNewTableData(formatTableData)
            const colors = tableData.map(() => getRandomColor())
            setColorsArr(colors)
        }
    },[open])
    const initializeChart = (chartData, colors) => {
        if (chartInstanceRef.current) {
            chartInstanceRef.current.dispose()
        }
        chartInstanceRef.current = echarts.init(chartRef.current)
        const allData = chartData.flatMap(data => data.annualExpenses.map(expense => expense.totalExpenses))
        const maxLength = Math.max(...chartData.map(item => item.annualExpenses.length))
        const minValue = Math.min(...allData)
        const maxValue = Math.max(...allData)
        const options = {
            tooltip: {
                show: false,
            },
            grid: {
                top: 34,
                bottom: 44,
                left: 90,
                right: 0
            },
            xAxis: {
                type: 'category',
                data: Array.from({ length: maxLength }, (_, index) => new Date().getFullYear() + index),
                axisLabel: {
                    color: '#000',
                    fontSize: 14,
                    fontFamily: 'Inter',
                    interval: 5
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: '#D9D9D9',
                        width: 2,
                    }
                }
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: '#000',
                    fontSize: 14,
                    fontFamily: 'Inter',
                    formatter: function (value) {
                        return `$${com.commaize(value)}`
                    }
                },
                splitLine: {
                    lineStyle: {
                        color: '#D9D9D9',
                        width: 1,
                    }
                },
                // min: 0,
                // max: maxValue,
                splitNumber: 5,
            },
            legend: {
                show: false,
            },
            series: chartData.map((v, index) => {
                return {
                    name: v.program,
                    type: 'line',
                    smooth: true,
                    symbol: 'none',
                    lineStyle: {
                        color: colors[index],
                        width: 2,
                    },
                    data: v.annualExpenses.map(expense => expense.totalExpenses)
                }
            })
        }

        chartInstanceRef.current.setOption(options)
        console.log(chartInstanceRef.current)
        chartInstanceRef.current.on('mousemove', function () {
            console.log('move mouse')
        })
    }
    const getRandomColor = () => {
        const letters = '0123456789ABCDEF'
        let color = '#'
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)]
        }
        return color
    }
    const deleteRates = (indexToDelete, key) => {
        cancelSelect(key)
        setNewTableData((prevData) => prevData.filter((_, index) => index !== indexToDelete));
    }
    const reminderCancel = () => {
        if (chartInstanceRef.current) {
            chartInstanceRef.current.dispose()
        }
        onCancel()
    }

    const changeChartStatus = () => {
        setShowChart(!showChart)
    }
    let tableHeaders = [
        { label: 'Product', value: 'program' },
        { label: 'Lender', value: 'Lender' },
        { label: 'Loan size', value: 'loanAmount' },
        // { label: 'Downpayment', value: 'Downpayment' },
        // { label: 'Type', value: 'Type' },
        { label: 'Term', value: 'Term' },
        { label: 'Amortization', value: 'MortgageAmortization' },
        { label: 'Mo.payment', value: 'monthly_payment' },
        { label: 'Base rate', value: 'rate' },
        { label: 'APR', value: 'APR' },
        { label: 'Closing cost', value: 'closing_cost' },
        // { label: 'Remaning credits', value: 'Remaning credits' },
        // { label: 'Cash to close', value: 'Cash to close' },
        // { label: 'Total cost over term', value: 'Total cost over term' },
        // { label: 'Cost over 20 years', value: 'Cost over 20 years' },
        // { label: 'Reserves required', value: 'Reserves required' },
        { label: 'Needs PMI', value: 'PMICost' },
        { label: 'Prepaid interest', value: 'prepaid_interest'},
    ]
    return (
        <>
            <Modal
                className='compare-product-modal'
                maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.80)' }}
                // destroyOnClose
                maskClosable={true}
                width={994}
                open={open}
                title=""
                onCancel={reminderCancel}
                footer={null}
            >
                <div className='compare-product-title'>Compare product</div>
                <div className='compare-product-content'>
                    <div className='compare-product-chart'>
                        <div className='compare-product-chart-top'>
                            <span className='compare-product-chart-title'>Loan expenses chart</span>
                            <span className='compare-product-chart-hide' onClick={changeChartStatus}>{showChart ? 'Hide' : 'Show'} chart</span>
                        </div>
                        {
                            showChart && (
                                <div className='compare-product-chart-box'>
                                    <div
                                        ref={chartRef}
                                        style={{ 
                                            width: '100%',
                                            height: 240
                                        }}
                                    />
                                </div>
                            )
                        }
                    </div>
                    <div className='compare-product-table'>
                        <div className='compare-product-table-header compare-table-column'>
                            {
                                tableHeaders.map((column, index) => (
                                    <div key={index} className={`compare-table-column-item ${index === 0 ? 'compare-table-column-item-first' : ''}`}>
                                        {column.label}
                                    </div>
                                ))
                            }
                        </div>
                        {
                            newTableData.map((row, i) => (
                                <div key={row.key} className='compare-product-table-content-item compare-table-column'>
                                    {
                                        tableHeaders.map((column, index) => (
                                            <div key={index} className={`compare-table-column-item ${index === 0 ? 'compare-table-column-item-first' : ''}`}>
                                                <div style={{width: '100%'}}>
                                                    {index === 0 ? (
                                                        <>
                                                            <div className='rate-tag-bar'>
                                                                <div className='rate-tag' style={{ backgroundColor: colorsArr[i]}}>Rate {i + 1}</div>
                                                                {newTableData.length !== 1 && <div className='rate-tag-close' onClick={() => deleteRates(i, row.key)}><CloseOutlined style={{fontSize: 12, color: '#6E6E70'}} /></div>}
                                                            </div>
                                                            <div style={{fontWeight: 600}} className='compare-table-column-item-value'>{row.Lender}</div>
                                                            <div className='compare-table-column-item-value'>{row.Name}</div>
                                                        </>
                                                    ) : (
                                                        <div className='compare-table-column-item-value'>{row[column.value] ? row[column.value] : ''}</div>
                                                    )}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </Modal>
        </>
    )
}