import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

import Row from 'react-bootstrap/Row'
import Steps from 'awesome-steps'
import Col from 'react-bootstrap/Col'
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux'
import { UpdateChangeProgress, UpdateChangeLastTouch } from "../Store/progress"
import { getCurrentState } from '../Store'
import * as act from "../Store/actions"
import * as com from "../Common"

const mapStateToProps = (state) => {

  return {
    application: state.application,
    progress: state.progress,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateChangeProgress: (step, stage) => {
      dispatch(UpdateChangeProgress(step, stage))
  },
  updateChangeLastTouch: (t) => {
      dispatch(UpdateChangeLastTouch(t))
  },    
  changeReviewedLoans: (value) => {
    dispatch(act.ChangeReviewedLoans(value))
},  
});

class Introduction extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loimage: "/avatar.png",
      loname: "",
      lophone: "",
      loemail: "",
      lonmls: "",
      loaded: false,
    }
    this.loadLoanOfficer()

    }
  

  loadLoanOfficer = () => {
    let token = com.getUserToken()

    fetch('/borrower/getloanofficer', {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-Borrower": this.props.borrowerid,        
      }
    }).then(
      response => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
          this.setState({ loaded: false })
          window.document.dispatchEvent(new Event('reauthenticate'), "");

          return;
        }
        // Examine the text in the response
        response.json().then(js => {

          if (js.Status !== "OK") {
            console.log("Error: " + js.Status)
          } else {
            this.setState({ loimage: js.Image, loname: js.Name, loemail: js.Email, lophone: js.Phone, lonmls: js.Nmls, loaded: true })
          }
          this.setState({ loaded: true })
        });
      }
    )
      .catch(function (err) {
        console.log('Fetch Error :', err);
        this.setState({ loaded: false })

      });
  }
  render() {


    return (
      <div className="text-left mb-3">

        <h2 className="heading text-wrap">Welcome to Zeitro!
                </h2>
        <div className="text-left pt-2  ">
          <div className="pb-2 ">
            {this.state.loaded ?
              <div className="viewport">

                <div className="text-left sectionquestion">
                  <Row >
                    <Col md="auto" style={{ minWidth: "100px" }} className="pl-0 text-left">
                      <img alt="Loan officer" style={{ width: "96px", height: "96px", borderRadius: "50%" }}
                        src={this.state.loimage}></img>
                    </Col>
                    <Col className="text-wrap" style={{ marginTop: '0.2em' }}>
                      <div>Your loan officer {this.state.loname} will be with you all steps of the way. The contact information is below:</div>

                      <div >Email: {this.state.loemail} </div>
                      <div >Phone: {this.state.lophone} </div>
                      <div >Nmls license: {this.state.lonmls} </div>
                    </Col>
                  </Row>
                  <div className="mt-2">You can also use the <i className="fa fa-envelope mr-1"></i>Contact Us form in the sidebar on the left. </div>
                </div>
              </div>
              : ""}
            <div className="viewport mt-2 mb-4 ">
              <div className="p-0 hideonphone">
                <div className="sectionquestion">
                  Our team is looking forward to finding you the best mortgage to suit your needs!</div>
                <br />

                <div style={{ fontWeight: 'bold', fontSize: '1.3em' }} >Here is what to expect:</div>
                <div className="text-center w-100">
                  <div style={{ fontSize: '1.3em' }} id="explainsteps" className="mt-3 ml-2 text-left text-wrap w-100">

                    <Steps  id="explainsteps" direction="vertical" current={0} className="mt-3 ml-3">
                      <Steps.Step status="process" title="Tell us about yourself" description="Answer a few general questions that can help us better understand your loan needs. We'll review your liabilities, and ask about your income." />
                      <Steps.Step status="process" title="Select a loan" description="See your prequalified loan offers, and choose your preferred loan. We scan multiple lenders, and find the best choices.  After loan selection, provide more details about your finances and work history" />
                      <Steps.Step status="process" title="Submit the application, and lock your rate. " />
                      <Steps.Step status="process" title="Upload the required supporting documents." />
                      <Steps.Step status="process" title="We submit the application to the lender and keep you updated at every step" />
                      <Steps.Step status="process" title="Enjoy your low rate!" />

                    </Steps>


                  </div>
                </div>
              </div>
              <div className="mt-3 mb-3 text-wrap">
                No need to finish your application in one sitting. The progress is saved as you go.
              </div>
              {isMobileOnly ? 
                <div className="text-wrap">We recommend to fill out this application on a large screen, but feel free to explore on a mobile device</div> : 
              ""}
            </div>
          </div>


        </div>
      </div >
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Introduction)
