import React, { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Select, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";

import * as com from "../../Common";
import * as act from "../../Store/actions";
import { checkAccess } from "../../Auth";
import "./common.css";
import { UR_Owner } from "../../State";

interface CurrentStatusProps {
  currentStatus: string;
  currentStage: string;
  loanID: string;
}

const CurrentStatus = ({ currentStatus, loanID }: CurrentStatusProps) => {
  const currentStage = useSelector(
    (state: any) => state.application.currentStage
  );
  const dispatch = useDispatch();
  const updateApplicationAttribute = (t, verb) => {
    dispatch(act.UpdateApplicationAttribute(t, verb));
  };

  const loanPurpose: string = useSelector(
    (state: any) => state.application?.property?.purpose
  );

  const handleCurrentStatusChange = (
    loanID: string,
    newCurrentStatus: string
  ) => {
    let token = sessionStorage.getItem("ZeitroA");
    let body = {
      LoanID: loanID,
      Key: "currentStatus",
      Value: newCurrentStatus,
      IsAdmin: checkAccess([UR_Owner]) ? true : false,
    };
    fetch("/los/updatepipeline", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        updateApplicationAttribute(newCurrentStatus, "currentStatus");
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
      });
  };

  const items = com.CurrentStatusUtils.getStatusesByStage(
    currentStage,
    loanPurpose
  ).map(({ code, name }, idx) => {
    return {
      value: name,
      key: code,
      label: <div>{com.CurrentStatusUtils.getStatusName(name)}</div>,
    };
  });

  const [statusToDisplay, setStatusToDisplay] = useState(currentStatus);

  useEffect(() => {
    const applicableStatuses = com.CurrentStatusUtils.getStatusesByStage(
      currentStage,
      loanPurpose
    );
    if (!applicableStatuses.some((status) => status.name === currentStatus)) {
      const firstApplicableStatus = applicableStatuses[0].name;
      setStatusToDisplay(firstApplicableStatus);
      handleCurrentStatusChange(loanID, firstApplicableStatus);
    } else {
      setStatusToDisplay(currentStatus);
    }
  }, [currentStatus, currentStage]);

  return (
    <div className="summaryLoansStatusContainer col-3">
      <div
        className="loansStatus"
        style={{
          borderColor:
            com.CurrentStatusUtils.getStatusBorderColor(currentStatus),
          backgroundColor:
            com.CurrentStatusUtils.getStatusBackgroundColor(currentStatus),
        }}
      >
        <Select
          style={{
            backgroundColor:
              com.CurrentStatusUtils.getStatusBackgroundColor(currentStatus),
            color: com.CurrentStatusUtils.getStatusTextColor(currentStatus),
            border: "none",
          }}
          value={com.CurrentStatusUtils.getStatusName(statusToDisplay)}
          onChange={(value) => {
            handleCurrentStatusChange(loanID, value);
          }}
          options={items}
          suffixIcon={
            <img src="/images/stepper-menu-arrow-down.svg" alt="down-arrow" />
          }
        />
      </div>
    </div>
  );
};

export default CurrentStatus;
