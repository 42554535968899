import React, { useState, useEffect } from "react";
import "./index.css";
import { Menu, Button, Form, Row, Col, AutoComplete, InputNumber, Spin } from "antd";



const Estimatedclosingfee = () => {
    const [cannotshop, setCannotshop] = useState([])
    const [canshop, setCanshop] = useState([])
    const [loading, setLoading] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)

    useEffect(() => {
        getloanestimatedclosingfee()
    }, [])
    let handleCannotshop = (i, e) => {
        let newFormValues = [...cannotshop]
        newFormValues[i][e.target.name] = e.target.value
        setCannotshop(newFormValues)
    }
  
    let addCannotshop = () => {
        setCannotshop([...cannotshop, { name: "", value: "" }]);
    }
  
    let removeCannotshop = (i) => {
        let newFormValues = [...cannotshop]
        newFormValues.splice(i, 1)
        setCannotshop(newFormValues)
    }
    let handleCanshop = (i, e) => {
        let newFormValues = [...canshop]
        newFormValues[i][e.target.name] = e.target.value
        setCanshop(newFormValues)
    }
  
    let addCanshop = () => {
        setCanshop([...canshop, { name: "", value: "" }])
    }
  
    let removeCanshop = (i) => {
        let newFormValues = [...canshop]
        newFormValues.splice(i, 1)
        setCanshop(newFormValues)
    }

    let saveEstFee = () => {
        let data = {
            cannotshopservices: cannotshop.map(item => ({
                servicename: item.name,
                estimateamount: item.value,
                identifiedprovider: "",
                contactinfo: ""
            })),
            canshopservices: canshop.map(item => ({
                servicename: item.name,
                estimateamount: item.value,
                identifiedprovider: "",
                contactinfo: ""
            }))
        }
        let token = sessionStorage.getItem("ZeitroA");
        setBtnLoading(true)
        fetch("/los/setloanestimatedclosingfee", {
            cache: "no-cache",
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
                Cache: "no-cache",
            },
            body: JSON.stringify(data)
          }).then(response => {
            if (!response.ok) {
                setBtnLoading(false)
            } else {
                setBtnLoading(false)
            }
        }).catch(error => {
            setBtnLoading(false)
        })
    }
    const getloanestimatedclosingfee = () => {
        let token = sessionStorage.getItem("ZeitroA")
        setLoading(true)
        fetch("/los/getloanestimatedclosingfee", {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache",
          },
        })
        .then((response) => {
            setLoading(false)
            if (response.status !== 200) {
                alert(
                    "Looks like there was a problem. Status Code: " + response.status
                );
                return
            }
            response.json().then((js) => {
                if (js.status !== "OK") {
                console.log("Error: " + js.Error)
                } else {
                let data = js.configurations
                if (data.loanEstimatedClosingFee) {
                    if (data.loanEstimatedClosingFee.cannotshopservices.length > 0) {
                        setCannotshop(data.loanEstimatedClosingFee.cannotshopservices.map(item => ({ name: item.servicename, value: item.estimateamount })))
                    } else {
                        setCannotshop([
                            { name: "Appraisal Fee", value: 750 },
                            { name: "Credit Report", value: 65 },
                            { name: "Flood Certification", value: 10 },
                            { name: "HOA Certification Fee", value: 350 },
                            { name: "Lender Doc Prep", value: 75 },
                            { name: "Tax Service Fee", value: 80 },
                        ])
                    }
                    if (data.loanEstimatedClosingFee.canshopservices.length > 0) {
                        setCanshop(data.loanEstimatedClosingFee.canshopservices.map(item => ({ name: item.servicename, value: item.estimateamount })))
                    } else {
                        setCanshop([
                            { name: "Pest Inspection", value: 175 },
                            { name: "Title-Closing/Escrow Fee", value: 475 },
                            { name: "Title-Courier Fee", value: 35 },
                            { name: "Title-Endorsement Fee", value: 25 },
                            { name: "Title-Lender's Title Insurance", value: 645 },
                            { name: "Title-Loan Tie in Fee", value: 280 },
                            { name: "Title-Notary Fee", value: 200 },
                            { name: "Title-Processing Fees to Escrow", value: 15 },
                            { name: "Title-Wire Fee to Title", value: 15 },
                        ])
                    }
                }
                }
            })
        })
        .catch(function (err) {
            setLoading(false)
            console.log("Fetch Error :-S", err);
        })
    }
    return (
        <Spin wrapperClassName="estimatedclosingfee-loading" spinning={loading}>
            <div className='estimatedclosingfee-wrapper'>
            <div className='estimatedclosingfee-title'>
                Loan estimated closing fee prefill
            </div>
            <div className='estimatedclosingfee-subtitle'>
                Please prefill these loan fees to the best of your knowledge. Your team member will be able to make adjustments when calculating rates on our platform.
            </div>
            <div className='estimatedclosingfee-form'>
                <div className="edit-fees-form-label">Services you <span style={{fontWeight: 600}}>CANNOT</span> shop for</div>
                <Row gutter={56}>
                    {cannotshop.map((element, index) => (
                        <Col sm={10} span={24}>
                            <Row gutter={2}>
                                <Col sm={14} span={24}>
                                    <AutoComplete
                                        name="name"
                                        className="estFee-select"
                                        // options={servicesOptions}
                                        style={{
                                            width: "100%",
                                            height: 50,
                                            marginBottom: 12
                                        }}
                                        value={element.name || ""}
                                        filterOption={(inputValue, option) =>
                                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={(value) => handleCannotshop(index, { target: { name: 'name', value } })}
                                    />
                                </Col>
                                <Col sm={8} span={24}>
                                    <InputNumber
                                        className="estFee-input"
                                        name="value"
                                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                        value={element.value || ""}
                                        onChange={(value) => handleCannotshop(index, { target: { name: "value", value } })}
                                    />
                                </Col>
                                <Col sm={2} span={24}>
                                    <div style={{ display: 'flex', alignItems: 'center', height: '100%', paddingLeft: 6, paddingBottom: 12 }}>
                                        <img style={{ cursor: 'pointer' }} src="/images/delete.svg" onClick={() => removeCannotshop(index)} />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    ))}
                </Row>
                <div className="estFee-add-more" onClick={() => addCannotshop()}>Add more</div>
                <div className="edit-fees-form-label">Services you <span style={{fontWeight: 600}}>CAN</span> shop for</div>
                <Row gutter={56}>
                    {canshop.map((element, index) => (
                        <Col sm={10} span={24}>
                            <Row gutter={2}>
                                <Col sm={14} span={24}>
                                <AutoComplete
                                    name="name"
                                    className="estFee-select"
                                    // options={servicesOptions}
                                    style={{
                                        width: "100%",
                                        height: 50,
                                        marginBottom: 12
                                    }}
                                    value={element.name || ""}
                                    filterOption={(inputValue, option) =>
                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={(value) => handleCanshop(index, { target: { name: 'name', value } })}
                                />
                                </Col>
                                <Col sm={8} span={24}>
                                <InputNumber
                                    className="estFee-input"
                                    name="value"
                                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                    value={element.value || ""}
                                    onChange={(value) => handleCanshop(index, { target: { name: "value", value } })}
                                />
                                </Col>
                                <Col sm={2} span={24}>
                                <div style={{ display: 'flex', alignItems: 'center', height: '100%', paddingLeft: 6, paddingBottom: 12 }}>
                                    <img style={{ cursor: 'pointer' }} src="/images/delete.svg" onClick={() => removeCanshop(index)} />
                                </div>
                                </Col>
                            </Row>
                        </Col>
                    ))}
                </Row>
                <div className="estFee-add-more" onClick={() => addCanshop()}>Add more</div>
            </div>
            <div><Button type='primary' loading={btnLoading} className="estr-fee-save-btn" onClick={saveEstFee}>Save</Button></div>
        </div>
        </Spin>
        
    )
}

export default Estimatedclosingfee