import { compareToStep, getMainMenuStep } from "./utils";

export const getStepInfoStyle = (step: string, currentStep: string) => {
  return compareToStep(step, currentStep) <= 0 ? { color: "#325CEB" } : {};
};

export const getStepCircleClassName = (
  step: string,
  currentStep: string
): string => {
  return getMainMenuStep(step) < getMainMenuStep(currentStep)
    ? "stepCircle stepPassed"
    : getMainMenuStep(step) === getMainMenuStep(currentStep)
    ? "stepCircle stepCurrent"
    : "stepCircle";
};

export const getSubMenuItemButtonStyle = (
  selectedTabKey: string,
  visitedMenus: string[],
  tabKey: string
): { [styleKey: string]: string } => {
  let color = "#6E6E70";
  if (selectedTabKey === tabKey) {
    color = "#081265";
  } else if (visitedMenus.includes(tabKey)) {
    color = "#222222";
  }
  return { color };
};
