import React, { useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Select, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";

import * as com from "../../Common";
import * as act from "../../Store/actions";
import { checkAccess } from "../../Auth";
import { UR_Owner } from "../../State";
import "./common.css";

interface CurrentStageProps {
  currentStage: string;
  loanID: string;
}

const CurrentStage = ({ currentStage, loanID }: CurrentStageProps) => {
  const dispatch = useDispatch();
  const updateApplicationAttribute = (t, verb) => {
    dispatch(act.UpdateApplicationAttribute(t, verb));
  };
  const handleCurrentStageChange = (loanID, newCurrentStage) => {
    let token = sessionStorage.getItem("ZeitroA");
    let body = {
      LoanID: loanID,
      Key: "currentStage",
      Value: newCurrentStage,
      IsAdmin: checkAccess([UR_Owner]) ? true : false,
    };
    fetch("/los/updatepipeline", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          // this.setState({ showErrorModal: true})
          return;
        }
        console.log(newCurrentStage);
        updateApplicationAttribute(newCurrentStage, "currentStage");
        //   handleStatus(loanID, newStatus)
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
      });
  };
  const items = Object.entries(com.CurrentStage).map(([key, _]) => {
    return {
      value: key,
      key: key,
      label: <div>{key}</div>,
    };
  });
  return (
    <div className="summaryLoansStageContainer col-3">
      <div className="loansStage">
        <Select
          value={currentStage}
          options={items}
          onChange={(value) => {
            handleCurrentStageChange(loanID, value);
          }}
          suffixIcon={
            <img src="/images/stepper-menu-arrow-down.svg" alt="down-arrow" />
          }
        />
      </div>
    </div>
  );
};

export default CurrentStage;
